import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  IconButton,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseRequest, CaseAttachment, ClientOffer } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import FileSaver from 'file-saver';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  ReferentialCode,
  AttachmentTypeCode,
  OfferTypeCode,
  Constants,
  WorkflowFormActionTypeCode
} from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { AppUser } from '../../interfaces/AppUser';
import CaseDetailsCaseRequests from '../cases/CaseDetailsCaseRequests';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { Utils } from '../../helpers/Utils';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { OfferType } from '../../enums/OfferType';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { PersonType } from '../../enums/PersonType';
import moment from 'moment';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ICaseDetailsCaseTabOtherServiceFormState {
  otherServiceFormData: CaseRequest;
  attachments: CaseAttachment[];
  caseAttachments: CaseAttachment[];
  isLoading: boolean;
  attachType: IReferential;
  hasRights: boolean;
  executing: boolean;
  to: string[];
  selectedCaseAttachments: CaseAttachment[];
  clientOffers: ClientOffer[];
  selectedClientOffers: ClientOffer | null;
  hasInvoice: boolean | undefined;
  hasOffer: boolean | undefined;
  editorState: EditorState;
  hasEmailTemplate: boolean;
}

export interface ExternalCaseDetailsCaseTabOtherServiceFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabOtherServiceFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ExternalCaseDetailsCaseTabOtherServiceFormProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabOtherServiceForm extends React.PureComponent<
  ICaseDetailsCaseTabOtherServiceFormProps,
  ICaseDetailsCaseTabOtherServiceFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialServie!: IReferentialService;
  private vehicleService!: IVehicleService;
  static contextType = ApplicationContext;
  state = {
    otherServiceFormData: {},
    caseAttachments: [] as CaseAttachment[],
    isLoading: true,
    hasRights: false,
    executing: false,
    to: [] as string[],
    selectedCaseAttachments: [] as CaseAttachment[],
    clientOffers: [] as ClientOffer[],
    selectedClientOffers: null,
    hasInvoice: undefined,
    hasOffer: undefined,
    editorState: EditorState.createEmpty()
  } as ICaseDetailsCaseTabOtherServiceFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseOtherServiceForm();
      }
    );
  }

  stringToArray = (input: string): string[] => {
    if (input === null || input === undefined || input === '') {
      return [];
    }

    return input.split(';');
  };

  arrayToString = (input: string[]): string => {
    if (input === null || input === undefined) {
      return '';
    }

    return input.join(';');
  };

  isSellOrBuyOffer = (): string | null => {
    const workflowSteps = this.props.caseSettingsState.caseSettings?.workflow.workflowSections;
    if (!isNullOrUndefined(workflowSteps)) {
      const code17Steps = workflowSteps?.find((wfs) => {
        const codeF17 = wfs.workflowSectionSteps.find((x) =>
          x.workflowSectionStepForms.find((wfssf) => wfssf.form.code === 'F17')
        );
        return !isNullOrUndefined(codeF17);
      });
      if (!isNullOrUndefined(code17Steps)) {
        return OfferTypeCode.SELL;
      }
      return OfferTypeCode.BUY;
    }
    return null;
  };

  handleSwitchValue = (fieldName: string) => (e: any) => {
    this.setState({
      ...this.state,
      [fieldName]: e.currentTarget.checked
    });
  };

  renderEmailEditor = () => {
    return (
      <Editor
        editorState={this.state.editorState}
        editorStyle={{
          border: '1px solid #d1d2db',
          borderRadius: '0.29rem',
          height: '250px',
          paddingLeft: '10px'
        }}
        onEditorStateChange={(editorState) =>
          this.setState({
            editorState,
            otherServiceFormData: {
              ...this.state.otherServiceFormData,
              text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            }
          })
        }
        toolbarHidden={true}
        placeholder={this.translatorService.Tranlate(
          'CASE_OTHER_SERVICE_FORM_WRITE_MESSAGE_PLACEHOLDER',
          'Write your message...'
        )}
      />
    );
  };

  loadCaseOtherServiceForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );
    const [attachmentTypes] = await Promise.all([this.appReferentialServie.Get(ref!.baseUrl)]);

    const [caseAttachments] = await Promise.all([
      this.caseService.GetCaseAttachments(
        caseId,
        attachmentTypes.filter((at) => at.isActive).map((item) => item.code!)
      )
    ]);

    let clientOffers = [] as ClientOffer[];
    if (this.props.caseSettingsState.case!.vehicleId) {
      clientOffers = await this.caseService.GetClientOffers(
        caseId,
        this.props.caseSettingsState.case!.vehicleId!
      );

      await Promise.all(
        clientOffers.map(async (co) => {
          const result = await this.vehicleService.GetVehicle(co.vehicleId);
          co.vehicle = result;
        })
      );
      clientOffers = clientOffers.filter((x) => !isNullOrUndefined(x.vehicle));
      // grab only the latest offer
      clientOffers = [
        orderBy(clientOffers, (offer: any) => moment(offer.dateModification), ['desc'])?.[0]
      ];
    }
    //await clientOffers.forEach(async (x) => { x.vehicle = await this.vehicleService.GetVehicle(x.vehicleId) });
    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const template = this.props.workflowForm.workflowFormActions.find((a) => {
      return (
        a.actionType?.code === WorkflowFormActionTypeCode.SEND_MAIL && a.reuseEmailTemplate === true
      );
    });

    let templateSubject = undefined;
    let templateBody = undefined;
    let templateBodyText = undefined;

    if (template) {
      const response = await this.caseService.BuildFilledInEmailTemplate(
        caseId,
        this.props.workflowForm.id,
        this.props.appState.appUser
      );

      if (response && response.length) {
        templateSubject = response[0];
        templateBodyText = response[1];
        const blocksFromHTML = htmlToDraft(templateBodyText);
        templateBody = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
      }
    }

    const caseRequest = {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      text: template ? templateBodyText : '',
      subject: template ? templateSubject : '',
      to: '',
      attachments: [] as CaseAttachment[],
      caseSectionStepFormId: this.props.workflowForm.id,
      cc: this.props.appState.appUser!.email
    } as CaseRequest;

    this.setState({
      hasEmailTemplate: templateBody ? true : false,
      editorState: templateBody
        ? EditorState.createWithContent(templateBody)
        : EditorState.createEmpty(),
      otherServiceFormData: caseRequest,
      attachments: caseRequest.attachments,
      isLoading: false,
      attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
      hasRights: hasRights,
      caseAttachments: caseAttachments.filter((ca) => isNullOrUndefined(ca.caseRequestId)),
      clientOffers: clientOffers
    });
  };

  handleSelectedClientOffer = (
    e: React.ChangeEvent<HTMLSelectElement>,
    newValue: ClientOffer | null
  ) => {
    let hasInvoice = undefined;
    if (this.isSellOrBuyOffer() == OfferTypeCode.SELL) {
      hasInvoice = false;
    }

    const offerType =
      newValue?.offerTypeId === OfferType.SALE
        ? this.translatorService.Tranlate('CASE_OTHER_SERVICE_FORM_SELLING_OFFER', 'Oferta Vanzare')
        : this.translatorService.Tranlate(
            'CASE_OTHER_SERVICE_FORM_PURCHASE_OFFER',
            'Oferta Vanzare'
          );

    this.setState({
      hasOffer: false,
      hasInvoice: hasInvoice,
      selectedClientOffers: newValue,
      otherServiceFormData: {
        ...this.state.otherServiceFormData,
        subject: newValue
          ? `${offerType} ${newValue.id} ${newValue.vehicle?.make?.displayName} ${newValue.vehicle?.model?.displayName} ${newValue.vehicle?.version}`
          : ''
      } as CaseRequest
    });
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const attachments = [...this.state.attachments];

      if (isNullOrUndefined(e.target.files)) {
        return;
      }
      for (let i = 0; i < e.target.files!.length; i++) {
        attachments.push({
          id: 0,
          caseId: this.props.caseSettingsState.case!.id,
          attachmentTypeId: this.state.attachType.id,
          attachmentType: this.state.attachType,
          file: e.target.files![i],
          fileName: e.target.files![i].name,
          uploadDate: new Date(),
          carPhotos: false,
          originalFileName: e.target.files![i].name,
          caseRequestId: null,
          paymentId: null,
          serviceAppointmentId: null,
          caseClientId: null,
          isSelection: false
        });
      }

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        attachments: attachments
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };
  renderGenerateDocsButtons = () => {
    if (this.state.selectedClientOffers) {
      if (this.isSellOrBuyOffer() == OfferTypeCode.SELL)
        return (
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                name="hasInvoice"
                control={
                  <Switch
                    disabled={!this.state.hasRights}
                    checked={this.state.hasInvoice}
                    onChange={this.handleSwitchValue('hasInvoice')}
                    color="primary"
                  />
                }
                label={this.translatorService.Tranlate(
                  'CASE_DETAILS_OTHER_SERVICE_ATTACH_INVOICE',
                  'Ataseaza Factura la email'
                )}
                labelPlacement="end"
                className="mt-3 ml-0 mr-0"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                name="hasOffer"
                control={
                  <Switch
                    disabled={!this.state.hasRights}
                    checked={this.state.hasOffer}
                    onChange={this.handleSwitchValue('hasOffer')}
                    color="primary"
                  />
                }
                label={this.translatorService.Tranlate(
                  'CASE_DETAILS_OTHER_SERVICE_ATTACH_OFFER',
                  'Ataseaza Oferta la email'
                )}
                labelPlacement="end"
                className="mt-3 ml-0 mr-0"
              />
            </Grid>
          </Grid>
        );
      else
        return (
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                name="hasOffer"
                control={
                  <Switch
                    disabled={!this.state.hasRights}
                    checked={this.state.hasOffer}
                    onChange={this.handleSwitchValue('hasOffer')}
                    color="primary"
                  />
                }
                label={this.translatorService.Tranlate(
                  'CASE_DETAILS_OTHER_SERVICE_ATTACH_OFFER',
                  'Ataseaza Oferta la email'
                )}
                labelPlacement="end"
                className="mt-3 ml-0 mr-0"
              />
            </Grid>
          </Grid>
        );
    }
  };
  renderAttachementBoxInAppDocs = () => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <Autocomplete
            id="clientOffers"
            className="m-2"
            multiple={false}
            disabled={!this.state.hasRights}
            options={this.state.clientOffers}
            value={this.state.selectedClientOffers || null}
            onChange={(e: any, newValue: ClientOffer | null) =>
              this.handleSelectedClientOffer(e, newValue)
            }
            getOptionLabel={(option: ClientOffer) => {
              return !isNil(option.id)
                ? `${option.id} ${Utils.FormatDateString(option.date)}
                  ${
                    option.caseClientHistory?.personTypeId === PersonType.PF
                      ? `${option.caseClientHistory?.firstName} ${option.caseClientHistory?.lastName}`
                      : `${option.caseClientHistory?.companyName}`
                  } ${option.vehicle?.make?.displayName} ${option.vehicle?.model?.displayName} ${
                    option.offerValue
                  } ${option.currency}`
                : '';
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {!isNil(option.id)
                  ? `${option.id} ${Utils.FormatDateString(option.date)}
                  ${
                    option.caseClientHistory?.personTypeId === PersonType.PF
                      ? `${option.caseClientHistory?.firstName} ${option.caseClientHistory?.lastName}`
                      : `${option.caseClientHistory?.companyName}`
                  } ${option.vehicle?.make?.displayName} ${option.vehicle?.model?.displayName} ${
                      option.offerValue
                    } ${option.currency}`
                  : ''}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="clientOffers"
                value={this.state.clientOffers}
                label={this.translatorService.Tranlate(
                  'CASE_OTHER_SERVICE_FORM_CASE_ATTACH_DOCS',
                  'Alege oferta pentru a atasa documente suplimentare'
                )}
                fullWidth
              />
            )}
          />
        </Grid>
        {this.renderGenerateDocsButtons()}
      </Grid>
    );
  };
  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <Autocomplete
            id="caseAttachemnts"
            className="m-2"
            multiple={true}
            disableCloseOnSelect
            disabled={!this.state.hasRights}
            options={this.state.caseAttachments}
            value={this.state.selectedCaseAttachments}
            onChange={(e: any, newValue: CaseAttachment[]) =>
              this.setState({ selectedCaseAttachments: newValue })
            }
            getOptionLabel={(option: CaseAttachment) => option.originalFileName || ''}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.originalFileName}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="caseAttachemnts"
                value={this.state.caseAttachments}
                label={this.translatorService.Tranlate(
                  'CASE_OTHER_SERVICE_FORM_CASE_ATTACH',
                  'Atasamente dosar'
                )}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={9}>
          {this.state.attachments.length !== 0
            ? this.state.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        disabled={!this.state.hasRights}
                        //onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.fileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        disabled={!this.state.hasRights}
                        className="text-danger"
                        size="small"
                        onClick={(e) => this.handleDeleteAttachment(attach.id, attach.fileName)}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>

        <Grid item xs={3} container justify="flex-end" direction="column">
          <input
            className="d-none"
            disabled={!this.state.hasRights}
            id={'contained-button-file'}
            multiple
            onChange={(e) => {
              return this.handleUploadAttachment(e);
            }}
            type="file"
          />
          <label htmlFor={'contained-button-file'}>
            <Button
              variant="contained"
              color="primary"
              className="d-block text-center shadow-none"
              component="div"
              disabled={!this.state.hasRights}
            >
              {this.translatorService.Tranlate('CASE_OTHER_SERVICE_FORM_ATTACH', 'Ataseaza')}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  };

  sendEmail = async () => {
    try {
      this.setState({ executing: true, isLoading: true });

      const otherServiceFormData = this.state.otherServiceFormData;

      otherServiceFormData!.id = 0;
      otherServiceFormData.attachments = this.state.attachments;

      for (const ca of this.state.selectedCaseAttachments) {
        const file = await this.caseService.GetCaseAttachment(ca.fileName);
        otherServiceFormData.attachments.push({
          id: ca.id,
          caseId: this.props.caseSettingsState.case!.id,
          attachmentTypeId: this.state.attachType.id,
          attachmentType: this.state.attachType,
          file: new File([file], ca.originalFileName),
          fileName: ca.originalFileName,
          uploadDate: new Date(),
          carPhotos: false,
          originalFileName: ca.originalFileName,
          caseRequestId: null,
          paymentId: null,
          serviceAppointmentId: null,
          caseClientId: null,
          isSelection: false
        });
      }

      if (this.state.selectedClientOffers && (this.state.hasInvoice || this.state.hasOffer)) {
        otherServiceFormData.caseClientHistoryId =
          this.state.selectedClientOffers.caseClientHistoryId;
        otherServiceFormData.gatewayServiceUrl = Constants.apiRoot;
        otherServiceFormData.hasInvoice = isNullOrUndefined(this.state.hasInvoice)
          ? null
          : this.state.hasInvoice;
        otherServiceFormData.hasOffer = isNullOrUndefined(this.state.hasOffer)
          ? null
          : this.state.hasOffer;
        otherServiceFormData.calculationId =
          this.props.caseSettingsState.case?.caseCalculation?.id || null;
        otherServiceFormData.userOrganizationId = this.props.appState.appUser!.organization?.id;
        otherServiceFormData.offerId = this.state.selectedClientOffers.id;
        otherServiceFormData.vehicleId = this.state.selectedClientOffers.vehicleId;
      }
      const request = await this.caseService.AddCaseRequest(otherServiceFormData);

      await this.submitForm(request.caseId, request.caseSectionStepFormId, null);

      this.setState({
        editorState: EditorState.createEmpty(),
        otherServiceFormData: {
          id: 0,
          caseId: this.props.caseSettingsState.case!.id,
          text: '',
          subject: '',
          to: '',
          attachments: [] as CaseAttachment[],
          caseSectionStepFormId: this.props.workflowForm.id,
          hasInvoice: false,
          hasOffer: false
        } as CaseRequest,
        attachments: [],
        selectedCaseAttachments: [],
        selectedClientOffers: null
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false, isLoading: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  rendeOtherServiceForm = () => {
    const options: string[] = [];

    return isNullOrUndefined(this.state.otherServiceFormData) ? null : (
      <ValidatorForm
        onSubmit={(e) => {
          this.sendEmail();
        }}
      >
        <div className="m-3 text-center">
          {/* <TextValidator fullWidth
                        id="sendTo"
                        name="sendTo"
                        disabled={!this.state.hasRights}
                        className="mb-3"
                        label={this.translatorService.Tranlate("CASE_OTHER_SERVICE_FORM_TO_PLACEHOLDER", "catre")}
                        value={this.state.otherServiceFormData.to}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                otherServiceFormData: {
                                    ...this.state.otherServiceFormData,
                                    to: e.target.value
                                }
                            });
                        }
                        }
                        //variant="outlined"
                        validators={['required', 'isEmail']}
                        errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu"),
                        this.translatorService.Tranlate("VALIDATORS_EMAIL", "E-mail invalid")]}
                    /> */}

          <Autocomplete
            multiple
            id="to"
            options={options}
            disabled={!this.state.hasRights}
            value={this.stringToArray(this.state.otherServiceFormData.to)}
            freeSolo
            onChange={(e: any, newValue: any) => {
              this.setState({
                otherServiceFormData: {
                  ...this.state.otherServiceFormData,
                  to: this.arrayToString(newValue)
                }
              });
            }}
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="to"
                value={this.state.otherServiceFormData.to}
                label={this.translatorService.Tranlate(
                  'CASE_OTHER_SERVICE_FORM_TO_PLACEHOLDER',
                  'Catre'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                  this.translatorService.Tranlate('VALIDATORS_EMAIL', 'E-mail invalid')
                ]}
              />
            )}
          />

          <Autocomplete
            multiple
            id="cc"
            disabled={!this.state.hasRights}
            options={options}
            value={this.stringToArray(this.state.otherServiceFormData.cc)}
            freeSolo
            onChange={(e: any, newValue: any) => {
              this.setState({
                otherServiceFormData: {
                  ...this.state.otherServiceFormData,
                  cc: this.arrayToString(newValue)
                }
              });
            }}
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="cc"
                value={this.state.otherServiceFormData.cc}
                label={this.translatorService.Tranlate(
                  'CASE_OTHER_SERVICE_FORM_TO_PLACEHOLDER_CC',
                  'Cc'
                )}
                fullWidth
                //validators={['required']}
                //errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu"),
                //this.translatorService.Tranlate("VALIDATORS_EMAIL", "E-mail invalid")]}
              />
            )}
          />

          <TextValidator
            fullWidth
            id="emailSubject"
            name="emailSubject"
            disabled={!this.state.hasRights}
            className="mb-3"
            label={this.translatorService.Tranlate(
              'CASE_OTHER_SERVICE_FORM_EMAIL_SUBJECT_PLACEHOLDER',
              'subiect'
            )}
            value={this.state.otherServiceFormData.subject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({
                otherServiceFormData: {
                  ...this.state.otherServiceFormData,
                  subject: e.target.value
                }
              });
            }}
            //variant="outlined"
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
          />
          {this.state.hasEmailTemplate ? (
            this.renderEmailEditor()
          ) : (
            <TextValidator
              fullWidth
              id="textComment"
              name="textComment"
              disabled={!this.state.hasRights}
              multiline
              rows={8}
              placeholder={this.translatorService.Tranlate(
                'CASE_OTHER_SERVICE_FORM_WRITE_MESSAGE_PLACEHOLDER',
                'Write your message...'
              )}
              value={this.state.otherServiceFormData.text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  otherServiceFormData: {
                    ...this.state.otherServiceFormData,
                    text: e.target.value
                  }
                });
              }}
              variant="outlined"
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          )}
          {this.renderAttachmentBox()}
          {this.renderAttachementBoxInAppDocs()}
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
        </Button>
      </ValidatorForm>
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialServie = (this.context as AppContext).referentialService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeOtherServiceForm()}</div>
        ) : null}
        <Divider></Divider>
        {this.state.executing ? null : (
          <CaseDetailsCaseRequests
            {...this.props}
            formId={this.props.workflowForm.id}
            caseRequests={null}
            carfixOfferComments={false}
          ></CaseDetailsCaseRequests>
        )}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabOtherServiceFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabOtherServiceForm as any));
