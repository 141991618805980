import { IWorkflowService } from './Interfaces/IWorkflowService';
import {
  Workflow,
  WorkflowParameter,
  WorkflowPartnerType,
  WorkflowPartner,
  WorkflowOptimization,
  WorkflowTariff,
  WorkflowSection,
  WorkflowSectionStep,
  WorkflowSectionStepForm,
  WorkflowSupplier,
  WorkflowReminder,
  WorkflowDocumentType,
  WorkflowCalculation,
  WorkflowPermission,
  WorkflowForm,
  EmailTemplate,
  WorkflowFormReport,
  WorkflowFormAction,
  WorkflowFormInterface,
  WorkflowSectionStepCaseStatus,
  WorkflowInterface,
  WorkflowCostCenter
} from '../interfaces/Workflow';
import { API } from '../helpers/API';
import { IReferential } from '../interfaces/IReferential';

export class WorkflowService implements IWorkflowService {

  private baseController = '/workflow';

  public async GetWorkflows(orgId: number): Promise<Workflow[]> {
    return (await API.GetAsync<Workflow[]>(`${this.baseController}/byorganization/${orgId}`)).data;
  }

  public async GetUserWorkflows(): Promise<Workflow[]> {
    return (await API.GetAsync<Workflow[]>(`${this.baseController}/listUser`)).data;
  }

  public async GetTariffWorkflowsCount(tariffId: number): Promise<number> {
    return (await API.GetAsync<number>(`${this.baseController}/tariff/${tariffId}/count`)).data;
  }

  public async GetWorkflow(workflowId: number): Promise<Workflow> {
    return (await API.GetAsync<Workflow>(`${this.baseController}/${workflowId}`)).data;
  }
  public async GetUserWorkflow(workflowId: number): Promise<Workflow> {
    return (await API.GetAsync<Workflow>(`${this.baseController}/user/${workflowId}`)).data;
  }

  public async GetWorkflowSettings(workflowId: number): Promise<Workflow> {
    return (await API.GetAsync<Workflow>(`${this.baseController}/${workflowId}/settings`)).data;
  }

  public async GetWorkflowSettingsForVehiclesTab(workflowId: number): Promise<Workflow> {
    return (await API.GetAsync<Workflow>(`${this.baseController}/${workflowId}/settingsForVehiclesTab`)).data;
  }

  public async AddWorkflow(workflow: Workflow): Promise<Workflow> {
    return (await API.PostAsync<Workflow>(`${this.baseController}`, workflow)).data;
  }

  public async UpdateWorkflow(workflow: Workflow): Promise<void> {
    await API.PutAsync<Workflow>(`${this.baseController}`, workflow);
  }

  public async GetWorkflowParameters(workflowId: number): Promise<WorkflowParameter[]> {
    return (
      await API.GetAsync<WorkflowParameter[]>(
        `${this.baseController}/${workflowId}/WorkflowParameters`
      )
    ).data;
  }
  public async GetUserWorkflowParameters(workflowId: number): Promise<WorkflowParameter[]> {
    return (
      await API.GetAsync<WorkflowParameter[]>(
        `${this.baseController}/${workflowId}/user/WorkflowParameters`
      )
    ).data;
  }

  public async AddWorkflowParameter(
    workflowParameter: WorkflowParameter
  ): Promise<WorkflowParameter> {
    return (
      await API.PostAsync<WorkflowParameter>(
        `${this.baseController}/WorkflowParameter`,
        workflowParameter
      )
    ).data;
  }

  public async UpdateWorkflowParameter(workflowParameter: WorkflowParameter): Promise<void> {
    await API.PutAsync<WorkflowParameter>(
      `${this.baseController}/WorkflowParameter`,
      workflowParameter
    );
  }

  public async RemoveWorkflowParameter(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowParameter/${id}`)).data;
  }

  public async GetWorkflowOrganizationTypes(workflowId: number): Promise<WorkflowPartnerType[]> {
    return (
      await API.GetAsync<WorkflowPartnerType[]>(
        `${this.baseController}/${workflowId}/WorkflowPartnerTypes`
      )
    ).data;
  }

  public async AddWorkflowOrganizationType(
    workflowOrgType: WorkflowPartnerType
  ): Promise<WorkflowPartnerType> {
    return (
      await API.PostAsync<WorkflowPartnerType>(
        `${this.baseController}/WorkflowPartnerType`,
        workflowOrgType
      )
    ).data;
  }

  public async RemoveWorkflowOrganizationType(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowPartnerType/${id}`)).data;
  }

  public async GetWorkflowPartners(workflowId: number): Promise<WorkflowPartner[]> {
    return (
      await API.GetAsync<WorkflowPartner[]>(
        `${this.baseController}/WorkflowPartner/list/${workflowId}`
      )
    ).data;
  }

  public async GetUserWorkflowPartners(workflowId: number): Promise<WorkflowPartner[]> {
    return (
      await API.GetAsync<WorkflowPartner[]>(
        `${this.baseController}/WorkflowPartner/listUser/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowPartner(workflowPartners: WorkflowPartner[]): Promise<void> {
    await API.PostAsync<WorkflowPartner[]>(
      `${this.baseController}/WorkflowPartner/list`,
      workflowPartners
    );
  }

  public async UpdateWorkflowPartner(workflowPartner: WorkflowPartner): Promise<void> {
    await API.PutAsync<WorkflowPartner>(`${this.baseController}/WorkflowPartner`, workflowPartner);
  }

  public async GetWorkflowOptimizations(workflowId: number): Promise<WorkflowOptimization[]> {
    return (
      await API.GetAsync<WorkflowOptimization[]>(
        `${this.baseController}/WorkflowOptimization/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowOptimization(
    workflowOptimization: WorkflowOptimization
  ): Promise<WorkflowOptimization> {
    return (
      await API.PostAsync<WorkflowOptimization>(
        `${this.baseController}/WorkflowOptimization`,
        workflowOptimization
      )
    ).data;
  }

  public async UpdateWorkflowOptimization(
    workflowOptimization: WorkflowOptimization
  ): Promise<void> {
    await API.PutAsync<WorkflowOptimization>(
      `${this.baseController}/WorkflowOptimization`,
      workflowOptimization
    );
  }

  public async RemoveWorkflowOptimization(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowOptimization/${id}`)).data;
  }

  public async GetWorkflowTariffs(workflowId: number): Promise<WorkflowTariff[]> {
    return (
      await API.GetAsync<WorkflowTariff[]>(
        `${this.baseController}/WorkflowTariff/list/${workflowId}`
      )
    ).data;
  }

  public async GetUserWorkflowTariffs(workflowId: number): Promise<WorkflowTariff[]> {
    return (
      await API.GetAsync<WorkflowTariff[]>(
        `${this.baseController}/WorkflowTariff/listUser/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowTariff(workflowTariff: WorkflowTariff): Promise<WorkflowTariff> {
    return (
      await API.PostAsync<WorkflowTariff>(`${this.baseController}/WorkflowTariff`, workflowTariff)
    ).data;
  }

  public async UpdateWorkflowTariff(workflowTariff: WorkflowTariff): Promise<void> {
    await API.PutAsync<WorkflowTariff>(`${this.baseController}/WorkflowTariff`, workflowTariff);
  }

  public async GetWorkflowSections(workflowId: number): Promise<WorkflowSection[]> {
    return (
      await API.GetAsync<WorkflowSection[]>(
        `${this.baseController}/WorkflowSection/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowSection(workflowSection: WorkflowSection): Promise<WorkflowSection> {
    return (
      await API.PostAsync<WorkflowSection>(
        `${this.baseController}/WorkflowSection`,
        workflowSection
      )
    ).data;
  }

  public async UpdateWorkflowSection(workflowSection: WorkflowSection): Promise<void> {
    await API.PutAsync<WorkflowSection>(`${this.baseController}/WorkflowSection`, workflowSection);
  }

  public async RemoveWorkflowSection(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowSection/${id}`)).data;
  }

  public async GetWorkflowSectionSteps(workflowSectionId: number): Promise<WorkflowSectionStep[]> {
    return (
      await API.GetAsync<WorkflowSectionStep[]>(
        `${this.baseController}/WorkflowSectionStep/list/${workflowSectionId}`
      )
    ).data;
  }
  public async GetWorkflowSteps(workflowId: number): Promise<WorkflowSectionStep[]> {
    return (
      await API.GetAsync<WorkflowSectionStep[]>(
        `${this.baseController}/WorkflowSectionStep/listSteps/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowSectionStep(
    workflowSectionStep: WorkflowSectionStep
  ): Promise<WorkflowSectionStep> {
    return (
      await API.PostAsync<WorkflowSectionStep>(
        `${this.baseController}/WorkflowSectionStep`,
        workflowSectionStep
      )
    ).data;
  }

  public async UpdateWorkflowSectionStep(workflowSectionStep: WorkflowSectionStep): Promise<void> {
    await API.PutAsync<WorkflowSectionStep>(
      `${this.baseController}/WorkflowSectionStep`,
      workflowSectionStep
    );
  }

  public async RemoveWorkflowStep(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowSectionStep/${id}`)).data;
  }

  public async GetWorkflowSectionStepForms(
    workflowSectionStepId: number
  ): Promise<WorkflowSectionStepForm[]> {
    return (
      await API.GetAsync<WorkflowSectionStepForm[]>(
        `${this.baseController}/WorkflowSectionStepForm/list/${workflowSectionStepId}`
      )
    ).data;
  }

  public async GetWorkflowSectionStepForm(id: number): Promise<WorkflowSectionStepForm> {
    return (
      await API.GetAsync<WorkflowSectionStepForm>(
        `${this.baseController}/WorkflowSectionStepForm/${id}`
      )
    ).data;
  }

  public async AddWorkflowSectionStepForm(
    workflowSectionStepForm: WorkflowSectionStepForm
  ): Promise<WorkflowSectionStepForm> {
    return (
      await API.PostAsync<WorkflowSectionStepForm>(
        `${this.baseController}/WorkflowSectionStepForm`,
        workflowSectionStepForm
      )
    ).data;
  }

  public async UpdateWorkflowSectionStepForm(
    workflowSectionStepForm: WorkflowSectionStepForm
  ): Promise<void> {
    await API.PutAsync<WorkflowSectionStepForm>(
      `${this.baseController}/WorkflowSectionStepForm`,
      workflowSectionStepForm
    );
  }

  public async GetWorkflowSectionStepCaseStatuses(
    workflowSectionStepId: number
  ): Promise<WorkflowSectionStepCaseStatus[]> {
    return (
      await API.GetAsync<WorkflowSectionStepCaseStatus[]>(
        `${this.baseController}/WorkflowSectionStepCaseStatus/list/${workflowSectionStepId}`
      )
    ).data;
  }

  public async AddWorkflowSectionStepCaseStatus(
    workflowSectionStepCaseStatus: WorkflowSectionStepCaseStatus
  ): Promise<WorkflowSectionStepCaseStatus> {
    return (
      await API.PostAsync<WorkflowSectionStepCaseStatus>(
        `${this.baseController}/workflowSectionStepCaseStatus`,
        workflowSectionStepCaseStatus
      )
    ).data;
  }

  public async RemoveWorkflowSectionStepCaseStatus(id: number): Promise<void> {
    return (
      await API.DeleteAsync<void>(`${this.baseController}/WorkflowSectionStepCaseStatus/${id}`)
    ).data;
  }

  public async GetWorkflowForms(): Promise<WorkflowForm[]> {
    return (await API.GetAsync<WorkflowForm[]>(`${this.baseController}/referential/workflowform`))
      .data;
  }

  public async RemoveWorkflowForm(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowSectionStepForm/${id}`))
      .data;
  }

  public async GetWorkflowSuppliers(workflowId: number): Promise<WorkflowSupplier[]> {
    return (
      await API.GetAsync<WorkflowSupplier[]>(
        `${this.baseController}/WorkflowSupplier/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowSupplier(workflowSupplier: WorkflowSupplier): Promise<WorkflowSupplier> {
    return (
      await API.PostAsync<WorkflowSupplier>(
        `${this.baseController}/WorkflowSupplier`,
        workflowSupplier
      )
    ).data;
  }

  public async AddWorkflowSuppliers(workflowSuppliers: WorkflowSupplier[]): Promise<void> {
    await API.PostAsync<WorkflowSupplier[]>(
      `${this.baseController}/WorkflowSupplier/list`,
      workflowSuppliers
    );
  }
  public async UpdateWorkflowSupplier(workflowSupplier: WorkflowSupplier): Promise<void> {
    await API.PutAsync<WorkflowSupplier>(
      `${this.baseController}/WorkflowSupplier`,
      workflowSupplier
    );
  }

  public async RemoveWorkflowSupplier(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowSupplier/${id}`)).data;
  }

  public async GetWorkflowReminders(workflowId: number): Promise<WorkflowReminder[]> {
    return (
      await API.GetAsync<WorkflowReminder[]>(
        `${this.baseController}/WorkflowNotification/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowReminder(workflowReminder: WorkflowReminder): Promise<WorkflowReminder> {
    return (
      await API.PostAsync<WorkflowReminder>(
        `${this.baseController}/WorkflowNotification`,
        workflowReminder
      )
    ).data;
  }

  public async UpdateWorkflowReminder(workflowReminder: WorkflowReminder): Promise<void> {
    await API.PutAsync<WorkflowReminder>(
      `${this.baseController}/WorkflowNotification`,
      workflowReminder
    );
  }

  public async RemoveWorkflowReminder(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowNotification/${id}`)).data;
  }

  public async GetWorkflowDocumentTypes(workflowId: number): Promise<WorkflowDocumentType[]> {
    return (
      await API.GetAsync<WorkflowDocumentType[]>(
        `${this.baseController}/WorkflowDocumentType/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowDocumentType(
    workflowDocumentType: WorkflowDocumentType
  ): Promise<WorkflowDocumentType> {
    return (
      await API.PostAsync<WorkflowDocumentType>(
        `${this.baseController}/WorkflowDocumentType`,
        workflowDocumentType
      )
    ).data;
  }

  public async UpdateWorkflowDocumentType(
    workflowDocumentType: WorkflowDocumentType
  ): Promise<void> {
    await API.PutAsync<WorkflowDocumentType>(
      `${this.baseController}/WorkflowDocumentType`,
      workflowDocumentType
    );
  }

  public async RemoveWorkflowDocumentType(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowDocumentType/${id}`)).data;
  }

  public async GetWorkflowFormInterfaces(formId: number): Promise<WorkflowFormInterface[]> {
    return (
      await API.GetAsync<WorkflowFormInterface[]>(
        `${this.baseController}/WorkflowFormInterface/list/${formId}`
      )
    ).data;
  }

  public async AddWorkflowFormInterface(
    workflowFormInterface: WorkflowFormInterface
  ): Promise<WorkflowFormInterface> {
    return (
      await API.PostAsync<WorkflowFormInterface>(
        `${this.baseController}/WorkflowFormInterface`,
        workflowFormInterface
      )
    ).data;
  }

  public async UpdateWorkflowFormInterface(
    workflowFormInterface: WorkflowFormInterface
  ): Promise<void> {
    await API.PutAsync<WorkflowFormInterface>(
      `${this.baseController}/WorkflowFormInterface`,
      workflowFormInterface
    );
  }

  public async RemoveWorkflowFormInterface(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowFormInterface/${id}`)).data;
  }

  public async GetWorkflowCalculations(workflowId: number): Promise<WorkflowCalculation[]> {
    return (
      await API.GetAsync<WorkflowCalculation[]>(
        `${this.baseController}/WorkflowCalculation/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowCalculation(
    workflowCalculation: WorkflowCalculation
  ): Promise<WorkflowCalculation> {
    return (
      await API.PostAsync<WorkflowCalculation>(
        `${this.baseController}/WorkflowCalculation`,
        workflowCalculation
      )
    ).data;
  }

  public async UpdateWorkflowCalculation(workflowCalculation: WorkflowCalculation): Promise<void> {
    await API.PutAsync<WorkflowCalculation>(
      `${this.baseController}/WorkflowCalculation`,
      workflowCalculation
    );
  }

  public async RemoveWorkflowCalculation(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowCalculation/${id}`)).data;
  }

  public async GetWorkflowPermissions(workflowId: number): Promise<WorkflowPermission[]> {
    return (
      await API.GetAsync<WorkflowPermission[]>(
        `${this.baseController}/WorkflowPermission/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowPermission(
    workflowPermission: WorkflowPermission
  ): Promise<WorkflowPermission> {
    return (
      await API.PostAsync<WorkflowPermission>(
        `${this.baseController}/WorkflowPermission`,
        workflowPermission
      )
    ).data;
  }

  public async UpdateWorkflowPermission(workflowPermission: WorkflowPermission): Promise<void> {
    await API.PutAsync<WorkflowPermission>(
      `${this.baseController}/WorkflowPermission`,
      workflowPermission
    );
  }

  public async RemoveWorkflowPermission(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowPermission/${id}`)).data;
  }

  public async GetWorkflowFormReports(workflowId: number): Promise<WorkflowFormReport[]> {
    return (
      await API.GetAsync<WorkflowFormReport[]>(
        `${this.baseController}/WorkflowReport/list/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowFormReport(
    workflowReport: WorkflowFormReport
  ): Promise<WorkflowFormReport> {
    return (
      await API.PostAsync<WorkflowFormReport>(
        `${this.baseController}/workflowReport`,
        workflowReport
      )
    ).data;
  }

  public async UpdateWorkflowFormReport(workflowReport: WorkflowFormReport): Promise<void> {
    await API.PutAsync<WorkflowFormReport>(`${this.baseController}/WorkflowReport`, workflowReport);
  }

  public async RemoveWorkflowFormReport(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowReport/${id}`)).data;
  }

  public async GetWorkflowSectionStepFormActions(
    workflowSectionStepFormId: number
  ): Promise<WorkflowFormAction[]> {
    return (
      await API.GetAsync<WorkflowFormAction[]>(
        `${this.baseController}/WorkflowSectionStepFormAction/list/${workflowSectionStepFormId}`
      )
    ).data;
  }

  public async AddWorkflowSectionStepFormAction(
    workflowSectionStepFormAction: WorkflowFormAction
  ): Promise<WorkflowFormAction> {
    return (
      await API.PostAsync<WorkflowFormAction>(
        `${this.baseController}/workflowSectionStepFormAction`,
        workflowSectionStepFormAction
      )
    ).data;
  }

  public async AddWorkflowSectionStepFormActions(
    workflowSectionStepFormActions: WorkflowFormAction[]
  ): Promise<void> {
    await API.PostAsync<WorkflowFormAction[]>(
      `${this.baseController}/workflowSectionStepFormAction/list`,
      workflowSectionStepFormActions
    );
  }

  public async UpdateWorkflowSectionStepAction(
    workflowSectionStepFormAction: WorkflowFormAction
  ): Promise<void> {
    await API.PutAsync<WorkflowFormAction>(
      `${this.baseController}/WorkflowSectionStepFormAction`,
      workflowSectionStepFormAction
    );
  }

  public async RemoveWorkflowSectionStepFormAction(id: number): Promise<void> {
    return (
      await API.DeleteAsync<void>(`${this.baseController}/WorkflowSectionStepFormAction/${id}`)
    ).data;
  }

  public async GetEmailTemplates(): Promise<EmailTemplate[]> {
    return (await API.GetAsync<EmailTemplate[]>(`${this.baseController}/referential/emailtemplate`))
      .data;
  }

  public async AddEmailTemplate(emailTemplate: EmailTemplate): Promise<EmailTemplate> {
    return (
      await API.PostAsync<EmailTemplate>(
        `${this.baseController}/referential/emailtemplate`,
        emailTemplate
      )
    ).data;
  }

  public async UpdateEmailTemplate(emailTemplate: EmailTemplate): Promise<void> {
    await API.PutAsync<EmailTemplate>(
      `${this.baseController}/referential/emailtemplate`,
      emailTemplate
    );
  }
  public async GetWorkflowInterfaces(workflowId: number): Promise<WorkflowInterface[]> {
    return (
      await API.GetAsync<WorkflowInterface[]>(
        `${this.baseController}/WorkflowInterface/list/${workflowId}`
      )
    ).data;
  }

  public async RemoveWorkflowInterface(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowInterface/${id}`)).data;
  }

  public async AddWorkflowInterface(
    workflowInterface: WorkflowInterface
  ): Promise<WorkflowInterface> {
    return (
      await API.PostAsync<WorkflowInterface>(
        `${this.baseController}/WorkflowInterface`,
        workflowInterface
      )
    ).data;
  }
  
  public async UpdateWorkflowInterface(
    workflowInterface: WorkflowInterface
  ): Promise<void> {
    await API.PutAsync<WorkflowInterface>(
      `${this.baseController}/WorkflowInterface`,
      workflowInterface
    );
  }

  public async GetOwnerTariffPartner(workflowId: number): Promise<WorkflowPartner> {
    return (
      await API.GetAsync<WorkflowPartner>(
        `${this.baseController}/partner-owner-tariff/${workflowId}`
      )
    ).data;
  }

  public async GetWorkflowCostCenters(workflowId: number): Promise<WorkflowCostCenter[]> {
    return (
      await API.GetAsync<WorkflowCostCenter[]>(
        `${this.baseController}/WorkflowCostCenter/list/${workflowId}`
      )
    ).data;
  }

  public async GetUserWorkflowCostCenters(workflowId: number): Promise<WorkflowCostCenter[]> {
    return (
      await API.GetAsync<WorkflowCostCenter[]>(
        `${this.baseController}/WorkflowCostCenter/listUser/${workflowId}`
      )
    ).data;
  }

  public async AddWorkflowCostCenter(workflowCostCenters: WorkflowCostCenter[]): Promise<void> {
    await API.PostAsync<WorkflowCostCenter[]>(
      `${this.baseController}/WorkflowCostCenter/list`,
      workflowCostCenters
    );
  }

  public async UpdateWorkflowCostCenter(workflowCostCenter: WorkflowCostCenter): Promise<void> {
    await API.PutAsync<WorkflowCostCenter>(`${this.baseController}/WorkflowCostCenter`, workflowCostCenter);
  }

  public async RemoveWorkflowCostCenter(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/WorkflowCostCenter/${id}`))
      .data;
  }

  public async GetCostCenter(id: number): Promise<IReferential> {
    return (
      await API.GetAsync<IReferential>(
        `${this.baseController}/${id}/CostCenter`
      )
    ).data;
  }
}
