import React, { Fragment, useEffect, useState } from 'react';
import { Box, Modal } from '@material-ui/core';
import { SmallCalendar } from './SmallCalendar';
import { BigCalendar } from './BigCalendar';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { AppointmentsTable } from './AppointmentsTable';
import { AGENDA_SET_APPOINTMENTS, AGENDA_SET_CATEGORY_TYPE, AGENDA_SET_STATUS_LIST } from '../../store/actions/ActionTypes';
import { getAppointmentsRequest } from './agenda.requests';
import { AppointmentType, AppointmentsFiltersType, ResponseAppointmentsData } from '../../types/Agenda';
import { areDatesInSameWeek, getCategoryTypeList, getStatusList, formatDate } from '../../utils/appointments';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';

const AgendaPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const selectedRange = useAppSelector((state)=>state.agenda?.selectedRange)
  const [localAppointments, setLocalAppoitments] = React.useState<Array<AppointmentType>>([]);
  const [defaultView, setDefaultView] = React.useState<'month' | 'week' | 'day' | 'agenda'>('month'); 
  const [defaultDay, setDefaultDay] = useState<any>()
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [tableProps, setTabelProps] = useState<any>({
    total: 0,
    page: 0,
    rowsPerPage: 10,
    search:''
  });
  const language = useAppSelector((state)=>state.app?.language)
  const dispatch = useAppDispatch();

  const changeBigCalendar = (appointments:any, dates:any) =>{
    dispatch({
      type: AGENDA_SET_APPOINTMENTS,
      payload: appointments,
    });
    const date = formatDate(dates[0])
    setDefaultDay(date)
    if (dates.length === 1){
      setDefaultView('day');
      return
    }
    if(dates.length > 7){
      setDefaultView('month')
    }else{
      areDatesInSameWeek(dates) ? setDefaultView('week'): setDefaultView('month')
    }  
  }

  const fetchAppointments = async (filters: AppointmentsFiltersType, local=false) => {
    !local && setIsLoading(true);
    const response = await getAppointmentsRequest(filters);
    const responseData = response.data as ResponseAppointmentsData;
    if(!responseData?.appointments){
      !local && setIsLoading(false)
      return 
    }
    if(local){
      setTabelProps({
        total:responseData?.total,
        page: (filters?.page || 1) -1,
        rowsPerPage: filters?.pageSize || 10,
        search:filters?.text || ''
      })
      setLocalAppoitments(responseData.appointments)
      filters['formatedDateList']?.length > 0 && changeBigCalendar(responseData.appointments, filters['formatedDateList'])
    }else{
      dispatch({
        type: AGENDA_SET_APPOINTMENTS,
        payload: responseData.appointments,
      });
    } 
    !local && setIsLoading(false);
  };

  useEffect(()=>{
    setDefaultDay(null)
  },[selectedRange])
  

  const formatRangeValue = () =>{
    if (!selectedRange) {
      return
    }
    if(selectedRange.start_date !== selectedRange?.end_date){
      fetchAppointments({
        dateFilterStartDate: moment(selectedRange.start_date).format(),
        datefilterEndDate: moment(selectedRange.end_date).format()
      });
    }else{
      const endDate = new Date(selectedRange?.end_date)
      endDate.setHours(23, 59, 59, 999)
      fetchAppointments({
        dateFilterStartDate: moment(selectedRange.start_date).format(),
        datefilterEndDate: moment(endDate).format()
      });
    }
  }

  React.useEffect(() => {
    if(!localAppointments.length){
      fetchAppointments({
        page:1,
        pageSize:10
      }, true);
    }
    if(!selectedRange){
      const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      lastDayOfMonth.setHours(23, 59, 59, 999);
      fetchAppointments({
        dateFilterStartDate: moment(firstDayOfMonth).format(),
        datefilterEndDate: moment(lastDayOfMonth).format()
      });
    }else{
      formatRangeValue()
    }
    getStatusList(setIsLoading, (value:any)=>{
      dispatch({
        type: AGENDA_SET_STATUS_LIST,
        payload: value
      });
    }, enqueueSnackbar)
    getCategoryTypeList(setIsLoading, (value:any)=>{
      dispatch({
        type:AGENDA_SET_CATEGORY_TYPE,
        payload:value
      })
    }, enqueueSnackbar)
  }, []);

  const renderModal = () => {
    return (
      <div>
        <Modal open={modalOpen} onClose={()=>setModalOpen(false)}>
          <div className="search-vehicle-modal" style={{ width:'auto' }}>
            <SmallCalendar getAppointments={fetchAppointments} setModalOpen={setModalOpen}/>
          </div>
        </Modal>
      </div>
    );
  };
  return (
    <Fragment>
      {isLoading ? <Loader/> :<>
      {renderModal()}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px'
          }}
        >
          <AppointmentsTable 
            key={language} 
            appointments={localAppointments} 
            tableProps={tableProps}
            setOpenModal={setModalOpen}
            getAppointment={(filters: AppointmentsFiltersType)=>{
              fetchAppointments(filters, true)
          }}/>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            marginTop: '16px'
          }}
        >
          <BigCalendar getAppointments={fetchAppointments} defaultView={defaultView} setDefaultView={setDefaultView} defaultDay={defaultDay}/>
        </Box>
      </>}
    </Fragment>
  );
};

export default AgendaPage;
