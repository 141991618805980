import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState, VehicleState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Card, Grid, Tab, Tabs, AppBar, TextField, Button } from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { isNullOrUndefined } from 'util';
import { IReferential } from '../../interfaces/IReferential';
import { Organization } from '../../interfaces/Organization';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  VehicleExtensionList,
  VehicleFilters,
  SimpleVehicleCarfix
} from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';
import VehicleImportModal from './VehicleImportModal';
import ImportVehiclesRenaultButton from './ImportVehiclesRenaultButton';
import Loader from '../Loader';
import noop from 'lodash/noop';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import { IconButton } from '@material-ui/core';
import {
  Tooltip,
  CardContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Radio,
  Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CaseDetailsCaseTabSaleOfferFormDialog from '../cases/CaseDetailsCaseTabSaleOfferFormDialog';
import { Workflow, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { Case, CaseByVehicleIdSearchResult } from '../../interfaces/Case';
import { FormCode } from '../../helpers/forms/FormCode';
import { CaseSettings } from '../../interfaces/CaseSettings';
import * as R from 'ramda';
import Referentials from '../../helpers/Referentials.json';
import { CaseStatusCode, ReferentialCode, VehicleStatusCodeEnum } from '../../helpers/Constants';
import _ from 'lodash';
import { ScaleLoader } from 'react-spinners';
import { FormControlLabel, InputAdornment, Switch } from '@material-ui/core';
import VehicleImportOneCarModal from './VehicleImportOneCarModal';
import { Utils } from '../../helpers/Utils';


type VehiclesProps = {
  appState: AppState;
  caseSettingsState: CaseSettingsState;
  vehicleState: VehicleState;
} & ProviderContext &
  RouteComponentProps;

interface IVehiclesState {
  vehiclesList: VehicleExtensionList;
  organizationEntities: Organization[];
  selectedOrgEntities: Organization[];
  location: string;
  makeEntities: IReferential[];
  selectedMakeEntities: IReferential[];
  modelEntities: IReferential[];
  selectedModelEntities: IReferential[];
  fuelEntities: IReferential[];
  selectedFuelEntities: IReferential[];
  transmissionEntities: IReferential[];
  selectedTransmissionEntities: IReferential[];
  tractionEntities: IReferential[];
  selectedTractionEntities: IReferential[];
  statusEntities: IReferential[];
  selectedStatusEntities: IReferential[];
  selectedTab: number;
  isLoading: boolean;
  isLoadingVehicles: boolean;
  count: number;
  page: number;
  pageSize: number;
  vehicleImportDialogOpenend: boolean;
  vehicleImportOneCarDialogOpenend: boolean;
  isCarSoldDialogOpen: boolean;
  salesWokflowForm: WorkflowSectionStepForm | null;
  paramList: IReferential[];
  isLoadingDialog: boolean;
  vin: string;
}

class Vehicles extends React.PureComponent<VehiclesProps, IVehiclesState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    vehiclesList: {} as VehicleExtensionList,
    organizationEntities: [],
    selectedOrgEntities: [],
    location: '',
    makeEntities: [],
    selectedMakeEntities: [],
    modelEntities: [],
    selectedModelEntities: [],
    fuelEntities: [],
    selectedFuelEntities: [],
    transmissionEntities: [],
    selectedTransmissionEntities: [],
    tractionEntities: [],
    selectedTractionEntities: [],
    statusEntities: [],
    selectedStatusEntities: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingVehicles: false,
    count: 1,
    page: 0,
    pageSize: 10,
    vehicleImportDialogOpenend: false,
    vehicleImportOneCarDialogOpenend: false,
    isCarSoldDialogOpen: false,
    vehiclesCaseList: [],
    salesWokflowForm: null,
    paramList: [],
    isLoadingDialog: false,
    vin: ''
  } as IVehiclesState;

  public async componentDidMount() {
    const vehiclesFilters = {
      makeIds: [],
      modelIds: [],
      fuelTypeIds: [],
      transmissionIds: [],
      tractionIds: [],
      statusIds: [],
      organizationIds: [],
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      location: '',
      text: '',
      vehicleHistory: false,
      vin: ''
    } as VehicleFilters;

    this.setState({
      isLoading: true
    });

    const [vehicles] = await Promise.all([
      this.cbContext.vehicleService.GetVehicles(vehiclesFilters)
    ]);

    const [organizations] = await Promise.all([
      this.cbContext.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(vehicles.vehicles.map(({ vehicle }) => vehicle.organizationId)))
      )
    ]);
    const interfacePromises = [] as any[];
    vehicles.vehicles.forEach(({ vehicle }) => {
      if (vehicle.currentStatus.statusId == VehicleStatusCodeEnum.ForSale) {
        interfacePromises.push(this.cbContext.caseService.GetLastCaseByVehicleId(vehicle.id));
      }
    });

    const result = await Promise.all(interfacePromises);
    const cases: CaseByVehicleIdSearchResult[] = [];
    result.forEach((item) => {
      cases.push(item);
    });
    const refParam = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CompanyParameter
    );
    const [paramList] = await Promise.all([
      this.cbContext.referentialService.Get(refParam!.baseUrl)
    ]);

    vehicles.vehicles.forEach(({ vehicle }) => {
      const org = organizations.find((item) => item.id === vehicle.organizationId);
      vehicle.organization = isNullOrUndefined(org) ? null : org;
      const vehicleCase = _.find(cases, function (item) {
        return item && item.vehicleId == vehicle.id;
      });
      vehicle.case = isNullOrUndefined(vehicleCase) ? null : vehicleCase;
      if (vehicle.case) {
        vehicle.case.caseParameters.forEach((item) => {
          const param = paramList.find((p) => item.parameterId === p.id);
          item.parameter = R.isNil(param) ? null : param;
        });
      }
    });

    console.log('vehicles', vehicles);
    this.setState(
      {
        paramList: paramList,
        vehiclesList: vehicles,
        organizationEntities: organizations,
        count: vehicles.total,
        page: vehicles.page - 1
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadVehicles = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingVehicles: true
    });

    const vehiclesFilters = {
      makeIds: this.state.selectedMakeEntities.map((item) => item.id),
      modelIds: this.state.selectedModelEntities.map((item) => item.id),
      fuelTypeIds: this.state.selectedFuelEntities.map((item) => item.id),
      transmissionIds: this.state.selectedTransmissionEntities.map((item) => item.id),
      tractionIds: this.state.selectedTractionEntities.map((item) => item.id),
      statusIds: this.state.selectedStatusEntities.map((item) => item.id),
      organizationIds: this.state.selectedOrgEntities.map((item) => item.id),
      page: page + 1,
      pageSize: rowsPerPage,
      location: this.state.location,
      text: '',
      vehicleHistory: false,
      vin: this.state.vin
    } as VehicleFilters;

    const vehicleList = await this.cbContext.vehicleService.GetVehicles(vehiclesFilters);
    const [organizations] = await Promise.all([
      this.cbContext.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(vehicleList.vehicles.map(({ vehicle }) => vehicle.organizationId)))
      )
    ]);
    const interfacePromises = [] as any[];
    vehicleList.vehicles.forEach(({ vehicle }) => {
      if (vehicle.currentStatus.statusId == VehicleStatusCodeEnum.ForSale) {
        interfacePromises.push(this.cbContext.caseService.GetLastCaseByVehicleId(vehicle.id));
      }
    });

    const result = await Promise.all(interfacePromises);
    const cases: CaseByVehicleIdSearchResult[] = [];
    result.forEach((item) => {
      cases.push(item);
    });

    vehicleList.vehicles.forEach(({ vehicle }) => {
      const org = organizations.find((item) => item.id === vehicle.organizationId);
      vehicle.organization = isNullOrUndefined(org) ? null : org;
      const vehicleCase = _.find(cases, function (item) {
        return item && item.vehicleId == vehicle.id;
      });
      vehicle.case = isNullOrUndefined(vehicleCase) ? null : vehicleCase;
      if (vehicle.case) {
        vehicle.case.caseParameters.forEach((item) => {
          const param = this.state.paramList.find((p) => item.parameterId === p.id);
          item.parameter = R.isNil(param) ? null : param;
        });
      }
    });

    console.log('vehicleList', vehicleList);

    this.setState({
      vehiclesList: vehicleList,
      organizationEntities: organizations,
      page: vehicleList.page - 1,
      pageSize: rowsPerPage,
      count: vehicleList.total,
      isLoadingVehicles: false
    });
  };

  reloadFilters = async () => {
    const vehicleHistory = false;
    const filters = await this.cbContext.vehicleService.GetVehiclesFilters(vehicleHistory);

    const [organizations] = await Promise.all([
      this.cbContext.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(filters.organizationIds))
      )
    ]);

    this.setState({
      organizationEntities: organizations,
      makeEntities: filters.makeList,
      modelEntities: filters.modelList,
      fuelEntities: filters.fuelTypes,
      transmissionEntities: filters.transmissionTypes,
      tractionEntities: filters.tractionTypes,
      statusEntities: filters.vehicleStatuses,
      isLoading: false
    });
  };

  onClick = (id: number) => {
    this.props.history.push('/vehicle/' + id + '/history');
  };

  closeCarSoldDialogOnSaveButton = async () => {
    this.props.caseSettingsState.case = null;
    this.props.caseSettingsState.caseSettings = null;
    this.props.vehicleState.vehicle = null;
    this.setState(
      {
        isCarSoldDialogOpen: false,
        salesWokflowForm: null
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  closeDialog = async () => {
    this.setState({
      isCarSoldDialogOpen: false,
      salesWokflowForm: null
    });
    this.props.caseSettingsState.case = null;
    this.props.caseSettingsState.caseSettings = null;
    this.props.vehicleState.vehicle = null;
  };

  getColumns = () => {
    const columns = [
      {
        name: 'vehicle.id',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_ID', 'ID'),
        options: {
          sort: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.organization.displayName',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_ORGANIZATION',
          'Organizatie'
        )
      },
      {
        name: 'vehicle.vin',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_VIN', 'VIN')
      },
      {
        name: 'vehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.version',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_VERSION',
          'Marca Model Versiune'
        ),
        options: {
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const vehicleExtension = this.state.vehiclesList.vehicles.find(
              ({ vehicle }) => vehicle.id === tableMeta.rowData[0]
            );

            return (
              <div>
                <span className="font-size-sm">
                  {vehicleExtension?.vehicle?.make?.displayName}{' '}
                </span>
                <span className="font-size-sm">
                  {vehicleExtension?.vehicle?.model?.displayName}{' '}
                </span>
                <span className="font-size-sm">{value} </span>
              </div>
            );
          }
        }
      },
      {
        name: 'vehicle.firstRegistrationDate',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_FIRST_REGISTRATION_DATE',
          'Data primei inmatriculari'
        ),
        options: {
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {moment
                      .utc(value)
                      .local()
                      .toDate()
                      .toLocaleDateString(this.props.appState.language)}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'addedInStockDate',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_ADDED_IN_STOCK_DATE',
          'Data intrare stoc'
        ),
        options: {
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                        .utc(value)
                        .local()
                        .toDate()
                        .toLocaleDateString(this.props.appState.language)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'vehicle.makeDate',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_YEAR_OF_FABRICATION',
          'An'
        ),
        options: {
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">{value ? new Date(value).getFullYear() : null}</div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'vehicle.kilometersNr',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_NR_OF_KM', 'KM')
      },
      {
        name: 'vehicle.currentStatus.status.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_STATUS', 'Status')
      },
      {
        name: 'vehicle',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_PURCHASE_EUR_PRICE',
          'Pret Achizitie EUR cu TVA'
        ),
        options: {
          customBodyRender: (value: any) => {
            return value.purchaseVatRate === 0 ? (
              <>
                {value.purchasePrice}
                <div className="text-light bg-warning rounded p-1">
                  {this.cbContext.translatorService.Tranlate(
                    'VEHICLES_WITHOUT_VAT',
                    'Achizitionat cu TVA=0'
                  )}
                </div>
              </>
            ) : (
              value.purchasePrice
            );
          }
        }
      },
      {
        name: '',
        label: this.cbContext.translatorService.Tranlate(
          'ORGANIZATION_FORM_EDIT_ACTIONS_TAB',
          'Actiuni'
        ),
        options: {
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const vehicleExtension = this.state.vehiclesList.vehicles.find(
              ({ vehicle }) => vehicle.id === tableMeta.rowData[0]
            );

            const buttonsToBeRendered = [];

            if (vehicleExtension?.vehicle.currentStatus.statusId == VehicleStatusCodeEnum.ForSale) {
              buttonsToBeRendered.push(
                <div className="text-center" key={vehicleExtension?.vehicle.id}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ width: '140px' }}
                    disabled={
                      vehicleExtension?.vehicle.case?.caseStatus.code != CaseStatusCode.DATE_CLIENT
                    }
                    onClick={async () => {
                      console.log(vehicleExtension?.vehicle.case);
                      if (vehicleExtension) {
                        this.setState({
                          isLoadingDialog: true,
                          isCarSoldDialogOpen: true
                        });
                        const [workflow] = await Promise.all([
                          this.cbContext.workflowService.GetWorkflowSettingsForVehiclesTab(
                            vehicleExtension.vehicle.case!.workflowId
                          )
                        ]);
                        const salesForm = this.getForms(workflow, FormCode.CASE_SALE_OFFER);
                        this.props.caseSettingsState.caseSettings = {
                          workflow: { ...workflow }
                        } as CaseSettings;
                        this.props.caseSettingsState.case = vehicleExtension.vehicle
                          .case as any as Case;
                        this.props.vehicleState.vehicle = vehicleExtension.vehicle;
                        this.setState({
                          salesWokflowForm: salesForm,
                          isLoadingDialog: false
                        });
                      }
                    }}
                  >
                    {this.cbContext.translatorService.Tranlate(
                      'VEHICLES_LIST_SALES_CAR',
                      'Masina Vanduta'
                    )}
                  </Button>
                </div>
              );
            }
            if (vehicleExtension?.vehicle.isImported && this.props.appState?.appUser?.userRole?.code === 'MAVO_ADMIN') {
              buttonsToBeRendered.push(
                <div className="text-center" key={vehicleExtension?.vehicle.id}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ width: '140px' }}
                    onClick={async () => {
                      const body = {
                        ConnectId: vehicleExtension?.vehicle.id,
                        PlateNumber: vehicleExtension?.vehicle.plateNumber,
                        Vin: vehicleExtension?.vehicle.vin,
                        RcrEmployeeMail: vehicleExtension?.vehicle.rcrEmployeeEmail,
                        RcrEmployeePhone: vehicleExtension?.vehicle.rcrEmployeePhone
                      } as SimpleVehicleCarfix;

                      const isPlateNumberValid = Utils.validatePlateNumber(body.PlateNumber);

                      if(!isPlateNumberValid){
                        this.props.enqueueSnackbar(
                          this.cbContext.translatorService.Tranlate(
                            'VEHICLES_VEHICLE_SEND_TO_CARFIX_INVALID_PLATE_NUMBER',
                            'Numar de inmatriculare incorect'
                          ),
                          {
                            variant: 'error',
                            autoHideDuration: 3000
                          }
                        );

                        return;
                      }

                      try {
                        const response = await this.cbContext.vehicleService.SendVehicleToCarfix(
                          body
                        );

                        if (response.success) {
                          this.props.enqueueSnackbar(
                            this.cbContext.translatorService.Tranlate(
                              'VEHICLES_VEHICLE_SEND_TO_CARFIX_SUCCESS',
                              'Vehicle successfully sent to Carfix'
                            ),
                            {
                              variant: 'success',
                              autoHideDuration: 6000
                            }
                          );
                        } else {
                          this.props.enqueueSnackbar(
                            this.cbContext.translatorService.Tranlate(
                              'VEHICLES_VEHICLE_SEND_TO_CARFIX_ERROR',
                              'There was an unexpected error while sending the vehicle to Carfix'
                            ),
                            {
                              variant: 'error',
                              autoHideDuration: 6000
                            }
                          );
                        }
                      } catch (error) {
                        this.props.enqueueSnackbar(
                          this.cbContext.translatorService.Tranlate(
                            'VEHICLES_VEHICLE_SEND_TO_CARFIX_UNEXPECTED_ERROR',
                            'There was an unexpected error while sending the vehicle'
                          ),
                          {
                            variant: 'error',
                            autoHideDuration: 6000
                          }
                        );
                      }
                    }}
                  >
                    {this.cbContext.translatorService.Tranlate(
                      'VEHICLES_LIST_SEND_TO_CARFIX_BUTTON',
                      'Send to Carfix'
                    )}
                  </Button>
                </div>
              );
            }

            return buttonsToBeRendered.length > 0 ? buttonsToBeRendered : '';
          }
        }
      }
    ];

    return columns;
  };

  getForms = (workflow: Workflow, code: string): WorkflowSectionStepForm | null => {
    let form = null;
    const sections = workflow
      .workflowSections!.filter((item) => item.isActive)
      .sort(this.sortFunction);
    // console.log('sections', sections);
    for (const section of sections) {
      const steps = section.workflowSectionSteps
        .filter((item) => item.isActive)
        .sort(this.sortFunction);
      // console.log('steps', steps);
      for (const step of steps) {
        const forms = step.workflowSectionStepForms
          .filter((item) => item.isActive)
          .sort(this.sortFunction);
        // console.log('forms', forms);
        const formIndex = forms.findIndex((item) => item.form.code === code);
        if (formIndex !== -1) {
          form = forms[formIndex];
        }
      }
    }
    return form;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadVehicles(0, this.state.pageSize)
    );
  };
  handleOrgEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedOrgEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleMakeEntitiesChange = (newValue: any[]) => {
    const selectedModels = [...this.state.selectedModelEntities];
    const selectedMakeIds = newValue.map(({ id }) => id);

    const models = selectedModels.filter(({ dependencyId }) =>
      selectedMakeIds.includes(dependencyId)
    );

    this.setState(
      {
        selectedMakeEntities: newValue,
        selectedModelEntities: models
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleModelEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedModelEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleFuelEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedFuelEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleTransmissionEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedTransmissionEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleTractionEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedTractionEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleStatusEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedStatusEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleImportDialogOpen = (attributeName: 'vehicleImportOneCarDialogOpenend' | 'vehicleImportDialogOpenend') => {
    this.setState(prevState => ({
      ...prevState,
      [attributeName]: true
    }))
  };

  handleImportDialogClose = (attributeName: 'vehicleImportOneCarDialogOpenend' | 'vehicleImportDialogOpenend') => {
    this.setState(prevState => ({
      ...prevState,
      [attributeName]: false
    }))
  };

  renderVehicleImport() {
    return (
      <div style={{ display: 'flex' }}>
        {/* <div className="text-center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={()=>this.handleImportDialogOpen('vehicleImportDialogOpenend')}
          >
            {this.cbContext.translatorService.Tranlate(
              'VEHICLES_VEHICLE_IMPORT_VEHICLES',
              'Importa Vehicule'
            )}
          </Button>
        </div> */}
        {this.props.appState.appUser?.userRole?.code === 'MAVO_ADMIN' ? <div style={{ marginLeft: 10 }} className="text-center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.handleImportDialogOpen('vehicleImportOneCarDialogOpenend')}
          >
            {this.cbContext.translatorService.Tranlate(
              'VEHICLES_VEHICLE_IMPORT_MANUAL',
              'Importa Vehicul Manual'
            )}
          </Button>
        </div> : ''}
        <div>
          <VehicleImportModal
            isOpen={this.state.vehicleImportDialogOpenend}
            handleClose={() => this.handleImportDialogClose('vehicleImportDialogOpenend')}
            translatorService={this.cbContext.translatorService}
            vehicleService={this.cbContext.vehicleService}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        </div>
        <div>
          <VehicleImportOneCarModal
            isOpen={this.state.vehicleImportOneCarDialogOpenend}
            handleClose={() => this.handleImportDialogClose('vehicleImportOneCarDialogOpenend')}
            translatorService={this.cbContext.translatorService}
            vehicleService={this.cbContext.vehicleService}
            enqueueSnackbar={this.props.enqueueSnackbar}
            language={this.props.appState.language}
            dateFormat={this.props.appState.dateFormat}
          />
        </div>
      </div>
    );
  }

  renderFilters = () => {
    const selectedMakes = this.state.selectedMakeEntities;
    let models = this.state.modelEntities;

    if (selectedMakes.length) {
      const makeIds = selectedMakes.map(({ id }) => id);
      models = this.state.modelEntities.filter(({ dependencyId }) => {
        return makeIds.includes(dependencyId!);
      });
    }

    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="orgEntities"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.organizationEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedOrgEntities}
                onChange={(e: any, newValue: any | null) => this.handleOrgEntitiesChange(newValue)}
                getOptionLabel={(option: Organization) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="orgEntities"
                    value={this.state.selectedOrgEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_ORGANIZATION',
                      'Organizatie'
                    )}
                    fullWidth
                  />
                )}
              />

              <TextValidator
                fullWidth
                id="locationEntities"
                className="m-0 mt-1"
                name="color"
                placeholder={this.cbContext.translatorService.Tranlate(
                  'ORDERS_LOCATION_LABEL',
                  'Locatie'
                )}
                value={this.state.location}
                onChange={(e: any) => {
                  this.setState({
                    location: e.target.value
                  });
                }}
                onBlur={() => this.loadVehicles(this.state.page, this.state.pageSize)}
                validators={['required']}
                label={this.cbContext.translatorService.Tranlate(
                  'ORDERS_LOCATION_LABEL',
                  'Locatie'
                )}
              />

              <Autocomplete
                id="make"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.makeEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedMakeEntities}
                onChange={(e: any, newValue: any | null) => this.handleMakeEntitiesChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="makeEntities"
                    value={this.state.selectedMakeEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MAKE',
                      'Marca'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="model"
                size="small"
                className="m-0 mt-2"
                multiple
                options={models.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedModelEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleModelEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="modelEntities"
                    value={this.state.selectedModelEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MODEL',
                      'Model'
                    )}
                    fullWidth
                  />
                )}
              />

              <TextValidator
                fullWidth
                id="vin"
                className="m-0 mt-1"
                name="color"
                placeholder={this.cbContext.translatorService.Tranlate(
                  'CALCULATION_DETAILS_VIN',
                  'Vin'
                )}
                value={this.state.vin}
                onChange={(e: any) => {
                  this.setState({
                    vin: e.target.value
                  });
                }}
                onBlur={() => this.loadVehicles(this.state.page, this.state.pageSize)}
                validators={['required']}
                label={this.cbContext.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'Vin')}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="fuel"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.fuelEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedFuelEntities}
                onChange={(e: any, newValue: any | null) => this.handleFuelEntitiesChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="fuelEntities"
                    value={this.state.selectedFuelEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_FUEL',
                      'Combustibil'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="transmission"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.transmissionEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedTransmissionEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleTransmissionEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="transmissionEntities"
                    value={this.state.selectedTransmissionEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_TRANSMISSION',
                      'Transmisie'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="traction"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.tractionEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedTractionEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleTractionEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="tractionEntities"
                    value={this.state.selectedTractionEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_TRACTION',
                      'Tractiune'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="status"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_STATUS',
                      'Status'
                    )}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
        {this.state.isCarSoldDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isCarSoldDialogOpen}
            fullScreen={false}
            disableBackdropClick
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                    {this.cbContext.translatorService.Tranlate(
                      'CASE_OTHER_SERVICE_FORM_SELLING_OFFER_DIALOG',
                      'Pret Vanzare'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingDialog} />
              </div>
              {this.state.salesWokflowForm && !this.state.isLoadingDialog ? (
                <CaseDetailsCaseTabSaleOfferFormDialog
                  key={this.state.salesWokflowForm.id}
                  {...this.props}
                  workflowForm={this.state.salesWokflowForm}
                  closeDialog={this.closeCarSoldDialogOnSaveButton}
                  isSaleOfferType={true}
                />
              ) : (
                ''
              )}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </div>
    );
  };

  onCellClick = (
    colData: any,
    cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }
  ) => {
    if (cellMeta.colIndex === 12) {
      return;
    }
    this.onClick(this.state.vehiclesList.vehicles[cellMeta.dataIndex].vehicle.id);
  };

  tableOptions = () => {
    return {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onCellClick: this.onCellClick,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadVehicles(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadVehicles(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };

  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('MENU_VEHICLES', 'Stoc Vehicule')}
              />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  {this.renderVehicleImport()}
                  {this.props.appState?.appUser?.userRole?.code === 'MAVO_ADMIN' ?
                    <ImportVehiclesRenaultButton
                      translatorService={this.cbContext.translatorService}
                      vehicleService={this.cbContext.vehicleService}
                      enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                    : null}
                </div>
                {this.renderFilters()}
                {this.state.isLoadingVehicles ? (
                  <Loader />
                ) : (
                  <MUIDataTable
                    title=""
                    data={this.state.vehiclesList.vehicles}
                    columns={this.getColumns()}
                    options={this.tableOptions()}
                  />
                )}
              </div>
            </TabPanel>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings,
    vehicleState: state.vehicle
  }),
  null
)(withSnackbar(Vehicles as any));
