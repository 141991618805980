import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Grid, Button, Box } from '@material-ui/core';
import OrganizationDetailsTab from '../organizations/OrganizationDetailsTab';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import OrganizationUsers from '../organizations/OrganizationUsers';
import OrganizationInterfaces from '../organizations/OrganizationInterfaces';
import OrganizationParameters from '../organizations/OrganizationParameters';
import OrganizationNewCaseType from './organizationCase/OrganizationNewCaseType';
import OrganizationTariffs from '../tariffs/Tariffs';
import Workflows from '../workflows/Workflows';
import { RouteComponentProps } from 'react-router';
import { PageTitle } from '../../../layout-components';
import OrganizationFilters from '../../admin/organizations/OrganizationFilters';
import { Organization } from '../../../interfaces/Organization';
import OrganizationJobType from './OrganizationJobTypes';
import OrganizationJobTypes from './OrganizationJobTypes';
import { RoleClaimsEnum, RoleCodeEnum } from '../../../helpers/Constants';
import OrganizationMaintenancePackages from './OrganizationMaintenancePackages';
import OrganizationOilViscosities from './OrganizationOilViscosities';
import OrganizationInvoiceRanges from './OrganizationInvoiceRanges';

interface IOrganizationAdminStateState {
  selectedTab: number;
  organization: Organization | null;
}

type IOrganizationAdminProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationAdmin extends React.PureComponent<
  IOrganizationAdminProps,
  IOrganizationAdminStateState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;
  state = {
    selectedTab: 0,
    organization: null
  } as IOrganizationAdminStateState;

  componentDidMount = async () => {
    const orgId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(orgId)) {
      return;
    }
    const organizations = await this.organizationService.GetUserOrganizationsByIds([orgId]);

    this.setState({
      organization: organizations.length === 1 ? organizations[0] : null
    });
  };

  onButtonClick = (newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        {
          this.hasRight(RoleClaimsEnum.AdminService) ? (
            <PageTitle
              titleHeading={this.state.organization ? this.state.organization.name : ''}
              backButtonName={this.translatorService.Tranlate(
                'EDIT_WORKFLOW_BACK_TO_ORGANIZATION_BTN',
                'Organizatie'
              )}
              backButtonHref={'/admin/hoorganizations/' + this.props.match.params.id}
            />
          ) : (
            <PageTitle
              titleHeading={this.state.organization ? this.state.organization.name : ''}
              backButtonName={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_BACK_TO_HO_BTN',
                'Organizatii'
              )}
              backButtonHref="/admin/hoorganizations"
              {...this.props}
            />
          )
        }
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Box height={500}>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 0 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={
                    this.state.selectedTab === 0 ? 'text-white h-100' : 'text-dark h-100 h-100'
                  }
                  onClick={() => this.onButtonClick(0)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'ORGANIZATIONS_TAB_GENERAL_DATA',
                      'Date Generale'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 1 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 1 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(1)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_USERS', 'Utilizatori')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 2 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 2 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(2)}
                >
                  <b>
                    {this.translatorService.Tranlate('ORGANIZATIONS_TAB_PARAMETERS', 'Parametri')}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 3 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 3 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(3)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'ORGANIZATIONS_TAB_INTERFACES',
                      'Interfete Organizatie'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 4 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 4 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(4)}
                >
                  <b>
                    {this.translatorService.Tranlate('ORGANIZATIONS_TAB_NEW_CASE', 'Dosar nou')}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 5 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 5 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(5)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_TARIFFS', 'Tarife')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 6 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 6 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(6)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'ORGANIZATIONS_TAB_WORKFLOWS',
                      'Fluxuri de lucru'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 7 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 7 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(7)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_JOB_TYPE', 'Job Type')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 8 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 8 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(8)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_MAINTENANCE_PACKAGES', 'Pachete Mentenanta')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 9 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 9 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(9)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_OIL_VISCOSITIES_PRICE', 'Preturi Ulei')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 10 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab ===10 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(10)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE', 'Plaja Facturi')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 11}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 11 ? 'secondary.main' : ''}
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 11 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(11)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_FILTERS', 'Filtre')}</b>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            {this.state.selectedTab === 0 ? (
              <OrganizationDetailsTab {...this.props}></OrganizationDetailsTab>
            ) : null}
            {this.state.selectedTab === 1 ? (
              <OrganizationUsers {...this.props}></OrganizationUsers>
            ) : null}
            {this.state.selectedTab === 2 ? <OrganizationParameters {...this.props} /> : null}
            {this.state.selectedTab === 3 ? (
              <OrganizationInterfaces {...this.props}></OrganizationInterfaces>
            ) : null}
            {this.state.selectedTab === 4 ? (
              <OrganizationNewCaseType {...this.props}></OrganizationNewCaseType>
            ) : null}
            {this.state.selectedTab === 5 ? (
              <OrganizationTariffs {...this.props}></OrganizationTariffs>
            ) : null}
            {this.state.selectedTab === 6 ? <Workflows {...this.props}></Workflows> : null}
            {this.state.selectedTab === 7 ? (
              <OrganizationJobTypes {...this.props}></OrganizationJobTypes>
            ) : null}
            {this.state.selectedTab === 8 ? (
              <OrganizationMaintenancePackages {...this.props}></OrganizationMaintenancePackages>
            ) : null}
            {this.state.selectedTab === 9 ? (
              <OrganizationOilViscosities {...this.props}></OrganizationOilViscosities>
            ) : null}
            {this.state.selectedTab === 10 ? (
              <OrganizationInvoiceRanges {...this.props}></OrganizationInvoiceRanges>
            ) : null}
            {this.state.selectedTab === 11 ? (
              <OrganizationFilters {...this.props}></OrganizationFilters>
            ) : null}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationAdmin as any));
