import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Card, Grid, Tab, Tabs, AppBar, TextField } from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { isNullOrUndefined } from 'util';
import { IReferential } from '../../interfaces/IReferential';
import { Organization } from '../../interfaces/Organization';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import { VehicleExtensionList, VehicleFilters } from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';
import Loader from '../Loader';
import noop from 'lodash/noop';

type VehiclesHistoryProps = { appState: AppState } & ProviderContext & RouteComponentProps;

interface IVehiclesHistoryState {
  vehiclesList: VehicleExtensionList;
  organizationEntities: Organization[];
  selectedOrgEntities: Organization[];
  location: string;
  makeEntities: IReferential[];
  selectedMakeEntities: IReferential[];
  modelEntities: IReferential[];
  selectedModelEntities: IReferential[];
  fuelEntities: IReferential[];
  selectedFuelEntities: IReferential[];
  transmissionEntities: IReferential[];
  selectedTransmissionEntities: IReferential[];
  tractionEntities: IReferential[];
  selectedTractionEntities: IReferential[];
  statusEntities: IReferential[];
  selectedStatusEntities: IReferential[];
  selectedTab: number;
  isLoading: boolean;
  isLoadingVehicles: boolean;
  count: number;
  page: number;
  pageSize: number;
  vehicleImportDialogOpenend: boolean;
  vin: string;
}

class VehiclesHistory extends React.PureComponent<VehiclesHistoryProps, IVehiclesHistoryState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    vehiclesList: {} as VehicleExtensionList,
    organizationEntities: [],
    selectedOrgEntities: [],
    location: '',
    makeEntities: [],
    selectedMakeEntities: [],
    modelEntities: [],
    selectedModelEntities: [],
    fuelEntities: [],
    selectedFuelEntities: [],
    transmissionEntities: [],
    selectedTransmissionEntities: [],
    tractionEntities: [],
    selectedTractionEntities: [],
    statusEntities: [],
    selectedStatusEntities: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingVehicles: false,
    count: 1,
    page: 0,
    pageSize: 10,
    vehicleImportDialogOpenend: false,
    vin: ''
  } as IVehiclesHistoryState;

  public async componentDidMount() {
    const vehiclesFilters = {
      makeIds: [],
      modelIds: [],
      fuelTypeIds: [],
      transmissionIds: [],
      tractionIds: [],
      statusIds: [],
      organizationIds: [],
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      location: '',
      text: '',
      vehicleHistory: true,
      vin: ''
    } as VehicleFilters;

    this.setState({
      isLoading: true
    });

    const [vehicles] = await Promise.all([
      this.cbContext.vehicleService.GetVehicles(vehiclesFilters)
    ]);

    const [organizations] = await Promise.all([
      this.cbContext.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(vehicles.vehicles.map(({ vehicle }) => vehicle.organizationId)))
      )
    ]);

    vehicles.vehicles.forEach(({ vehicle }) => {
      const org = organizations.find((item) => item.id === vehicle.organizationId);
      vehicle.organization = isNullOrUndefined(org) ? null : org;
    });

    this.setState(
      {
        vehiclesList: vehicles,
        organizationEntities: organizations,
        count: vehicles.total,
        page: vehicles.page - 1
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadVehicles = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingVehicles: true
    });

    const vehiclesFilters = {
      makeIds: this.state.selectedMakeEntities.map((item) => item.id),
      modelIds: this.state.selectedModelEntities.map((item) => item.id),
      fuelTypeIds: this.state.selectedFuelEntities.map((item) => item.id),
      transmissionIds: this.state.selectedTransmissionEntities.map((item) => item.id),
      tractionIds: this.state.selectedTractionEntities.map((item) => item.id),
      statusIds: this.state.selectedStatusEntities.map((item) => item.id),
      organizationIds: this.state.selectedOrgEntities.map((item) => item.id),
      page: page + 1,
      pageSize: rowsPerPage,
      location: this.state.location,
      text: '',
      vehicleHistory: true,
      vin: this.state.vin
    } as VehicleFilters;

    const vehicleList = await this.cbContext.vehicleService.GetVehicles(vehiclesFilters);
    const [organizations] = await Promise.all([
      this.cbContext.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(vehicleList.vehicles.map(({ vehicle }) => vehicle.organizationId)))
      )
    ]);

    vehicleList.vehicles.forEach(({ vehicle }) => {
      const org = organizations.find((item) => item.id === vehicle.organizationId);
      vehicle.organization = isNullOrUndefined(org) ? null : org;
    });

    this.setState({
      vehiclesList: vehicleList,
      organizationEntities: organizations,
      page: vehicleList.page - 1,
      pageSize: rowsPerPage,
      count: vehicleList.total,
      isLoadingVehicles: false
    });
  };

  reloadFilters = async () => {
    const vehicleHistory = true;
    const filters = await this.cbContext.vehicleService.GetVehiclesFilters(vehicleHistory);

    const [organizations] = await Promise.all([
      this.cbContext.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(filters.organizationIds))
      )
    ]);

    this.setState({
      organizationEntities: organizations,
      makeEntities: filters.makeList,
      modelEntities: filters.modelList,
      fuelEntities: filters.fuelTypes,
      transmissionEntities: filters.transmissionTypes,
      tractionEntities: filters.tractionTypes,
      statusEntities: filters.vehicleStatuses,
      isLoading: false
    });
  };

  onClick = (id: number) => {
    this.props.history.push('/vehicle/' + id);
  };

  getColumns = () => {
    const columns = [
      {
        name: 'vehicle.id',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_ID', 'ID'),
        options: {
          sort: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.organization.displayName',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_ORGANIZATION',
          'Organizatie'
        )
      },
      {
        name: 'vehicle.vin',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_VIN', 'VIN')
      },
      {
        name: 'vehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.version',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_VERSION',
          'Marca Model Versiune'
        ),
        options: {
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const vehicleExtension = this.state.vehiclesList.vehicles.find(
              ({ vehicle }) => vehicle.id === tableMeta.rowData[0]
            );

            return (
              <div>
                <span className="font-size-sm">
                  {vehicleExtension?.vehicle?.make?.displayName}{' '}
                </span>
                <span className="font-size-sm">
                  {vehicleExtension?.vehicle?.model?.displayName}{' '}
                </span>
                <span className="font-size-sm">{value} </span>
              </div>
            );
          }
        }
      },
      {
        name: 'vehicle.firstRegistrationDate',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_FIRST_REGISTRATION_DATE',
          'Data primei inmatriculari'
        ),
        options: {
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {moment
                      .utc(value)
                      .local()
                      .toDate()
                      .toLocaleDateString(this.props.appState.language)}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'addedInStockDate',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_ADDED_IN_STOCK_DATE',
          'Data intrare stoc'
        ),
        options: {
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .toDate()
                          .toLocaleDateString(this.props.appState.language)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'vehicle.makeDate',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_YEAR_OF_FABRICATION',
          'An'
        ),
        options: {
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">{value ? new Date(value).getFullYear() : null}</div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'vehicle.kilometersNr',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_NR_OF_KM', 'KM')
      },
      {
        name: 'vehicle.horsePower',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_HP', 'CP')
      },
      {
        name: 'vehicle.engineSize',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_ENGINE_SIZE', 'CM3')
      },
      {
        name: 'vehicle.currentStatus.status.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_STATUS', 'Status')
      },
      {
        name: 'vehicle',
        label: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_PURCHASE_EUR_PRICE',
          'Pret Achizitie EUR cu TVA'
        ),
        options: {
          customBodyRender: (value: any) => {
            return value.purchaseVatRate === 0 ? (
              <>
                {value.purchasePrice}
                <div className="text-light bg-warning rounded p-1">
                  {this.cbContext.translatorService.Tranlate(
                    'VEHICLES_WITHOUT_VAT',
                    'Achizitionat cu TVA=0'
                  )}
                </div>
              </>
            ) : (
              value.purchasePrice
            );
          }
        }
      }
    ];

    return columns;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadVehicles(0, this.state.pageSize)
    );
  };
  handleOrgEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedOrgEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleMakeEntitiesChange = (newValue: any[]) => {
    const selectedModels = [...this.state.selectedModelEntities];
    const selectedMakeIds = newValue.map(({ id }) => id);

    const models = selectedModels.filter(({ dependencyId }) =>
      selectedMakeIds.includes(dependencyId)
    );

    this.setState(
      {
        selectedMakeEntities: newValue,
        selectedModelEntities: models
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleModelEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedModelEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleFuelEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedFuelEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleTransmissionEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedTransmissionEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleTractionEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedTractionEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleStatusEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedStatusEntities: newValue
      },
      async () => await this.loadVehicles(this.state.page, this.state.pageSize)
    );
  };

  handleImportDialogOpen = () => {
    this.setState({
      vehicleImportDialogOpenend: true
    });
  };

  handleImportDialogClose = () => {
    this.setState({
      vehicleImportDialogOpenend: false
    });
  };

  renderFilters = () => {
    const selectedMakes = this.state.selectedMakeEntities;
    let models = this.state.modelEntities;

    if (selectedMakes.length) {
      const makeIds = selectedMakes.map(({ id }) => id);
      models = this.state.modelEntities.filter(({ dependencyId }) => {
        return makeIds.includes(dependencyId!);
      });
    }

    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="orgEntities"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.organizationEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedOrgEntities}
                onChange={(e: any, newValue: any | null) => this.handleOrgEntitiesChange(newValue)}
                getOptionLabel={(option: Organization) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="orgEntities"
                    value={this.state.selectedOrgEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_ORGANIZATION',
                      'Organizatie'
                    )}
                    fullWidth
                  />
                )}
              />

              <TextValidator
                fullWidth
                id="locationEntities"
                className="m-0 mt-1"
                name="color"
                placeholder={this.cbContext.translatorService.Tranlate(
                  'ORDERS_LOCATION_LABEL',
                  'Locatie'
                )}
                value={this.state.location}
                onChange={(e: any) => {
                  this.setState({
                    location: e.target.value
                  });
                }}
                onBlur={() => this.loadVehicles(this.state.page, this.state.pageSize)}
                validators={['required']}
                label={this.cbContext.translatorService.Tranlate(
                  'ORDERS_LOCATION_LABEL',
                  'Locatie'
                )}
              />

              <Autocomplete
                id="make"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.makeEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedMakeEntities}
                onChange={(e: any, newValue: any | null) => this.handleMakeEntitiesChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="makeEntities"
                    value={this.state.selectedMakeEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MAKE',
                      'Marca'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="model"
                size="small"
                className="m-0 mt-2"
                multiple
                options={models.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedModelEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleModelEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="modelEntities"
                    value={this.state.selectedModelEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MODEL',
                      'Model'
                    )}
                    fullWidth
                  />
                )}
              />
              <TextValidator
                fullWidth
                id="vin"
                className="m-0 mt-1"
                name="color"
                placeholder={this.cbContext.translatorService.Tranlate(
                  'CALCULATION_DETAILS_VIN',
                  'Vin'
                )}
                value={this.state.vin}
                onChange={(e: any) => {
                  this.setState({
                    vin: e.target.value
                  });
                }}
                onBlur={() => this.loadVehicles(this.state.page, this.state.pageSize)}
                validators={['required']}
                label={this.cbContext.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'Vin')}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="fuel"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.fuelEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedFuelEntities}
                onChange={(e: any, newValue: any | null) => this.handleFuelEntitiesChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="fuelEntities"
                    value={this.state.selectedFuelEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_FUEL',
                      'Combustibil'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="transmission"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.transmissionEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedTransmissionEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleTransmissionEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="transmissionEntities"
                    value={this.state.selectedTransmissionEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_TRANSMISSION',
                      'Transmisie'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="traction"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.tractionEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedTractionEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleTractionEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="tractionEntities"
                    value={this.state.selectedTractionEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_TRACTION',
                      'Tractiune'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="status"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_STATUS',
                      'Status'
                    )}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };

  onCellClick = (
    colData: any,
    cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }
  ) => {
    this.onClick(this.state.vehiclesList.vehicles[cellMeta.dataIndex].vehicle.id);
  };

  tableOptions = () => {
    return {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onCellClick: this.onCellClick,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadVehicles(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadVehicles(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };

  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate(
                  'MENU_HISTORY_VEHICLES',
                  'MENU_HISTORY_VEHICLES'
                )}
              />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                {this.renderFilters()}
                {this.state.isLoadingVehicles ? (
                  <Loader />
                ) : (
                  <MUIDataTable
                    title=""
                    data={this.state.vehiclesList.vehicles}
                    columns={this.getColumns()}
                    options={this.tableOptions()}
                  />
                )}
              </div>
            </TabPanel>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(VehiclesHistory as any));
