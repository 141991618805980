import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Button,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepConnector,
  AppBar,
  IconButton,
  Typography,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { ApplicationContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { Card } from 'reactstrap';
import TabPanel from '../_shared/Tabs';
import { ScaleLoader } from 'react-spinners';
import {
  WorkflowCurrentSectionStepResult,
  WorkflowSection,
  WorkflowSectionStep,
  WorkflowSectionStepForm,
  WorkflowSectionStepResult
} from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import CaseDetailsCaseTabSummaryForm from './CaseDetailsCaseTabSummaryForm';
import CaseDetailsCaseTabHistoryForm from './CaseDetailsCaseTabHistoryForm';
import CaseDetailsCaseTabModifyWorkflowForm from './CaseDetailsCaseTabModifyWorkflowForm';
import CaseDetailsCaseTabDocumentsForm from './CaseDetailsCaseTabDocumentsForm';
import CaseDetailsCaseTabCarPhotosForm from './CaseDetailsCaseTabCarPhotosForm';
import CaseDetailsCaseTabCalculationsForm from './CaseDetailsCaseTabCalculationsForm';
import CaseDetailsCaseTabCarDetailsForm from './CaseDetailsCaseTabCarDetailsForm';
import CaseDetailsCaseTabEventDetailsForm from './CaseDetailsCaseTabEventDetailsForm';
import CaseDetailsCaseTabBackToServiceForm from './CaseDetailsCaseTabBackToServiceForm';
import CaseDetailsCaseTabOtherServiceForm from './CaseDetailsCaseTabOtherServiceForm';
import CaseDetailsCaseTabPaymentDetailsForm from './CaseDetailsCaseTabPaymentDetailsForm';
import CaseDetailsCaseTabOrderForm from './CaseDetailsCaseTabOrderForm';
import CaseDetailsCaseServiceAppointmentsForm from './CaseDetailsCaseServiceAppointmentsForm';
import CaseDetailsCaseTabClientDataForm from './CaseDetailsCaseTabClientDataForm';
import CaseDetailsCaseTabCarDetailsExtendedContainer from './vehicleDetails/CaseDetailsCaseTabCarDetailsExtendedContainer';
import CaseDetailsCaseTabCarRepairCostForm from './CaseDetailsCaseTabCarRepairCostForm';
import CaseDetailsCaseTabSaleOfferForm from './CaseDetailsCaseTabSaleOfferForm';
import CaseDetailsCaseTabVehicleAdvertComponent from './advert/CaseDetailsCaseTabVehicleAdvertComponent';
import { FormCode } from '../../helpers/forms/FormCode';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Xarrow from 'react-xarrows';
import CloseIcon from '@material-ui/icons/Close';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Badge } from '@material-ui/core';
import CaseDetailsCaseTabEventBillingDetailsForm from './CaseDetailsCaseTabEventBillingDetailsForm';
import CaseDetailsCaseTabSoldCarForm from './CaseDetailsCaseTabSoldCarForm';
import CaseDetailsCaseServiceAddAppointmentForm from './CaseDetailsCaseServiceAddAppointmentForm';
import CaseDetailsCaseTabInOutDataForm from './CaseDetailsCaseTabInOutDataForm';
import CaseDetailsCaseTabIntegrationClientDataForm from './CaseDetailsCaseTabIntegrationClientDataForm';
import CaseDetailsCaseCarfixAnalysisForm from './CaseDetailsCaseCarfixAnalysisForm';
import _ from 'lodash';
import CaseDetailsCaseCarfixBackToServiceForm from './CaseDetailsCaseCarfixBackToServiceForm';
import CaseDetailsCaseCarfixValidateAppointmentForm from './CaseDetailsCaseCarfixValidateAppointmentForm';
import CaseDetailsCaseRepairInProgressForm from './CaseDetailsCaseRepairInProgressForm';


interface ICaseDetailsCaseTabState {
  workflowForms: WorkflowSectionStepForm[];

  workflowCaseSections: WorkflowSection[];
  workflowCaseSectionSteps: WorkflowSectionStep[];
  workflowCaseSectionStepForms: WorkflowSectionStepForm[];

  selectedWorkflowSection: WorkflowSection | null;
  selectedStep: WorkflowSectionStep | null;

  activeStep: number;
  selectedTabForm: number;
  isLoading: boolean;

  formData: any;
  refreshDesign: boolean;
  isDialogOpen: boolean;
  isDialogFormOpen: boolean;

  renderForms: boolean;
}

export const excludedSteps = [FormCode.CASE_VEHICLE_PB_ANUNT];


type ICaseDetailsCaseTabProps = { caseSettingsState: CaseSettingsState } & ProviderContext &
  RouteComponentProps<{ id: string; formId: string }>;

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

class CaseDetailsCaseTab extends React.PureComponent<
  ICaseDetailsCaseTabProps,
  ICaseDetailsCaseTabState
> {
  static contextType = ApplicationContext;
  state = {
    workflowCaseSections: [],
    selectedWorkflowSection: null,
    selectedStep: null,
    workflowCaseSectionSteps: [],
    workflowForms: [],
    workflowCaseSectionStepForms: [],
    selectedTabForm: 0,
    activeStep: 0,
    isLoading: false,
    formData: null,
    refreshDesign: false,
    isDialogOpen: false,
    isDialogFormOpen: false,
    renderForms: true
  } as ICaseDetailsCaseTabState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    if (isNullOrUndefined(caseSettings)) {
      return;
    }

    if (!isNullOrUndefined(this.props.match.params.formId)) {
      const sections = caseSettings.workflow
        .workflowSections!.filter((item) => item.isActive)
        .sort(this.sortFunction);
      const currentStatus = this.props.caseSettingsState.case!.caseStatus;
      const selectedWorkflowSection = sections.find((item: WorkflowSection) => item.isCurrent(currentStatus.caseStatusId));
      for (const section of sections) {
        const steps = section.workflowSectionSteps
          .filter((item) => item.isActive)
          .sort(this.sortFunction);
        for (const step of steps) {
          const forms = step.workflowSectionStepForms
            .filter((item) => item.isActive)
            .sort(this.sortFunction);
          const formIndex = forms.findIndex(
            (form) => form.id.toString() === this.props.match.params.formId
          );
          if (formIndex !== -1 && section.id === selectedWorkflowSection?.id) {
            this.setState(
              {
                isLoading: true,
                selectedWorkflowSection: section,
                selectedStep: step,
                selectedTabForm: formIndex,
                activeStep: steps.findIndex((item) => item.id === step.id)
              },
              () => {
                this.loadCaseSettings(section.id);
              }
            );

            return;
          }
        }
      }
    }

    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadCaseSettings(null);
      }
    );
  }

  loadCaseSettings = (workflowCaseSectionId: number | null) => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const initialWorkflowCaseSectionId = _.cloneDeep(workflowCaseSectionId);
    if (isNullOrUndefined(caseSettings)) {
      return;
    }

    const workflowCaseSections = caseSettings
      .workflow!.workflowSections!.filter((item) => item.isActive)
      .sort(this.sortFunction);

    workflowCaseSectionId =
      workflowCaseSectionId === null ? workflowCaseSections[0].id : workflowCaseSectionId;

    const workflowCaseSection = workflowCaseSections.find(
      (item) => item.id === workflowCaseSectionId
    );

    this.setState(
      {
        isLoading: false,
        workflowCaseSections: workflowCaseSections,
        selectedWorkflowSection: isNullOrUndefined(workflowCaseSection) ? null : workflowCaseSection
      },
      () => {
        if (initialWorkflowCaseSectionId  === null) {
          this.loadInitialWorkflowSteps();
        } else {
          this.loadWorkflowSteps();
        }
      }
    );
  };

  loadWorkflowSteps = () => {
    if (isNullOrUndefined(this.state.selectedWorkflowSection)) {
      return;
    }

    const workflowCaseSectionSteps = this.state.selectedWorkflowSection.workflowSectionSteps
      .filter((item) => item.isActive)
      .sort(this.sortFunction);

    const selectedStep =
      this.state.selectedStep == null ? workflowCaseSectionSteps[0] : this.state.selectedStep;
    const workflowCaseSectionStepForms =
      workflowCaseSectionSteps.length === 0
        ? []
        : selectedStep.workflowSectionStepForms.sort(this.sortFunction);

    this.setState({
      isLoading: false,
      workflowCaseSectionSteps: workflowCaseSectionSteps,
      workflowCaseSectionStepForms: workflowCaseSectionStepForms,
      selectedTabForm: 0
    });
  };

  loadInitialWorkflowSteps = () => {
    if (isNullOrUndefined(this.state.selectedWorkflowSection)) {
      return;
    }

    const workflowCaseSectionSteps = this.state.selectedWorkflowSection.workflowSectionSteps
      .filter((item) => item.isActive)
      .sort(this.sortFunction);

    const selectedStep =
      this.state.selectedStep == null ? workflowCaseSectionSteps[0] : this.state.selectedStep;
    const workflowCaseSectionStepForms =
      workflowCaseSectionSteps.length === 0
        ? []
        : selectedStep.workflowSectionStepForms.sort(this.sortFunction);

    this.setState({
      isLoading: false,
      workflowCaseSectionSteps: workflowCaseSectionSteps,
      workflowCaseSectionStepForms: workflowCaseSectionStepForms,
      selectedTabForm: 0
    }, () => {
      this.loadCurrentWorkflowSelectedStep();
    });
  };

  loadCurrentWorkflowSelectedStep = () => {
    const currentStatus = this.props.caseSettingsState.case!.caseStatus;
    console.log('currentStatus', currentStatus);
    const selectedWorkflowSection = this.getSelectedWorkflowSection(currentStatus.caseStatusId);
    console.log('selectedWorkflowSection', selectedWorkflowSection);
    if (!isNullOrUndefined(selectedWorkflowSection)) {
      this.handleTabChange(selectedWorkflowSection);
    }
  }
  
  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  onButtonClick = (newValue: WorkflowSection) => {
    this.setState(
      {
        selectedWorkflowSection: newValue,
        selectedTabForm: 0,
        activeStep: 0,
        selectedStep: null,
        isDialogOpen: true
      },
      () => {
        this.loadWorkflowSteps();
      }
    );
  };

  renderSectionTabs = () => {
    const sectionTabs = [] as any[];

    let selectedItemId = -1;
    if (!isNullOrUndefined(this.state.selectedWorkflowSection)) {
      selectedItemId = this.state.selectedWorkflowSection.id;
    }

    if (this.props.caseSettingsState.caseSettings !== null) {
      const sections = this.state.workflowCaseSections.length;
      this.state.workflowCaseSections.forEach((item: WorkflowSection, index: number) => {
        sectionTabs.push(
          <Box
            height={80}
            key={index}
            border={1}
            borderColor="text.disabled"
            borderBottom={index === sections - 1 ? null : 0}
            borderRadius="0%"
            bgcolor={
              selectedItemId === item.id
                ? 'secondary.main'
                : item.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId)
                ? 'green'
                : ''
            }
          >
            <Button
              fullWidth
              className={
                selectedItemId === item.id ||
                item.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId)
                  ? 'text-white h-100'
                  : 'text-dark h-100'
              }
              onClick={() => this.onButtonClick(item)}
            >
              <b>{item.section!.displayName}</b>
            </Button>
          </Box>
        );
      });
    }
    return sectionTabs;
  };

  renderSectionTabs2 = () => {
    const sectionTabs = [] as any[];

    let selectedItemId = -1;
    if (!isNullOrUndefined(this.state.selectedWorkflowSection)) {
      selectedItemId = this.state.selectedWorkflowSection.id;
    }

    if (this.props.caseSettingsState.caseSettings !== null) {
      this.state.workflowCaseSections.forEach((item: WorkflowSection, index: number) => {
        sectionTabs.push(
          <Step key={index + 1} id={(index + 1).toString()} className="p-0">
            <Box
              border={1}
              borderColor="text.disabled"
              borderRadius="0%"
              width="115px"
              bgcolor={selectedItemId === item.id ? 'secondary.main' : ''}
            >
              <div className="text-center d-flex flex-column justify-content-end pt-4">
                <div className={selectedItemId === item.id ? 'text-white ' : 'text-dark '}>
                  <b>{index + 1 + '. ' + item.section!.displayName}</b>
                </div>
                <IconButton
                  aria-label="add"
                  className="p-1"
                  color="primary"
                  onClick={() => this.onButtonClick(item)}
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
            </Box>
            <Xarrow
              color="#000000"
              start={index.toString()}
              end={(index + 1).toString()}
              strokeWidth={2}
            />
          </Step>
        );
      });
    }
    return (
      <div>
        <div
          id="0"
          style={{
            backgroundColor: '#009e0f',
            borderRadius: '50%',
            width: 36,
            height: 36,
            marginLeft: 63.5,
            marginBottom: 25
          }}
        ></div>
        <Stepper
          nonLinear
          className="flex justify-content-center"
          orientation="horizontal"
          activeStep={this.state.activeStep}
          style={{ backgroundColor: 'transparent' }}
        >
          {sectionTabs}
        </Stepper>
        <Xarrow
          color="#000000"
          start={this.state.workflowCaseSections.length.toString()}
          end={(this.state.workflowCaseSections.length + 1).toString()}
          strokeWidth={2}
        />
        <div
          className="ml-auto"
          id={(this.state.workflowCaseSections.length + 1).toString()}
          style={{
            backgroundColor: '#cf2a27',
            borderRadius: '50%',
            width: 36,
            height: 36,
            marginRight: 63.5,
            marginTop: 25
          }}
        ></div>
      </div>
    );
  };

  renderStepper = () => {
    return (
      <div className="pt-5 mt-4">
        <Stepper
          nonLinear
          orientation="vertical"
          className="flex justify-content-center pt-0"
          connector={<StepConnector style={{ maxWidth: '100px' }} />}
          activeStep={this.state.activeStep}
          style={{ backgroundColor: 'transparent' }}
        >
          {this.renderSteps()}
        </Stepper>
      </div>
    );
  };

  
  componentDidUpdate(prevProps: ICaseDetailsCaseTabProps) {
    if (prevProps.caseSettingsState.case!.caseStatus.caseStatusId != this.props.caseSettingsState.case!.caseStatus.caseStatusId) {
      const prevStatus = prevProps.caseSettingsState.case!.caseStatus;
      const currentStatus = this.props.caseSettingsState.case!.caseStatus;

      const prevStep = this.getStepBasedCaseStatus(this.state.workflowCaseSectionSteps, prevStatus.caseStatusId);
      const step = prevStep.step;
      console.log('prevStep', prevStep);
      if (!isNullOrUndefined(step.workflowStep) && excludedSteps.includes(step.workflowStep!.code ?? '')) {
        console.log('Nu trece la pasul urmator!');
        return;
      }

      const selectedWorkflowSection = this.getSelectedWorkflowSection(currentStatus.caseStatusId);
      console.log('selectedWorkflowSection', selectedWorkflowSection);
      if (!isNullOrUndefined(selectedWorkflowSection)) {
        this.handleTabChange(selectedWorkflowSection);
      }

    }
  }

  handleTabChange = (newValue: WorkflowSection) => {
    this.setState(
      {
        selectedWorkflowSection: newValue,
        selectedTabForm: 0,
        activeStep: 0,
        selectedStep: null,
        isDialogOpen: true
      },
      () => {
        this.loadWorkflowCurrentSteps();
      }
    );
  };

  loadWorkflowCurrentSteps = () => {
    if (isNullOrUndefined(this.state.selectedWorkflowSection)) {
      return;
    }

    /*
    Get current section steps
    */
    const workflowCaseSectionSteps = this.state.selectedWorkflowSection.workflowSectionSteps
      .filter((item) => item.isActive)
      .sort(this.sortFunction);

    console.log('Current section steps', workflowCaseSectionSteps);
    /*
    Get current step
    */
    const workflowCaseSectionStep = this.getStepBasedCaseStatus(workflowCaseSectionSteps, this.props.caseSettingsState.case!.caseStatus.caseStatusId);
    console.log('Current step', workflowCaseSectionStep);
    const step = workflowCaseSectionStep.index;
    const selectedStep = workflowCaseSectionStep.step;

    this.setState(
      {
        isLoading: false,
        workflowCaseSectionSteps: workflowCaseSectionSteps,
        workflowCaseSectionStepForms: selectedStep.workflowSectionStepForms
          .filter((item) => item.isActive)
          .sort(this.sortFunction),
        selectedTabForm: 0,
        activeStep: step,
        selectedStep: selectedStep,
        isDialogFormOpen: true,
        renderForms: false
      },
      () => {
        this.setState({ renderForms: true });
      }
    );
  };

  getSelectedWorkflowSection = (caseStatusId: number): WorkflowSection | null => {
    const selectedWorkflowSection = this.state.workflowCaseSections.find((item: WorkflowSection) => item.isCurrent(caseStatusId));
    if (!isNullOrUndefined(selectedWorkflowSection)) {
      return selectedWorkflowSection;
    }
    return null;
  }

  getStepBasedCaseStatus = (workflowCaseSectionSteps: WorkflowSectionStep[], caseStatusId: number) : WorkflowSectionStepResult => {
    const index = workflowCaseSectionSteps.findIndex((step: WorkflowSectionStep) => step.isCurrent(caseStatusId));

    if (index !== -1) {
      return {
        index: index,
        step: workflowCaseSectionSteps[index]
      };
    }

    return {
      index: 0,
      step: workflowCaseSectionSteps[0]
    };
  }

  handleStep = (step: number, selectedStep: WorkflowSectionStep) => () => {
    this.setState(
      {
        activeStep: step,
        selectedStep: selectedStep,
        workflowCaseSectionStepForms: selectedStep.workflowSectionStepForms
          .filter((item) => item.isActive)
          .sort(this.sortFunction),
        selectedTabForm: 0,
        isDialogFormOpen: true,
        renderForms: false
      },
      () => this.setState({ renderForms: true })
    );
  };

  renderSteps = () => {
    const renderSteps = [] as any[];
    this.state.workflowCaseSectionSteps.forEach((step: WorkflowSectionStep, index: number) => {
      renderSteps.push(
        <Step key={index} className="mb-2">
          <StepButton onClick={this.handleStep(index, step)}>
            <StepLabel className="text-left ">
              {step.workflowStep!.displayName}
              {step.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId) ? (
                <StyledBadge
                  className="ml-3"
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  variant="dot"
                >
                  <></>
                </StyledBadge>
              ) : null}
            </StepLabel>
          </StepButton>
        </Step>
      );
    });
    return renderSteps;
  };

  isStepCurrent = (step: WorkflowSectionStep): boolean => {
    const caseStatusId = this.props.caseSettingsState.case!.caseStatus.caseStatusId;

    const result = step.workflowSectionStepCaseStatuses.find(
      (cs) => cs.caseStatusId === caseStatusId
    );

    return result !== undefined;
  };

  renderSteps2 = () => {
    const renderSteps = [] as any[];
    let selectedItemId = 0;
    if (!isNullOrUndefined(this.state.selectedStep)) {
      selectedItemId = this.state.selectedStep.id;
    }

    renderSteps.push(<Step id="0step"></Step>);
    this.state.workflowCaseSectionSteps.forEach((step: WorkflowSectionStep, index: number) => {
      renderSteps.push(
        <Step key={index}>
          <Box
            border={1}
            id={(index + 1).toString() + 'step'}
            borderColor="text.disabled"
            borderRadius="0%"
            width="115px"
            bgcolor={selectedItemId === step.id ? 'secondary.main' : ''}
          >
            <div className="text-center d-flex flex-column justify-content-end pt-4">
              <div className={selectedItemId === step.id ? 'text-white ' : 'text-dark '}>
                <b>{index + 1 + '. ' + step.workflowStep!.displayName}</b>
              </div>
              <IconButton
                aria-label="add"
                className="p-1"
                color="primary"
                onClick={this.handleStep(index, step)}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
            </div>
          </Box>
          <Xarrow
            color="#000000"
            start={index.toString() + 'step'}
            end={(index + 1).toString() + 'step'}
            strokeWidth={2}
          />
        </Step>
      );
    });
    renderSteps.push(
      <Step id={(this.state.workflowCaseSectionSteps.length + 1).toString() + 'step'}></Step>
    );

    return (
      <div>
        <Stepper
          nonLinear
          className="flex justify-content-center"
          orientation="horizontal"
          activeStep={this.state.activeStep}
          style={{ backgroundColor: 'transparent' }}
        >
          {renderSteps}
        </Stepper>
        <Xarrow
          color="#000000"
          start={this.state.workflowCaseSectionSteps.length.toString() + 'step'}
          end={(this.state.workflowCaseSectionSteps.length + 1).toString() + 'step'}
          strokeWidth={2}
        />
        <Dialog
          onClose={this.closeDialogForm}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogFormOpen}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" style={{ color: '#1F2857' }}>
            <Typography variant="h4" align="center">
              {this.state.selectedWorkflowSection!.section!.displayName}
            </Typography>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#1F2857' }}
              onClick={this.closeDialogForm}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className="p-0" dividers style={{ backgroundColor: '#fafafa' }}>
            {this.renderTabForms()}
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  handleChangeTabForm = (event: React.ChangeEvent<any>, newValue: number) => {
    this.setState({
      selectedTabForm: newValue
    });
  };

  renderForms = () => {
    const renderForms = [] as any[];
    this.state.workflowCaseSectionStepForms.forEach(
      (form: WorkflowSectionStepForm, index: number) => {
        renderForms.push(<Tab key={index} label={form.displayName} />);
      }
    );

    return renderForms;
  };
  renderTabForms = () => {
    return (
      <div className="h-100">
        <AppBar position="static" elevation={0} color="secondary">
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px'
              }
            }}
            value={this.state.selectedTabForm}
            onChange={this.handleChangeTabForm}
            variant="fullWidth"
            centered={true}
            indicatorColor="primary"
            aria-label="simple tabs example"
            className="tab-forms"
          >
            {this.renderForms()}
          </Tabs>
        </AppBar>
        {this.renderSelectedForm()}
      </div>
    );
  };

  renderSelectedForm = () => {
    const tabPanels = [] as any[];
    if (!this.state.renderForms) {
      return null;
    }

    this.state.workflowCaseSectionStepForms.forEach(
      (item: WorkflowSectionStepForm, index: number) => {
        tabPanels.push(
          <TabPanel value={this.state.selectedTabForm} index={index} key={index}>
            {item.form.code === FormCode.CASE_SUMMARY ? (
              <CaseDetailsCaseTabSummaryForm key={item.id} {...this.props} />
            ) : null}
            {item.form.code === FormCode.CASE_HISTORY ? (
              <CaseDetailsCaseTabHistoryForm key={item.id} {...this.props} />
            ) : null}
            {item.form.code === FormCode.CASE_CHANGE_WORKFLOW ? (
              <CaseDetailsCaseTabModifyWorkflowForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_ATTACHMENTS ? (
              <CaseDetailsCaseTabDocumentsForm key={item.id} {...this.props} workflowForm={item} />
            ) : null}
            {item.form.code === FormCode.CASE_CAR_PHOTOS ? (
              <CaseDetailsCaseTabCarPhotosForm key={item.id} {...this.props} workflowForm={item} />
            ) : null}
            {item.form.code === FormCode.CASE_CAR_DATA ? (
              <CaseDetailsCaseTabCarDetailsForm key={item.id} workflowForm={item} {...this.props} />
            ) : null}
            {item.form.code === FormCode.CASE_EVENT_DATA ? (
              <CaseDetailsCaseTabEventDetailsForm
                key={item.id}
                workflowForm={item}
                {...this.props}
              />
            ) : null}
            {item.form.code === FormCode.CASE_EVENT_DATE_FACT ? (
              <CaseDetailsCaseTabEventBillingDetailsForm
                key={item.id}
                workflowForm={item}
                {...this.props}
              />
            ) : null}
            {item.form.code === FormCode.CASE_CALCULATIONS ? (
              <CaseDetailsCaseTabCalculationsForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                stepFormId={item.id}
              />
            ) : null}
            {item.form.code === FormCode.CASE_ORDER ? (
              <CaseDetailsCaseTabOrderForm key={item.id} {...this.props} workflowForm={item} />
            ) : null}
            {item.form.code === FormCode.CASE_BACK_TO_SERVICE ? (
              <CaseDetailsCaseTabBackToServiceForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_OTHER_SERVICE ? (
              <CaseDetailsCaseTabOtherServiceForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_PAYMENT_DETAILS_SERVICE ? (
              <CaseDetailsCaseTabPaymentDetailsForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                withCurrency={false}
              />
            ) : null}
            {item.form.code === FormCode.CASE_SERVICE_APPOINTMENT ? (
              <CaseDetailsCaseServiceAppointmentsForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_LKQ_APPOINTMENT ? (
              <CaseDetailsCaseServiceAddAppointmentForm 
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_CLIENT_DATA ? (
              <CaseDetailsCaseTabClientDataForm key={item.id} {...this.props} workflowForm={item} />
            ) : null}
            {item.form.code === FormCode.CASE_CAR_DETAILS ? (
              <CaseDetailsCaseTabCarDetailsExtendedContainer
                vehicleInfoDisabled={false}
                showCostTypes={false}
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_CAR_REPAIR_COST ? (
              <CaseDetailsCaseTabCarRepairCostForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_SALE_OFFER ? (
              <CaseDetailsCaseTabSaleOfferForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                isSaleOfferType={true}
              />
            ) : null}
            {item.form.code === FormCode.CASE_BUY_OFFER ? (
              <CaseDetailsCaseTabSaleOfferForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                isSaleOfferType={false}
              />
            ) : null}
            {item.form.code === FormCode.CASE_SOLD_CAR ? (
              <CaseDetailsCaseTabSoldCarForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                isSaleOfferType={true}
              />
            ) : null}
            {item.form.code === FormCode.CASE_PAYMENT_DETAILS_WITH_CURRENCY ? (
              <CaseDetailsCaseTabPaymentDetailsForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                withCurrency={true}
              />
            ) : null}
            {item.form.code === FormCode.CASE_VEHICLE_ADVERT ? (
              <CaseDetailsCaseTabVehicleAdvertComponent
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CASE_IN_OUT_REPORT ? (
              <CaseDetailsCaseTabInOutDataForm key={item.id} {...this.props} workflowForm={item} />
            ) : null}
            {item.form.code === FormCode.CARFIX_CASE_CLIENT_DATA ? (
              <CaseDetailsCaseTabIntegrationClientDataForm key={item.id} {...this.props} workflowForm={item} />
            ) : null}
            {item.form.code === FormCode.CARFIX_ANALYSIS_STEP ? (
              <CaseDetailsCaseCarfixAnalysisForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CARFIX_BACK_TO_SERVICE_STEP ? (
              <CaseDetailsCaseCarfixBackToServiceForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CARFIX_APPOINTMENT_VALIDATION_STEP ? (
              <CaseDetailsCaseCarfixValidateAppointmentForm
                key={item.id}
                {...this.props}
                workflowForm={item}
              />
            ) : null}
            {item.form.code === FormCode.CARFIX_REPAIR_IN_PROGRESS_STEP ? (
              <CaseDetailsCaseRepairInProgressForm
                key={item.id}
                {...this.props}
                workflowForm={item}
                tabChange={this.handleTabChange}
              />
            ) : null}
          </TabPanel>
        );
      }
    );
    return tabPanels;
  };

  closeDialog = async () => {
    this.setState({ isDialogOpen: false });
  };

  closeDialogForm = async () => {
    this.setState({ isDialogFormOpen: false });
  };

  public render() {
    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {/* <IconButton aria-label="add" color="primary" className="m-2" onClick={(e) => this.setState({ refreshDesign: !this.state.refreshDesign })}>
                    <CachedRoundedIcon />
                </IconButton> */}
        {!this.state.isLoading ? (
          this.state.refreshDesign ? (
            <div>
              {this.renderSectionTabs2()}
              <Dialog
                onClose={this.closeDialog}
                aria-labelledby="customized-dialog-title"
                open={this.state.isDialogOpen}
                fullScreen={true}
              >
                <DialogTitle
                  id="customized-dialog-title"
                  style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
                >
                  <Typography variant="h4" align="center">
                    {this.state.selectedWorkflowSection!.section!.displayName}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className="position-absolute"
                    style={{ right: 12, top: 12, color: '#ffffff' }}
                    onClick={this.closeDialog}
                    size={'small'}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                  <div style={{ marginTop: 120 }}>{this.renderSteps2()}</div>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            <Grid container spacing={2} className="mt-4">
              <Grid item xs={12} lg={2}>
                {this.renderSectionTabs()}
              </Grid>
              <Grid item xs={12} lg={10}>
                <Card className="rounded-0 border-1">
                  <Grid container>
                    <Grid item xs={12} lg={3} className="bg-secondary">
                      {this.renderStepper()}
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      {this.renderTabForms()}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          )
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(CaseDetailsCaseTab as any));
