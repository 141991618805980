import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import {
  Tooltip,
  Box,
  Button,
  Card,
  CardContent,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent,
  Switch
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WorkflowSupplier } from '../../../interfaces/Workflow';
import { Organization, OrganizationInterface } from '../../../interfaces/Organization';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import NumberFormat from 'react-number-format';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { ReferentialCode, OrganizationTypeCode, SupplierApiType, Descripted, enumToDescriptedArray } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import * as R from 'ramda';
import { X } from 'react-feather';

interface IWorkflowSupplierState {
  newWorkflowSupplier: WorkflowSupplier | null;
  workflowSuppliers: WorkflowSupplier[];
  isLoading: boolean;
  workflowSupplierTypes: Organization[];
  workflowSupplierInterfaceTypes: OrganizationInterface[];
  // workflowCarPartCategoryTypes: { id: number, displayName: string }[]
  selectedWorkflowSupplierType: Organization | null;
  selectedWorkflowSupplierInterfaceType: OrganizationInterface | null;
  // selectedWorkflowCarPartCategoryTypeMultiple: { id: number, displayName: string }[],
  // selectedWorkflowCarPartCategoryType: { id: number, displayName: string } | null,
  urlInterface: string;
  discountTariff: number;
  userName: string;
  password: string;
  port: number | null;
  workflowId: number;
  isDialogOpen: boolean;
  workflowSupplierId: number | null;
  supplierApiType: Descripted<typeof SupplierApiType> | null;
  supplierApiTypes: Descripted<typeof SupplierApiType>[];
  clientId: string;
}

type IWorkflowSupplierProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowSuppliers extends React.PureComponent<
  IWorkflowSupplierProps,
  IWorkflowSupplierState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;
  state = {
    newWorkflowSupplier: null,
    workflowSuppliers: [],
    workflowSupplierTypes: [],
    workflowSupplierInterfaceTypes: [],
    // workflowCarPartCategoryTypes: [],
    selectedWorkflowSupplierType: null,
    selectedWorkflowSupplierInterfaceType: null,
    // selectedWorkflowCarPartCategoryTypeMultiple: [],
    // selectedWorkflowCarPartCategoryType: null,
    urlInterface: '',
    discountTariff: 0,
    userName: '',
    password: '',
    port: 0,
    workflowId: 0,
    isLoading: false,
    isDialogOpen: false,
    workflowSupplierId: null,
    supplierApiType: null,
    supplierApiTypes: enumToDescriptedArray(SupplierApiType),
    clientId: ''
  } as IWorkflowSupplierState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowSuppliers();
      }
    );
  }

  loadWorkflowSuppliers = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const [ws, workflowSuppliers] = await Promise.all([
      this.workflowService.GetWorkflowSuppliers(workflowId),
      this.organizationService.GetHOOrganizationsByTypes([OrganizationTypeCode.SUPPLIER])
    ]);

    const interfacePromises = [] as any[];
    const supplierIds: number[] = [];

    ws.forEach((ws) => {
      const supplier = workflowSuppliers.find((item) => item.id === ws.supplierId);
      // const carPartCategory = workflowCarPartCategoryTypes.find(item=> item.id === ws.carPartCategoryId);

      if (supplierIds.indexOf(ws.supplierId) === -1) {
        supplierIds.push(ws.supplierId);
        interfacePromises.push(this.organizationService.GetOrganizationInterfaces(ws.supplierId));
      }

      ws.supplier = R.isNil(supplier) ? null : supplier;
      // ws.carPartCategory = R.isNil(carPartCategory) ? null :  carPartCategory;
    });

    const result = await Promise.all(interfacePromises);

    const organizationInterfaces: OrganizationInterface[] = [];
    result.forEach((item) => {
      organizationInterfaces.push(...item);
    });

    ws.forEach((ws) => {
      const suppInterface = organizationInterfaces.find(
        (item) => item.id === ws.supplierInterfaceId
      );
      ws.supplierInterface = R.isNil(suppInterface) ? null : suppInterface;
    });

    this.setState({
      workflowSupplierTypes: workflowSuppliers.filter((item) => item.isActive === true),
      // workflowCarPartCategoryTypes: workflowCarPartCategoryTypes,
      isLoading: false,
      workflowId: workflowId,
      workflowSuppliers: ws,
    });
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowSuppliers = [...this.state.workflowSuppliers];
    const updatedWorkflowSupplierIndex = workflowSuppliers.findIndex((o) => o.id === id);

    if (updatedWorkflowSupplierIndex >= 0) {
      workflowSuppliers[updatedWorkflowSupplierIndex] = {
        ...workflowSuppliers[updatedWorkflowSupplierIndex],
        isActive: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowSupplier(
          workflowSuppliers[updatedWorkflowSupplierIndex]
        );

        this.setState({ workflowSuppliers: workflowSuppliers });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  reloadWorkflowSuppliers = async (supplierId: number | null) => {
    if (supplierId === null) {
      this.setState({
        workflowSuppliers: [],
        isLoading: false
      });
      return;
    }

    const workflowSupplierInterfaceTypes = await this.organizationService.GetOrganizationInterfaces(
      supplierId
    );

    this.setState({
      workflowSupplierInterfaceTypes: workflowSupplierInterfaceTypes,
      isLoading: false
    });
  };

  addOrgForm = async () => {
    try {
      debugger;
      const workflowSuppliers: WorkflowSupplier[] = [];
      if (this.state.workflowSupplierId === null) {
        if (
          this.state.workflowSuppliers.findIndex(
            (item) => item.supplierId === this.state.selectedWorkflowSupplierType!.id && item.supplierApiTypeId === this.state.supplierApiType!.id
            //  &&  this.state.selectedWorkflowCarPartCategoryTypeMultiple.findIndex(itemm => itemm.id === item.carPartCategoryId)!==-1
          ) !== -1
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ERROR_MSG_WORKFLOW_SUPPLIER_EXISTS',
              'Furnizorul exista deja!'
            ),
            { variant: 'error' }
          );
          return;
        }
        let contor = 0;
        // for (let carPart of this.state.selectedWorkflowCarPartCategoryTypeMultiple) {
        workflowSuppliers.push({
          id: 0,
          supplierId: this.state.selectedWorkflowSupplierType!.id,
          supplierInterfaceId: this.state.selectedWorkflowSupplierInterfaceType!.id,
          urlInterface: this.state.urlInterface,
          discountTariff: this.state.discountTariff,
          userName: this.state.userName,
          password: this.state.password,
          port: this.state.port,
          clientId: this.state.clientId,
          // carPartCategoryId: carPart!.id,
          workflowId: this.state.workflowId,
          supplierApiTypeId: this.state.supplierApiType!.id
        } as WorkflowSupplier);
        contor = contor + 1;
        // }

        await this.workflowService.AddWorkflowSuppliers(workflowSuppliers);
      } else {
        if (this.state.workflowSuppliers.findIndex(
              (item) => item.supplierId === this.state.selectedWorkflowSupplierType!.id  && item.supplierApiTypeId === this.state.supplierApiType!.id && item.id !== this.state.workflowSupplierId
            ) !== -1
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ERROR_MSG_WORKFLOW_SUPPLIER_EXISTS',
              'Furnizorul exista deja!'
            ),
            { variant: 'error' }
          );
          return;
        }

        await this.workflowService.UpdateWorkflowSupplier({
          id: this.state.workflowSupplierId,
          supplierId: this.state.selectedWorkflowSupplierType!.id,
          supplierInterfaceId: this.state.selectedWorkflowSupplierInterfaceType!.id,
          urlInterface: this.state.urlInterface,
          discountTariff: this.state.discountTariff,
          userName: this.state.userName,
          password: this.state.password,
          port: this.state.port,
          clientId: this.state.clientId,
          // carPartCategoryId: this.state.selectedWorkflowCarPartCategoryType!.id,
          workflowId: this.state.workflowId,
          supplierApiTypeId: this.state.supplierApiType!.id
        } as WorkflowSupplier);
      }

      this.setState(
        {
          isLoading: true,
          isDialogOpen: false
        },
        async () => await this.loadWorkflowSuppliers()
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteInterfaceChange = (newValue: any | null) => {
    this.setState(
      {
        selectedWorkflowSupplierInterfaceType: newValue,
        urlInterface: newValue === null ? '' : newValue.url,
        port: newValue === null ? 80 : newValue.port
        //isLoading: true
      }
      // , () => {
      //     this.reloadWorkflowSuppliers(newValue === null ? null : newValue.id);
      // }
    );
  };

  // handleAutocompleteCarPartMultipleChange = (newValue: any[]) => {
  //     this.setState({ selectedWorkflowCarPartCategoryTypeMultiple: newValue });
  // }

  // handleAutocompleteCarPartChange = (newValue: any) => {
  //     this.setState({ selectedWorkflowCarPartCategoryType: newValue });
  // }
  handleAutocompleteSupplierChange = async (newValue: Organization | null) => {
    if (newValue === null) {
      this.setState(
        {
          selectedWorkflowSupplierType: null,
          workflowSupplierInterfaceTypes: [],
          urlInterface: '',
          selectedWorkflowSupplierInterfaceType: null
          //isLoading: true
        }
        // , () => {
        //     this.reloadWorkflowSuppliers(newValue === null ? null : newValue!.id);
        // }
      );
      return;
    }
    debugger;
    const orgInterfaceTypes = await this.organizationService.GetOrganizationInterfaces(newValue.id);
    this.setState(
      {
        selectedWorkflowSupplierType: newValue,
        workflowSupplierInterfaceTypes: orgInterfaceTypes,
        selectedWorkflowSupplierInterfaceType: null
        //isLoading: true
      }
      // , () => {
      //     this.reloadWorkflowSuppliers(null);
      // }
    );
  };

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text' || 'password') {
      value = event.currentTarget.value;
    }
    this.setState({
      ...this.state,
      [event.target.id]: value
    });
  };

  handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      discountTariff: event.target.value === null ? 0 : parseInt(event.target.value)
    });
  };

  handlePortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ port: event.target.value === null ? 0 : parseInt(event.target.value) });
  };
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator
        decimalScale={2}
        isNumericString
        suffix=" %"
      />
    );
  };

  IntFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        min={0}
        decimalScale={0}
        isNumericString
      />
    );
  };

  public renderAddForm() {
    const isMultiple = this.state.workflowSupplierId === null ? true : false;
    return (
      <ValidatorForm onSubmit={(e) => this.addOrgForm()}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="suppliers"
              className="m-2"
              options={this.state.workflowSupplierTypes.sort(function (a, b) {
                return a.name.localeCompare(b.name);
              })}
              value={this.state.selectedWorkflowSupplierType}
              onChange={(e: any, newValue: Organization | null) =>
                this.handleAutocompleteSupplierChange(newValue)
              }
              getOptionLabel={(option: Organization) => option.name}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="suppliers"
                  value={this.state.selectedWorkflowSupplierType}
                  label={this.translatorService.Tranlate(
                    'WORKFLOW_SUPPLIERS_TAB_SUPPLIER_LABEL',
                    'Furnizor'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="interfaces"
              className="m-2"
              options={this.state.workflowSupplierInterfaceTypes}
              value={this.state.selectedWorkflowSupplierInterfaceType}
              onChange={(e: any, newValue: OrganizationInterface | null) =>
                this.handleAutocompleteInterfaceChange(newValue)
              }
              getOptionLabel={(option: OrganizationInterface) => option.code || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="interfaces"
                  value={this.state.selectedWorkflowSupplierInterfaceType}
                  label={this.translatorService.Tranlate(
                    'WORKFLOW_SUPPLIERS_TAB_SUPPLIER_INTERFACE_LABEL',
                    'Interfata Furnizor'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
            <TextValidator
              fullWidth
              name="userName"
              className="m-2"
              id="userName"
              value={this.state.userName}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'WORKFLOW_SUPPLIERS_TAB_USERNAME_LABEL',
                'Utilizator'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />

            <TextValidator
              fullWidth
              name="port"
              className="m-2"
              id="port"
              value={this.state.port}
              onChange={this.handlePortChange}
              label={this.translatorService.Tranlate('WORKFLOW_SUPPLIERS_TAB_PORT_LABEL', 'Port')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              InputProps={{
                inputComponent: this.IntFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              name="discountTariff"
              className="m-2"
              id="discountTariff"
              value={this.state.discountTariff}
              onChange={this.handleDiscountChange}
              label={this.translatorService.Tranlate(
                'WORKFLOW_SUPPLIERS_TAB_DISCOUNT_LABEL',
                'Discount (%)'
              )}
              validators={['required', 'minNumber:0', 'maxNumber:100']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                this.translatorService.Tranlate(
                  'WORKFLOW_SUPPLIERS_TAB_DISCOUNT_VALIDATORS_NUMBER_BETWENN',
                  'Valoarea discount-ului trebuie sa fie cuprinsa intre 0 si 100'
                ),
                this.translatorService.Tranlate(
                  'WORKFLOW_SUPPLIERS_TAB_DISCOUNT_VALIDATORS_NUMBER_BETWENN',
                  'Valoarea discount-ului trebuie sa fie cuprinsa intre 0 si 100'
                )
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />

            <TextValidator
              fullWidth
              name="password"
              className="m-2"
              id="password"
              type="password"
              value={this.state.password}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'WORKFLOW_SUPPLIERS_TAB_PASSWORD_LABEL',
                'Parola'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <Autocomplete
                disableClearable
                id="supplierApiType"
                className="m-2"
                options={this.state.supplierApiTypes}
                disabled={!this.state.supplierApiTypes.length}
                value={this.state.supplierApiType}
                onChange={(e: any, newValue: any | null) =>
                  this.setState({ supplierApiType : newValue })
                }
                getOptionLabel={(option: any) => option.description}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="supplierApiType"
                    value={this.state.supplierApiType}
                    label={this.translatorService.Tranlate('DETAILS_OF_CAR_STATUS_LABEL1', 'Tip Api Furnizor')}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                    ]}
                  />
                )}
              />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              name="urlInterface"
              className="m-2"
              id="urlInterface"
              value={this.state.urlInterface}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'WORKFLOW_SUPPLIERS_TAB_SUPPLIER_INTERFACE_URL_LABEL',
                'URL'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          </Grid>
          
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              name="clientId"
              className="m-2"
              id="clientId"
              value={this.state.clientId}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'WORKFLOW_SUPPLIERS_TAB_CLIENT_ID_LABEL',
                'Client Id'
              )}
            />
          </Grid>
        </Grid>

        <div className="text-right">
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('SAVE', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },

      {
        name: 'supplier.name',
        label: this.translatorService.Tranlate(
          'WORKFLOW_SUPPLIERS_TAB_SUPPLIER_HEADER',
          'Furnizor'
        ),
        options: {
          customBodyRender: (value: Organization, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className="text-primary"
                onClick={(e) => this.newWorkflowSupplier(tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        }
      },
      {
        name: 'supplierInterface',
        label: this.translatorService.Tranlate(
          'WORKFLOW_SUPPLIERS_TAB_SUPPLIER_INTERFACE_HEADER',
          'Interfata Furnizor'
        ),
        options: {
          sort: false,
          customBodyRender: (value: OrganizationInterface) => {
            return R.isNil(value) ? '' : value.code;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'urlInterface',
        label: this.translatorService.Tranlate(
          'WORKFLOW_SUPPLIERS_TAB_SUPPLIER_INTERFACE_URL_HEADER',
          'URL'
        ),
        searchable: true,
        options: {
          setCellProps: () => ({ style: { maxWidth: '300px', 'overflow-wrap': 'break-word' } })
        } as MUIDataTableColumnOptions
      },
      {
        name: 'discountTariff',
        label: this.translatorService.Tranlate(
          'WORKFLOW_SUPPLIERS_TAB_DISCOUNT_HEADER',
          'Discount (%)'
        ),
        searchable: true
      },
      {
        name: 'userName',
        label: this.translatorService.Tranlate(
          'WORKFLOW_SUPPLIERS_TAB_USERNAME_HEADER',
          'Utilizator'
        ),
        searchable: true
      },
      {
        name: 'password',
        label: this.translatorService.Tranlate('WORKFLOW_SUPPLIERS_TAB_PASSWORD_HEADER', 'Parola'),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return <span>***</span>;
          }
        }
      },
      {
        name: 'clientId',
        label: this.translatorService.Tranlate(
          'WORKFLOW_SUPPLIERS_TAB_CLIENT_ID_HEADER',
          'Client Id'
        ),
        searchable: true
      },
      {
        name: 'supplierApiTypeId',
        label: this.translatorService.Tranlate('WORKFLOW_SUPPLIERS_TAB_PASSWORD_HEADER11', 'Tip Api Furnizor'),
        searchable: true,
        options: {
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return this.state.supplierApiTypes.find((item) => item.id === value)!.description
          }
        }
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteWorkflowSupplier(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  deleteWorkflowSupplier = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.workflowService.RemoveWorkflowSupplier(id);
      await this.loadWorkflowSuppliers();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  newWorkflowSupplier = async (supplierId: number | null) => {
    let newWorkflowSupplier: WorkflowSupplier;
    if (supplierId === null) {
      newWorkflowSupplier = { ...this.state.newWorkflowSupplier } as WorkflowSupplier;
      newWorkflowSupplier.workflowId = this.state.workflowId;
      newWorkflowSupplier.id = this.state.workflowSuppliers.length;
    } else {
      newWorkflowSupplier = this.state.workflowSuppliers.find((item) => item.id === supplierId)!;
    }
    this.setState({
      newWorkflowSupplier: newWorkflowSupplier,
      selectedWorkflowSupplierType: supplierId === null ? null : newWorkflowSupplier.supplier,
      selectedWorkflowSupplierInterfaceType:
        supplierId === null ? null : newWorkflowSupplier.supplierInterface,
      // selectedWorkflowCarPartCategoryType: supplierId === null ? null : newWorkflowSupplier.carPartCategory,
      // selectedWorkflowCarPartCategoryTypeMultiple: [],
      urlInterface: supplierId === null ? '' : newWorkflowSupplier.urlInterface,
      discountTariff: supplierId === null ? 0 : newWorkflowSupplier.discountTariff,
      userName: supplierId === null ? '' : newWorkflowSupplier.userName,
      password: supplierId === null ? '' : newWorkflowSupplier.password,
      clientId: supplierId === null ? '' : newWorkflowSupplier.clientId,
      port: supplierId === null ? null : newWorkflowSupplier.port,
      workflowSupplierId: supplierId,
      supplierApiType: this.state.supplierApiTypes.find((item) => item.id === newWorkflowSupplier.supplierApiTypeId)!,
      isDialogOpen: true
    });
  };

  closeDialog = async () => {
    this.setState({ isDialogOpen: false });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newWorkflowSupplier(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('WORKFLOWS_TAB_SUPPLIERS', 'Furnizori')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>

        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowSuppliers}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.state.workflowSupplierId === null
                    ? this.translatorService.Tranlate(
                        'WORKFLOW_SUPPLIERS_TAB_NEW_MODAL_TITLE',
                        'Adauga furnizor'
                      )
                    : this.translatorService.Tranlate(
                        'WORKFLOW_SUPPLIERS_TAB_UPDATE_MODAL_TITLE',
                        'Actualizeaza furnizor'
                      )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{this.renderAddForm()}</DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowSuppliers as any));
