import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  AppUserAdminState,
  ApplicationState,
  AppActionCreators
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Box,
  Card,
  CardContent
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Organization } from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { AppUser } from '../../../interfaces/AppUser';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IReferential } from '../../../interfaces/IReferential';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { isNullOrUndefined } from 'util';

export interface ExternalEditUserProps {
  userChanged: () => Promise<void>;
}

type EditUserProps = AppUserAdminState &
  ProviderContext &
  typeof AppUserAdminActionCreators &
  typeof AppActionCreators &
  RouteComponentProps<{ id: string }> &
  ExternalEditUserProps;

interface IEditUserState {
  organizations: Organization[];
  managers: AppUser[];
  userRoles: IReferential[];
  users: AppUser[];
  user: AppUser;
  countryList: IReferential[];
  costCenterList: IReferential[];
}

class EditUser extends React.PureComponent<EditUserProps, IEditUserState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizations: [],
    managers: [] as AppUser[],
    users: [] as AppUser[],
    userRoles: [],
    user: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      isActive: true,
      organization: null,
      organizationId: 0,
      jobFunction: '',
      manager: null,
      managerId: '',
      role: null,
      roleId: null,
      id: null,
      isMavo: false,
      hideOrderPrices: false,
      country: null,
      countryId: null,
      costCenterId: null,
    costCenter: null
    } as AppUser,
    countryList: [],
    costCenterList: []
  };

  public async componentDidMount() {
    const refRole = Referentials.referential.find((item) => item.code === ReferentialCode.UserRole);
    const countryRef = Referentials.referential.find((item) => item.code === ReferentialCode.Country);
    const costCenterRef = Referentials.referential.find((item) => item.code === ReferentialCode.CostCenter);
    const [org, users, userRoles, countryList, costCenterList] = await Promise.all([
      this.organizationService.GetOrganizations(),
      this.appUserService.GetAppUsers(),
      this.appReferentialService.Get(refRole!.baseUrl),
      this.appReferentialService.Get(countryRef!.baseUrl),
      this.appReferentialService.Get(costCenterRef!.baseUrl)
    ]);

    let user = this.state.user;

    if (this.props.match.params.id.toUpperCase() !== 'NEW') {
      try {
        user = await this.appUserService.GetAppUser(this.props.match.params.id);

        const organization = org.find((o) => o.id === user.organizationId);
        user.organization = organization === undefined ? null : organization;

        const manager = users.users.find((m) => m.id === user.managerId);
        user.manager = manager === undefined ? null : manager;

        user.role = null;
        if (!isNullOrUndefined(user.roleId)) {
          const role = userRoles.find((item) => item.id === user.roleId);
          user.role = isNullOrUndefined(role) ? null : role;
        }
        user.country = null;
        if (!isNullOrUndefined(user.countryId)) {
          const country = countryList.find((item) => item.id === user.countryId);
          user.country = isNullOrUndefined(country) ? null : country;
        }
        user.costCenter = null;
        if (!isNullOrUndefined(user.costCenterId)) {
          const costCenter = costCenterList.find((item) => item.id === user.costCenterId);
          user.costCenter = isNullOrUndefined(costCenter) ? null : costCenter;
        }
      } catch (error) {}
    }

    this.setState({
      organizations: org,
      users: users.users,
      user: user,
      userRoles: userRoles.filter((item) => item.isActive),
      managers: this.filterMangers(users.users, user.organization),
      countryList: countryList,
      costCenterList: costCenterList
    });
  }

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    if (event.target.type === 'checkbox') {
      value = event.currentTarget.checked;
    }

    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        [event.target.id]: value
      }
    });
  };

  private handleAutocompleteChange = (
    property: string,
    propertyId: string,
    newValue: Organization | null | undefined | AppUser | IReferential
  ) => {
    this.setState({
      user: {
        ...this.state.user,
        [property]: newValue,
        [propertyId]: newValue === null || newValue === undefined ? null : newValue.id
        // gtId: newValue === null || newValue === undefined || property === "manager" ?
        //                                 this.state.user.gtId : newValue.gtId
      }
    });
  };

  private handleOrganizationChange = (newValue: Organization | null) => {
    this.setState({
      user: {
        ...this.state.user,
        organization: newValue,
        organizationId: newValue === null ? null : newValue.id,
        gtId: newValue === null || newValue === undefined ? this.state.user.gtId : newValue.gtId,
        hoId:
          newValue === null || newValue === undefined
            ? this.state.user.hoId
            : isNullOrUndefined(newValue.hoId)
            ? newValue.id
            : newValue.hoId,
        manager: null,
        managerId: null
      },
      managers: this.filterMangers(this.state.users, newValue)
    });
  };
  

  private saveUser = async () => {
    try {
      const users = await this.appUserService.GetAppUsers(this.state.user.email);

      if (users.totalCount > 1) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_USER_EXISTS', 'Utilizatorul exista deja!'),
          { variant: 'error' }
        );
        return;
      }
      if (users.totalCount === 1 && users.users[0].id !== this.state.user.id) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_USER_EXISTS', 'Utilizatorul exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      if (this.state.user.id !== null) {
        await this.appUserService.UpdateUser(this.state.user);
      } else {
        const newUser = await this.appUserService.AddUser(this.state.user);
        this.props.history.push('/admin/users/' + newUser.id);
      }

      this.props.userChanged();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public filterMangers = (users: AppUser[], newOrganization: Organization | null | undefined) => {
    return newOrganization === null || newOrganization === undefined
      ? []
      : users.filter((value) => value.organization!.hoId === newOrganization!.hoId);
  };

  public renderForm() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={4} alignContent="center" alignItems="center">
            <Grid item xs={12} lg={4}>
              <div className="text-center">
                <div className="avatar-icon-wrapper  m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper  m-0 d-140">
                    <FontAwesomeIcon
                      icon={['far', 'user']}
                      className="d-flex align-self-center display-1 "
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div className="p-3">
                <ValidatorForm onSubmit={this.saveUser} instantValidate={true}>
                  <TextValidator
                    fullWidth
                    name="email"
                    className="m-2"
                    id="email"
                    value={this.state.user.email}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate('EDIT_USER_USER_EMAIL_FIELD', 'Email')}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      ),
                      this.translatorService.Tranlate('VALIDATORS_EMAIL', 'Email invalid')
                    ]}
                  />
                  <TextValidator
                    fullWidth
                    className="m-2"
                    id="lastName"
                    name="lastName"
                    value={this.state.user.lastName}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate('EDIT_USER_LAST_NAME_FIELD', 'Nume')}
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                  <TextValidator
                    fullWidth
                    className="m-2"
                    id="firstName"
                    name="firstName"
                    value={this.state.user.firstName}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate('EDIT_USER_FIRST_NAME_FIELD', 'Prenume')}
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />

                  <TextField
                    fullWidth
                    className="m-2"
                    id="phoneNumber"
                    value={this.state.user.phoneNumber || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate('EDIT_USER_PHONE_FIELD', 'Telefon')}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="jobFunction"
                    value={this.state.user.jobFunction || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_USER_JOBFUNCTION_FIELD',
                      'Functie'
                    )}
                  />

                  <Autocomplete
                    id="organization"
                    className="m-2"
                    options={this.state.organizations}
                    value={this.state.user.organization}
                    onChange={(e: any, newValue: Organization | null) =>
                      this.handleOrganizationChange(newValue)
                    }
                    getOptionLabel={(option: Organization) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="organization"
                        value={this.state.user.organization}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_ORGANIZATION_FIELD',
                          'Entitate'
                        )}
                        fullWidth
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />

                  <Autocomplete
                    id="manager"
                    className="m-2"
                    options={this.state.managers}
                    value={this.state.user.manager}
                    onChange={(e: any, newValue: AppUser | null) =>
                      this.handleAutocompleteChange('manager', 'managerId', newValue)
                    }
                    getOptionLabel={(option: AppUser) => option.userName || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="manager"
                        value={this.state.user.manager}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_MANAGER_FIELD',
                          'Manager'
                        )}
                        fullWidth
                      />
                    )}
                  />
                  <Autocomplete
                    id="role"
                    className="m-2"
                    options={this.state.userRoles}
                    value={this.state.user.role}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleAutocompleteChange('role', 'roleId', newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="role"
                        value={this.state.user.role}
                        label={this.translatorService.Tranlate('EDIT_USER_ROLE_FIELD', 'Rol')}
                        fullWidth
                      />
                    )}
                  />

                  <Autocomplete
                    id="country"
                    className="m-2"
                    options={this.state.countryList}
                    value={this.state.user.country}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleAutocompleteChange('country', 'countryId', newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="country"
                        value={this.state.user.country}
                        label={this.translatorService.Tranlate('EDIT_USER_COUNTRY_FIELD', 'Tara')}
                        fullWidth
                      />
                    )}
                  />

                  <Autocomplete
                    id="costCenter"
                    className="m-2"
                    options={this.state.costCenterList}
                    value={this.state.user.costCenter}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleAutocompleteChange('costCenter', 'costCenterId', newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="costCenter"
                        value={this.state.user.costCenter}
                        label={this.translatorService.Tranlate('EDIT_USER_COST_CENTER_FIELD', 'Centru de cost')}
                        fullWidth
                      />
                    )}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.user.isActive}
                        onChange={(e) => this.handleTextChange(e)}
                        color="primary"
                        id="isActive"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={this.translatorService.Tranlate('EDIT_USER_ISACTIVE_FIELD', 'Stare')}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.user.isMavo}
                        onChange={(e) => this.handleTextChange(e)}
                        color="primary"
                        id="isMavo"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={this.translatorService.Tranlate('EDIT_USER_ISMAVO_FIELD', 'User MAVO')}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.user.hideOrderPrices}
                        onChange={(e) => this.handleTextChange(e)}
                        color="primary"
                        id="hideOrderPrices"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={this.translatorService.Tranlate('EDIT_USER_HIDE_ORDER_PRICES_FIELD', 'Nu trimite email de confirmare comanda')}
                  />

                  <div className="text-right">
                    <Button className="m-2" variant="contained" color="primary" type="submit">
                      {this.translatorService.Tranlate('EDIT_USER_SAVE_BTN', 'Salveaza')}
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box m={1} p={1}>
          <Card>
            <CardContent>{this.renderForm()}</CardContent>
          </Card>
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: ExternalEditUserProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
//export default EditUser as any
export default connect(
  (state: ApplicationState) => state.appUserAdmin,
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditUser as any));
