import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, IconButton, Card, CardContent } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { WorkflowOptimization, WorkflowPartner } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { IReferential } from '../../../interfaces/IReferential';
import { isNullOrUndefined } from 'util';

interface IWorkflowOptimizationState {
  workflowOptimization: WorkflowOptimization[];
  isLoading: boolean;
  statusList: IReferential[];
  selectedStatus: IReferential | null;
  optimizationTypeList: IReferential[];
  selectedOptimizationType: IReferential | null;
  selectedUserRole: IReferential | null;
  userRoles: IReferential[];
  workflowPartnerTypeList: WorkflowPartner[];
  selecteWorkflowPartnerType: WorkflowPartner | null;
}
export interface ExternalOptimizationFormActionProps {
  workflowFormId: number;
}

type IWorkflowOptimizationProps = ExternalOptimizationFormActionProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowOptimizations extends React.PureComponent<
  IWorkflowOptimizationProps,
  IWorkflowOptimizationState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowOptimization: [],
    isLoading: false,
    statusList: [],
    selectedStatus: null,
    optimizationTypeList: [],
    selectedOptimizationType: null,
    workflowPartnerTypeList: [],
    selecteWorkflowPartnerType: null,
    selectedUserRole: null,
    userRoles: [],
    workflowId: 0
  } as IWorkflowOptimizationState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowOptimization();
      }
    );
  }

  loadWorkflowOptimization = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OptimizationType
    );
    const refStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );
    const refRole = Referentials.referential.find((item) => item.code === ReferentialCode.UserRole);

    const [
      optimizationTypes,
      statusList,
      workflowOptimizationTypes,
      workflowPartnerList,
      userRoles
    ] = await Promise.all([
      this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(refStatus!.baseUrl),
      this.workflowService.GetWorkflowOptimizations(this.props.workflowFormId),
      this.workflowService.GetWorkflowPartners(workflowId),
      this.appReferentialService.Get(refRole!.baseUrl)
    ]);

    const partners = await this.organizationService.GetOrganizationsByIds(
      workflowPartnerList.map((item) => item.partnerId)
    );
    workflowPartnerList.forEach((wpItem) => {
      const partner = partners.find((item) => item.id === wpItem.partnerId);
      wpItem.partner = isNullOrUndefined(partner) ? null : partner;
    });

    workflowOptimizationTypes.forEach((wo) => {
      const wp: any = workflowPartnerList.find((item) => item.id === wo.workflowPartnerId);
      const cs = statusList.find((item) => item.id === wo.caseStatusId);

      wo.workflowPartner = isNullOrUndefined(wp) ? null : wp;
      wo.caseStatus = isNullOrUndefined(cs) ? null : cs;
    });

    this.setState({
      workflowOptimization: workflowOptimizationTypes,
      statusList: statusList.filter((item) => item.isActive),
      optimizationTypeList: optimizationTypes.filter((item) => item.isActive === true),
      workflowPartnerTypeList: workflowPartnerList.filter((item) => item.isActive === true),
      userRoles: userRoles.filter((item) => item.isActive === true),
      isLoading: false
    });
  };

  // deleteWorkflowOrgType = async (event: any, tableMeta: MUIDataTableMeta) => {

  //     try {
  //         const id = tableMeta.rowData[0];

  //         //    await this.workflowService.RemoveWorkflowOrganizationType(id);

  //         //   this.setState({
  //         //     isLoading: true
  //         //   }, () => {
  //             // this.loadWorkflowOptimization();
  //         //   });

  //         const workflowOrgTypes = [...this.state.workflowOrganizationTypes];
  //         const index = workflowOrgTypes.findIndex(item => item.id === id);
  //         workflowOrgTypes.splice(index, 1);

  //         this.setState({
  //             workflowOrganizationTypes: workflowOrgTypes,
  //         });

  //         this.props.enqueueSnackbar(this.translatorService.Tranlate("SUCCES_MSG", "OK"), { variant: 'success' });
  //     }
  //     catch (error) {
  //         this.props.enqueueSnackbar(this.translatorService.Tranlate("ERROR_MSG", "Eroare"), { variant: 'error' });
  //     }
  // }

  deleteWorkflowOptimization = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.workflowService.RemoveWorkflowOptimization(id);
      await this.loadWorkflowOptimization();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'optimizationType',
        label: this.translatorService.Tranlate(
          'WORKFLOW_OPTIMIZATION_TAB_OPTIMIZATION_TYPE_HEADER',
          'Optimizare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'workflowPartner',
        label: this.translatorService.Tranlate(
          'WORKFLOW_OPTIMIZATION_TAB_PARTNER_HEADER',
          'Entitate Partener'
        ),
        options: {
          sort: false,
          customBodyRender: (value: WorkflowPartner) => {
            return value.partner!.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_REPORT_STATUS_HEADER',
          'Status'
        ),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return value !== null ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'userRole',
        label: this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol'),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_OPTIMIZATION_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                color="default"
                size="small"
                onClick={(e) => this.deleteWorkflowOptimization(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addWorkflowOptimization = async () => {
    try {
      if (
        this.state.workflowOptimization.findIndex(
          (item) =>
            item.workflowPartnerId === this.state.selecteWorkflowPartnerType!.id &&
            ((this.state.selectedUserRole !== null &&
              item.userRoleId === this.state.selectedUserRole!.id) ||
              (this.state.selectedUserRole === null && item.userRoleId !== null) ||
              (this.state.selectedUserRole !== null && item.userRoleId === null) ||
              (this.state.selectedUserRole === null && item.userRoleId === null)) &&
            item.optimizationTypeId === this.state.selectedOptimizationType!.id &&
            item.caseStatusId === this.state.selectedStatus!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_OPTIMIZATION_EXISTS',
            'Optimizarea exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }

      await this.workflowService.AddWorkflowOptimization({
        id: 0,
        workflowPartnerId: this.state.selecteWorkflowPartnerType!.id,
        optimizationTypeId: this.state.selectedOptimizationType!.id,
        workflowSectionStepFormId: this.props.workflowFormId,
        userRoleId: this.state.selectedUserRole ? this.state.selectedUserRole.id : null,
        caseStatusId: this.state.selectedStatus!.id
      } as WorkflowOptimization);

      this.setState(
        {
          isLoading: true,
          selectedOptimizationType: null
        },
        () => {
          this.loadWorkflowOptimization();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleOptimizationTypeChange = (newValue: any | null) => {
    this.setState({ selectedOptimizationType: newValue });
  };
  handleUserRoleChange = (newValue: any | null) => {
    this.setState({ selectedUserRole: newValue });
  };
  handleWorkflowPartnerChange = (newValue: WorkflowPartner | null) => {
    this.setState({ selecteWorkflowPartnerType: newValue });
  };

  handleAutocompleteStatusChange = (newValue: any | null) => {
    this.setState({
      selectedStatus: newValue
    });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addWorkflowOptimization}>
              <Autocomplete
                id="workflowPartner"
                className="m-2"
                options={this.state.workflowPartnerTypeList.sort(function (a, b) {
                  return a.partner!.displayName.localeCompare(b.partner!.displayName);
                })}
                value={this.state.selecteWorkflowPartnerType}
                onChange={(e: any, newValue: WorkflowPartner | null) =>
                  this.handleWorkflowPartnerChange(newValue)
                }
                getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option: WorkflowPartner) => option.partner!.displayName
                })}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="workflowPartner"
                    value={this.state.selecteWorkflowPartnerType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_CALCULATION_TAB_ORGANIZATION_LABEL',
                      'Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <Autocomplete
                id="statusList"
                className="m-2"
                options={this.state.statusList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatus}
                onChange={(e: any, newValue: any | null) =>
                  this.handleAutocompleteStatusChange(newValue)
                }
                getOptionLabel={(option: any) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="statusList"
                    value={this.state.selectedStatus}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_REPORT_STATUS_LABEL',
                      'Status'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="userRole"
                className="m-2"
                options={this.state.userRoles.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedUserRole}
                onChange={(e: any, newValue: any | null) => this.handleUserRoleChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="userRole"
                    value={this.state.selectedUserRole}
                    label={this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol')}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="organizationType"
                className="m-2"
                options={this.state.optimizationTypeList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedOptimizationType}
                onChange={(e: any, newValue: any | null) =>
                  this.handleOptimizationTypeChange(newValue)
                }
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationType"
                    value={this.state.selectedOptimizationType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_OPTIMIZATION_TAB_OPTIMIZATION_TYPE_LABEL',
                      'Optimizare'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {/* <Box>
                    <Card>
                        <CardContent>
                            <Tabs
                                value={0}
                                indicatorColor="primary"
                                textColor="secondary"
                                variant="standard"
                                color="primary"
                                aria-label="disabled tabs example">
                                <Tab className="text-capitalize" label={this.translatorService.Tranlate("WORKFLOWS_TAB_OPTIMIZATION", "Optimizari")} />
                            </Tabs>
                        </CardContent>
                    </Card>
                </Box> */}
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowOptimization}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalOptimizationFormActionProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(WorkflowOptimizations as any));
