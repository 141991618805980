import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Button, Card, Tabs, Tab, CardContent, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Organization } from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import TabPanel from '../../_shared/Tabs';
import { Tariff, TariffPartner } from '../../../interfaces/Tariff';
import { ITariffService } from '../../../services/Interfaces/ITariffService';
import { ReferentialCode, RoleClaimsEnum, RoleCodeEnum, TariffCategoryCode, includedTariffTypeGroupCodes } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { IReferential } from '../../../interfaces/IReferential';
import EditTariffLaborTab from './EditTariffLaborTab';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';

interface IEditTariffState {
  tariff: Tariff;
  partnersTariff: Organization[];
  selectedTab: number;
  tariffTypeGroupList: IReferential[];
  hoOrganizations: Organization[];
  isUsed: boolean;
}

export interface ExternalEditTariffProps {
  tariffChanged?: () => void;
  tariffId: number;
}

type EditTariffProps = { appState: AppState } & ProviderContext &
  ExternalEditTariffProps &
  RouteComponentProps<{ id: string }>;

class EditTariff extends React.PureComponent<EditTariffProps, IEditTariffState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;
  private tariffService!: ITariffService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;

  state = {
    tariff: {
      id: 0,
      name: '',
      owner: null,
      partner: null,
      gtId: this.props.appState.appUser!.gtId,
      tariffCategoryId: 0,
      isActive: true,
      tariffPartners: [] as TariffPartner[]
    } as Tariff,
    partnersTariff: [],
    selectedTab: 0,
    tariffTypeGroupList: [],
    hoOrganizations: [] as Organization[],
    isUsed: false
  } as IEditTariffState;

  public async componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    let tariff = this.state.tariff;

    const tariffCategoryRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.TariffCategory
    );
    const tariffGroupTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.TariffTypeGroup
    );

    const insurerRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Insurer
    );

    const countryId = this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService) ? this.props.appState.appUser!.countryId : null;
    const [
      organizations,
      tariffCategoryList,
      tariffGroupTypeList,
      insurerList,
      tariffPartners,
      hoOrganizations,
      workflowsCount
    ] = await Promise.all([
      this.organizationService.GetOrganizationsForTariffPartners(hoId, countryId),
      this.appReferentialService.Get(tariffCategoryRef!.baseUrl),
      this.appReferentialService.Get(tariffGroupTypeRef!.baseUrl),
      this.appReferentialService.Get(insurerRef!.baseUrl),
      this.tariffService.GetTariffPartners(this.props.tariffId),
      this.organizationService.GetHOOrganizations(countryId),
      this.workflowService.GetTariffWorkflowsCount(this.props.tariffId)
    ]);

    tariff.ownerId = hoId;

    if (this.props.tariffId !== 0) {
      tariff = await this.tariffService.GetTariff(this.props.tariffId);
      tariff.tariffPartners = tariffPartners;

      const partner = organizations.find((item) => item.id === tariff.partnerId);
      tariff.partner = partner !== undefined ? partner : null;

      tariff.tariffPartners.forEach((p) => {
        const partner = hoOrganizations.find((o) => o.id === p.partnerId);
        p.partner = partner !== undefined ? partner : null;
      });
    }

    const owner = await this.organizationService.GetOrganization(tariff.ownerId); // organizations.find(item=>item.id === tariff.ownerId) ;
    tariff.owner = owner !== undefined ? owner : null;

    tariff.tariffCategoryId = tariffCategoryList.find(
      (item) => item.code === TariffCategoryCode.REP
    )!.id;

    this.setState({
      tariff: tariff,
      partnersTariff: organizations.filter((item) => item.isActive === true),
      tariffTypeGroupList: this.hasRight(RoleClaimsEnum.AdminService) ? tariffGroupTypeList.filter((item) => includedTariffTypeGroupCodes.includes(item.code ?? "")) : tariffGroupTypeList.filter((item) => item.isActive === true),
      hoOrganizations: hoOrganizations,
      isUsed: workflowsCount > 0
    });
  }

  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  private handleNameGUIChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    this.setState({
      tariff: {
        ...this.state.tariff,
        name: value
      }
    });
  };

  private handlePartnerTariffChange = (newValue: Organization | null) => {
    this.setState({
      tariff: {
        ...this.state.tariff,
        name:
          newValue === null ? '' : this.state.tariff.owner!.name + ' - ' + newValue!.displayName,
        partner: newValue,
        partnerId: newValue === null ? 0 : newValue!.id
      }
    });
  };

  private handleOtherPartnerTariffChange = (newValue: Organization[]) => {
    this.setState({
      tariff: {
        ...this.state.tariff,
        tariffPartners: newValue.map((item) => {
          return {
            id: 0,
            partner: item,
            partnerId: item.id,
            tariffId: this.props.tariffId
          } as TariffPartner;
        })
      }
    });
  };
  public saveTariff = async () => {
    try {
      if (!(await this.isValid(this.state.tariff))) {
        return;
      }

      if (this.props.tariffId === 0) {
        await this.tariffService.AddTariff(this.state.tariff);
      } else {
        await this.tariffService.UpdateTariff(this.state.tariff);
      }

      if (this.props.tariffChanged) {
        this.props.tariffChanged();
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public isValid = async (tariff: Tariff): Promise<boolean> => {
    const tariffList = await this.tariffService.GetTariffs(tariff.ownerId);

    let filterList = tariffList.filter((item) => item.name === tariff.name);

    if (filterList.length > 1) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ERROR_MSG_TARIFF_EXISTS', 'Tariful exista deja!'),
        { variant: 'error' }
      );
      return false;
    }
    if (filterList.length === 1 && filterList[0].id !== tariff.id) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ERROR_MSG_TARIFF_EXISTS', 'Tariful exista deja!'),
        { variant: 'error' }
      );
      return false;
    }

    filterList = tariffList.filter(
      (item) => item.ownerId === tariff.ownerId && item.partnerId === tariff.partnerId
    );

    if (filterList.length > 1) {
      // this.props.enqueueSnackbar(
      //   this.translatorService.Tranlate('ERROR_WARNING_TARIFF_EXISTS', 'Tariful exista deja!'),
      //   { variant: 'warning' }
      // );
      return true;
    }
    if (filterList.length === 1 && filterList[0].id !== tariff.id) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ERROR_WARNING_TARIFF_EXISTS', 'Tariful exista deja!'),
        { variant: 'warning' }
      );
      return true;
    }

    return true;
  };

  public renderForm() {
    return (
      <ValidatorForm onSubmit={this.saveTariff} instantValidate={true}>
        <Box mt={1} pt={1}>
          <Card>
            <CardContent>
              <TextValidator
                fullWidth
                name="owner"
                className="m-2"
                id="owner"
                disabled={true}
                value={this.state.tariff.owner === null ? '' : this.state.tariff.owner!.name || ''}
                // onChange={this.handleTextChange}
                label={this.translatorService.Tranlate(
                  'EDIT_TARIFF_OWNER_LABEL',
                  'Proprietar tarif'
                )}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />

              <Autocomplete
                disabled={this.state.isUsed}
                id="partner"
                className="m-2"
                options={this.state.partnersTariff}
                value={this.state.tariff.partner}
                onChange={(e: any, newValue: Organization | null) =>
                  this.handlePartnerTariffChange(newValue)
                }
                getOptionLabel={(option: Organization) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="partner"
                    value={this.state.tariff.partner}
                    label={this.translatorService.Tranlate(
                      'EDIT_TARIFF_PARTNER_LABEL',
                      'Partener tarif'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <TextValidator
                fullWidth
                name="name"
                className="m-2"
                id="name"
                //defaultValue={this.state.tariff.partner === null ? '' : "Daune " + this.state.tariff.partner.name}
                value={this.state.tariff.name || ''}
                onChange={this.handleNameGUIChange}
                label={this.translatorService.Tranlate('EDIT_TARIFF_NAME_GUI_LABEL', 'Nume GUI')}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
              {
                !this.hasRight(RoleClaimsEnum.AdminService) &&  (
                  <Autocomplete
                  id="otherPartners"
                  className="m-2"
                  multiple={true}
                  options={this.state.hoOrganizations.filter((item) => item.isActive)}
                  value={this.state.tariff.tariffPartners.map((tp) => tp.partner!)}
                  onChange={(e: any, newValue: Organization[]) =>
                    this.handleOtherPartnerTariffChange(newValue)
                  }
                  getOptionLabel={(option: Organization) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="partner"
                      value={this.state.tariff.partner}
                      label={this.translatorService.Tranlate(
                        'EDIT_TARIFF_OTHER_PARTNER_LABEL',
                        'Alti Parteneri'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
                )
              }
             
            </CardContent>
          </Card>
        </Box>

        <div className="text-right m-2">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.props.tariffChanged}
          >
            {this.translatorService.Tranlate('EDIT_TARIFF_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_TARIFF_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  createTabTariffGroupType = () => {
    const dataToRender = [] as any;
    this.state.tariffTypeGroupList.forEach((item: IReferential, index: number) => {
      dataToRender.push(<Tab key={index} className="text-capitalize" label={item.displayName} />);
    });
    return dataToRender;
  };

  createTabPanelTariffGroupType = () => {
    const dataToRender = [] as any;
    this.state.tariffTypeGroupList.forEach((item: IReferential, index: number) => {
      dataToRender.push(
        <TabPanel key={index} value={this.state.selectedTab} index={index + 1}>
          <EditTariffLaborTab
            {...this.props}
            tariffId={this.props.tariffId}
            tariffGroupTypeId={item.id}
            tariffGroupTypeName={item.displayName}
            tariffGroupTypeCode={item!.code ?? ''}
          ></EditTariffLaborTab>
        </TabPanel>
      );
    });
    return dataToRender;
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.tariffService = (this.context as AppContext).tariffService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'TARIFF_EDIT_GENERAL_DATA_TAB',
                  'Date generale'
                )}
              />
              {this.createTabTariffGroupType()}
            </Tabs>
          </CardContent>
        </Card>
        <TabPanel value={this.state.selectedTab} index={0}>
          {this.renderForm()}
        </TabPanel>
        {this.createTabPanelTariffGroupType()}
      </Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: ExternalEditTariffProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditTariff as any));
