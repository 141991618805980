import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { Case } from '../../interfaces/Case';
import moment from 'moment';
import { Vehicle } from '../../interfaces/Vehicle';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

interface IVehicleCaseFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  vehicle: Vehicle | null;
  vehicleCaseList: Case[];
}

export interface ExternalVehicleCaseFormProps {
  workflowForm: WorkflowSectionStepForm;
  vehicleDetails: Vehicle;
}

type IVehicleCaseFormProps = ExternalVehicleCaseFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & AppState &
  typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class VehicleCaseForm extends React.PureComponent<IVehicleCaseFormProps, IVehicleCaseFormState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    vehicle: null,
    vehicleCaseList: []
  } as IVehicleCaseFormState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadVehicleCaseForm();
      }
    );
  }

  loadVehicleCaseForm = async () => {
    const vehicleId = this.props.vehicleState.vehicle!.id;
    const [vehicleCase, workflows, vehicle] = await Promise.all([
      this.cbContext.caseService.SearchByVehicle(vehicleId),
      this.cbContext.workflowService.GetUserWorkflows(),
      this.cbContext.vehicleService.GetVehicle(vehicleId)
    ]);

    const vehicleCaseDetailsList = vehicleCase;

    const [usersAssignedList, users] = await Promise.all([
      this.cbContext.appUserService.GetUsersInfo(
        Array.from(new Set(vehicleCase.map((item) => item.caseStatus.assignedToId)))
      ),
      this.cbContext.appUserService.GetUsersInfo(
        Array.from(new Set(vehicleCase.map((item) => item.createdBy)))
      )
    ]);

    vehicleCaseDetailsList.forEach((cd) => {
      const workflow = workflows.find((item) => item.id === cd.caseWorkflow.workflowId);
      const userAssignedTo = usersAssignedList.find(
        (item) => item.id === cd.caseStatus.assignedToId
      );
      const createdBy = users.find((item) => item.id === cd.createdBy);

      cd.caseWorkflow.workflowDisplayName = R.isNil(workflow) ? '' : workflow.displayName;
      cd.caseStatus.assignedTo = R.isNil(userAssignedTo) ? null : userAssignedTo;
      cd.createdByUser = R.isNil(createdBy) ? null : createdBy;
    });

    this.setState({
      isLoading: false,
      vehicle: vehicle,
      vehicleCaseList: vehicleCaseDetailsList
    });
  };

  public render() {
    this.cbContext = this.context as AppContext;

    return (
      <div>
        {this.state.vehicleCaseList.map((item, index) => {
          const casesUrl = `/cases/${item.id}`;

          return (
            <Link to={casesUrl} key={index}>
              <div className="card-box border-1 p-4" style={{ width: '420px' }}>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ flexBasis: '23%' }}>
                    <b>{item.id}</b>:
                  </div>
                  <div className="text-left" style={{ flexBasis: '77%', fontWeight: 'bold' }}>
                    {item.caseWorkflow.workflowDisplayName}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ flexBasis: '23%' }}>
                    {this.cbContext.translatorService.Tranlate(
                      'VEHICLE_CASE_CREATED_BY_LABEL',
                      'Creat de'
                    )}
                    :
                  </div>
                  <div className="text-left" style={{ flexBasis: '77%' }}>
                    {item.createdByUser!.userName + ' '}{' '}
                    {moment
                      .utc(item.dateCreation)
                      .local()
                      .toDate()
                      .toLocaleString(this.props.language)}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ flexBasis: '23%' }}>
                    {this.cbContext.translatorService.Tranlate(
                      'VEHICLE_CASE_ASSIGNED_TO_LABEL',
                      'Asignat la'
                    )}
                    :
                  </div>
                  <div className="text-left" style={{ flexBasis: '77%' }}>
                    {item.caseStatus.assignedTo!.userName + ' '}{' '}
                    {moment
                      .utc(item.caseStatus.date)
                      .local()
                      .toDate()
                      .toLocaleString(this.props.language)}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ flexBasis: '23%' }}>
                    {this.cbContext.translatorService.Tranlate('VEHICLE_CASE_STATE_LABEL', 'Stare')}
                    :
                  </div>
                  <div className="text-left" style={{ flexBasis: '77%' }}>
                    {this.state.vehicle!.currentStatus
                      ? this.state.vehicle!.currentStatus.status!.displayName
                      : ''}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalVehicleCaseFormProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicelState: state.vehicle
  }),
  CaseSettingsActionCreators
)(withSnackbar(VehicleCaseForm as any));
