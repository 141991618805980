import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppUserAdminActionCreators, AppState } from '../../../store';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableColumnOptions,
  MUIDataTableMeta
} from 'mui-datatables';
import { ScaleLoader } from 'react-spinners';
import { IconButton, Box, Switch, Button, Tooltip } from '@material-ui/core';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { AppUserRole } from '../../../interfaces/AppUser';
import { withSnackbar, ProviderContext } from 'notistack';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';

type RolesTabProps = ProviderContext & RouteComponentProps & { appState: AppState };

interface IRolesTabState {
  roles: AppUserRole[];
  isLoading: boolean;
}
class RolesTab extends React.PureComponent<RolesTabProps, IRolesTabState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    roles: [],
    isLoading: false
  } as IRolesTabState;

  public async componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadRoles();
      }
    );
  }

  loadRoles = async () => {
    const roleList = await this.appUserService.GetAppUserRoles();

    this.setState({
      roles: roleList.roles,
      isLoading: false
    });
  };

  handleActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const rolesClone = [...this.state.roles];
    const role = rolesClone.find((item) => item.id === id);

    if (role !== undefined) {
      role.isActive = event.target.checked;

      try {
        await this.appUserService.UpdateRole(role);
        this.setState({
          roles: rolesClone
        });
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  onRowClick = (e: any, id: string) => {
    this.props.history.push('/admin/roles/' + id);
  };

  getColumns = () => {
    return [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate('ROLES_NAME_HEADER', 'Grup Permisiuni'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('ROLES_DATE_MODIFICATION_HEADER', 'Data Modificare'),

        options: {
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('ROLES_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      }
    ];
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={() => {
                this.props.history.push('/admin/roles/new');
              }}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Box m={1} p={1}>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {this.state.isLoading ? null : (
          <MUIDataTable
            title={''}
            data={this.state.roles}
            columns={this.getColumns()}
            options={options}
          />
        )}
      </Box>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({ appState: state.app }), // Selects which state properties are merged into the component's props
  AppUserAdminActionCreators // Selects which action creators are merged into the component's props
)(withSnackbar(RolesTab as any));
