import { AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';
import { AppContext } from '../../context/Contexts';
import { CaseSettings } from '../../interfaces/CaseSettings';
import {
  Case,
  CaseVehicleHistory,
  CaseEventHistory,
  CaseStatusHistory,
  CasePartnerHistory,
  CaseValuationHistory,
  CaseClient,
  CaseParameterHistory,
  CaseTariffValueHistory,
  CaseCalculationHistory
} from '../../interfaces/Case';
import { WorkflowSectionStep } from '../../interfaces/Workflow';

interface CaseSettingsSetCaseAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CASE;
  caseSettings: CaseSettings | null;
  case: Case;
}

interface CaseSettingsSetCaseVehicleAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CASE_VEHICLE;
  caseVehicle: CaseVehicleHistory;
}

interface CaseSettingsSetCaseEventAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CASE_EVENT;
  caseEvent: CaseEventHistory;
}
interface CaseSettingsSetCaseStatusAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CASE_STATUS;
  caseStatus: CaseStatusHistory;
}
interface CaseSettingsSetCaseValuationAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CASE_VALUATION;
  caseValuation: CaseValuationHistory;
}
interface CaseSettingsSetLoadingAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_LOADING;
  isLoading: boolean;
}

interface CaseSettingsAddPartnerAction {
  type: typeof ActionTypes.CASE_SETTINGS_ADD_CASE_PARTNER;
  partner: CasePartnerHistory;
}

interface CaseSettingsSetClientDataAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CLIENT_DATA;
  caseClientData: CaseClient;
}

interface CaseSettingsSetVehicleDataAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_VEHICLE_DATA;
  vehicleId: number;
}

interface CaseSettingsUpdateParametersAction {
  type: typeof ActionTypes.CASE_SETTINGS_UPDATE_PARAMETERS;
  caseParameter: CaseParameterHistory;
}

interface CaseSettingsUpdateTariffValueAction {
  type: typeof ActionTypes.CASE_SETTINGS_UPDATE_TARIFF_VALUE;
  tariffValue: CaseTariffValueHistory;
}

interface CaseSettingsSetCaseCalculationHistoryAction {
  type: typeof ActionTypes.CASE_SETTINGS_SET_CASE_CALCULATION_HISTORY;
  caseCalculationHistory: CaseCalculationHistory;
}

export type CaseSettingsAction =
  | CaseSettingsSetCaseAction
  | CaseSettingsSetCaseStatusAction
  | CaseSettingsSetLoadingAction
  | CaseSettingsSetCaseVehicleAction
  | CaseSettingsSetCaseEventAction
  | CaseSettingsAddPartnerAction
  | CaseSettingsSetCaseValuationAction
  | CaseSettingsSetClientDataAction
  | CaseSettingsSetVehicleDataAction
  | CaseSettingsUpdateParametersAction
  | CaseSettingsUpdateTariffValueAction
  | CaseSettingsSetCaseCalculationHistoryAction;

export const CaseSettingsActionCreators = {
  SetCaseSettings:
    (caseId: number): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      const caseSettingsService = (context as AppContext).caseSettingsService;
      const caseService = (context as AppContext).caseService;

      dispatch({ type: ActionTypes.CASE_SETTINGS_SET_LOADING, isLoading: true });

      const curentCase = await caseService.GetCaseDetails(caseId);
      const currentCaseSettings = await caseSettingsService.GetCaseSettings(
        curentCase.caseWorkflow.workflowId
      );

      currentCaseSettings!.workflow.workflowSections!.forEach((section) => {
        section.workflowSectionSteps.forEach((step) => {
          step.isCurrent = function (caseStatusId: number) {
            const result = this.workflowSectionStepCaseStatuses.find(
              (cs) => cs.caseStatusId === caseStatusId
            );
            return result !== undefined;
          };
        });

        section.isCurrent = function (caseStatusId: number) {
          return (
            this.workflowSectionSteps.find((item) => item.isCurrent(caseStatusId)) !== undefined
          );
        };
      });

      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_CASE,
        caseSettings: currentCaseSettings,
        case: curentCase
      });
    },

  SetCaseClientData:
    (clientData: CaseClient): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_CLIENT_DATA,
        caseClientData: clientData
      });
    },

  SetCaseVehicleData:
    (vehicleId: number): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_VEHICLE_DATA,
        vehicleId: vehicleId
      });
    },

  UpdateCaseParameters:
    (caseParameterArg: CaseParameterHistory): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_UPDATE_PARAMETERS,
        caseParameter: caseParameterArg
      });
    },

  UpdateCaseTariffvalue:
    (tariffValue: CaseTariffValueHistory): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_UPDATE_TARIFF_VALUE,
        tariffValue: tariffValue
      });
    },

  SetCaseVehicle:
    (vehicle: CaseVehicleHistory): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_CASE_VEHICLE,
        caseVehicle: vehicle
      });
    },

  SetCaseEvent:
    (event: CaseEventHistory): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_CASE_EVENT,
        caseEvent: event
      });
    },

  SetCaseValuation:
    (valuation: CaseValuationHistory): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_CASE_VALUATION,
        caseValuation: valuation
      });
    },

  SetCaseStatus:
    (caseStatus: CaseStatusHistory): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      if (caseStatus === null) {
        return;
      }
      const appUserService = (context as AppContext).appUserService;
      const users = await appUserService.GetUsersInfo(
        Array.from(new Set([caseStatus.assignedToId, caseStatus.userId]))
      );

      caseStatus.assignedTo = users.find((item) => item.id === caseStatus.assignedToId)!;
      caseStatus.user = users.find((item) => item.id === caseStatus.userId)!;

      dispatch({
        type: ActionTypes.CASE_SETTINGS_SET_CASE_STATUS,
        caseStatus: caseStatus
      });
    },

  AddPartner:
    (organizationId: number | null): AppThunkAction<CaseSettingsAction> =>
    async (dispatch, getState, context) => {
      if (organizationId === null) {
        return;
      }
      const orgService = (context as AppContext).organizationService;

      const state = getState();
      if (
        state.caseSettings!.case!.casePartners.find(
          (item) => item.organizationId === organizationId
        ) !== undefined
      ) {
        return;
      }

      const organization = await orgService.GetOrganization(organizationId);

      const firstPrtner = state.caseSettings!.case!.casePartners[0];

      dispatch({
        type: ActionTypes.CASE_SETTINGS_ADD_CASE_PARTNER,
        partner: {
          id: 0,
          caseWorkflowHistoryId: firstPrtner.caseWorkflowHistoryId,
          caseId: firstPrtner.caseWorkflowHistoryId,
          organization: organization,
          organizationId: organization.id,
          date: new Date(),
          user: state.app!.appUser,
          userId: state.app!.appUser!.id!
        }
      });
    },

  SetCaseCalculationHistory:
    (caseCalculationHistory: CaseCalculationHistory): AppThunkAction<CaseSettingsAction> =>
        async (dispatch, getState, context) => {
          dispatch({
            type: ActionTypes.CASE_SETTINGS_SET_CASE_CALCULATION_HISTORY,
            caseCalculationHistory: caseCalculationHistory
          });
        },

};

const isStepCurrent = (step: WorkflowSectionStep, caseStatusId: number): boolean => {
  const result = step.workflowSectionStepCaseStatuses.find(
    (cs) => cs.caseStatusId === caseStatusId
  );
  return result !== undefined;
};
