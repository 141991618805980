import * as React from 'react';

import { IAuthService } from '../../services/Interfaces/IAuthService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppActionCreators, ApplicationState, AppState } from '../../store';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { ScaleLoader } from 'react-spinners';
import { Button, Grid, IconButton, List, ListItem } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { withRouter } from 'react-router';
import moment from 'moment';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CheckIcon from '@material-ui/icons/Check';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CountryCodeEnum } from '../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

export interface ExternalCaseNewInNotificationProps {
  onClick?: () => void;
}

type CaseNewInNotificationProps = { appState: AppState } & typeof AppActionCreators &
  RouteComponentProps &
  ExternalCaseNewInNotificationProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICaseNewInNotificationState {}

class CaseNewInNotification extends React.PureComponent<
  CaseNewInNotificationProps,
  ICaseNewInNotificationState
> {
  private authService!: IAuthService;
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {} as ICaseNewInNotificationState;

  onClick = async (id: number, caseStatusId: number, moveToViewed: boolean, notNew: boolean) => {
    try {
      await this.caseService.SetCaseNotNew(id, caseStatusId, false, moveToViewed, notNew);

      this.props.MarkAsViewedNewInCase(id);

      const currentPath = this.props.history.location.pathname.toLowerCase();
      this.props.history.push('/cases/' + id);

      if (currentPath.indexOf('/cases/') !== -1) {
        this.props.history.go(0);
      }

      if (this.props.onClick !== undefined) {
        this.props.onClick();
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleAddToTaskList = async (id: number, caseStatusId: number, addToTaskList: boolean, moveToViewed: boolean, notNew: boolean) => {
    try {
      await this.caseService.SetCaseNotNew(id, caseStatusId, addToTaskList, moveToViewed, notNew);

      if (notNew) {
        this.props.RemoveNewInCase(id);
      }

      if (addToTaskList) {
        this.props.LoadTasks();
      }
    } catch (err) {
      console.log(err);
    }
  };

  public render() {
    this.authService = (this.context as AppContext).authenticationService;
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <React.Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.appState.isNewInCasesLoading} />
        </div>
        {!this.props.appState.isNewInCasesLoading && this.props.appState.newInCases.length === 0 ? (
          <div className="text-center">
            <div className="avatar-icon-wrapper  m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper  m-0 d-140">
                {/* <FontAwesomeIcon icon={['far', 'user']} className="d-flex align-self-center display-1 " /> */}
                <FontAwesomeIcon
                  icon={['far', 'folder-open']}
                  className="d-flex align-self-center display-1 "
                />
              </div>
            </div>
          </div>
        ) : null}

        {!this.props.appState.isNewInCasesLoading && this.props.appState.newInCases.length !== 0 ? (
          <List className="p-0">
            {this.props.appState.newInCases.map((item, index) => (
              <ListItem divider key={index} style={{ 
                backgroundColor: !item.caseStatus.isViewed ? '#f7f7fc' : 'inherit'              
                }}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <div
                      className={`p-0 ${!item.caseStatus.isViewed ? 'font-weight-bold' : ''}`}
                    >
                      {index + 1}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className={`text-primary justify-content-start p-0 ${!item.caseStatus.isViewed ? 'font-weight-bold' : ''}`}
                      onClick={(e) => this.onClick(item.id, item.caseStatus.id, true, false)}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.id}
                    </div>
                    <div className={`${!item.caseStatus.isViewed ? 'font-weight-bold' : ''}`}>
                      {item.caseStatus.caseStatus!.displayName}
                    </div>
                    <div className="font-size-sm text-black-50">
                      {isNullOrUndefined(item.caseEvent) ? '' : item.caseEvent.caseNumber}
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <div className={`${!item.caseStatus.isViewed ? 'font-weight-bold' : ''}`}>{item.caseType!.displayName}</div>
                    <div className="font-size-sm text-black-50">
                      {isNullOrUndefined(item.caseVehicle) ? '' : item.caseVehicle.plateNumber}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={`${!item.caseStatus.isViewed ? 'font-weight-bold' : ''}`}>
                      {this.props.appState.appUser?.organization?.country?.code?.toUpperCase() === CountryCodeEnum.CZ.toString() ? null : item.organizationOwner!.name}
                    </div>
                    <div className="font-size-sm text-black-50">
                      {moment
                        .utc(item.caseStatus.date)
                        .local()
                        .toDate()
                        .toLocaleString(this.props.appState.language)}
                    </div>
                  </Grid>
                  <Grid item container direction="column" xs={1}>
                    {this.props.appState.appUser?.organization?.country?.code?.toUpperCase() === CountryCodeEnum.CZ.toString() ? null : (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.handleAddToTaskList(item.id, item.caseStatus.id, true, false, true);
                        }}
                      >
                        <StarBorderIcon fontSize="small"></StarBorderIcon>
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="delete"
                      color="inherit"
                      className="text-danger"
                      size="small"
                      onClick={(e) => {
                        this.handleAddToTaskList(item.id, item.caseStatus.id, false, false, true);
                      }}                      
                      >
                      <DeleteOutlineTwoToneIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : null}
      </React.Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseNewInNotificationProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  AppActionCreators,
  mergeProps
)(withRouter(CaseNewInNotification as any));
