import * as React from 'react';

import { IAuthService } from '../../services/Interfaces/IAuthService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppActionCreators, ApplicationState, AppState } from '../../store';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { ScaleLoader } from 'react-spinners';
import { Button, Grid, IconButton, List, ListItem } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { withRouter } from 'react-router';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CheckIcon from '@material-ui/icons/Check';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { CaseComment } from '../../interfaces/Case';
import moment from 'moment';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ExternalCaseMessageNotificationProps {
  onClick?: () => void;
}

type CaseMessageNotificationProps = { appState: AppState } & typeof AppActionCreators &
  ExternalCaseMessageNotificationProps &
  RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICaseMessageNotificationState {}

class CaseMessageNotification extends React.PureComponent<
  CaseMessageNotificationProps,
  ICaseMessageNotificationState
> {
  private authService!: IAuthService;
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {} as ICaseMessageNotificationState;

  onClick = async (id: number) => {
    try {
      await this.caseService.RemoveUserComment(id, false);
      this.props.RemoveComment(id);

      const currentPath = this.props.history.location.pathname.toLowerCase();
      this.props.history.push('/cases/' + id);

      if (currentPath.indexOf('/cases/') !== -1) {
        this.props.history.go(0);
      }
      if (this.props.onClick !== undefined) {
        this.props.onClick();
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleAddToTaskList = async (id: number) => {
    try {
      await this.caseService.RemoveUserComment(id, true);
      this.props.RemoveComment(id);
      this.props.LoadTasks();
    } catch (ex) {
      console.log(ex);
    }
  };
  handleCheck = async (id: number) => {
    try {
      await this.caseService.RemoveUserComment(id, false);
      this.props.RemoveComment(id);
    } catch (ex) {
      console.log(ex);
    }
  };

  public render() {
    this.authService = (this.context as AppContext).authenticationService;
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <React.Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.appState.isCommentsLoading} />
        </div>

        {!this.props.appState.isCommentsLoading && this.props.appState.comments.length === 0 ? (
          <div className="text-center">
            <div className="avatar-icon-wrapper  m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper  m-0 d-140">
                <FontAwesomeIcon
                  icon={['far', 'comment-dots']}
                  className="d-flex align-self-center display-1 "
                />
              </div>
            </div>
          </div>
        ) : null}

        {!this.props.appState.isCommentsLoading && this.props.appState.comments.length !== 0 ? (
          <List>
            {this.props.appState.comments.map((item, index) => (
              <ListItem divider key={index}>
                <Grid container direction="column">
                  <Grid item container spacing={1}>
                    <Grid item xs={3}>
                      <Button
                        color="primary"
                        className="text-primary justify-content-start p-0"
                        onClick={(e) => this.onClick(isNullOrUndefined(item) ? 0 : item.caseId)}
                      >
                        {isNullOrUndefined(item) ? '' : item.caseId}
                      </Button>
                      <div>{item.case.caseStatus.caseStatus!.displayName}</div>
                    </Grid>

                    <Grid item xs={8}>
                      <div>
                        {isNullOrUndefined(item.user)
                          ? ''
                          : item.user.firstName +
                            ' ' +
                            item.user.lastName +
                            ' (' +
                            item.user.organization!.hoName +
                            ')'}
                      </div>
                      <div className="font-size-sm text-black-50">
                        {moment
                          .utc(item.date)
                          .local()
                          .toDate()
                          .toLocaleString(this.props.appState.language)}
                      </div>
                    </Grid>
                    {/* <Grid item xs={8}>
                                                    <div className="font-size-sm text-black-50">{ moment.utc(item.date).local().toDate().toLocaleDateString(this.props.appState.language)}</div>
                                                </Grid> */}
                    <Grid item container direction="column" xs={1}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.handleAddToTaskList(item.id);
                        }}
                      >
                        <StarBorderIcon fontSize="small"></StarBorderIcon>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={11}>
                      <div className="font-size-sm text-black-50">
                        {isNullOrUndefined(item) ? '' : item.text}
                      </div>
                    </Grid>
                    <Grid item container direction="column" xs={1}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          this.handleCheck(item.id);
                        }}
                      >
                        <CheckIcon fontSize="small"></CheckIcon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : null}
      </React.Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseMessageNotificationProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  AppActionCreators,
  mergeProps
)(withRouter(CaseMessageNotification as any));
