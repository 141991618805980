import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState, VehicleState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { withSnackbar, ProviderContext } from 'notistack';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows,
  MUIDataTableColumnDef
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CaseAttachment,
  CaseCalculationAlternativePart,
  CaseCalculationSupplierAlternativePart,
  TariffPercentResponse
} from '../../interfaces/Case';
import {
  Order,
  OrderPart,
  OrderPartDTO,
  OrderStatusChangeRequestDto,
  OrderStatusHistory,
  UpdateOrderAmountsRequestDto
} from '../../interfaces/Order';
import { isNull, isNullOrUndefined } from 'util';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ro';
import Referentials from '../../helpers/Referentials.json';
import {
  ReferentialCode,
  ParametreCode,
  OrganizationTypeCode,
  OrderStatusTypesCode,
  AttachmentTypeCode,
  OrderStatusCode,
  OrderPartStatus,
  SupplierCode,
  OwnStockCodeEnum
} from '../../helpers/Constants';
import { IReferential } from '../../interfaces/IReferential';
import FileSaver from 'file-saver';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import * as R from 'ramda';
import { map } from 'ramda';
import { isUndefined, rest } from 'lodash';
import { red } from '@material-ui/core/colors';
import { pretUnitarFormat } from '../../utils/orderUtils';
import { OrganizationService } from '../../services/OrganizationService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { Organization } from '../../interfaces/Organization';
import { AlignRight } from 'react-feather';
import _ from 'lodash';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { Utils } from '../../helpers/Utils';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';

export interface ExternalOrderDetailsProps {
  orderDetails: Order;
  onOrderChange: (order: Order) => void;
  hasRights: boolean;
  additionTariff: TariffPercentResponse;
  discountTariff: TariffPercentResponse;
  automatePlaceOrder: boolean;
  workflowAutomatePlaceOrder: boolean;
}

type OrderDetailsProps = ExternalOrderDetailsProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & ProviderContext &
  RouteComponentProps;

interface IOrderDetailsState {
  userStartCode: string;
  isLoading: boolean;
  orderpartsList: OrderPartDTO[];
  isChangeOrderStatusDialogOpen: boolean;
  changeOrderStatusComment: string;
  statuses: IReferential[];
  selectedStatus: IReferential;
  orderPartStatus: IReferential[];
  orderStatuses: IReferential[];
  selectedDisplayedStatus: string;
  attachments: CaseAttachment[];
  orderStatusHistory: OrderStatusHistory[];
  TVA: string;
  attachType: IReferential | null;
  partsLocationOrganization: Organization | null;
  caseCalculationPartsThatChangedDuringPlacingOrder: CaseCalculationSupplierAlternativePart[];
  organizationId: number | null;
  organizations: Organization[];
  showOrderPrice: boolean;
  costCenter: IReferential | null;
  executing: boolean;
}

class OrderDetails extends React.PureComponent<OrderDetailsProps, IOrderDetailsState> {
  private translatorService!: ITranslatorService;
  private orderService!: IOrderService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;
  private caseService!: ICaseService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  static contextType = ApplicationContext;
  
  state = {
    userStartCode: '',
    isLoading: true,
    orderpartsList: [],
    isChangeOrderStatusDialogOpen: false,
    changeOrderStatusComment: '',
    statuses: [] as IReferential[],
    selectedStatus: this.props.orderDetails.orderStatus,
    orderPartStatus: [],
    orderStatuses: [],
    selectedDisplayedStatus: '',
    attachments: [],
    orderStatusHistory: [],
    TVA: '0',
    attachType: null,
    partsLocationOrganization: null,
    caseCalculationPartsThatChangedDuringPlacingOrder: [],
    organizationId: this.props.appState.appUser!.organizationId,
    organizations: [],
    showOrderPrice: false,
    costCenter: null,
    executing: false
  } as IOrderDetailsState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const refOrderStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrderStatus
    );
    const refOrderPartStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrderPartStatus
    );
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );

    const [orderStatus, orderPartStatus, statusHist, attachmentTypes, caseEvent] = await Promise.all([
      this.appReferentialService.Get(refOrderStatus!.baseUrl),
      this.appReferentialService.Get(refOrderPartStatus!.baseUrl),
      this.orderService.GetOrderStatusHistories(this.props.orderDetails.id),
      this.appReferentialService.Get(ref!.baseUrl),
      this.caseService.GetCurrentEvent(this.props.orderDetails.caseId)    
    ]);

    let partsLocationOrganization = null;
    if (!isNullOrUndefined(this.props.orderDetails.partsLocationOrganizationId)) {
      partsLocationOrganization = await this.organizationService.GetOrganization(
        this.props.orderDetails.partsLocationOrganizationId!
      );
    }

    let costCenter = null;
    if (!R.isNil(caseEvent)) {
      if (!isNullOrUndefined(caseEvent.organizationId)) {
        costCenter = await this.workflowService.GetCostCenter(
          caseEvent.organizationId!
        );
      }
    }
    let orgList: Organization[] = [];
    if (!isNullOrUndefined(this.props.caseSettingsState.case)) {
      orgList = await this.organizationService.GetOrganizationsByHO(
        this.props.caseSettingsState.case!.organizationOwnerId,
        null
      );
    }
    const showOrderPrice = !this.props.appState.appUser!.organization!.organizationType!.hideOrderPrices;
    console.log('showOrderPrice', showOrderPrice);
    let TVA = '0';
    if (this.props.orderDetails.amountWithoutTVA !== 0) {
      TVA = (
        (this.props.orderDetails.amountTVA / this.props.orderDetails.amountWithoutTVA) *
        100
      ).toFixed(0);
    }

    const startCode =
      this.props.appState.appUser!.organization!.organizationType!.code ===
      OrganizationTypeCode.SUPPLIER
        ? OrderStatusTypesCode.FZR
        : OrderStatusTypesCode.REP;

    const orderPartStatusFiltered = orderPartStatus.filter((item) => item.isActive === true);
    const x = this.props.orderDetails.orderParts!.map((item) => {
      return { ...item } as OrderPartDTO;
    });
    this.setState({
      userStartCode: startCode,
      orderpartsList: x,
      statuses: orderStatus.filter(
        (item) => item.isActive === true && item.code!.startsWith(startCode)
      ),
      orderStatuses: orderStatus.filter((item) => item.isActive === true),
      orderPartStatus: orderPartStatusFiltered,
      selectedDisplayedStatus: !isNullOrUndefined(this.props.orderDetails.orderStatus)
        ? this.props.orderDetails.orderStatus.displayName
        : '',
      selectedStatus: this.props.orderDetails.orderStatus!,
      orderStatusHistory: statusHist,
      TVA: TVA,
      isLoading: false,
      attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
      partsLocationOrganization: partsLocationOrganization,
      organizations: orgList,
      showOrderPrice: showOrderPrice,
      costCenter: costCenter
    });
  };

  displayOtherOrderDetails = (value: any, row: any) => {
    const orderPart = this.state.orderpartsList.find((item) => item.id === row[0]);
    return (
      <Tooltip
        {...rest}
        title={
          <div>
            <div>
              {this.translatorService.Tranlate('ORDER_PART_DETAILS_STOCK_LABEL', 'Disponibil') +
                ': ' +
                (orderPart!.alternativePartQualityId === null
                  ? orderPart!.availableStock
                  : orderPart!.alternativePartStockQuantity === null
                  ? ''
                  : orderPart!.alternativePartStockQuantity)}
            </div>
            <div>
              {this.translatorService.Tranlate('ORDER_PART_DETAILS_LOCATION_LABEL', 'Locatie') +
                ': ' +
                (orderPart!.alternativePartQualityId === null
                  ? this.isNull(orderPart!.location, '')
                  : this.isNull(orderPart!.alternativePartStockLocation, ''))}
            </div>
          </div>
        }
      >
        <div>
          <span>{(isNullOrUndefined(value) || value === '' ? 'N/A' : value) + ' '}</span>
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
        </div>
      </Tooltip>
    );
  };

  isNull = (value: any, nullValue: any) => {
    if (isNullOrUndefined(value)) {
      return nullValue;
    }

    return value;
  };

  displayDeliveryOrderDetails = (value: any, row: any) => {
    const orderPart = this.state.orderpartsList.find((item) => item.id === row[0]);
    return (
      <Box component="div">
        {isNullOrUndefined(orderPart!.deliveryTime) ? '' : orderPart!.deliveryTime}
        {!isNullOrUndefined(orderPart?.prevVal) && (
          <Box component="div">
            {this.translatorService.Tranlate(
              'ORDER_PART_DETAILS_DELIVERY_TIME_LABEL11',
              'Livrare valoare anterioara'
            ) +
              ': ' +
              (isNullOrUndefined(orderPart!.prevVal?.deliveryTime)
                ? ''
                : orderPart!.prevVal?.deliveryTime)}
          </Box>
        )}
      </Box>
    );
  };

  getPartsByColumns = (): MUIDataTableColumnDef[] => {
    const columns = [
      {
        name: 'id',
        options: {
          /*display: 'excluded',*/
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'guideNumber',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_CODE_LABEL', 'Cod'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);

            if (!isNullOrUndefined(part!.alternativePartQualityId)) {
              return part!.alternativeGuideNumber;
            }

            return part!.guideNumber;
          }
        }
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_NAME_LABEL', 'Denumire'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);

            if (!isNullOrUndefined(part!.alternativePartQualityId)) {
              return part!.alternativePartName;
            }

            return part!.name;
          }
        }
      },
      {
        name: 'carPartCategory.displayName',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_QUALITY_LABEL', 'Calitate'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);
            if (part!.alternativePartQualityId === null) {
              return part!.carPartCategory!.displayName;
            } else {
              if (isNullOrUndefined(part!.alternativePartQuality)) {
                return '';
              }
              return part!.alternativePartQuality.displayName;
            }
          }
        }
      },
      {
        name: 'originalPrice',
        label: this.translatorService.Tranlate(
          'ORDER_PART_DETAILS_ORIGINAL_PRICE_LABEL',
          'Pret unitar'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);
            const order = this.props.orderDetails;

            if (
              !isNullOrUndefined(part?.prevVal) &&
              pretUnitarFormat(
                part!.prevVal,
                part!.prevVal!.originalPrice,
                this.props.orderDetails.currencyCode,
                this.props.appState.appUser?.organization?.country ?? null
              ) !== pretUnitarFormat(part, value, this.props.orderDetails.currencyCode, this.props.appState.appUser?.organization?.country ?? null)
            ) {
              return (
                <React.Fragment>
                  <TextField
                    label={
                      'Valoare Anterioara: ' +
                      pretUnitarFormat(
                        part!.prevVal,
                        part!.prevVal!.originalPrice,
                        this.props.orderDetails.currencyCode,
                        this.props.appState.appUser?.organization?.country ?? null
                      )
                    }
                    disabled
                    value={
                      pretUnitarFormat(part, value, this.props.orderDetails.currencyCode, this.props.appState.appUser?.organization?.country ?? null) || ''
                    }
                    style={{ border: 'red' }}
                  />
                </React.Fragment>
              );
            }
            return this.state.showOrderPrice
              ? pretUnitarFormat(part, value, this.props.orderDetails.currencyCode, this.props.appState.appUser?.organization?.country ?? null)
              : '';
          }
        }
      },
      {
        name: 'pieceNr',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_PIECE_NR_LABEL', 'Unitati'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);
            if (!isNullOrUndefined(part?.prevVal) && part?.prevVal.pieceNr !== value) {
              return (
                <React.Fragment>
                  <TextField
                    label={'Valoare Anterioara: ' + part?.prevVal.pieceNr}
                    disabled
                    value={value || ''}
                    style={{ border: 'red' }}
                  />
                </React.Fragment>
              );
            }
            return value;
          }
        }
      },
      {
        name: 'discount',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_DISCOUNT_LABEL', 'Discount (%)'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);

            if (part!.alternativePartQualityId === null) {
              return part!.discount;
            } else {
              return part!.alternativePartDiscount;
            }
          }
        }
      },
      {
        name: '',
        label: this.translatorService.Tranlate(
          'ORDER_PART_DETAILS_TOTAL_PRICE_LABEL',
          'Pret total'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.orderpartsList.find((item) => item.id === tableMeta.rowData[0]);
            const order = this.props.orderDetails;
            if (part!.alternativePartQualityId === null) {
              if (isNullOrUndefined(tableMeta.rowData[6])) {
                return this.state.showOrderPrice
                  ? Utils.countryNumberFormat(
                    (Math.round(tableMeta.rowData[5] * tableMeta.rowData[4] * 100) / 100).toFixed(
                      2
                    ),  
                    this.props.appState.appUser?.organization?.country ?? null) +
                      ' ' +
                      (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                      this.props.orderDetails.currencyCode !== ''
                        ? this.props.orderDetails.currencyCode
                        : '')
                  : '';
              }
              return this.state.showOrderPrice
                ? Utils.countryNumberFormat((
                    Math.round(
                      (tableMeta.rowData[5] * tableMeta.rowData[4] -
                        (tableMeta.rowData[5] * tableMeta.rowData[4] * tableMeta.rowData[6]) /
                          100) *
                        100
                    ) / 100
                  ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                    ' ' +
                    (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                    this.props.orderDetails.currencyCode !== ''
                      ? this.props.orderDetails.currencyCode
                      : '')
                : '';
            } else {
              if (isNullOrUndefined(part!.alternativePrice)) {
                return 0;
              }
              if (isNullOrUndefined(part!.alternativePartDiscount)) {
                return this.state.showOrderPrice
                  ? Utils.countryNumberFormat((Math.round(tableMeta.rowData[5] * part!.alternativePrice * 100) / 100).toFixed(
                      2
                    ), this.props.appState.appUser?.organization?.country ?? null) +
                      ' ' +
                      (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                      this.props.orderDetails.currencyCode !== ''
                        ? this.props.orderDetails.currencyCode
                        : '')
                  : '';
              }
              return this.state.showOrderPrice
                ? Utils.countryNumberFormat((
                    Math.round(
                      (tableMeta.rowData[5] * part!.alternativePrice -
                        (tableMeta.rowData[5] *
                          part!.alternativePrice *
                          part!.alternativePartDiscount) /
                          100) *
                        100
                    ) / 100
                  ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                    ' ' +
                    (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                    this.props.orderDetails.currencyCode !== ''
                      ? this.props.orderDetails.currencyCode
                      : '')
                : '';
            }
          }
        }
      },
      {
        name: 'producer',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_PRODUCER_LABEL', 'Producator'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return this.displayOtherOrderDetails(value, tableMeta.rowData);
          }
        }
      },
      {
        name: 'remarks',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_DELIVERY_TIME_LABEL', 'Livrare'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return this.displayDeliveryOrderDetails(value, tableMeta.rowData);
          }
        }
      },
      {
        name: '',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_STATUS_LABEL', 'Status'),
        options: {
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const partId = tableMeta.rowData[0];
            const part = this.state.orderpartsList.find((item) => item.id === partId);

            if (part == null) {
              return;
            }
            return part!.status!.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const id = tableMeta.rowData[0];
            const part = this.state.orderpartsList.find((item) => item.id === id);
            if (part!.isDeleted) {
              return (
                <span className="text-danger">
                  {this.translatorService.Tranlate('ORDER_PART_DETAILS_DELETED_LABEL', 'Sters')}
                </span>
              );
            }

            if (this.props.orderDetails.clientId !== this.props.appState.appUser!.organizationId) {
              return '';
            }

            return (
              this.props.orderDetails?.orderStatus?.code === OrderStatusCode.REP_DRAFT && (
                <IconButton
                  onClick={(e) => this.deleteOrderPart(e, tableMeta)}
                  aria-label="delete"
                  color="inherit"
                  hidden={!this.props.hasRights}
                  className="text-danger"
                  size="small"
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              )
            );
          }
        }
      }
    ];

    return columns;
  };

  closeDialog = async () => {
    this.setState({ isChangeOrderStatusDialogOpen: false });
  };

  changeOrderStatusClick = async () => {
    this.setState({ isChangeOrderStatusDialogOpen: true });
  };

  getNextOrderStatus = (): IReferential | undefined => {
    if (this.props.orderDetails?.orderStatus?.code === OrderStatusCode.REP_DRAFT) {
      return this.state.statuses.find((item) => item.code === OrderStatusCode.REP_PLASATA);
    }
    return undefined;
  };

  canChangeNextOrderStatus = () => {
    return (
      !this.props.hasRights ||
      !this.props.orderDetails?.orderStatus?.code?.includes(this.state.userStartCode) ||
      !(this.getNextOrderStatus() != undefined)
    );
  };

  getOrderButton = (order: Order) => {
    if (order.orderStatus?.code == 'REP_DRAFT') {
      return this.props.automatePlaceOrder ? this.translatorService.Tranlate('CASE_CAR_CALCULATIONS_CONFIRM_BUTTON', 'Confirma')  
        : this.translatorService.Tranlate('ORDER_PART_PLACE_ORDER_LABEL', 'Lanseaza Comanda');
    } else return order.orderStatus?.displayName;
  };

  renderOrderDetailsSection = () => {
    console.log
    return (
      <Card>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <CardContent className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="d-flex align-items-center float-right mt-2">
                  <div className="ml-2">
                    <span className="font-weight-bold">
                      {this.translatorService.Tranlate(
                        'CALCULATION_DETAILS_VEHICAL_PLATE_NUMBER',
                        'Numar inmatriculare'
                      )}
                      {''}:
                    </span>{' '}
                    {!R.isNil(this.props.caseSettingsState.case) ? this.props.caseSettingsState.case.caseVehicle.plateNumber : this.props.orderDetails.caseVehicle.plateNumber}
                  </div>
                  <div className="ml-2">
                    <span className="font-weight-bold">
                      {this.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'VIN')}
                      {''}:
                    </span>{' '}
                    {!R.isNil(this.props.caseSettingsState.case) ? this.props.caseSettingsState.case.caseVehicle.vin : this.props.orderDetails.caseVehicle.vin}
                  </div>
                  <div className="ml-2">
                    <span className="font-weight-bold">
                      {this.translatorService.Tranlate(
                        'CALCULATION_DETAILS_VEHICAL_NUMBER_OF_KM',
                        'Numar kilometri'
                      )}
                      {''}:
                    </span>{' '}
                    {!R.isNil(this.props.caseSettingsState.case) ?  this.props.caseSettingsState.case.caseVehicle.numberOfKilometers  : this.props.orderDetails.caseVehicle.numberOfKilometers}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="d-flex align-items-center">
                  <Button
                    onClick={(e) => this.props.automatePlaceOrder || this.props.workflowAutomatePlaceOrder ? this.changeOrderStatusSubmit() 
                      : this.changeOrderStatusClick()}
                    color="inherit"
                    className="text-white bg-success mr-2"
                    disabled={this.canChangeNextOrderStatus()}
                  >
                    {this.getOrderButton(this.props.orderDetails)}
                    {/*{this.state.selectedDisplayedStatus}*/}
                  </Button>
                  <div className="text-black font-weight-bold">
                    <span>{this.props.orderDetails.id} </span>
                    <span title="Client">
                      {!isNullOrUndefined(this.props.orderDetails.client)
                        ? this.props.orderDetails.client.name
                        : ''}
                    </span>
                    <span
                      title={this.translatorService.Tranlate(
                        'ORDER_PART_DETAILS_SUPPLIER_LABEL',
                        'Furnizor'
                      )}
                    >
                      {!isNullOrUndefined(this.props.orderDetails.supplier)
                        ? ' - ' + (this.props.orderDetails.supplier.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : this.props.orderDetails.supplier.name)
                        : ''}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="align-items-center">
                  <div className="text-black font-weight-bold">
                    <span className="">
                      {!isNullOrUndefined(this.state.partsLocationOrganization)
                        ? this.translatorService.Tranlate(
                            'ORDER_PART_DETAILS_SUPPLIER_LABEL11',
                            'Adresa livrare:'
                          ) +
                          ' ' +
                          this.state.partsLocationOrganization.name +
                          '; ' +
                          (!isNullOrUndefined(this.state.partsLocationOrganization.address)
                            ? 'strada: ' + this.state.partsLocationOrganization.address
                            : '')
                        : ''}
                    </span>
                  </div>
                  <div className="text-black font-weight-bold">
                    <span className="">
                      {!isNullOrUndefined(this.state.costCenter)
                        ? this.translatorService.Tranlate(
                          'CASE_EVENT_COST_CENTER',
                          'Centru de cost'
                          ) + ':' + 
                          ' ' +
                          this.state.costCenter.name 
                        : ''}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="d-flex align-items-center float-right mt-2">
                  <div className="text-black-50 font-weight-bold">
                    <span>
                      {!isNullOrUndefined(this.props.orderDetails.createdByUser)
                        ? this.props.orderDetails.createdByUser.userName
                        : ''}{' '}
                    </span>
                    <span>
                      {moment
                        .utc(this.props.orderDetails.date)
                        .local()
                        .toDate()
                        .toLocaleString(this.props.appState.language)}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        ) : null}
      </Card>
    );
  };

  renderOrderPartsSection = () => {
    const options = {
      filter: false,
      sort: false,
      search: false,
      pagination: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      elevation: 0,
      textLabels: MUITranslations.GetTranslations(this.translatorService)
      // responsive: "stacked",
    };

    return (
      <Card className="mt-2">
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <Box m={1} p={1}>
            <MUIDataTable
              title={''}
              data={this.state.orderpartsList}
              columns={this.getPartsByColumns()}
              options={options}
            />
          </Box>
        ) : null}
      </Card>
    );
  };

  checkIfOrderPartsChangedDuringOrderPlacement = async () => {
    const orderDatePlacement = new Date();
    let validityTimeOkay = true;
    const caseCalculationPartsThatChangedDuringPlacingOrder: CaseCalculationSupplierAlternativePart[] =
      [];

    this.state.orderpartsList.forEach((orderPart) => {
      orderPart.prevVal = null;
    });
    this.setState({
      caseCalculationPartsThatChangedDuringPlacingOrder: []
    });

    //Detect if the order part list was changed during the order placemenent
    (await Promise.all(
      this.state.orderpartsList.map(async (product) => {
        if (!product.isDeleted) {
          const freshOrderedParts =
            await this.caseService.InterogateCaseCalculationsAlternativePartsBySupplier(
              product.id,
              product.guideNumber,
              this.props.additionTariff.value,
              this.props.discountTariff.value,
              this.props.caseSettingsState.caseSettings!.workflow.id,
              this.props.orderDetails.supplierId,
              this.props.orderDetails.partsLocationOrganizationId
            );
          const changedOrderedProducts = freshOrderedParts.filter(
            (part) =>
              part.supplierId == product.supplierId &&
              part.producer == product.producer &&
              part.alternativeGuideNumber == product.alternativeGuideNumber &&
              part.name == product.alternativePartName &&
              part.alternativePartCode == product.alternativePartCode &&
              part.locationId == product.locationId
          );
          console.log('changedOrderedProducts', changedOrderedProducts);
          if (changedOrderedProducts.length == 1) {
            const changedOrderedProduct = changedOrderedProducts[0];
            if (
              changedOrderedProduct.alternativePriceWithoutDiscount !== product.alternativePrice ||
              changedOrderedProduct.deliveryTime !== product.deliveryTime ||
              changedOrderedProduct.validityTime !== product.validityTime ||
              changedOrderedProduct.type != product.type ||
              changedOrderedProduct.orderCode != product.orderCode
            ) {
              caseCalculationPartsThatChangedDuringPlacingOrder.push({
                prevVal: _.cloneDeep(product),
                newVal: _.cloneDeep(changedOrderedProduct)
              });
              product.prevVal = {
                ...product
              };
              if (product.alternativePrice != changedOrderedProduct.alternativePriceWithoutDiscount) {
                product.alternativePrice = changedOrderedProduct.alternativePriceWithoutDiscount;
              }
              if (product.deliveryTime != changedOrderedProduct.deliveryTime) {
                product.deliveryTime = changedOrderedProduct.deliveryTime;
              }
              if (product.type != changedOrderedProduct.type) {
                product.type = changedOrderedProduct.type;
              }
              if (product.orderCode != changedOrderedProduct.orderCode) {
                product.orderCode = changedOrderedProduct.orderCode;
              }
              if (product.validityTime != changedOrderedProduct.validityTime) {
                product.validityTime = changedOrderedProduct.validityTime;
              }
            }
            if (product.validityTime) {
              if (new Date(orderDatePlacement) > new Date(product.validityTime)) {
                validityTimeOkay = false;
                this.props.enqueueSnackbar(
                  'Codul: ' +
                    changedOrderedProduct.alternativeGuideNumber +
                    ' producator: ' +
                    changedOrderedProduct.producer +
                    ' furnizor: ' +
                    this.props.orderDetails.supplier?.name +
                    ' nu mai este valid. \n' +
                    'A fost valid pana la : ' +
                    moment
                      .utc(changedOrderedProduct.validityTime)
                      .local()
                      .toDate()
                      .toLocaleString(this.props.appState.language),
                  {
                    variant: 'error'
                  }
                );
              }
            }
          }
        }
      })
    )) || [];

    console.log(
      'caseCalculationPartsThatChangedDuringPlacingOrder',
      caseCalculationPartsThatChangedDuringPlacingOrder
    );

    if (!validityTimeOkay) {
      return true;
    }

    if (caseCalculationPartsThatChangedDuringPlacingOrder.length > 0) {
      this.setState({
        caseCalculationPartsThatChangedDuringPlacingOrder:
          caseCalculationPartsThatChangedDuringPlacingOrder,
        isChangeOrderStatusDialogOpen: false,
        isLoading: false
      });

      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ERROR_MSG_SMTH', 'Produsele comandate au fost modificate'),
        {
          variant: 'error'
        }
      );

      //Update the order parts
      await Promise.all([
        caseCalculationPartsThatChangedDuringPlacingOrder.map(async (row) => {
          const part = row.newVal;
          const orderedPart = this.state.orderpartsList.filter(
            (product) =>
              part!.supplierId == product.supplierId &&
              part!.producer == product.producer &&
              part!.alternativeGuideNumber == product.alternativeGuideNumber &&
              part!.name == product.alternativePartName &&
              part.alternativePartCode == product.alternativePartCode
          )[0];
          await this.orderService.UpdateOrderPart(orderedPart!);
        })
      ]);

      const order = { ...this.props.orderDetails };
      const newOrder = await this.orderService.UpdateOrderAmounts({
        orderId: this.props.orderDetails.id,
      } as UpdateOrderAmountsRequestDto);

      order.amountWithoutTVA = newOrder!.amountWithoutTVA;
      order.amountTVA = newOrder!.amountTVA;
      order.amount = newOrder!.amount;

      this.props.onOrderChange(order);

      return true;
    }

    return false;
  };

  changeOrderStatusSubmit = async () => {
    try {
      this.setState({ isLoading: true, executing: true });
      const orderedPartsChanged = await this.checkIfOrderPartsChangedDuringOrderPlacement();
      console.log(
        'Changed Order Part List ',
        this.state.caseCalculationPartsThatChangedDuringPlacingOrder
      );
      if (orderedPartsChanged) {
        this.setState({ executing: false });
        return;
      }
      const nextOrderStatus = this.getNextOrderStatus();
      const request = {
        statusId: nextOrderStatus?.id,
        comment: this.state.changeOrderStatusComment,
        orderId: this.props.orderDetails.id,
        attachments: this.state.attachments,
        partsLocationOrganizationId: this.props.orderDetails.partsLocationOrganizationId,
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        partsOrganizationId: this.props.appState.appUser?.organizationId
      } as OrderStatusChangeRequestDto;

      await this.orderService.ChangeOrderStatus(request);

      await Promise.all([
        this.state.orderpartsList.map(async (product) => {
          const orderedPartStatus = this.state.orderPartStatus.find(
            (item) => item.code === OrderPartStatus.PROCESSING
          )!;
          if (!product.isDeleted) {
            product.status = orderedPartStatus;
            product.statusId = orderedPartStatus.id;
            await this.orderService.UpdateOrderPart(product!);
          }
        })
      ]);

      const orderStatusHistory = [...this.state.orderStatusHistory];

      const order = { ...this.props.orderDetails };
      order.orderStatus = nextOrderStatus!;
      order.orderStatusId = nextOrderStatus!.id;

      this.props.onOrderChange(order);

      orderStatusHistory.unshift({
        id: 1,
        orderId: this.props.orderDetails.id,
        orderStatusId: this.props.orderDetails.orderStatusId,
        orderStatus: nextOrderStatus!,
        date: new Date(),
        userId: this.props.appState.appUser!.id!,
        user: this.props.appState.appUser,
        comment: this.state.changeOrderStatusComment,
        attachments: this.state.attachments.length > 0 ? this.state.attachments : null
      });

      if (
        this.props.orderDetails.supplier?.name == SupplierCode.MATEROM ||
        this.props.orderDetails.supplier?.name == SupplierCode.ADTOTAL ||
        this.props.orderDetails.supplier?.name == SupplierCode.CONEX ||
        this.props.orderDetails.supplier?.name == SupplierCode.EUROEST ||
        this.props.orderDetails.supplier?.name == SupplierCode.LKQ  || 
        this.props.orderDetails.supplier?.name == SupplierCode.NESTE
      ) {
        const order = await this.orderService.GetOrder(this.props.orderDetails.id, false);
        if (order.supplierOrderNumber) {
          const proceedOrderStatus = this.state.orderStatuses.find(
            (item) => item.code === OrderStatusCode.FZR_COMANDA_CONFIRMATA
          );
          const request = {
            statusId: proceedOrderStatus?.id,
            comment: '',
            orderId: this.props.orderDetails.id,
            attachments: [],
            partsLocationOrganizationId: this.props.orderDetails.partsLocationOrganizationId,
            workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
            partsOrganizationId: this.props.appState.appUser?.organizationId
          } as OrderStatusChangeRequestDto;

          await this.orderService.ChangeOrderStatus(request);

          await Promise.all([
            this.state.orderpartsList.map(async (product) => {
              const orderedPartStatus = this.state.orderPartStatus.find(
                (item) => item.code === OrderPartStatus.PROCESSED
              )!;
              if (!product.isDeleted) {
                product.status = orderedPartStatus;
                product.statusId = orderedPartStatus.id;
                await this.orderService.UpdateOrderPart(product!);
              }
            })
          ]);

          await this.caseService.SendConfirmedOrderNotification(this.props.orderDetails.caseId, this.props.orderDetails.id);

          const order = { ...this.props.orderDetails };
          order.orderStatus = proceedOrderStatus!;
          order.orderStatusId = proceedOrderStatus!.id;

          this.props.onOrderChange(order);

          orderStatusHistory.unshift({
            id: 0,
            orderId: this.props.orderDetails.id,
            orderStatusId: this.props.orderDetails.orderStatusId,
            orderStatus: proceedOrderStatus!,
            date: new Date(),
            userId: this.props.appState.appUser!.id!,
            user: this.props.appState.appUser,
            comment: '',
            attachments: []
          });

        }
      }

      console.log(orderStatusHistory);
      this.setState({
        orderStatusHistory: orderStatusHistory,
        isChangeOrderStatusDialogOpen: false,
        selectedDisplayedStatus: this.state.selectedStatus.displayName,
        changeOrderStatusComment: '',
        attachments: [],
        isLoading: false,
        executing: false
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.setState({ 
        isLoading: false,
        executing: false 
      });
      console.log('Exception:' + ex);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteOrderStatusChange = async (newValue: any | null) => {
    this.setState({
      selectedStatus: newValue
    });
  };

  handleOrderPartsStatusChange = async (part: OrderPart, newVal: IReferential | null) => {
    try {
      await this.orderService.ChangeOrderPartStatus(part.id, newVal!.id);
      const order = await this.orderService.GetOrder(this.props.orderDetails.id, false);

      const newArray = [...this.state.orderpartsList];
      const index = newArray.findIndex((item) => item.id === part.id);
      newArray[index] = {
        ...newArray[index],
        status: newVal,
        statusId: newVal !== null ? newVal.id : null
      };

      order.orderParts = newArray;

      this.props.onOrderChange(order);
      this.setState({
        orderpartsList: newArray
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const attachments = [...this.state.attachments];

      attachments.push({
        id: 0,
        caseId: this.props.orderDetails.caseId,
        attachmentTypeId: this.state.attachType!.id,
        attachmentType: this.state.attachType,
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        uploadDate: new Date(),
        carPhotos: false,
        originalFileName: e.target.files![0].name,
        caseRequestId: null,
        paymentId: null,
        serviceAppointmentId: null,
        caseClientId: null,
        isSelection: false
      });

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9}>
          {this.state.attachments.length !== 0
            ? this.state.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left pl-0"
                        disabled={false}
                        onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        onClick={(e) => this.handleDeleteAttachment(attach.id, attach.fileName)}
                        disabled={false}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
        <Grid item xs={3} container justify="flex-end" direction="column">
          <input
            className="d-none"
            id={'contained-button-file'}
            disabled={false}
            onChange={(e) => {
              return this.handleUploadAttachment(e);
            }}
            type="file"
          />
          <label className="mb-0" htmlFor={'contained-button-file'}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={false}
              className="d-block text-center shadow-none"
              component="div"
            >
              {this.translatorService.Tranlate(
                'ORDER_PART_DETAILS_DIALOG_ATTACH_BTN_LABEL',
                'Atasament'
              )}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  };

  deleteOrderPart = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      const newArray = [...this.state.orderpartsList];
      const selectedPartIndex = newArray.findIndex((item) => item.id === id);
      const part = newArray[selectedPartIndex];
      const groupedParts = newArray.filter((item) => item.groupedUniqueId === part.groupedUniqueId);
      console.log('part', part);
      console.log('groupedParts', groupedParts);
      for (let i = 0; i < newArray.length; i++) {
        if (newArray[i].groupedUniqueId === part.groupedUniqueId) {
          await this.orderService.DeleteOrderPart(newArray[i].id);
          newArray[i].isDeleted = true;
        }
      }
      const order = await this.orderService.GetOrder(this.props.orderDetails.id, false);
      const orderStatusHistory = await this.orderService.GetOrderStatusHistories(
        this.props.orderDetails.id
      );

      order.orderParts = newArray;
      this.props.onOrderChange(order);
      this.setState({
        orderpartsList: newArray,
        orderStatusHistory: orderStatusHistory
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderChangeOrderStatusForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.changeOrderStatusSubmit();
        }}
      >
        <div className="text-center m-0">
          {
            !this.props.workflowAutomatePlaceOrder
            ? (
              <TextValidator
                fullWidth
                disabled={false}
                id="textComment"
                name="textComment"
                multiline
                rows={5}
                label={this.translatorService.Tranlate(
                  'ORDER_PART_DETAILS_DIALOG_COMMENT_LABEL',
                  'Comentariu'
                )}
                value={this.state.changeOrderStatusComment}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({
                    changeOrderStatusComment: e.target.value
                  });
                }}
                variant="outlined"
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            ): (
              <TextValidator
                fullWidth
                disabled={false}
                id="textComment"
                name="textComment"
                multiline
                rows={5}
                label={this.translatorService.Tranlate(
                  'ORDER_PART_DETAILS_DIALOG_COMMENT_LABEL',
                  'Comentariu'
                )}
                value={this.state.changeOrderStatusComment}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({
                    changeOrderStatusComment: e.target.value
                  });
                }}
                variant="outlined"
              />
            )
          }
          {this.renderAttachmentBox()}
        </div>
        <Button
          className="mt-3 float-right"
          variant="contained"
          color="primary"
          type="submit"
          disabled={this.state.executing}
          >
          {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
        </Button>
      </ValidatorForm>
    );
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  showOrdeStatusTimelineHistory = () => {
    const orderStatusHistory = this.state.orderStatusHistory;

    if (orderStatusHistory.length === 0) {
      return (
        <b>
          {this.translatorService.Tranlate(
            'ORDER_DETAILS_HISTORY_NO_DATA',
            'Nu exista date de afisat!'
          )}
        </b>
      );
    }

    return (
      <div className="timeline-list  timeline-list--primary">
        {orderStatusHistory.map((orderStatus, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-item--content">
              <div className="timeline-item--icon " />
              <h4 className="timeline-item--label font-weight-bold">
                {' '}
                {orderStatus.orderStatus!.displayName}
              </h4>
              <p className="mt-2 text-black-50 font-weight-bold">
                {moment
                  .utc(orderStatus.date)
                  .local()
                  .toDate()
                  .toLocaleString(this.props.appState.language)}
                {!isNullOrUndefined(this.props.orderDetails.createdByUser)
                  ? '(' + this.props.orderDetails.createdByUser.userName + ')'
                  : ''}
              </p>
              <p className="mt-2 text-black">{orderStatus.comment}</p>

              {orderStatus.attachments === null
                ? null
                : orderStatus.attachments.map((attach, index) => {
                    return (
                      <div key={index} className="d-flex flex-row w-100">
                        <div className="w-75 text-left" key={index}>
                          <Button
                            color="primary"
                            className="text-primary text-left"
                            onClick={(e) =>
                              this.downloadFile(attach.fileName, attach.originalFileName)
                            }
                          >
                            <span className="crop">{attach.originalFileName}</span>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.orderService = (this.context as AppContext).orderService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.caseService = (this.context as AppContext).caseService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    
    const options = {
      filter: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      elevation: 0,
      textLabels: MUITranslations.GetTranslations(this.translatorService)
      // responsive: "stacked",
    };

    return (
      <React.Fragment>
        {this.renderOrderDetailsSection()}
        {this.renderOrderPartsSection()}
        <Card className="mt-2">
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>

          {!this.state.isLoading && this.state.showOrderPrice ? (
            <div>
              <div style={{ backgroundColor: '#cccccc' }} className="card-header text-black">
                <span className="mb-0 py-2 font-weight-bold">
                  {this.translatorService.Tranlate('ORDER_DETAILS_TOTAL_TITLE', 'TOTAL COMANDA')}
                </span>
              </div>
              <CardContent className="p-3">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="">
                      <p className="m-0">
                        <span className="font-weight-bold">
                          {this.translatorService.Tranlate(
                            'ORDER_DETAILS_TOTAL_NO_TVA_LABEL',
                            'Total fara TVA'
                          ) + ': '}{' '}
                        </span>
                        {Utils.countryNumberFormat(this.props.orderDetails.amountWithoutTVA.toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                          ' ' +
                          (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                          this.props.orderDetails.currencyCode !== ''
                            ? this.props.orderDetails.currencyCode
                            : '')}
                      </p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          {this.translatorService.Tranlate('ORDER_DETAILS_TVA_LABEL', 'TVA')}
                          {this.state.TVA !== '' && !isNullOrUndefined(this.state.TVA)
                            ? ' (' + this.state.TVA + '%)'
                            : ''}
                          :{' '}
                        </span>
                        {Utils.countryNumberFormat(this.props.orderDetails.amountTVA.toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                          ' ' +
                          (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                          this.props.orderDetails.currencyCode !== ''
                            ? this.props.orderDetails.currencyCode
                            : '')}
                      </p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          {this.translatorService.Tranlate('ORDER_DETAILS_TOTAL_LABEL', 'Total') +
                            ': '}
                        </span>
                        {Utils.countryNumberFormat(this.props.orderDetails.amount.toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                          ' ' +
                          (!isNullOrUndefined(this.props.orderDetails.currencyCode) &&
                          this.props.orderDetails.currencyCode !== ''
                            ? this.props.orderDetails.currencyCode
                            : '')}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          ) : null}
        </Card>

        <Card className="mt-2">
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <div>
              <div style={{ backgroundColor: '#cccccc' }} className="card-header text-black">
                <span className="mb-0 py-2 font-weight-bold">
                  {this.translatorService.Tranlate(
                    'ORDER_DETAILS_HISTORY_TITLE',
                    'ISTORIC COMANDA'
                  )}
                </span>
              </div>
              <CardContent className="p-3">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {this.showOrdeStatusTimelineHistory()}
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          ) : null}
        </Card>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isChangeOrderStatusDialogOpen}
          fullScreen={false}
          disableBackdropClick
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
          >
            <span>
              {this.translatorService.Tranlate(
                'ORDER_DETAILS_DIALOG_TITLE',
                'Modificare stare comanda'
              )}
            </span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="p-3" dividers style={{ backgroundColor: '#fafafa' }}>
            {this.renderChangeOrderStatusForm()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: ExternalOrderDetailsProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(OrderDetails as any));
