import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as R from 'ramda';
import { MaintenanceProducer } from '../../../interfaces/Case';
import { OrganizationOilViscosity } from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import NumberFormat from 'react-number-format';

interface IOrganizationOilViscosityState {
  newOrganizationOilViscosity: OrganizationOilViscosity;
  oilViscosityList: IReferential[];
  organizationOilViscosityList: OrganizationOilViscosity[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newOrganizationOilViscosityId: number | null;
  organizationId: number;
}
type IOrganizationOilViscosityProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; }>;

class OrganizationOilViscosities extends React.PureComponent<
  IOrganizationOilViscosityProps,
  IOrganizationOilViscosityState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;
  static contextType = ApplicationContext;
  private newOrganizationOilViscosity = {
    id: 0,
    organizationId: 0,
    oilViscosityId: 0,
    price: 0
  } as OrganizationOilViscosity;

  state = {
    organizationOilViscosityList: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newOrganizationOilViscosity: this.newOrganizationOilViscosity,
    oilViscosityList: [] as IReferential[],
    newOrganizationOilViscosityId: null,
    organizationId: 0
  } as IOrganizationOilViscosityState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationOilViscosityList();
      }
    );
  }

  loadOrganizationOilViscosityList = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OilViscosity
    );

    const [organizationOilViscositiesList, oilViscosities] = await Promise.all([
      this.organizationService.GetOrganizationOilViscosities(hoId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    console.log('organizationOilViscositiesList', organizationOilViscositiesList);
    console.log('oilViscosities', oilViscosities);

    this.setState({
      organizationOilViscosityList: organizationOilViscositiesList,
      isLoading: false,
      oilViscosityList: oilViscosities,
      newOrganizationOilViscosity: { ...this.newOrganizationOilViscosity },
      organizationId: hoId
    });
  };

  private handleOilViscosityChange = (newValue: IReferential | null) => {
    this.setState({
      newOrganizationOilViscosity: {
        ...this.state.newOrganizationOilViscosity,
        oilViscosity: newValue,
        oilViscosityId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  private handlePriceChange = (value: string) => {
    this.setState({
      newOrganizationOilViscosity: {
        ...this.state.newOrganizationOilViscosity,
        price: parseFloat(value)
      }
    });
  };

  public saveOrganizationOilViscosity = async () => {
    if (this.state.newOrganizationOilViscosityId === null) {
      try {
        if (
          this.state.organizationOilViscosityList.findIndex(
            (item) =>
              this.state.newOrganizationOilViscosity.oilViscosityId === item.oilViscosityId 
          ) !== -1
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('ERROR_MSG_OIL_VISCOSITIES_EXISTS', 'Pretul pentru aceasta viscozitate exista deja!'),
            { variant: 'error' }
          );
          return;
        }

        await this.organizationService.AddOrganizationOilViscosity({
          ...this.state.newOrganizationOilViscosity,
          organizationId : this.state.organizationId
        });
        await this.loadOrganizationOilViscosityList();
        this.closeDialog();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
      
    } else {
      try {
        await this.organizationService.UpdateOrganizationOilViscosity(this.state.newOrganizationOilViscosity);
        await this.loadOrganizationOilViscosityList();
        this.closeDialog();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  
  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveOrganizationOilViscosity} instantValidate={true}>
        <TextValidator
          fullWidth
          name="price"
          className="m-2"
          id="price"
          value={this.state.newOrganizationOilViscosity.price || ''}
          onChange={(e: any) => this.handlePriceChange(e.target.value)}
          label={this.translatorService.Tranlate(
            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
            'Pret'
          )}
          validators={['required', 'minNumber:1']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          InputProps={{
            inputComponent: this.NumberFormatCustom,
            style: { fontWeight: 500 }
          }}
        />
        <Autocomplete
          id="oilViscosity"
          className="m-2"
          options={this.state.oilViscosityList}
          value={this.state.newOrganizationOilViscosity.oilViscosity}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleOilViscosityChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="oilViscosity"
              value={this.state.newOrganizationOilViscosity.oilViscosity}
              label={this.translatorService.Tranlate(
                'ORGANIZATIONS_TAB_OIL_VISCOSITY',
                'Viscozitate Ulei'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        />

        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'oilViscosity',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_TAB_OIL_VISCOSITY',
          'Viscozitate Ulei'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.name!.localeCompare(b.displayName!);
          },
          customBodyRender: (value: IReferential) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'price',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_TAB_OIL_VISCOSITY_PRICE',
          'Pret'
        ) 
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newOrganizationOilViscosityPopup(tableMeta.rowData[0])}
                >
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteOrganizationOilViscosity(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];
    return columns;
  };

  deleteOrganizationOilViscosity = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      await this.organizationService.RemoveOrganizationOilViscosity(id);
      await this.loadOrganizationOilViscosityList();
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  newOrganizationOilViscosityPopup = async (organizationOilViscosityId: number | null) => {
    let newOrganizationOilViscosity: OrganizationOilViscosity;
    if (organizationOilViscosityId == null) {
      newOrganizationOilViscosity = { ...this.state.newOrganizationOilViscosity } as OrganizationOilViscosity;
    } else {
      newOrganizationOilViscosity = this.state.organizationOilViscosityList.find((item) => item.id === organizationOilViscosityId)!;
    }
    this.setState({
      isDialogOpen: true,
      newOrganizationOilViscosity: newOrganizationOilViscosity,
      newOrganizationOilViscosityId: organizationOilViscosityId
    });
  };

  closeDialog = async () => {
    this.setState(
        { 
          isDialogOpen: false, 
          newOrganizationOilViscosity: this.newOrganizationOilViscosity
        }
      );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.organizationService = (this.context as AppContext).organizationService;


    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newOrganizationOilViscosityPopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };
    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationOilViscosityList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4">
                  {!this.state.newOrganizationOilViscosityId ? 
                    this.translatorService.Tranlate(
                        'ORGANIZATION_OIL_VISCOSITIES_ADD_MODAL_TITLE',
                        'Adauga Pret Viscozitate Ulei'
                      ) : this.translatorService.Tranlate(
                        'ORGANIZATION_OIL_VISCOSITIES_UPDATE_MODAL_TITLE',
                        'Actualizeaza Pret Viscozitate Ulei'
                      )}               
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>{this.renderAddForm()}</DialogContent>
          </Dialog>) : null}
      </Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationOilViscosities as any));