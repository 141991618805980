import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Organization, OrganizationJobType } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, Tabs, Tab, IconButton } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { IReferential } from '../../../interfaces/IReferential';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

interface IOrganizationJobTypesState {
  organizationJobTypes: OrganizationJobType[];
  isLoading: boolean;
  jobTypes: IReferential[];
  selectedJobType: IReferential[];
  organizationId: number;
}

type IOrganizationJobTypesProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string;}>;

class OrganizationJobTypes extends React.PureComponent<IOrganizationJobTypesProps, IOrganizationJobTypesState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationJobTypes: [],
    isLoading: false,
    jobTypes: [],
    selectedJobType: [],
    organizationId: 0
  } as IOrganizationJobTypesState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationJobTypes();
      }
    );
  }

  loadOrganizationJobTypes = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.JobType
    );

    const [organizationJobTypesList, jobTypes] = await Promise.all([
      this.organizationService.GetOrganizationJobTypes(hoId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    console.log('organizationJobTypesList', organizationJobTypesList);
    console.log('jobTypes', jobTypes);


    organizationJobTypesList.forEach((ojt) => {
      const jobType = jobTypes.find((item) => item.id === ojt.jobTypeId);

      ojt.jobType = isNullOrUndefined(jobType) ? null : jobType;
    });

    this.setState({
      organizationJobTypes: organizationJobTypesList,
      jobTypes: jobTypes.filter((item) => item.isActive === true),
      isLoading: false,
      organizationId: hoId
    });
  };

  
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'jobType',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_TAB_JOB_TYPE',
          'Job Type'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Organization) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PARTNERS_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgJobType(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  deleteOrgJobType = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationJobType(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadOrganizationJobTypes();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  addOrganizationJobType = async () => {
    try {
      if (
        this.state.organizationJobTypes.findIndex(
          (item) =>
            this.state.selectedJobType.findIndex((itemm) => itemm.id === item.jobTypeId) !==
            -1
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_PARTNER_EXISTS', 'Partenerul exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      const organizationJobTypes = [];
      for (const jobType of this.state.selectedJobType) {
        organizationJobTypes.push({
          id: 0,
          organizationId: this.state.organizationId,
          jobTypeId: jobType!.id,
          jobType: jobType
        } as OrganizationJobType);
      }

      await this.organizationService.AddOrganizationJobType(organizationJobTypes);

      this.setState(
        {
          isLoading: true,
          selectedJobType: []
        },
        () => {
          this.loadOrganizationJobTypes();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  handleOrganizationJobTypeChange = (newValue: any[]) => {
    this.setState({ selectedJobType: newValue });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrganizationJobType}>
              <Autocomplete
                id="jobTypes"
                className="m-2"
                multiple
                options={this.state.jobTypes.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedJobType}
                onChange={(e: any, newValue: any[]) => this.handleOrganizationJobTypeChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="jobType"
                    value={this.state.selectedJobType}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATIONS_TAB_JOB_TYPE',
                      'Job Type'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('ORGANIZATIONS_TAB_JOB_TYPES', 'Job Types')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationJobTypes}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationJobTypes as any));
