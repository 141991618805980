import React, { Fragment, ChangeEvent } from 'react';
import { ApplicationContext, AppContext } from '../../../context/Contexts';

import { ScaleLoader } from 'react-spinners';
import {
  Box,
  Switch,
  Card,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  List,
  ListSubheader,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ITranslation, IReferentialConfig } from '../../../interfaces/IReferential';
import { IAuthService } from '../../../services/Interfaces/IAuthService';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import MaterialTable, { Icons, EditComponentProps, Column } from 'material-table';
import { withSnackbar, ProviderContext } from 'notistack';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { Language } from '../../../interfaces/Organization';
import { isNullOrUndefined } from 'util';
import { EmailTemplate } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SubjectIco from '@material-ui/icons/Subject';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { mailPlaceHolders } from '../mailPlaceHolders';
import { toBase64 } from '../../../utils/toBase64';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

type OtherListsOfValuesProps = { appState: AppState } & ProviderContext;

interface IOtherListsOfValuesState {
  otherListsOfValues: EmailTemplate[];
  isLoading: boolean;
  selectedReferential: IReferentialConfig | null;
  languages: Language[];
  isDialogEmailTemplateOpen: boolean;
  emailTemplateId: number;
  selectedEmailBodyTemplate: EditorState;
  selectedEmailSubjectTemplate: string;
}

class OtherListsOfValues extends React.PureComponent<
  OtherListsOfValuesProps,
  IOtherListsOfValuesState
> {
  private authService!: IAuthService;
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private worflowService!: IWorkflowService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;

  state = {
    otherListsOfValues: [],
    isLoading: false,
    selectedReferential: null,
    languages: [] as Language[],
    isDialogEmailTemplateOpen: false,
    emailTemplateId: 0,
    selectedEmailBodyTemplate: EditorState.createEmpty(),
    selectedEmailSubjectTemplate: ''
  } as IOtherListsOfValuesState;

  componentDidMount = () => {
    this.setState({
      languages: this.activeLanguages()
    });
  };

  onTagsChange = (event: React.ChangeEvent<any>, values: IReferentialConfig | null) => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadReferentials(values);
      }
    );
  };

  loadReferentials = async (value: IReferentialConfig | null) => {
    let otherListsOfValues: EmailTemplate[] = [];
    if (value !== null && value.getUrl !== '') {
      try {
        otherListsOfValues = await this.worflowService.GetEmailTemplates();

        otherListsOfValues.forEach((refItem) => {
          this.state.languages.forEach((lang) => {
            const translation = refItem.translations.find((item) => item.language === lang.code);
            if (isNullOrUndefined(translation)) {
              refItem.translations.push({
                id: 0,
                name: '',
                language: lang.code,
                referentialTypeId: refItem.id
              } as ITranslation);
            }
          });
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }

    this.setState({
      otherListsOfValues: otherListsOfValues,
      isLoading: false,
      selectedReferential: value!
    });
  };

  handleIsActiveChange = async (event: ChangeEvent<HTMLInputElement>, rowData: any) => {
    const id = rowData.id;
    const newListsOfValues = [...this.state.otherListsOfValues];
    // let updatedValue = newListsOfValues.find(ref => ref.id === id);
    const index = newListsOfValues.findIndex((ref) => ref.id === id);
    if (newListsOfValues[index] !== undefined) {
      newListsOfValues[index].isActive = event.target.checked;

      try {
        await this.worflowService.UpdateEmailTemplate(newListsOfValues[index]);
        this.setState({
          otherListsOfValues: newListsOfValues
        });
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter((language) => {
        if (language.isActive === true) {
          return language;
        }
      });
    activeLanguages.forEach((language) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  handleLocListChange = (event: any, gridProps: any, code: string | null) => {
    let newLocList = [] as ITranslation[];
    if (gridProps.rowData.translations) {
      newLocList = gridProps.rowData.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData,
      translations: newLocList
    });
  };

  onEmailTemplateClick = (
    e: any,
    id: number,
    bodyEmailTemplate: string,
    subjectEmailTemplate: string
  ) => {
    const blocksFromHTML = htmlToDraft(bodyEmailTemplate);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.setState({
      isDialogEmailTemplateOpen: true,
      emailTemplateId: id,
      selectedEmailBodyTemplate: EditorState.createWithContent(contentState),
      selectedEmailSubjectTemplate: subjectEmailTemplate
    });
  };

  getColumns = () => {
    return [
      {
        field: 'code',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_COD_HEADER', 'Cod'),
        searchable: true,
        // initialEditValue: Guid.create().toString()
        render: (rowData: EmailTemplate) => {
          return (
            <Button
              color="primary"
              className=" text-primary"
              onClick={(e) =>
                this.onEmailTemplateClick(e, rowData.id, rowData.body, rowData.subject)
              }
            >
              <span>{rowData.code}</span>
            </Button>
          );
        }
      } as Column<any>,
      {
        field: 'translations',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_NAME_HEADER', 'Nume'),
        searchable: true,
        render: (rowData: any) => {
          return rowData.translations.map((val: ITranslation, index: number) => {
            const language = this.state.languages.find((language: any) => {
              return language.code === val.language;
            });
            return language ? (
              <div key={index} className="pt-2">
                <TextField
                  id="standard-basic"
                  label={language.displayName}
                  fullWidth
                  value={val.name}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  size="small"
                ></TextField>{' '}
              </div>
            ) : null;
          });
        },
        customFilterAndSearch: (value, rowData) => {
          let matchFilter = false;
          rowData.translations.map((val: any) => {
            const findMatch = val.name.toLowerCase().indexOf(value.toLowerCase()) != -1;
            if (findMatch) {
              matchFilter = true;
            }
          });
          return matchFilter;
        },
        editComponent: (props: any) => {
          if (!isNullOrUndefined(props.value)) {
            return props.value.map((val: ITranslation, index: number) => {
              const language = this.state.languages.find(
                (language: any) => language.code === val.language
              );

              return language ? (
                <div key={index} className="pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    name={'name_' + index}
                    fullWidth
                    value={val.name}
                    label={language.displayName}
                    onChange={(e) => this.handleLocListChange(e, props, val.language)}
                  ></TextField>
                </div>
              ) : null;
            });
          } else {
            //add
            const newLocList = [] as ITranslation[];
            for (let i = 0; i < this.state.languages!.length; i++) {
              const locListItem = {} as ITranslation;
              locListItem.language = this.state.languages[i].code;
              locListItem.name = '';
              newLocList.push(locListItem);
            }

            props.onChange(newLocList);

            return null;
          }
        }
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_DATE_HEADER', 'Data'),
        defaultSort: 'desc',
        searchable: true,
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .toDate()
            .toLocaleString(this.props.appState.language);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{new Date().toLocaleString(this.props.appState.language)}</Label>;
        }
      } as Column<any>,
      {
        field: 'isActive',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_ISACTIVE_HEADER', 'Stare'),
        render: (rowData: any) => {
          return (
            <Switch
              checked={rowData.isActive}
              onChange={(e) => this.handleIsActiveChange(e, rowData)}
              value={rowData.isActive}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          );
        },

        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Switch
              checked={props.value || false}
              value={props.value || false}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          );
        }
      }
    ];
  };
  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza')
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };
  public renderTypeList = () => {
    // const ListsOFValueTypes = Referentials.referential;
    const ListsOFValueTypes = [
      {
        name: 'Email Template',
        getUrl: 'string',
        updateUrl: 'string',
        addUrl: 'string'
      } as IReferentialConfig
    ];

    return (
      <Fragment>
        <Card className="card-box p-4 mb-4">
          <Button className="m-3" variant="contained" color="primary" onClick={this.syncClick}>
            {this.translatorService.Tranlate(
              'LIST_OF_VALUES_SYNC_BUTTON',
              'Actualizeaza Lista Modele'
            )}
          </Button>
          <Button className="m-3" variant="contained" color="primary" onClick={this.syncUndocumentedModels}>
            {this.translatorService.Tranlate(
              'LIST_OF_UNDOCUMENTED_MODELS_VALUES_SYNC_BUTTON',
              'Actualizeaza Lista Modele Nedocumentate'
            )}
          </Button>
          <div className="d-flex justify-content-between w-100">
            <Autocomplete
              id="combo-box-demo"
              options={ListsOFValueTypes}
              getOptionLabel={(option) => option.name}
              style={{ width: '100%' }}
              onChange={this.onTagsChange}
              renderInput={(params: RenderInputParams) => (
                <TextField
                  fullWidth
                  {...params}
                  label={this.translatorService.Tranlate(
                    'LIST_OF_VALUES_TYPE',
                    'Tip lista de valori'
                  )}
                  variant="outlined"
                />
              )}
            />
          </div>
        </Card>
      </Fragment>
    );
  };

  onRowUpdate = (newData: EmailTemplate, oldData?: EmailTemplate): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (!this.validateRef(newData)) {
        reject();
        return;
      }

      if (oldData) {
        this.setState((prevState: IOtherListsOfValuesState) => {
          const newListOfValues = [...this.state.otherListsOfValues];
          newListOfValues[newListOfValues.indexOf(oldData)] = newData;

          return { ...prevState, otherListsOfValues: newListOfValues };
        });

        try {
          await this.worflowService.UpdateEmailTemplate(newData);
          // await this.loadReferentials(this.state.selectedReferential);
          resolve();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  validateRef = (referential: EmailTemplate): boolean => {
    if (referential.code === '' || referential.code === undefined || referential.code === null) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    let resultRef = this.state.otherListsOfValues.find((item) => item.code === referential.code);
    if (referential.id !== undefined) {
      resultRef = this.state.otherListsOfValues.find(
        (item) => item.code === referential.code && item.id !== referential.id
      );
    }
    if (resultRef !== undefined) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_UNIQUE', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    if (referential.translations === undefined) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_NAME', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    for (const item of referential.translations) {
      if (item.name === '' || item.name === undefined || item.name === null) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_NAME', 'Eroare'),
          { variant: 'error' }
        );
        return false;
      }
    }

    return true;
  };

  onRowAdd = (newData: EmailTemplate): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (!this.validateRef(newData)) {
        reject();
        return;
      }
      try {
        newData.id = 0;
        newData.name = newData.translations[0].name;
        newData.gtId = this.props.appState.appUser!.gtId!;
        newData.body = '';
        newData.subject = '';

        const newItem = await this.worflowService.AddEmailTemplate(newData);
        const newListOfValues = [...this.state.otherListsOfValues];
        newListOfValues.push(newItem);
        this.setState({ otherListsOfValues: newListOfValues });
        //await this.loadReferentials(this.state.selectedReferential);
        resolve();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    if (this.state.selectedReferential === null) {
      return;
    }

    return (
      <MaterialTable
        icons={tableIcons}
        title={this.translatorService.Tranlate('LIST_OF_VALUES', 'Lista de valori')}
        columns={this.getColumns()}
        data={this.state.otherListsOfValues}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData)
        }}
      />
    );
  };

  closeDialog = () => {
    this.setState({ isDialogEmailTemplateOpen: false });
  };
  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ selectedEmailBodyTemplate: editorState });
  };

  renderEditEmailTemplate = () => {
    return (
      <Editor
        editorState={this.state.selectedEmailBodyTemplate}
        editorStyle={{ border: '1px solid #d1d2db', borderRadius: '0.29rem', height: '250px' }}
        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState)}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'history',
            'image'
          ],
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: async (file: File) => {
              const imageBase64String = await toBase64(file);
              return Promise.resolve({ data: { link: imageBase64String } });
            },
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: '140',
              width: 'auto'
            }
          }
        }}
      />
    );
  };

  placeHolderClick = (text: string) => {
    const editorState = this.state.selectedEmailBodyTemplate;
    const newBlockMap = convertFromHTML('{{' + text + '}}');
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const key = selectionState.getAnchorKey();
    const blocksAfter = contentState
      .getBlockMap()
      .skipUntil(function (_, k) {
        return k === key;
      })
      .skip(1)
      .toArray();
    const blocksBefore = contentState
      .getBlockMap()
      .takeUntil(function (_, k) {
        return k === key;
      })
      .toArray();

    newBlockMap.contentBlocks = blocksBefore
      .concat([contentState.getBlockForKey(key)])
      .concat(newBlockMap.contentBlocks)
      .concat(blocksAfter);

    const newContentState = ContentState.createFromBlockArray(
      newBlockMap.contentBlocks,
      newBlockMap.entityMap
    );
    const newEditorState = EditorState.createWithContent(newContentState);
    this.setState({ selectedEmailBodyTemplate: newEditorState });
  };
  addPlaceholderToSubject = (text: string) => {
    this.setState({
      selectedEmailSubjectTemplate: this.state.selectedEmailSubjectTemplate + '{{' + text + '}}'
    });
  };

  renderMailPlaceHolders = () => {
    return (
      <List component="nav" subheader={<ListSubheader>Placeholders</ListSubheader>}>
        {mailPlaceHolders.map((val, index) => (
          <ListItem button key={index} dense>
            <ListItemText primary={val} onClick={(e) => this.placeHolderClick(val)} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={(e) => this.addPlaceholderToSubject(val)}>
                <SubjectIco />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  saveEmailTemplateChanges = async () => {
    const id = this.state.emailTemplateId;
    const otherListsOfValues = [...this.state.otherListsOfValues];
    const index = otherListsOfValues.findIndex((item) => item.id === id);
    const emailBody = draftToHtml(
      convertToRaw(this.state.selectedEmailBodyTemplate.getCurrentContent())
    );

    otherListsOfValues[index] = {
      ...otherListsOfValues[index],
      body: emailBody,
      subject: this.state.selectedEmailSubjectTemplate
    };

    const data = otherListsOfValues.find((item) => item.id === id);
    await this.worflowService.UpdateEmailTemplate(data!);

    this.setState(
      {
        // isLoading: true,
        isDialogEmailTemplateOpen: false,
        otherListsOfValues: otherListsOfValues
      }
      // , async () => await this.loadData()
    );
  };

  syncClick = async () => {
    try {
      await this.caseService.SyncModels();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  syncUndocumentedModels = async () => {
    try {
      await this.caseService.SyncUndocumentedModels();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.worflowService = (this.context as AppContext).workflowService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <React.Fragment>
        <Box m={1} p={1}>
          {this.renderTypeList()}
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogEmailTemplateOpen}
            // fullWidth={true} maxWidth="md" scroll="body" >
            fullScreen
          >
            <DialogTitle style={{ color: '#ffffff', backgroundColor: '#1F2857' }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4">
                    {this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_EDIT_EMAIL_TEMPLATE_MODAL_TITLE',
                      'Editeaza template-ul email-ului'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="text-right">
                  <IconButton
                    aria-label="close"
                    onClick={() => this.closeDialog()}
                    size={'small'}
                    style={{ color: '#ffffff' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item sm={8} className="text-right">
                  <TextField
                    fullWidth
                    className="mb-2"
                    style={{ borderRadius: '0.29rem' }}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_EDIT_EMAIL_TEMPLATE_SUBJECT',
                      'Subiect'
                    )}
                    id="outlined-size-small"
                    value={this.state.selectedEmailSubjectTemplate}
                    onChange={(e) =>
                      this.setState({ selectedEmailSubjectTemplate: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                  />
                  {this.renderEditEmailTemplate()}
                </Grid>
                <Grid item sm={4} className="text-right">
                  <Card elevation={1}>
                    <Box>{this.renderMailPlaceHolders()}</Box>
                  </Card>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                {this.translatorService.Tranlate('CANCEL', 'Anuleaza')}
              </Button>
              <Button onClick={this.saveEmailTemplateChanges} color="primary">
                {this.translatorService.Tranlate('SAVE', 'Salveaza')}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => ({ appState: state.app }))(
  withSnackbar(OtherListsOfValues as any)
);
