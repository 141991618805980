export const APP_SET_USER = 'APP_SET_USER';
export const APP_REMOVE_USER = 'APP_REMOVE_USER';
export const APP_DISPLAY_MESSAGE = 'APP_DISPLAY_MESSAGE';
export const APP_SET_LANGUAGE = 'APP_SET_LANGUAGE';

export const APP_LOAD_NOTIFICATION = 'APP_LOAD_NOTIFICATION';
export const APP_LOAD_TASKS = 'APP_LOAD_TASKS';
export const APP_LOAD_COMMENTS = 'APP_LOAD_COMMENTS';

export const APP_REMOVE_TASK = 'APP_REMOVE_TASK';
export const APP_REMOVE_COMMENT = 'APP_REMOVE_COMMENT';
export const APP_REMOVE_NEW_IN_CASE = 'APP_REMOVE_NEW_IN_CASE';
export const APP_MARK_AS_VIEWED_NEW_IN_CASE = 'APP_MARK_AS_VIEWED_NEW_IN_CASE';
export const APP_REMOVE_NEW_IN_APPOINTMENT = 'APP_REMOVE_NEW_IN_APPOINTMENT';
export const APP_MARK_AS_VIEWED_NEW_IN_APPOINTMENT = 'APP_MARK_AS_VIEWED_NEW_IN_APPOINTMENT';
export const APP_SET_NOTIFICATIONS_LOADERS = 'APP_SET_NOTIFICATIONS_LOADERS';
export const APP_SET_CURRENCY_RATES = 'APP_SET_CURRENCY_RATES';

export const APPUSER_USER_GET_LIST_START = 'APPUSER_USER_GET_LIST_START';
export const APPUSER_USER_GET_LIST_END = 'APPUSER_USER_GET_LIST_END';
export const APPUSER_USER_EDIT = 'APPUSER_USER_EDIT';
export const APPUSER_USER_ADD = 'APPUSER_USER_ADD';

export const REQUEST_WEATHER_FORECASTS = 'REQUEST_WEATHER_FORECASTS';
export const RECEIVE_WEATHER_FORECASTS = 'RECEIVE_WEATHER_FORECASTS';

export const THEME_SET_SIDEBAR_HOVER = 'SET_SIDEBAR_HOVER';
export const THEME_SET_SIDEBAR_TOGGLE = 'SET_SIDEBAR_TOGGLE';
export const THEME_SET_SIDEBAR_TOGGLE_MOBILE = 'SET_SIDEBAR_TOGGLE_MOBILE';

export const CASE_SETTINGS_SET_CASE = 'CASE_SETTINGS_SET_CASE';
export const CASE_SETTINGS_SET_LOADING = 'CASE_SETTINGS_SET_LOADING';
export const CASE_SETTINGS_SET_CASE_VEHICLE = 'CASE_SETTINGS_SET_CASE_VEHICLE';
export const CASE_SETTINGS_SET_CASE_EVENT = 'CASE_SETTINGS_SET_CASE_EVENT';
export const CASE_SETTINGS_SET_CASE_STATUS = 'CASE_SETTINGS_SET_CASE_STATUS';
export const CASE_SETTINGS_ADD_CASE_PARTNER = 'CASE_SETTINGS_ADD_CASE_PARTNER';
export const CASE_SETTINGS_SET_CASE_VALUATION = 'CASE_SETTINGS_SET_CASE_VALUATION';
export const CASE_SETTINGS_SET_CLIENT_DATA = 'CASE_SETTINGS_SET_CLIENT_DATA';
export const CASE_SETTINGS_SET_VEHICLE_DATA = 'CASE_SETTINGS_SET_VEHICLE_DATA';
export const CASE_SETTINGS_UPDATE_PARAMETERS = 'CASE_SETTINGS_UPDATE_PARAMETERS';
export const CASE_SETTINGS_UPDATE_TARIFF_VALUE = 'CASE_SETTINGS_UPDATE_TARIFF_VALUE';
export const CASE_SETTINGS_SET_CASE_CALCULATION_HISTORY =
  'CASE_SETTINGS_SET_CASE_CALCULATION_HISTORY';

export const VEHICLE_SET_VEHICLE = 'VEHICLE_SET_VEHICLE';
export const VEHICLE_SET_VEHICLE_STATUS = 'VEHICLE_SET_VEHICLE_STATUS';
export const VEHICLE_RESET_STATE = 'VEHICLE_RESET_STATE';
export const VEHICLE_SET_VEHICLE_LOADING = 'VEHICLE_SET_VEHICLE_LOADING';

export const MAVO_VEHICLE_SET_VEHICLE = 'MAVO_VEHICLE_SET_VEHICLE';
export const MAVO_VEHICLE_SET_VEHICLE_STATUS = 'MAVO_VEHICLE_SET_VEHICLE_STATUS';

export const VEHICLE_SET_VEHICLE_AUCTION = 'VEHICLE_SET_VEHICLE_AUCTION';

/**
 * Agenda actions
 */
export const AGENDA_SET_SELECTED_RANGE = 'AGENDA_SET_SELECTED_RANGE';
export const AGENDA_SET_APPOINTMENTS = 'AGENDA_SET_APPOINTMENTS';
export const AGENDA_SET_SELECTED_APPOINTMENT = 'AGENDA_SET_SELECTED_APPOINTMENT';
export const AGENDA_SET_STATUS_LIST = 'AGENDA_SET_STATUS_LIST';
export const AGENDA_SET_CATEGORY_TYPE = 'AGENDA_SET_CATEGORY_TYPE';
