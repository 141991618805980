import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { RefConstants } from '../../../helpers/Constants';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NumberFormat from 'react-number-format';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import { FinancialParameterInfo } from '../../../interfaces/Vehicle';

interface IFinancialParameterInfoListState {
  newFinancialParameterInfo: FinancialParameterInfo;
  financialParameters: IReferential[];
  financialParametersInfoList: FinancialParameterInfo[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newFinancialParameterInfoId: number | null;
}

type IFinancialParameterInfoListProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class FinancialParameterInfoList extends React.PureComponent<
  IFinancialParameterInfoListProps,
  IFinancialParameterInfoListState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;


  static contextType = ApplicationContext;
  private newFinancialParameterInfo = {
    id: 0,
    value: '',
    financialParameterId: null,
    financialParameter: null
  } as unknown as FinancialParameterInfo;

  state = {
    financialParameters: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newFinancialParameterInfo: this.newFinancialParameterInfo,
    financialParametersInfoList: [],
    newFinancialParameterInfoId: null
  } as IFinancialParameterInfoListState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadFinancialParameterInfoList();
      }
    );
  }

  loadFinancialParameterInfoList = async () => {
    const financialParametersInfoList = await this.vehicleService.GetFinancialParameterInfoList();
    const { financialParameterRef } = RefConstants;

    const financialParameters = await this.appReferentialService.Get(financialParameterRef!.baseUrl);


    this.setState({
      financialParameters: financialParameters,
      isLoading: false,
      financialParametersInfoList: financialParametersInfoList,
      newFinancialParameterInfo: { ...this.newFinancialParameterInfo }
    });
  };

  private handleFinancialParameterChange = (newValue: IReferential | null) => {
    this.setState({
      newFinancialParameterInfo: {
        ...this.state.newFinancialParameterInfo,
        financialParameter: newValue,
        financialParameterId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public saveFinancialParameterInfo = async () => {
    if (this.state.newFinancialParameterInfoId === null) {
      try {
        await this.vehicleService.AddFinancialParameterInfo(this.state.newFinancialParameterInfo);

        await this.loadFinancialParameterInfoList();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {

        await this.vehicleService.UpdateFinancialParameterInfo(this.state.newFinancialParameterInfo);

        await this.loadFinancialParameterInfoList();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };
  
  onChange =
  (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
    isNumber
      ? this.setState({
        newFinancialParameterInfo: {
            ...this.state.newFinancialParameterInfo,
            [fieldName]: parseFloat(newValue || e.target.value)
          }
        })
      : this.setState({
        newFinancialParameterInfo: {
            ...this.state.newFinancialParameterInfo,
            [fieldName]: newValue || e.target.value
          }
        });
  };

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveFinancialParameterInfo} instantValidate={true}>
        <Autocomplete
          id="financialParameter"
          className="m-2"
          options={this.state.financialParameters}
          value={this.state.newFinancialParameterInfo.financialParameter}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleFinancialParameterChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="financialParameter"
              value={this.state.newFinancialParameterInfo.financialParameter}
              label={this.translatorService.Tranlate(
                'FINANCIAL_PARAMETER_INFO_FINANCIAL_PARAMETER',
                'Parametru financiar'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        /> 

        <TextValidator
          fullWidth
          id="value"
          className="m-2 pr-3 mt-0"
          name="value"
          placeholder={this.translatorService.Tranlate('FINANCIAL_PARAMETER_INFO_VALUE', 'Valoare')}
          value={this.state.newFinancialParameterInfo.value}
          onChange={(e: any) =>
            this.onChange('value', false)(e)
          }
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          label={this.translatorService.Tranlate('FINANCIAL_PARAMETER_INFO_VALUE', 'Valoare')}
        />

      
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'financialParameter',
        label: this.translatorService.Tranlate(
          'FINANCIAL_PARAMETER_INFO_FINANCIAL_PARAMETER',
          'Parametru financiar'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.displayName!.localeCompare(b.displayName!);
          },
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'value',
        label: this.translatorService.Tranlate('FINANCIAL_PARAMETER_INFO_VALUE', 'Valoare'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const financialParameterInfo = this.state.financialParametersInfoList.find((item) => item.id === tableMeta.rowData[0]);

            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newFinancialParameterInfoPopup(tableMeta.rowData[0])}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteFinancialParameterInfo(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  deleteFinancialParameterInfo = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.vehicleService.RemoveFinancialParameterInfo(id);

      await this.loadFinancialParameterInfoList();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  newFinancialParameterInfoPopup = async (financialParameterInfoId: number | null) => {
    let newFinancialParameterInfo: FinancialParameterInfo;
    if (financialParameterInfoId == null) {
      newFinancialParameterInfo = { ...this.state.newFinancialParameterInfo } as FinancialParameterInfo;
    } else {
      newFinancialParameterInfo = this.state.financialParametersInfoList.find((item) => item.id === financialParameterInfoId)!;
    }
    this.setState({
      isDialogOpen: true,
      newFinancialParameterInfo: newFinancialParameterInfo,
      newFinancialParameterInfoId: financialParameterInfoId
    });
  };

  closeDialog =  () => {
    this.setState(
      { 
        isDialogOpen: false, 
        newFinancialParameterInfo: this.newFinancialParameterInfo,
        newFinancialParameterInfoId: null
      }
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newFinancialParameterInfoPopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.financialParametersInfoList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                  {!this.state.newFinancialParameterInfoId ? 
                  this.translatorService.Tranlate(
                      'FINANCIAL_PARAMETER_INFO_NEW_MODAL_TITLE',
                      'Adauga parametru financiar'
                    ) : this.translatorService.Tranlate(
                      'FINANCIAL_PARAMETER_INFO_UPDATE_MODAL_TITLE',
                      'Actualizeaza parametru financiar'
                    )}   
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderAddForm()}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(FinancialParameterInfoList as any));
