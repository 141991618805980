import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState, AppState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import {
  CaseWorkflowHistory,
  CaseParameterHistory,
  CasePartnerHistory,
  CaseTariffHistory
} from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import { ReferentialCode } from '../../helpers/Constants';
import moment from 'moment';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { OrganizationType } from '../../interfaces/Organization';
import { ITariffService } from '../../services/Interfaces/ITariffService';
import CloseIcon from '@material-ui/icons/Close';
import TariffProperties from './TariffProperties';

interface ICaseDetailsCaseTabHistoryFormState {
  caseWorkflowHistory: CaseWorkflowHistory[];
  casePartenerHistory: CasePartnerHistory[];
  caseParameterHistory: CaseParameterHistory[];
  caseTariffHistory: CaseTariffHistory[];
  isLoading: boolean;
  isOpenDialog: boolean;
}

type ICaseDetailsCaseTabHistoryFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabHistoryForm extends React.PureComponent<
  ICaseDetailsCaseTabHistoryFormProps,
  ICaseDetailsCaseTabHistoryFormState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private workflowService!: IWorkflowService;
  private tariffService!: ITariffService;

  static contextType = ApplicationContext;
  state = {
    caseWorkflowHistory: [],
    caseParameterHistory: [],
    casePartenerHistory: [],
    caseTariffHistory: [],
    isLoading: false,
    isOpenDialog: false
  } as ICaseDetailsCaseTabHistoryFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseWorkflowHistoryForm();
      }
    );
  }

  loadCaseWorkflowHistoryForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CompanyParameter
    );
    const orgTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );

    const [
      caseWorkflowHistory,
      workflows,
      casePartenerHistory,
      caseParameterHistory,
      caseTariffHistory
    ] = await Promise.all([
      this.caseService.GetCaseWorkflowHistory(caseId),
      this.workflowService.GetUserWorkflows(),
      this.caseService.GetCasePartnerHistory(caseId),
      this.caseService.GetCaseParameterHistory(caseId),
      this.caseService.GetCaseTariffHistory(caseId)
    ]);

    caseWorkflowHistory.forEach((caseWh) => {
      const workflow = workflows.find((item) => item.id === caseWh.workflowId);

      caseWh.workflowDisplayName = isNullOrUndefined(workflow) ? '' : workflow.displayName;
      caseWh.user = this.props.appState.appUser;
    });

    const [partners, parameterList, orgTypes, tariffList] = await Promise.all([
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(casePartenerHistory.map((item) => item.organizationId)))
      ),
      this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(orgTypeRef!.baseUrl),
      this.tariffService.GetUserTariffs(caseTariffHistory.map((item) => item.tariffId))
    ]);

    casePartenerHistory.forEach((partnerItem) => {
      const partner = partners.find((item) => item.id === partnerItem.organizationId);
      partnerItem.organization = isNullOrUndefined(partner) ? null : partner;
      const orgType = orgTypes.find(
        (item) => item.id === partnerItem.organization!.organizationTypeId
      );
      partnerItem.organization!.organizationType = (isNullOrUndefined(orgType)
        ? null
        : orgType) as any as OrganizationType;
    });

    caseParameterHistory.forEach((paramItem) => {
      const param = parameterList.find((item) => item.id === paramItem.parameterId);
      paramItem.parameter = isNullOrUndefined(param) ? null : param;
    });

    caseTariffHistory.forEach((tariffItem) => {
      const tariff = tariffList.find((item) => item.id === tariffItem.tariffId);
      tariffItem.tariff = isNullOrUndefined(tariff) ? null : tariff;
    });

    this.setState({
      caseWorkflowHistory: caseWorkflowHistory.sort(function (a: any, b: any) {
        return b.date - a.date;
      }),
      casePartenerHistory: casePartenerHistory,
      caseParameterHistory: caseParameterHistory,
      caseTariffHistory: caseTariffHistory,
      isLoading: false
    });
  };
  renderPartenersHistory = (caseWorkflowId: number) => {
    const partners = [] as any[];
    const casePartenerHistory = this.state.casePartenerHistory.filter(
      (item) => item.caseWorkflowHistoryId === caseWorkflowId
    );
    if (casePartenerHistory.length !== 0) {
      casePartenerHistory.forEach((partnerItem, index) => {
        partners.push(
          <Typography key={index}>
            {partnerItem.organization!.name +
              ' (' +
              partnerItem.organization!.organizationType!.displayName +
              ')'}
          </Typography>
        );
      });
    }
    return partners;
  };

  renderParameterHistory = (caseWorkflowId: number) => {
    const parameters = [] as any[];
    const caseParameterHistory = this.state.caseParameterHistory.filter(
      (item) => item.caseWorkflowHistoryId === caseWorkflowId
    );
    if (caseParameterHistory.length !== 0) {
      caseParameterHistory.forEach((paramItem, index) => {
        parameters.push(
          <Typography key={index}>
            {paramItem.parameter!.displayName + ' = ' + paramItem.value}
          </Typography>
        );
      });
    }
    return parameters;
  };

  renderTariffHistory = (caseWorkflowId: number) => {
    const tariffs = [] as any[];
    const caseTariffHistory = this.state.caseTariffHistory.filter(
      (item) => item.caseWorkflowHistoryId === caseWorkflowId
    );
    if (caseTariffHistory.length !== 0) {
      caseTariffHistory.forEach((tariffItem, index) => {
        tariffs.push(
          <div key={index}>
            <Button
              color="primary"
              className="p-0"
              onClick={(e) => {
                this.setState({ isOpenDialog: true });
              }}
            >
              {tariffItem.tariff === null ? 'N/A' : tariffItem.tariff!.name}
            </Button>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={this.state.isOpenDialog}
              fullWidth={true}
              maxWidth={'md'}
            >
              <DialogTitle>
                <Grid container>
                  <Grid item xs={11}>
                    <Typography variant="h4">
                      {' '}
                      {tariffItem.tariff === null ? 'N/A' : tariffItem.tariff!.name}{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className="text-right">
                    <IconButton
                      aria-label="close"
                      onClick={() => this.setState({ isOpenDialog: false })}
                      size={'small'}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>

              <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                <TariffProperties {...this.props} caseTariffHistoryId={tariffItem.id} />
              </DialogContent>
            </Dialog>
          </div>
        );
      });
    }
    return tariffs;
  };

  showTimelineWorkflowHistory = () => {
    const caseWorkflowHistory = this.state.caseWorkflowHistory;

    if (caseWorkflowHistory.length === 0) {
      return (
        <b>
          {this.translatorService.Tranlate(
            'TARIFF_EDIT_LABOR_TAB_NO_DATA',
            'Nu exista date de afisat!'
          )}
        </b>
      );
    }

    return caseWorkflowHistory.map((caseWorkflow) => (
      <div key={caseWorkflow.date.toString()} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <h4 className="timeline-item--label mb-2 font-weight-bold">
            {caseWorkflow.workflowDisplayName +
              ' (' +
              this.props.caseSettingsState.caseSettings!.workflow.organizationOwner!.name +
              ' )'}
          </h4>
          <div className="ml-4">
            <h6 className="mb-2 font-weight-bold text-black">
              {this.translatorService.Tranlate('CASE_SUMMARY_FORM_PARTNERS', 'Parteneri')}
            </h6>
            <div className="mb-3 text-black ml-3">
              {this.renderPartenersHistory(caseWorkflow.id)}
            </div>
            <h6 className="mb-2 font-weight-bold text-black">
              {this.translatorService.Tranlate('CASE_SUMMARY_FORM_PARAMETERS', 'Parametri')}
            </h6>
            <div className="mb-3 text-black ml-3">
              {this.renderParameterHistory(caseWorkflow.id)}
            </div>
            <h6 className="mb-2 font-weight-bold text-black">
              {this.translatorService.Tranlate('CASE_SUMMARY_FORM_TARIFF', 'Tarif')}
            </h6>
            <div className="mb-3 text-black ml-3">{this.renderTariffHistory(caseWorkflow.id)}</div>
          </div>
          <Typography>
            {' '}
            {moment
              .utc(caseWorkflow.date)
              .local()
              .toDate()
              .toLocaleString(this.props.appState.language) +
              ' (' +
              caseWorkflow.user!.userName +
              ' )'}{' '}
          </Typography>
        </div>
      </div>
    ));
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.tariffService = (this.context as AppContext).tariffService;
    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="app-inner-content-layout ">
            <div className="bg-white p-0">
              <div className="m-4 p-4">
                <div className="timeline-list  timeline-list--primary">
                  {this.showTimelineWorkflowHistory()}{' '}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null
)(withSnackbar(CaseDetailsCaseTabHistoryForm as any));
