import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { AGENDA_SET_SELECTED_RANGE } from '../../store/actions/ActionTypes';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { appointmentsInDay } from '../../utils/appointments';
import { AppointmentStatusCodeEnum } from '../../helpers/Constants';
import moment from 'moment';

export const SmallCalendar = ({ getAppointments, setModalOpen }:any) => {
  const selectedRange = useAppSelector((state) => state.agenda?.selectedRange);
  const appointments = useAppSelector((state) => state.agenda?.appointments.filter((appointment) => appointment?.appointmentStatus?.code !== AppointmentStatusCodeEnum.CANCELED.toString()));
  const dispatch = useAppDispatch();

  const currentRange = useMemo(() => {
    if (!selectedRange) {
      return [new Date(), new Date()];
    }

    return [selectedRange?.start_date, selectedRange?.end_date];
  }, [selectedRange]);

  const onChangeDate = (date:any) =>{
    // If date is array, with 2 elements, it's range
    if (date?.length === 2) {
      dispatch({
        type: AGENDA_SET_SELECTED_RANGE,
        payload: {
          start_date: date[0],
          end_date: date[1]
        }
      });
      getAppointments({
        dateFilterStartDate: date[0],
        datefilterEndDate: date[1]
      })
      setModalOpen(false);
      return;
    }
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    dispatch({
      type: AGENDA_SET_SELECTED_RANGE,
      payload: {
        start_date: date,
        end_date: newDate
      }
    });
    getAppointments({
      dateFilterStartDate: date,
      datefilterEndDate: newDate
    })
    setModalOpen(false);
  }

  return (
    <Box>
      <Calendar
        tileContent={({ date, view }) => {
          const appoimentsForDate = appointmentsInDay(date, appointments);
          if (appoimentsForDate.length > 0) {
            return (
              <div
                style={{
                  width: 14,
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 14,
                  color: 'white',
                  borderRadius: 25,
                  backgroundColor: getColorHueIntensity(appoimentsForDate.length * 10),
                  fontSize: 10
                }}
              >
                {appoimentsForDate.length}
              </div>
            );
          }
        }}
        key={selectedRange?.start_date?.toString()}
        value={currentRange}
        showNeighboringMonth={false}
        onChange={(date:any) =>onChangeDate(date)}
        locale={moment.locale()}
      />
    </Box>
  );
};

const getColorHueIntensity = (intensity: number) => {
  // blue from light to dark
  if (intensity < 20) {
    return 'hsl(359, 100%, 90%)';
  }
  if (intensity < 40) {
    return 'hsl(359, 100%, 80%)';
  }

  if (intensity < 60) {
    return 'hsl(359, 100%, 70%)';
  }

  if (intensity < 80) {
    return 'hsl(359, 100%, 60%)';
  }

  if (intensity < 100) {
    return 'hsl(359, 100%, 50%)';
  }

  return 'hsl(359, 100%, 40%)';
};
