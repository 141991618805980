import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { CurrencyCode } from '../../../helpers/Constants';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NumberFormat from 'react-number-format';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import { ExchangeRate } from '../../../interfaces/Vehicle';
import * as R from 'ramda';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import _ from 'lodash';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IExchangeRateListState {
  newExchangeRate: ExchangeRate;
  currencyList: IReferential[];
  exchangeRates: ExchangeRate[];
  isLoading: boolean;
  isHistoryDialogOpen: boolean;
  isDialogOpen: boolean;
  newExchangeRateId: number | null;
  selectedExchangeRate: ExchangeRate | null;
  currentDate: string;
  lastExchangeRate: ExchangeRate | null;
}

type IExchangeRateListProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class LocalizedUtils extends MomentUtils {
    // dateFormat = "DD MM YYYY";
}
class ExchangeRateList extends React.PureComponent<
  IExchangeRateListProps,
  IExchangeRateListState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;
  private newExchangeRate = {
    id: 0,
    exchangeRatesHistory: [],
    exchangeRateHistory: {
      id: 0,
      currency: CurrencyCode.EUR,
      rate: null,
      date: null,
      startDate: moment(new Date()).format("YYYY-MM-DD") ,
      endDate: moment(new Date()).add(7, 'days').format("YYYY-MM-DD"),
      exchangeRateId: 0
    }
  } as unknown as ExchangeRate;

  state = {
    currencyList: [],
    exchangeRates: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newExchangeRate: this.newExchangeRate,
    newExchangeRateId: null,
    selectedExchangeRate: null,
    isHistoryDialogOpen: false,
    currentDate: moment(new Date()).format("YYYY-MM-DD"),
    lastExchangeRate: null
  } as IExchangeRateListState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadExchangeRatesList();
      }
    );
  }

  loadExchangeRatesList = async () => {
    const exchangeRates = await this.vehicleService.GetExchangeRates();

    const createdByList : string[] = [];
    for (let i = 0; i < exchangeRates.length; i++) {
      createdByList.push(...(_.uniq(exchangeRates[i].exchangeRatesHistory.map((item) => item!.createdBy))));
    }

    const users = await this.appUserService.GetUsersInfo(_.uniq(createdByList));

    for (let i = 0; i < exchangeRates.length; i++) {
      exchangeRates[i].exchangeRatesHistory.forEach((item) => {
        const user = users.find((u) => u.id === item.createdBy);
        item.createdByUser = R.isNil(user) ? null : user;
      });
    }

    console.log(this.newExchangeRate);
    const lastExchangeRate = _.sortBy(exchangeRates, 'dateCreation').reverse()[0];
    console.log('lastExchangeRate', lastExchangeRate);
    this.setState({
      isLoading: false,
      exchangeRates: exchangeRates,
      newExchangeRate: { ...this.newExchangeRate},
        lastExchangeRate: !R.isNil(lastExchangeRate) ? 
        {
          ...lastExchangeRate,
          exchangeRateHistory: {
            ...lastExchangeRate.exchangeRateHistory,
            startDate: lastExchangeRate.exchangeRateHistory.startDate.split('T')[0]
          }
        } : null
    });
  };

  public saveExchangeRate = async () => {
    if (this.state.newExchangeRate.exchangeRateHistory.startDate &&
      this.state.newExchangeRate.exchangeRateHistory.endDate &&
      this.state.newExchangeRate.exchangeRateHistory.startDate >
      this.state.newExchangeRate.exchangeRateHistory.endDate) {

      this.props.enqueueSnackbar(this.translatorService.Tranlate('EXCHANGE_RATE_START_DATE_ERROR', 'Data de start trebuie sa fie mai mica decat data de stop'), {
        variant: 'error'
      });
      return;
    }

    if (this.state.newExchangeRate.exchangeRateHistory.endDate &&
      this.state.newExchangeRate.exchangeRateHistory.endDate < this.state.currentDate) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('EXCHANGE_RATE_END_DATE_ERROR', 'Data de stop trebuie sa fie mai mare decat data curenta'), {
          variant: 'error'
        });
        return;
    }

    console.log('Start Date', this.state.newExchangeRate.exchangeRateHistory.startDate);
    console.log('Previous Start Date', this.state.lastExchangeRate?.exchangeRateHistory.startDate);
    if (this.state.lastExchangeRate && this.state.lastExchangeRate.exchangeRateHistory.startDate && 
      this.state.newExchangeRate.exchangeRateHistory.startDate < this.state.lastExchangeRate.exchangeRateHistory.startDate) {
        const previousStartDate = moment(new Date(this.state.lastExchangeRate.exchangeRateHistory.startDate)).format('DD.MM.YYYY');
        console.log('previousStartDate', previousStartDate);
        this.props.enqueueSnackbar(this.translatorService.Tranlate('EXCHANGE_RATE_START_DATE_ERROR_INFO', 'Data de start pentru urmatorul curs, nu poate fi mai mica decat data start a cursului precedent!')
        + ' ' + this.translatorService.Tranlate('EXCHANGE_RATE_START_DATE_PREVIOUS_LABEL', 'Data start curs precedent: ') + 
          previousStartDate, {
          variant: 'error'
        });
        return;
    }
  
    if (this.state.newExchangeRateId === null) {
      try {
        const request = {
          exchangeRatesHistory: [
            this.state.newExchangeRate.exchangeRateHistory
          ]
        } as ExchangeRate;
        await this.vehicleService.AddExchangeRate(request);

        await this.loadExchangeRatesList();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {
        const request = {
          id: this.state.newExchangeRateId,
          exchangeRatesHistory: [
            this.state.newExchangeRate.exchangeRateHistory
          ]
        } as ExchangeRate;
        await this.vehicleService.UpdateExchangeRate(request);

        await this.loadExchangeRatesList();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };
  
  onChange =
  (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
    isNumber
      ? this.setState({
        newExchangeRate: {
            ...this.state.newExchangeRate,
            [fieldName]: parseFloat(newValue || e.target.value)
          }
        })
      : this.setState({
        newExchangeRate: {
            ...this.state.newExchangeRate,
            [fieldName]: newValue || e.target.value
          }
        });
  };

  handleStartDateDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      newExchangeRate: {
        ...this.state.newExchangeRate,
        exchangeRateHistory: {
          ...this.state.newExchangeRate.exchangeRateHistory,
          startDate: date.format('YYYY-MM-DD')
        }
      }
    });
  };

  handleEndDateDateChange = (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      newExchangeRate: {
        ...this.state.newExchangeRate,
        exchangeRateHistory: {
          ...this.state.newExchangeRate.exchangeRateHistory,
          endDate: date.format('YYYY-MM-DD')
        }
      }
    });
  };

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveExchangeRate} instantValidate={true}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  variant="inline"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="startDate"
                  label={this.translatorService.Tranlate('EXCHANGE_RATE_START_DATE', 'Data start')}
                  value={this.state.newExchangeRate.exchangeRateHistory.startDate || null}
                  onChange={this.handleStartDateDateChange}
                  autoOk={true}
                  disabled={this.state.newExchangeRateId !== null}
                  error={
                    this.state.newExchangeRate.exchangeRateHistory.startDate &&
                    this.state.newExchangeRate.exchangeRateHistory.endDate &&
                    this.state.newExchangeRate.exchangeRateHistory.startDate >
                    this.state.newExchangeRate.exchangeRateHistory.endDate
                      ? true
                      : false
                  }
                  helperText={
                    this.state.newExchangeRate.exchangeRateHistory.startDate &&
                    this.state.newExchangeRate.exchangeRateHistory.endDate &&
                    this.state.newExchangeRate.exchangeRateHistory.startDate >
                    this.state.newExchangeRate.exchangeRateHistory.endDate
                      ? this.translatorService.Tranlate(
                          'EXCHANGE_RATE_START_DATE_ERROR',
                          'Data de start trebuie sa fie mai mica decat data de stop'
                        )
                      : ''
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  variant="inline"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="endDate"
                  label={this.translatorService.Tranlate('EXCHANGE_RATE_END_DATE', 'Data stop')}
                  value={this.state.newExchangeRate.exchangeRateHistory.endDate || null}
                  onChange={this.handleEndDateDateChange}
                  autoOk={true}
                  error={
                    this.state.newExchangeRate.exchangeRateHistory.endDate &&
                    this.state.newExchangeRate.exchangeRateHistory.endDate <
                      this.state.currentDate
                      ? true
                      : false
                  }
                  helperText={
                    this.state.newExchangeRate.exchangeRateHistory.endDate &&
                    this.state.newExchangeRate.exchangeRateHistory.endDate <
                      this.state.currentDate
                      ? this.translatorService.Tranlate(
                          'EXCHANGE_RATE_END_DATE_ERROR',
                          'Data de stop trebuie sa fie mai mare decat data curenta'
                        )
                      : ''
                  }
                  
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                fullWidth
                disabled={this.state.newExchangeRateId !== null}
                variant="outlined"
                className="mt-2 ml-2 pr-2"
                name="currency"
                placeholder={this.translatorService.Tranlate(
                  'EXCHANGE_RATE_VALUE',
                  'Curs valutar'
                )}
                value={this.state.newExchangeRate.exchangeRateHistory.rate}
                onChange={(e: any) => {
                  this.setState({
                    newExchangeRate: {
                      ...this.state.newExchangeRate,
                      exchangeRateHistory: {
                        ...this.state.newExchangeRate.exchangeRateHistory,
                        rate: parseFloat(e.target.value)
                      }
                    } 
                  });
                }}
                validators={['required', 'minNumber:1']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('EXCHANGE_RATE_VALUE', 'Curs valutar')}
                InputProps={{
                  inputComponent: this.NumberFormatCustomCurrencyRate,
                  style: { fontWeight: 500 }
                }}
              />
            </Grid>
         </Grid>
      
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'exchangeRateHistory.rate',
        label: this.translatorService.Tranlate('EXCHANGE_RATE_VALUE', 'Curs valutar'),
        options: {
          filter: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'exchangeRateHistory.currency',
        label: this.translatorService.Tranlate('EXCHANGE_RATE_CURRENCY', 'Moneda'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'exchangeRateHistory.startDate',
        label: this.translatorService.Tranlate('EXCHANGE_RATE_START_DATE', 'Data start'),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div>
                    {moment
                      .utc(value)
                      .local()
                      .format("DD.MM.YYYY")}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'exchangeRateHistory.endDate',
        label: this.translatorService.Tranlate('EXCHANGE_RATE_END_DATE', 'Data stop'),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div>
                    {moment
                        .utc(value)
                        .local()
                        .format("DD.MM.YYYY")}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'dateCreation',
        label: this.translatorService.Tranlate('USER_ROLE_DATE_CREATION_HEADER', 'Data Creare'),
        options: {
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {

            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newExchangeRatePopup(tableMeta.rowData[0])}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="view"
                  color="inherit"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.viewHistory(e, tableMeta)}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteExchangeRate(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  deleteExchangeRate = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.vehicleService.RemoveExchangeRate(id);

      await this.loadExchangeRatesList();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  NumberFormatCustomCurrencyRate = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={4}
      />
    );
  };
  
  showExchangeTimeline = (id: number) => {
    const exchangeRate = this.state.exchangeRates.find((item) => item.id === id)!;

    return exchangeRate.exchangeRatesHistory.map((exchangeRateHistory, index) => {
      return (
        <div key={index} className="timeline-item">
           <div className="timeline-item--content">
            <div className="timeline-item--icon " />
            <p className="timeline-item--label mb-2 font-weight-bold">
              {moment.utc(exchangeRateHistory.date).local().toDate().toLocaleString(this.props.appState.language)}{' '}
              {' (' + exchangeRateHistory.createdByUser!.email + ')'}
            </p>
            <p className="timeline-item--content font-weight-bold">
              {this.translatorService.Tranlate('EXCHANGE_RATE_VALUE', 'Valoare')}: {' '} 
              {exchangeRateHistory.rate}
            </p>
            <p className="timeline-item--content font-weight-bold">
              {this.translatorService.Tranlate('EXCHANGE_RATE_START_DATE', 'Data start')}: {' '} 
              {moment.utc(exchangeRateHistory.startDate).local().format("DD.MM.YYYY")}
            </p>
            <p className="timeline-item--content font-weight-bold">
              {this.translatorService.Tranlate('EXCHANGE_RATE_END_DATE', 'Data sfarsit')}: {' '} 
              {moment.utc(exchangeRateHistory.endDate).local().format("DD.MM.YYYY")}
            </p>
          </div>
        </div>
      );
    });
  };

  public renderViewHistory() {
    return (
      <Dialog
        onClose={this.closeHistoryDialog}
        aria-labelledby="customized-dialog-title"
        open={this.state.isHistoryDialogOpen}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h4">
                {this.translatorService.Tranlate('EXCHANGE_RATE_HISTORY_MODAL_TITLE','Istoric curs valutar')}               
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="close" onClick={this.closeHistoryDialog} size={'small'}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className="mt-4 timeline-container timeline-list timeline-list--primary text-left">
            {this.showExchangeTimeline(this.state.selectedExchangeRate!.id)}
          </div>
      </DialogContent>
      </Dialog>
    );
  }

  viewHistory = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    this.setState({
      selectedExchangeRate: this.state.exchangeRates.find((item) => item.id === id)!,
      isHistoryDialogOpen: true
    })
  };


  newExchangeRatePopup = async (exchangeRateId: number | null) => {
    let newExchangeRate: ExchangeRate;
    if (exchangeRateId == null) {
      newExchangeRate = { ...this.state.newExchangeRate } as ExchangeRate;
    } else {
      newExchangeRate = this.state.exchangeRates.find((item) => item.id === exchangeRateId)!;
    }
    this.setState({
      isDialogOpen: true,
      newExchangeRate: newExchangeRate,
      newExchangeRateId: exchangeRateId
    });
  };

  closeDialog = async () => {
    this.setState(
      { 
        isDialogOpen: false, 
        newExchangeRate: this.newExchangeRate,
        newExchangeRateId: null
      }
    );
  };

  closeHistoryDialog = async () => {
    this.setState(
      { 
        isHistoryDialogOpen: false, 
        selectedExchangeRate: null
      }
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newExchangeRatePopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.exchangeRates}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                  {!this.state.newExchangeRateId ? 
                  this.translatorService.Tranlate(
                      'EXCHANGE_RATE_NEW_MODAL_TITLE',
                      'Adauga curs valutar'
                    ) : this.translatorService.Tranlate(
                      'EXCHANGE_RATE_UPDATE_MODAL_TITLE',
                      'Actualizeaza curs valutar'
                    )}         
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderAddForm()}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
        {this.state.isHistoryDialogOpen ? this.renderViewHistory() : null}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(ExchangeRateList as any));
