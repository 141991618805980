import * as React from 'react';
import { AppState, AppActionCreators, ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { IAuthService } from '../../services/Interfaces/IAuthService';
import { History, LocationState } from 'history';
import { Redirect } from 'react-router';

type RenewCallBackProps = AppState & typeof AppActionCreators & History<LocationState>;

class RenewCallBack extends React.PureComponent<RenewCallBackProps, any> {
  private authService!: IAuthService;

  static contextType = ApplicationContext;

  public async componentDidMount() {
    if (this.props.user === null && this.props.location!.search === '') {
      this.props.SetUser();
      return;
    }

    this.authService = (this.context as AppContext).authenticationService;
    try {
      await this.authService.userManager.signinSilentCallback();
    } catch (error) {
      console.error(error);
    }
  }

  public render() {
    if (this.props.location!.search === '') {
      return <Redirect to="/" />;
    }

    return null;
  }
}

export default connect(
  (state: ApplicationState) => state.app,
  AppActionCreators
)(RenewCallBack as any);
