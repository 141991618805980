import React from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Box, Card, CardContent } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { withSnackbar, ProviderContext } from 'notistack';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import { DropzoneArea } from 'material-ui-dropzone';
import { isNullOrUndefined } from 'util';

interface IOrganizationLogoState {
  isLoading: boolean;
  image: File;
}

export interface ExternalOrganizationLogoProps {
  organizationId: number;
}

type OrganizationLogoProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }> &
  ExternalOrganizationLogoProps;

class OrganizationLogo extends React.PureComponent<OrganizationLogoProps, IOrganizationLogoState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true
  } as IOrganizationLogoState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  handleUploadAttachment = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(files[0]);
    img.onload = async () => {
      // if (img.width === 158 && img.height === 66) {
        await this.saveImage(files);
        return;
      // }

      // this.props.enqueueSnackbar(
      //   this.translatorService.Tranlate(
      //     'ERROR_INCORECT_FILE_SIZE',
      //     'Dimensiune imaginii este invalida'
      //   ),
      //   { variant: 'error' }
      // );
      // return true;
    };
  };

  saveImage = async (files: File[]) => {
    try {
      if (files.length === 0) {
        return;
      }

      await this.organizationService.AddLogo(this.props.organizationId, files![0]);

      this.setState({
        image: files![0]
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  loadData = async () => {
    try {
      const image = await this.organizationService.GetLogo(this.props.organizationId);

      if (image === null) {
        this.setState({
          isLoading: false
        });
        return;
      }

      this.setState({
        isLoading: false,
        image: new File([image], 'logo')
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderLogo = () => {
    return (
      <Box>
        <Card>
          <CardContent className="p-3">
            <div className="d-flex flex-row text-center flex-wrap justify-content-center p-3">
              {isNullOrUndefined(this.state.image) ? (
                ''
              ) : (
                <img src={URL.createObjectURL(this.state.image)} alt="" className="img-fluid" />
              )}
            </div>
            <DropzoneArea
              dropzoneClass="custom-dropzone mb-3"
              dropzoneText={
                this.translatorService.Tranlate(
                  'CASE_CAR_PHOTOS_FORM_UPLOAD_IMAGE',
                  'Drag and drop an image here or click!'
                ) + ' (158px x 66px)'
              }
              showPreviews={false}
              showPreviewsInDropzone={false}
              filesLimit={1}
              onChange={(files: File[]) => this.handleUploadAttachment(files)}
              previewText=""
              showAlerts={false}
              acceptedFiles={['image/*']}
            />
          </CardContent>
        </Card>
      </Box>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Box mt={1} pt={1}>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? this.renderLogo() : null}
      </Box>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalOrganizationLogoProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(OrganizationLogo as any));
