import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  OrganizationCaseType,
  OrganizationCaseTypeStep,
  OrganizationCaseTypeStepForm,
  OrganizationForm
} from '../../../../interfaces/Organization';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import {
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  DialogTitle,
  Dialog,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import EditOrganizationForm from './EditOrganizationForm';

interface IOrganizationCaseTypeStepFormState {
  organizationCaseTypes: OrganizationCaseType[];
  organizationCaseTypeStepForms: OrganizationCaseTypeStepForm[];
  isLoading: boolean;
  organizationCaseTypeSteps: OrganizationCaseTypeStep[];
  organizationCaseStepFormTypes: OrganizationForm[];
  selectedOrganizationCaseTypeStep: OrganizationCaseTypeStep | null;
  selectedOrganizationCaseStepFormType: OrganizationForm | null;
  selectedOrganizationCaseType: OrganizationCaseType | null;
  organizationId: number;
  organizationFormId: number;
  isOpenDialog: boolean;
}

type IOrganizationCaseTypeStepFormProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationCaseTypeStepFormTab extends React.PureComponent<
  IOrganizationCaseTypeStepFormProps,
  IOrganizationCaseTypeStepFormState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationCaseTypeStepForms: [],
    organizationCaseTypes: [],
    isLoading: false,
    organizationCaseTypeSteps: [],
    organizationCaseStepFormTypes: [],

    selectedOrganizationCaseTypeStep: null,
    selectedOrganizationCaseStepFormType: null,
    selectedOrganizationCaseType: null,
    organizationId: 0,
    organizationFormId: 0,
    isOpenDialog: false
  } as IOrganizationCaseTypeStepFormState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationForms(0);
      }
    );
  }

  loadOrganizationForms = async (caseTypeStepId: number) => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const refCaseType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseType
    );

    const [orgCaseList, orgCaseTypes, filterCaseTypeStepForms] = await Promise.all([
      this.organizationService.GetOrganizationCaseType(hoId),
      this.appReferentialService.Get(refCaseType!.baseUrl),
      this.organizationService.GetOrganizationForms()
    ]);

    const organizationCaseTypes = orgCaseList.map((item: OrganizationCaseType) => {
      item.caseType = orgCaseTypes.find(
        (caseType: IReferential) => caseType.id === item.caseTypeId
      )!;
      return item;
    });

    this.setState({
      organizationCaseTypes: organizationCaseTypes,
      organizationCaseStepFormTypes: filterCaseTypeStepForms,
      isLoading: false,
      organizationId: hoId
    });
  };

  reloadOrganizationForms = async (caseTypeStepId: number | null) => {
    if (caseTypeStepId === null) {
      this.setState({
        organizationCaseTypeStepForms: [],
        isLoading: false
      });
      return;
    }

    const orgCaseTypeStepFormList = await this.organizationService.GetOrganizationCaseTypeStepForms(
      caseTypeStepId
    ); //orgCaseList[0].id);

    this.setState({
      organizationCaseTypeStepForms: orgCaseTypeStepFormList.sort(function (a, b) {
        return a.order - b.order;
      }),
      isLoading: false
    });
  };

  deleteOrgLevel = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationCaseTypeStepForm(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.reloadOrganizationForms(this.state.selectedOrganizationCaseTypeStep!.id);
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveUp = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.organizationCaseTypeStepForms];
    const itemToMoveUpIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveUpIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveUpIndex].order === 1) {
      return;
    }

    const itemToMoveDownIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveUpIndex].order - 1
    );

    cloneArray[itemToMoveUpIndex] = {
      ...cloneArray[itemToMoveUpIndex],
      order: cloneArray[itemToMoveUpIndex].order - 1
    };
    cloneArray[itemToMoveDownIndex] = {
      ...cloneArray[itemToMoveDownIndex],
      order: cloneArray[itemToMoveDownIndex].order + 1
    };

    try {
      await this.organizationService.UpdateOrganizationCaseTypeStepForm(
        cloneArray[itemToMoveUpIndex]
      );
      await this.organizationService.UpdateOrganizationCaseTypeStepForm(
        cloneArray[itemToMoveDownIndex]
      );

      this.setState({
        organizationCaseTypeStepForms: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveDown = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.organizationCaseTypeStepForms];
    const itemToMoveIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveIndex].order === cloneArray.length) {
      return;
    }

    const itemToMoveToIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveIndex].order + 1
    );

    cloneArray[itemToMoveIndex] = {
      ...cloneArray[itemToMoveIndex],
      order: cloneArray[itemToMoveIndex].order + 1
    };
    cloneArray[itemToMoveToIndex] = {
      ...cloneArray[itemToMoveToIndex],
      order: cloneArray[itemToMoveToIndex].order - 1
    };

    try {
      await this.organizationService.UpdateOrganizationCaseTypeStepForm(
        cloneArray[itemToMoveIndex]
      );
      await this.organizationService.UpdateOrganizationCaseTypeStepForm(
        cloneArray[itemToMoveToIndex]
      );

      this.setState({
        organizationCaseTypeStepForms: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  onRowClick = (e: any, id: number) => {
    this.setState({ isOpenDialog: true, organizationFormId: id });
  };

  handleClose = (close: boolean) => {
    // if (reload)
    // {
    //   this.setState({isOpenDialog: false}, async () => { await this.loadOrganizationForms()})
    // }
    // else{
    this.setState({ isOpenDialog: !close });
    // }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'form',
        label: this.translatorService.Tranlate('ORGANIZATIONS_CASE_STEP_FORM_HEADER', 'Forma'),
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value.displayName}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'order',
        sortDirection: 'asc',
        label: this.translatorService.Tranlate('ORGANIZATION_CASE_STEP_FORM_ORDER_HEADER', 'Ord'),
        options: { sort: false }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          sortOrder: {
            name: 'order',
            direction: 'asc'
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Fragment>
                <IconButton color="inherit" size="small" onClick={(e) => this.moveUp(e, tableMeta)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => this.moveDown(e, tableMeta)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgLevel(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgForm = async () => {
    try {
      const translations = this.state.selectedOrganizationCaseStepFormType!.translations;
      translations.forEach((item) => {
        item.id = 0;
        item.referentialTypeId = 0;
      });

      await this.organizationService.AddOrganizationCaseTypeStepForm({
        id: 0,
        order: this.state.organizationCaseTypeStepForms.length + 1,
        json1: this.state.selectedOrganizationCaseStepFormType!.json1,
        json2: this.state.selectedOrganizationCaseStepFormType!.json2,
        translations: translations,
        organizationCaseTypeStepId: this.state.selectedOrganizationCaseTypeStep!.id,
        formId: this.state.selectedOrganizationCaseStepFormType!.id
      } as OrganizationCaseTypeStepForm);

      this.setState(
        {
          isLoading: true,
          selectedOrganizationCaseStepFormType: null
        },
        () => {
          this.reloadOrganizationForms(this.state.selectedOrganizationCaseTypeStep!.id);
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteStepChange = (newValue: any | null) => {
    this.setState({ selectedOrganizationCaseTypeStep: newValue, isLoading: true }, () => {
      this.reloadOrganizationForms(newValue === null ? null : newValue.id);
    });
  };

  handleAutocompleteFormChange = (newValue: any | null) => {
    this.setState({ selectedOrganizationCaseStepFormType: newValue });
  };

  handleAutocompleteCaseChange = async (newValue: any | null) => {
    if (newValue === null) {
      this.setState(
        {
          selectedOrganizationCaseType: null,
          organizationCaseTypeSteps: [],
          selectedOrganizationCaseTypeStep: null,
          isLoading: true
        },
        () => {
          this.reloadOrganizationForms(newValue === null ? null : newValue.id);
        }
      );
      return;
    }
    const orgCaseTypeStepList = await this.organizationService.GetOrganizationCaseTypeStep(
      newValue.id
    );
    this.setState(
      {
        selectedOrganizationCaseType: newValue,
        organizationCaseTypeSteps: orgCaseTypeStepList,
        selectedOrganizationCaseTypeStep: null,
        isLoading: true
      },
      () => {
        this.reloadOrganizationForms(null);
      }
    );
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgForm}>
              <Autocomplete
                id="caseTypes"
                className="m-2"
                options={this.state.organizationCaseTypes}
                value={this.state.selectedOrganizationCaseType}
                onChange={(e: any, newValue: OrganizationCaseType | null) =>
                  this.handleAutocompleteCaseChange(newValue)
                }
                getOptionLabel={(option: OrganizationCaseType) => option.caseType!.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationCaseTypes"
                    value={this.state.selectedOrganizationCaseType}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATION_CASE_TYPE_LABEL',
                      'Tip dosar'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="stepTypes"
                className="m-2"
                options={this.state.organizationCaseTypeSteps}
                value={this.state.selectedOrganizationCaseTypeStep}
                onChange={(e: any, newValue: OrganizationCaseTypeStep | null) =>
                  this.handleAutocompleteStepChange(newValue)
                }
                getOptionLabel={(option: OrganizationCaseTypeStep) =>
                  option.organizationStep!.displayName || ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationCaseTypes"
                    value={this.state.selectedOrganizationCaseTypeStep}
                    label={this.translatorService.Tranlate('ORGANIZATION_STEP_TYPE_LABEL', 'Pas')}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="formTypes"
                className="m-2"
                options={this.state.organizationCaseStepFormTypes}
                value={this.state.selectedOrganizationCaseStepFormType}
                onChange={(e: any, newValue: any | null) =>
                  this.handleAutocompleteFormChange(newValue)
                }
                getOptionLabel={(option: any) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationFormTypes"
                    value={this.state.selectedOrganizationCaseStepFormType}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATION_STEP_FORM_TYPE_LABEL',
                      'Forma'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationCaseTypeStepForms}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isOpenDialog}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate(
                    'ORGANIZATION_EDIT_FORM_MODAL_TITLE',
                    'Configurare forma'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleClose(true)}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <EditOrganizationForm
              {...this.props}
              organizationFormId={this.state.organizationFormId}
              organizationFormChanged={(close: boolean) => this.handleClose(close)}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationCaseTypeStepFormTab as any));
