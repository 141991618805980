import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, IconButton, Modal, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { OrganizationCaseTypeStepForm } from '../../interfaces/Organization';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import DoubleArrowTwoTone from '@material-ui/icons/DoubleArrowTwoTone';
import { SearchVehicleFormData } from '../../interfaces/FormData';
import { FormButton } from '../../helpers/forms/FormButton';
import { VehicleDto } from '../../interfaces/Case';
import Form from '@rjsf/material-ui';
import { ISubmitEvent } from '@rjsf/core';
import { isNullOrUndefined } from 'util';
import { CaseTypeCode, VehicleStatusCodeEnum } from '../../helpers/Constants';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

interface ISearchVehicleFormState {
  isLoading: boolean;
  isLoadingData: boolean;
  formData: SearchVehicleFormData;
  vehicleDtoList: VehicleDto[];
  modalOpen: boolean;
  modalMessage?: string;
  modalYesNoButtons: boolean;
  modalData?: any;
}

export interface ExternalSearchVehicleFormProps {
  onVehicleSelected: (vehicleDto: VehicleDto) => void;
  organizationCaseTypeStepForm: OrganizationCaseTypeStepForm;
  searchInVehicleStock: boolean;
}

type ISearchVehicleFormProps = ExternalSearchVehicleFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class SearchVehicleForm extends React.PureComponent<
  ISearchVehicleFormProps,
  ISearchVehicleFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    isLoading: true,
    isLoadingData: false,
    vehicleDtoList: [],
    formData: {} as SearchVehicleFormData,
    modalOpen: false,
    modalMessage: undefined,
    modalYesNoButtons: false,
    modalData: undefined
  } as ISearchVehicleFormState;

  public async componentDidMount() {
    this.setState({
      isLoading: false
    });
  }

  onSubmit = async () => {
    if (!this.state.formData.vin?.trim() && !this.state.formData.plateNumber?.trim()) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'SEARCH_NOTICE_EMPTY_FIELDS_MSG',
          'Completati cel putin un criteriu de cautare'
        ),
        { variant: 'info' }
      );
      return;
    }

    if (this.state.formData.vin || this.state.formData.plateNumber) {
      this.setState({ isLoadingData: true });
      const vehicles = await this.caseService.SearchVehicle(
        this.state.formData.vin?.toUpperCase(),
        this.state.formData.plateNumber?.toUpperCase(),
        this.props.searchInVehicleStock
      );

      this.setState({
        isLoadingData: false,
        vehicleDtoList: vehicles
      });
    }
  };

  onRowlick = async (e: any, rowData: any[]) => {
    const currentVehicle = this.state.vehicleDtoList.find((v) => v.id === rowData[0]);

    if (currentVehicle?.status) {
      const vehicleStatus = currentVehicle.status.id;
      switch (vehicleStatus) {
        case VehicleStatusCodeEnum.Available:
          this.handleModalConfirmation(rowData);
          break;
        case VehicleStatusCodeEnum.Sold:
          this.handleOpenModal(
            this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_SOLD',
              'Autovehiculul selectat este vandut.'
            ),
            undefined
          );
          break;
        case VehicleStatusCodeEnum.Repair:
          let message = this.translatorService.Tranlate(
            'VEHICLE_SEARCH_FORM_VEHICLE_REPAIR',
            'Autovehiculul selectat se afla in proces de reparatie'
          );

          if (
            currentVehicle.lastCase?.caseTypeCode === CaseTypeCode.CLAIM ||
            currentVehicle.lastCase?.caseTypeCode === CaseTypeCode.REP
          ) {
            message = `${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_REPAIR_CASE',
              'Autovehiculul selectat se afla in proces de reparatie pe dosarul'
            )} ${currentVehicle.lastCase.id}, ${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_OPENED_BY',
              'deschis de catre'
            )}
          ${currentVehicle.lastCase.createdByUserName}, 
            ${moment
              .utc(currentVehicle.lastCase.dateCreation)
              .local()
              .toDate()
              .toLocaleString(this.props.appState.language)}.`;
          } else {
            message = `${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_REPAIR',
              'Autovehiculul selectat se afla in proces de reparatie'
            )} ${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_OPENED_BY',
              'deschis de catre'
            )}
          ${currentVehicle.status.userName}, 
            ${moment
              .utc(currentVehicle.status.date)
              .local()
              .toDate()
              .toLocaleString(this.props.appState.language)}.`;
          }

          this.handleOpenModal(message, rowData, true);
          break;
        case VehicleStatusCodeEnum.Reserved:
          this.handleOpenModal(
            `${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_RESERVED',
              'Autovehiculul selectat a fost rezervat de catre'
            )} ${currentVehicle.status.userName}, 
                  ${moment
                    .utc(currentVehicle.status.date)
                    .local()
                    .toDate()
                    .toLocaleString(this.props.appState.language)}.`,
            rowData,
            true
          );
          break;
        case VehicleStatusCodeEnum.Aquisition:
          let message1 = this.translatorService.Tranlate(
            'VEHICLE_SEARCH_FORM_VEHICLE_AQUISITION',
            'Autovehiculul selectat se afla in proces de achizitie.'
          );

          if (currentVehicle.lastCase?.caseTypeCode === CaseTypeCode.AQUISITION) {
            message1 = `${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_AQUISITION_CASE',
              'Autovehiculul selectat se afla in proces de achizitie pe dosarul'
            )} ${currentVehicle.lastCase.id},  ${this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_OPENED_BY',
              'deschis de catre'
            )} ${currentVehicle.lastCase.createdByUserName}, 
          ${moment
            .utc(currentVehicle.lastCase.dateCreation)
            .local()
            .toDate()
            .toLocaleString(this.props.appState.language)}.`;
          }
          this.handleOpenModal(message1, undefined);
          break;
        case VehicleStatusCodeEnum.AcquisitionRefused:
          this.handleOpenModal(
            this.translatorService.Tranlate(
              'VEHICLE_SEARCH_FORM_VEHICLE_AQUISITION_REFUSED',
              'Autovehiculul selectat nu a fost achizitionat.'
            ),
            undefined
          );
          break;
        case VehicleStatusCodeEnum.ForSale:
          this.props.history.push('/cases/' + currentVehicle.lastCase.id);
          break;
        default:
          break;
      }
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                className="m-0"
                size="small"
              >
                <DoubleArrowTwoTone />
              </IconButton>
            );
          }
        }
      },
      {
        name: 'vin',
        label: this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_VIN_LABEL', 'Vin')
      },
      {
        name: 'plateNumber',
        label: this.translatorService.Tranlate(
          'VEHICLE_SEARCH_FORM_VEHICLE_PLATE_NUMBER_LABEL',
          'Numar Inmatriculare'
        )
      },
      {
        name: 'make',
        label: this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_MAKE_LABEL', 'Marca')
      },
      {
        name: 'model',
        label: this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_MODEL_LABEL', 'Model')
      },
      {
        name: 'status.nameLocalized',
        label: this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_MODEL_STATUS', 'Status')
      },
      {
        name: 'registrationDate',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'class',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'region',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      modalMessage: undefined,
      modalYesNoButtons: false,
      modalData: undefined
    });
  };

  handleOpenModal = (modalMessage: string, modalData?: any[], modalYesNoButtons = false) => {
    this.setState({
      modalOpen: true,
      modalMessage,
      modalYesNoButtons,
      modalData
    });
  };

  handleModalConfirmation = (rowData: any[]) => {
    this.props.onVehicleSelected({
      id: rowData[0],
      vin: rowData[1],
      plateNumber: rowData[2],
      make: rowData[3],
      model: rowData[4],
      firstPlateDate: rowData[6],
      class: rowData[7],
      region: rowData[8]
    } as VehicleDto);
  };

  renderModal = () => {
    return (
      <div>
        <Modal open={this.state.modalOpen} onClose={this.handleCloseModal}>
          <div className="search-vehicle-modal">
            <div className="text-right">
              <IconButton onClick={this.handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="mt-4 mb-4 text-center p-4">
              <h5>{this.state.modalMessage}</h5>
              {this.state.modalYesNoButtons && (
                <h5>
                  {' '}
                  {this.translatorService.Tranlate(
                    'VEHICLE_SEARCH_FORM_VEHICLE_CONTINUE',
                    'Doriti sa continuati?'
                  )}
                </h5>
              )}
            </div>
            <div className="text-center mb-4" style={{ marginTop: '20px' }}>
              {!this.state.modalYesNoButtons ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={this.handleCloseModal}
                >
                  Ok
                </Button>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => this.handleModalConfirmation(this.state.modalData)}
                  >
                    {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
                  </Button>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={this.handleCloseModal}
                  >
                    {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'NU')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  
  getMuiTheme = () => createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                '@media (max-width:599.98px)': { 
                    borderBottom: "none",
                    padding: "1px",
                },
            },
        },
        MUIDataTableBodyCell: {
          stackedCommon: {
              '@media (max-width:599.98px)': { 
                  display: "block",
                  width: "100% !important",
                  whiteSpace: "normal",
                  height: "100%",
                 // fontSize: "12px",
                  paddingLeft: "5px",
                  paddingTop: "5px"
              },
          },
          cellHide:{
              fontWeight: "bold",
              paddingTop: "15px",
          }
        },
        MuiTableRow: {
            root: {
              borderBottom: "1px solid rgba(224, 224, 224, 1)"
            }
        }
    } as any
  });
  
  handleRowClick = async (rowData: any[], rowMeta: MUIDataTableMeta) => {
    rowData[0] = this.state.vehicleDtoList[rowMeta.rowIndex].id;
    await this.onRowlick(rowData, rowData);
  }

  renderResults = () => {
    const options = {
      filter: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      // responsive: "stacked",
      elevation: 0,
      search: false,
      viewColumns: false,
      pagination: false,
      onRowClick: this.handleRowClick   as any
    };

    return (
      <div className="m-2">
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingData} />
        </div>
        {this.state.isLoadingData ? (
          ''
        ) : (
          <>
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                title={''}
                data={this.state.vehicleDtoList}
                columns={this.getColumns()}
                options={options}
              />
            </MuiThemeProvider>
            {this.renderModal()}
          </>
        )}
      </div>
    );
  };

  renderSelectedForm = () => {
    let jsonSchema = {};
    let jsonGUI = {};
    try {
      jsonSchema = JSON.parse(this.props.organizationCaseTypeStepForm.json1);
      jsonGUI = JSON.parse(this.props.organizationCaseTypeStepForm.json2);
    } catch {
      console.log('json schema/gui error');
    }
    const schema = this.translatorService.TranslateSchema(jsonSchema);

    return (
      <Form
        schema={schema}
        uiSchema={jsonGUI}
        liveValidate
        showErrorList={false}
        formData={this.state.formData}
        onChange={(e) => this.setState({ formData: e.formData })}
        onSubmit={async (e: ISubmitEvent<any>) => await this.onSubmit()}
        transformErrors={this.translatorService.TranslateErrors}
      >
        <div>
          {FormButton.GetButtons(
            this.props.organizationCaseTypeStepForm.form.code,
            this.translatorService,
            true,
            false
          )}
        </div>
      </Form>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <React.Fragment>
        {this.renderSelectedForm()}
        {this.state.isLoading ? '' : this.renderResults()}
      </React.Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalSearchVehicleFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(SearchVehicleForm as any));
