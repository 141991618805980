import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState, AppState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Grid, Button } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseClient, CaseRequest } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import moment from 'moment';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import FileSaver from 'file-saver';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import * as R from 'ramda';

interface ICaseDetailsCaseRequestsState {
  caseRequests: CaseRequest[];
  isLoading: boolean;
  clientData: CaseClient;
}

export interface ExternalCaseDetailsCaseRequestsProps {
  formId: number;
  caseRequests: CaseRequest[] | null;
  carfixOfferComments: boolean;
}

type ICaseDetailsCaseRequestsProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & AppState &
  ExternalCaseDetailsCaseRequestsProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseRequests extends React.PureComponent<
  ICaseDetailsCaseRequestsProps,
  ICaseDetailsCaseRequestsState
> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private caseService!: ICaseService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    caseRequests: [],
    isLoading: false,
    clientData: {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      caseSectionStepFormId: 0,
      clientId: 0,
      pin: '',
      companyNumber: '',
      companyName: '',
      gdprAgreement: true,
      address: '',
      city: null,
      region: null,
      district: null,
      bankAccount: '',
      email: '',
      phone: ''
    } as CaseClient
  } as ICaseDetailsCaseRequestsState;

  public componentDidMount() {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseRequests();
      }
    );
  }

  loadCaseRequests = async () => {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }

    const casee = this.props.caseSettingsState.case;
    let caseClient = this.state.clientData;
    if (!R.isNil(casee!.caseClient)) {
      caseClient = casee!.caseClient;
    }

    let caseRequests = this.props.caseRequests;

    if (caseRequests == null) {
      caseRequests = await this.caseService.GetCaseRequests(caseId, this.props.formId);

      const userIds = caseRequests.map((item) => item.userId);
      userIds.push(...caseRequests.map((item) => item.userId));

      const users = await this.appUserService.GetUsersInfo(Array.from(new Set(userIds)));

      for (const i of caseRequests) {
        const user = users.find((u) => u.id === i.userId);
        i.user = isNullOrUndefined(user) ? null : user;
      }
    }

    this.setState({
      isLoading: false,
      caseRequests: caseRequests.sort(function (a: any, b: any) {
        return b.date - a.date;
      }),
      clientData: caseClient
    });
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  downloadVehicleCostFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.vehicleService.GetVehicleCostAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  showTimeline = () => {
    const requests = this.state.caseRequests;

    if (requests.length === 0) {
      return null;
    }
    return requests.map((request, index) => (
      <div key={index} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <p className="timeline-item--content font-weight-bold">
            {moment.utc(request.date).local().toDate().toLocaleString(this.props.language) +
              ' (' +
              (!this.props.carfixOfferComments ? request.user!.userName : this.state.clientData.email ?? request.user!.userName) +
              ')'}{' '}
          </p>
          <p className="timeline-item--label mb-2 font-weight-bold">
            {request.to !== null ? (
              <span>
                {' '}
                <i>{request.to}</i> - <b>{request.subject}</b>
              </span>
            ) : (
              ''
            )}
          </p>
          {
            this.props.carfixOfferComments && !index ? (
              <p className="timeline-item--label mb-2 color-red">
                <div dangerouslySetInnerHTML={{ __html: request.text }} />
              </p>
            ) : (
              <p className="timeline-item--label mb-2">
                <div dangerouslySetInnerHTML={{ __html: request.text }} />
              </p>
            )
          }
          {request.isVehicleCost == true ? (
            <p className="timeline-item--label mb-2">
              <b>{request.costType?.name}</b>
            </p>
          ) : (
            ''
          )}
          {request.isVehicleCost == true ? (
            <p className="timeline-item--label mb-2">
              <span>
                {request.secondValue +
                  ' ' +
                  request.secondCurrency +
                  ' (' +
                  request.value +
                  ' ' +
                  request.currency +
                  ')'}
              </span>
            </p>
          ) : (
            ''
          )}
          {this.renderAttachmentBox(request)}
        </div>
      </div>
    ));
  };

  renderAttachmentBox = (caseRequest: CaseRequest) => {
    if (this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration && this.props.appState.appUser!.organization!.organizationType!.hideOtherAttachments) {
      return null;
    }
    
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9}>
          {caseRequest.attachments.length !== 0
            ? caseRequest.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left" key={index}>
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        onClick={(e) =>
                          caseRequest.isVehicleCost
                            ? this.downloadVehicleCostFile(attach.fileName, attach.originalFileName)
                            : this.downloadFile(attach.fileName, attach.originalFileName)
                        }
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
      </Grid>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading && this.state.caseRequests.length !== 0 ? (
          <div className="ml-4 p-4">
            <div className="timeline-list  timeline-list--dark"> {this.showTimeline()} </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseRequestsProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(CaseDetailsCaseRequests as any));
