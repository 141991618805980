import { IOrganizationService } from './Interfaces/IOrganizationService';
import {
  Organization,
  OrganizationType,
  OrganizationLevel,
  OrganizationBankAccount,
  OrganizationInterface,
  OrganizationParameter,
  OrganizationCaseType,
  OrganizationCaseTypeStep,
  OrganizationCaseTypeStepForm,
  OrganizationForm,
  OrganizationFormAction,
  OrganizationFilterType,
  OrganizationToken,
  SetCredentials,
  OrganizationAttachment,
  OrganizationJobType,
  OrganizationMaintenancePackage,
  OrganizationOilViscosity,
  CupiCodeCorespondence,
  OrganizationInvoiceRange,
  OrganizationOilMappingRequest
} from '../interfaces/Organization';
import { API } from '../helpers/API';
import { ICRUDService } from './Interfaces/ICRUDService';
import { CRUDService } from './CRUDService';
import { OrganizationLight } from '../interfaces/OrganizationLight';

export class OrganizationService implements IOrganizationService {
  private baseController = '/organization';

  OrganizationToken: ICRUDService<OrganizationToken>;

  constructor() {
    this.OrganizationToken = new CRUDService(this.baseController, 'OrganizationToken');
  }

  public async AddOrganization(organization: Organization): Promise<Organization> {
    return (await API.PostAsync<Organization>(this.baseController, organization)).data;
  }

  public async UpdateOrganization(organization: Organization): Promise<void> {
    await API.PutAsync<Organization>(this.baseController, organization);
  }

  public async AddOrganizationType(organization: OrganizationType): Promise<OrganizationType> {
    return (
      await API.PostAsync<OrganizationType>(`${this.baseController}/organizationtype`, organization)
    ).data;
  }
  public async UpdateOrganizationType(organizationTyoe: OrganizationType): Promise<void> {
    await API.PutAsync<OrganizationType>(
      `${this.baseController}/organizationtype`,
      organizationTyoe
    );
  }

  public async GetOrganizations(): Promise<Organization[]> {
    return (
      await API.Post2Async<number[], Organization[]>(
        `${this.baseController}/list`,
        []
      )
    ).data;
  }

  public async GetByCompanyNumber(companyNumber: string): Promise<Organization> {
    return (
      await API.GetAsync<Organization>(`${this.baseController}/ByCompanyNumber/${companyNumber}`)
    ).data;
  }

  public async GetOrganizationsByIds(ids: number[]): Promise<Organization[]> {
    return (
      await API.Post2Async<number[], Organization[]>(
        `${this.baseController}/list`,
        ids
      )
    ).data;
  }

  public async GetUserOrganizationsByIds(ids: number[]): Promise<Organization[]> {
    return (
      await API.Post2Async<number[], Organization[]>(
        `${this.baseController}/user`,
        ids
      )
    ).data;
  }

  public async GetHOOrganizations(countryId: number | null): Promise<Organization[]> {
    return (await API.GetAsync<Organization[]>(`${this.baseController}/HOO/${countryId == null ? '' : countryId}`)).data;
  }

  public async GetHOOrganizationsByTypes(types: string[]): Promise<Organization[]> {
    const typeList = types.join(',');

    return (await API.GetAsync<Organization[]>(`${this.baseController}/HO/${typeList}`)).data;
  }
  public async GetOrganizationsByHO(
    hoId: number,
    minLevelId: number | null
  ): Promise<Organization[]> {
    if (minLevelId !== null) {
      return (
        await API.GetAsync<Organization[]>(
          `${this.baseController}/byHO/${hoId}?minlevelId=${minLevelId}`
        )
      ).data;
    }

    return (await API.GetAsync<Organization[]>(`${this.baseController}/byHO/${hoId}`)).data;
  }

  public async GetGtOrganization(): Promise<Organization> {
    return (await API.GetAsync<Organization>(`${this.baseController}/GtOrganization`)).data;
  }

  public async GetCurrentOrganization(): Promise<Organization> {
    return (await API.GetAsync<Organization>(`${this.baseController}/Organization`)).data;
  }

  public async GetOrganization(id: number): Promise<Organization> {
    return (await API.GetAsync<Organization>(`${this.baseController}/Organization/${id}`)).data;
  }
  
  public async GetOrganizationInfo(id: number): Promise<Organization> {
    return (await API.GetAsync<Organization>(`${this.baseController}/Organization/${id}/info`)).data;
  }

  public async GetOrganizationsForTariffPartners(hoId: number, countryId: number | null): Promise<Organization[]> {
    return (await API.GetAsync<Organization[]>(`${this.baseController}/tariffpartners/${hoId}/${countryId == null ? '' : countryId}`))
      .data;
  }

  public async GetOrganizationHierarchy(hoId: number): Promise<Organization[]> {
    return (
      await API.GetAsync<Organization[]>(`${this.baseController}/GetOrganizationHierarchy/${hoId}`)
    ).data;
  }

  public async GetOrganizationDescendents(parentIds: number[]): Promise<OrganizationLight[]> {
    return (
      await API.Post2Async<number[], OrganizationLight[]>(
        `${this.baseController}/descendents`,
        parentIds
      )
    ).data;
  }

  public async GetOrganizationLevels(orgId: number): Promise<OrganizationLevel[]> {
    return (
      await API.GetAsync<OrganizationLevel[]>(`${this.baseController}/${orgId}/OrganizationLevels`)
    ).data;
  }

  public async AddOrganizationLevel(
    organizationLevel: OrganizationLevel
  ): Promise<OrganizationLevel> {
    return (
      await API.PostAsync<OrganizationLevel>(
        `${this.baseController}/organizationlevel`,
        organizationLevel
      )
    ).data;
  }

  public async UpdateOrganizationLevel(organizationLevel: OrganizationLevel): Promise<void> {
    await API.PutAsync<OrganizationLevel>(
      `${this.baseController}/organizationlevel`,
      organizationLevel
    );
  }

  public async RemoveOrganizationLevel(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/organizationlevel/${id}`)).data;
  }

  public async GetOrganizationFilters(orgId: number): Promise<OrganizationFilterType[]> {
    return (
      await API.GetAsync<OrganizationFilterType[]>(
        `${this.baseController}/${orgId}/OrganizationFilters`
      )
    ).data;
  }
  public async GetUserOrganizationFilters(orgId: number): Promise<OrganizationFilterType[]> {
    return (
      await API.GetAsync<OrganizationFilterType[]>(
        `${this.baseController}/OrganizationFilters/listUser/${orgId}`
      )
    ).data;
  }

  public async AddOrganizationFilter(
    organizationFilterType: OrganizationFilterType
  ): Promise<OrganizationFilterType> {
    return (
      await API.PostAsync<OrganizationFilterType>(
        `${this.baseController}/OrganizationFilterType`,
        organizationFilterType
      )
    ).data;
  }

  public async RemoveOrganizationFilter(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationFilterType/${id}`))
      .data;
  }

  public async GetOrganizationBankAccounts(orgId: number): Promise<OrganizationBankAccount[]> {
    return (
      await API.GetAsync<OrganizationBankAccount[]>(
        `${this.baseController}/${orgId}/OrganizationBankAccounts`
      )
    ).data;
  }

  public async GetUserOrganizationBankAccounts(orgId: number): Promise<OrganizationBankAccount[]> {
    return (
      await API.GetAsync<OrganizationBankAccount[]>(
        `${this.baseController}/OrganizationBankAccount/listUser/${orgId}`
      )
    ).data;
  }

  public async AddOrganizationBankAccount(
    organizationBankAccount: OrganizationBankAccount
  ): Promise<OrganizationBankAccount> {
    return (
      await API.PostAsync<OrganizationBankAccount>(
        `${this.baseController}/OrganizationBankAccount`,
        organizationBankAccount
      )
    ).data;
  }

  public async UpdateOrganizationBankAccount(
    organizationBankAccount: OrganizationBankAccount
  ): Promise<void> {
    await API.PutAsync<OrganizationBankAccount>(
      `${this.baseController}/OrganizationBankAccount`,
      organizationBankAccount
    );
  }

  public async RemoveOrganizationBankAccount(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationBankAccount/${id}`))
      .data;
  }

  public async GetOrganizationInterfaces(orgId: number): Promise<OrganizationInterface[]> {
    return (
      await API.GetAsync<OrganizationInterface[]>(
        `${this.baseController}/${orgId}/OrganizationInterfaces`
      )
    ).data;
  }

  public async GetInterfacesWithClientAccess(orgId: number): Promise<OrganizationInterface[]> {
    return (
      await API.GetAsync<OrganizationInterface[]>(
        `${this.baseController}/${orgId}/interfacesWithClientAccess`
      )
    ).data;
  }

  public async GetInterfaceAuthorizeUrl(orgId: number, interfaceId: number): Promise<string> {
    return (
      await API.GetAsync<string>(
        `${this.baseController}/${orgId}/interfacesWithClientAccess/${interfaceId}/authorizeUrl`
      )
    ).data;
  }

  public async AddOrganizationInterface(
    organizationInterface: OrganizationInterface
  ): Promise<OrganizationInterface> {
    return (
      await API.PostAsync<OrganizationInterface>(
        `${this.baseController}/OrganizationInterface`,
        organizationInterface
      )
    ).data;
  }

  public async UpdateOrganizationInterface(
    organizationInterface: OrganizationInterface
  ): Promise<void> {
    await API.PutAsync<OrganizationInterface>(
      `${this.baseController}/OrganizationInterface`,
      organizationInterface
    );
  }

  public async SetInterfaceCredentials(param: SetCredentials): Promise<void> {
    await API.PutAsync(
      `${this.baseController}/${param.orgId}/interfacesWithClientAccess/${param.interfaceId}/setCredentials`,
      param
    );
  }

  public async RemoveOrganizationInterface(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationInterface/${id}`)).data;
  }

  public async GetOrganizationParameters(orgId: number): Promise<OrganizationParameter[]> {
    return (
      await API.GetAsync<OrganizationParameter[]>(
        `${this.baseController}/${orgId}/OrganizationParameters`
      )
    ).data;
  }

  public async AddOrganizationParameter(
    organizationParameter: OrganizationParameter
  ): Promise<OrganizationParameter> {
    return (
      await API.PostAsync<OrganizationParameter>(
        `${this.baseController}/OrganizationParameter`,
        organizationParameter
      )
    ).data;
  }

  public async UpdateOrganizationParameter(
    organizationParameter: OrganizationParameter
  ): Promise<void> {
    await API.PutAsync<OrganizationParameter>(
      `${this.baseController}/OrganizationParameter`,
      organizationParameter
    );
  }

  public async RemoveOrganizationParameter(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationParameter/${id}`)).data;
  }

  public async GetOrganizationCaseType(orgId: number): Promise<OrganizationCaseType[]> {
    return (
      await API.GetAsync<OrganizationCaseType[]>(
        `${this.baseController}/OrganizationCaseType/list/${orgId}`
      )
    ).data;
  }

  public async GetUserOrganizationCaseType(): Promise<OrganizationCaseType[]> {
    return (
      await API.GetAsync<OrganizationCaseType[]>(
        `${this.baseController}/OrganizationCaseType/listUser/`
      )
    ).data;
  }

  public async AddOrganizationCaseType(
    organizationCaseType: OrganizationCaseType
  ): Promise<OrganizationCaseType> {
    return (
      await API.PostAsync<OrganizationCaseType>(
        `${this.baseController}/organizationcasetype`,
        organizationCaseType
      )
    ).data;
  }

  public async UpdateOrganizationCaseType(
    organizationCaseType: OrganizationCaseType
  ): Promise<void> {
    await API.PutAsync<OrganizationCaseType>(
      `${this.baseController}/OrganizationCaseType`,
      organizationCaseType
    );
  }

  public async RemoveOrganizationCaseType(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationCaseType/${id}`)).data;
  }

  public async GetOrganizationCaseTypeStep(
    orgCaseTypeId: number
  ): Promise<OrganizationCaseTypeStep[]> {
    return (
      await API.GetAsync<OrganizationCaseTypeStep[]>(
        `${this.baseController}/OrganizationCaseTypeStep/list/${orgCaseTypeId}`
      )
    ).data;
  }
  public async GetUserOrganizationCaseTypeStep(
    orgCaseTypeId: number
  ): Promise<OrganizationCaseTypeStep[]> {
    return (
      await API.GetAsync<OrganizationCaseTypeStep[]>(
        `${this.baseController}/OrganizationCaseTypeStep/listUser/${orgCaseTypeId}`
      )
    ).data;
  }
  public async AddOrganizationCaseTypeStep(
    OrganizationCaseTypeStep: OrganizationCaseTypeStep
  ): Promise<OrganizationCaseTypeStep> {
    return (
      await API.PostAsync<OrganizationCaseTypeStep>(
        `${this.baseController}/OrganizationCaseTypeStep`,
        OrganizationCaseTypeStep
      )
    ).data;
  }

  public async UpdateOrganizationCaseTypeStep(
    OrganizationCaseTypeStep: OrganizationCaseTypeStep
  ): Promise<void> {
    await API.PutAsync<OrganizationCaseTypeStep>(
      `${this.baseController}/organizationcasetypestep`,
      OrganizationCaseTypeStep
    );
  }

  public async RemoveOrganizationCaseTypeStep(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/organizationcasetypestep/${id}`))
      .data;
  }

  public async GetOrganizationCaseTypeStepForms(
    orgCaseTypeStepId: number
  ): Promise<OrganizationCaseTypeStepForm[]> {
    return (
      await API.GetAsync<OrganizationCaseTypeStepForm[]>(
        `${this.baseController}/OrganizationCaseTypeStepForm/list/${orgCaseTypeStepId}`
      )
    ).data;
  }

  public async GetUserOrganizationCaseTypeStepForms(
    orgCaseTypeStepId: number
  ): Promise<OrganizationCaseTypeStepForm[]> {
    return (
      await API.GetAsync<OrganizationCaseTypeStepForm[]>(
        `${this.baseController}/OrganizationCaseTypeStepForm/listUser/${orgCaseTypeStepId}`
      )
    ).data;
  }

  public async GetOrganizationCaseTypeStepForm(id: number): Promise<OrganizationCaseTypeStepForm> {
    return (
      await API.GetAsync<OrganizationCaseTypeStepForm>(
        `${this.baseController}/OrganizationCaseTypeStepForm/${id}`
      )
    ).data;
  }

  public async AddOrganizationCaseTypeStepForm(
    organizationCaseTypeStepForm: OrganizationCaseTypeStepForm
  ): Promise<OrganizationCaseTypeStepForm> {
    return (
      await API.PostAsync<OrganizationCaseTypeStepForm>(
        `${this.baseController}/OrganizationCaseTypeStepForm`,
        organizationCaseTypeStepForm
      )
    ).data;
  }

  public async UpdateOrganizationCaseTypeStepForm(
    organizationCaseTypeStepForm: OrganizationCaseTypeStepForm
  ): Promise<void> {
    await API.PutAsync<OrganizationCaseTypeStepForm>(
      `${this.baseController}/OrganizationCaseTypeStepForm`,
      organizationCaseTypeStepForm
    );
  }

  public async RemoveOrganizationCaseTypeStepForm(id: number): Promise<void> {
    return (
      await API.DeleteAsync<void>(`${this.baseController}/OrganizationCaseTypeStepForm/${id}`)
    ).data;
  }

  public async GetOrganizationForms(): Promise<OrganizationForm[]> {
    return (
      await API.GetAsync<OrganizationForm[]>(`${this.baseController}/referential/organizationform`)
    ).data;
  }

  public async GetOrganizationForm(orgForm: number): Promise<OrganizationForm> {
    return (
      await API.GetAsync<OrganizationForm>(`${this.baseController}/referential/organizationform`)
    ).data;
  }

  public async UpdateOrganizationForm(organizationForm: OrganizationForm): Promise<void> {
    await API.PutAsync<OrganizationForm>(
      `${this.baseController}/organizationform`,
      organizationForm
    );
  }

  public async GetOrganizationFormActions(orgFormId: number): Promise<OrganizationFormAction[]> {
    return (
      await API.GetAsync<OrganizationFormAction[]>(
        `${this.baseController}/OrganizationCaseTypeStepForm/list/${orgFormId}`
      )
    ).data;
  }

  public async AddOrganizationFormAction(
    organizationFormAction: OrganizationFormAction
  ): Promise<OrganizationFormAction> {
    return (
      await API.PostAsync<OrganizationFormAction>(
        `${this.baseController}/OrganizationCaseTypeStepForm`,
        organizationFormAction
      )
    ).data;
  }

  public async RemoveOrganizationFormAction(id: number): Promise<void> {
    return (
      await API.DeleteAsync<void>(`${this.baseController}/OrganizationCaseTypeStepForm/${id}`)
    ).data;
  }

  public async AddLogo(id: number, file: File): Promise<void> {
    const formData = new FormData();

    formData.append('logo', file);

    await API.PostAsync<FormData>(`${this.baseController}/${id}/Logo`, formData);
  }

  public async GetLogo(id: number): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/${id}/Logo`)).data;
  }

  public async AddOrganizationAttachments(organizationAttachments: OrganizationAttachment[]): Promise<OrganizationAttachment[]> {
    const formData = new FormData();
    formData.append('organizationId', organizationAttachments[0].organizationId.toString());
    organizationAttachments.forEach((item) => {
      formData.append('files', item.file);
    });
    return (
      await API.Post2Async<FormData, OrganizationAttachment[]>(
        `${this.baseController}/attachment`,
        formData
      )
    ).data;
  }

  public async GetOrganizationAttachments(organizationId: number): Promise<OrganizationAttachment[]> {
    return (
      await API.GetAsync<OrganizationAttachment[]>(
        `${this.baseController}/attachments/${organizationId}`
      )
    ).data;
  }

  public async GetOrganizationAdminAttachments(): Promise<OrganizationAttachment[]> {
    return (
      await API.GetAsync<OrganizationAttachment[]>(
        `${this.baseController}/attachments`
      )
    ).data;
  }

  public async GetOrganizationAttachment(fileName: string): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/attachment/${fileName}`)).data;
  }

  public async RemoveOrganizationAttachment(fileName: string): Promise<void> {
    await API.DeleteAsync<void>(`${this.baseController}/attachment/${fileName}`);
  }

  
  public async GetMavoHOOrganizations(): Promise<Organization[]> {
    return (await API.GetAsync<Organization[]>(`${this.baseController}/mavo/HO`)).data;
  }

  public async AddOrganizationJobType(organizationJobTypes: OrganizationJobType[]): Promise<void> {
    await API.PostAsync<OrganizationJobType[]>(
      `${this.baseController}/OrganizationJobType/list`,
      organizationJobTypes
    );
  }

  public async RemoveOrganizationJobType(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationJobType/${id}`))
      .data;
  }

  public async GetOrganizationJobTypes(orgId: number): Promise<OrganizationJobType[]> {
    return (
      await API.GetAsync<OrganizationJobType[]>(
        `${this.baseController}/OrganizationJobType/list/${orgId}`
      )
    ).data;
  }

  public async GetUserOrganizationJobTypes(orgId: number): Promise<OrganizationJobType[]> {
    return (
      await API.GetAsync<OrganizationJobType[]>(
        `${this.baseController}/OrganizationJobType/listUser/${orgId}`
      )
    ).data;
  }

  public async AddOrganizationMaintenancePackage(organizationMaintenancePackages: OrganizationMaintenancePackage[]): Promise<void> {
    await API.PostAsync<OrganizationMaintenancePackage[]>(
      `${this.baseController}/OrganizationMaintenancePackage/list`,
      organizationMaintenancePackages
    );
  }

  public async RemoveOrganizationMaintenancePackage(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationMaintenancePackage/${id}`))
      .data;
  }

  public async GetOrganizationMaintenancePackages(orgId: number): Promise<OrganizationMaintenancePackage[]> {
    return (
      await API.GetAsync<OrganizationMaintenancePackage[]>(
        `${this.baseController}/OrganizationMaintenancePackage/list/${orgId}`
      )
    ).data;
  }

  public async GetUserOrganizationMaintenancePackages(orgId: number): Promise<OrganizationMaintenancePackage[]> {
    return (
      await API.GetAsync<OrganizationMaintenancePackage[]>(
        `${this.baseController}/OrganizationMaintenancePackage/listUser/${orgId}`
      )
    ).data;
  }

  public async AddOrganizationOilViscosity(organizationOilViscosity: OrganizationOilViscosity): Promise<void> {
    await API.PostAsync<OrganizationOilViscosity>(
      `${this.baseController}/OrganizationOilViscosity`,
      organizationOilViscosity
    );
  }

  public async UpdateOrganizationOilViscosity(
    organizationOilViscosity: OrganizationOilViscosity
  ): Promise<void> {
    await API.PutAsync<OrganizationOilViscosity>(
      `${this.baseController}/OrganizationOilViscosity`,
      organizationOilViscosity
    );
  }

  public async RemoveOrganizationOilViscosity(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationOilViscosity/${id}`))
      .data;
  }

  public async GetOrganizationOilViscosities(orgId: number): Promise<OrganizationOilViscosity[]> {
    return (
      await API.GetAsync<OrganizationOilViscosity[]>(
        `${this.baseController}/OrganizationOilViscosity/list/${orgId}`
      )
    ).data;
  }

  public async CupiCodeCorespondenceList(): Promise<CupiCodeCorespondence[]> {
    return (
      await API.GetAsync<CupiCodeCorespondence[]>(
        `${this.baseController}/GtCupiCodeCorespondence/listUser`
      )
    ).data;
  }

  public async AddCupiCodeCorespondence(cupiCodeCorespondence: CupiCodeCorespondence): Promise<void> {
    await API.PostAsync<CupiCodeCorespondence>(
      `${this.baseController}/GtCupiCodeCorespondence`,
      cupiCodeCorespondence
    );
  }

  public async UpdateCupiCodeCorespondence(
    cupiCodeCorespondence: CupiCodeCorespondence
  ): Promise<void> {
    await API.PutAsync<CupiCodeCorespondence>(
      `${this.baseController}/GtCupiCodeCorespondence`,
      cupiCodeCorespondence
    );
  }

  public async RemoveCupiCodeCorespondence(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/GtCupiCodeCorespondence/${id}`))
      .data;
  }

  public async AddOrganizationInvoiceRange(organizationInvoiceRange: OrganizationInvoiceRange): Promise<void> {
    await API.PostAsync<OrganizationInvoiceRange>(
      `${this.baseController}/OrganizationInvoiceRange`,
      organizationInvoiceRange
    );
  }

  public async UpdateOrganizationInvoiceRange(organizationInvoiceRange: OrganizationInvoiceRange): Promise<void> {
    await API.PutAsync<OrganizationInvoiceRange>(
      `${this.baseController}/OrganizationInvoiceRange`,
      organizationInvoiceRange
    );
  }

  public async RemoveOrganizationInvoiceRange(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/OrganizationInvoiceRange/${id}`))
      .data;
  }

  public async GetOrganizationInvoiceRanges(orgId: number): Promise<OrganizationInvoiceRange[]> {
    return (
      await API.GetAsync<OrganizationInvoiceRange[]>(
        `${this.baseController}/OrganizationInvoiceRange/list/${orgId}`
      )
    ).data;
  }

  public async GetSupplierStockOrganization(types: string): Promise<Organization> {
    return (await API.GetAsync<Organization>(`${this.baseController}/supplierStock/${types}`)).data;
  }

  public async GetOrganizationOilMapping(request: OrganizationOilMappingRequest): Promise<OrganizationOilViscosity> {
    return (
      await API.Post2Async<OrganizationOilMappingRequest, OrganizationOilViscosity>(
        `${this.baseController}/oil-mapping`,
        request
      )
    ).data;
  }

}
