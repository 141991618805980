import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Box
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import { RouteComponentProps } from 'react-router';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import NumberFormat from 'react-number-format';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import {  AlcorErrorLog } from '../../../interfaces/Vehicle';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';

interface IAlcorErrorLogListState {
  alcorErrors: AlcorErrorLog[];
  isLoading: boolean;
}

type IAlcorErrorLogListProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class AlcorErrorLogList extends React.PureComponent<
  IAlcorErrorLogListProps,
  IAlcorErrorLogListState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;


  static contextType = ApplicationContext;

  state = {
    alcorErrors: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
  } as IAlcorErrorLogListState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadAlcorErrorLogs();
      }
    );
  }

  loadAlcorErrorLogs = async () => {
    const alcorErrors = await this.vehicleService.GetAlcorErrorLogs();

    this.setState({
      alcorErrors: alcorErrors,
      isLoading: false
    });
  };

  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };


  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'invoiceRangeType',
        label: this.translatorService.Tranlate(
          'INVOICE_RANGE_TYPE',
          'Tip'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.name!.localeCompare(b.name!);
          },
          customBodyRender: (value: IReferential) => {
            return value.name;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'number',
        label: this.translatorService.Tranlate('ALCOR_ERROR_NUMBER', 'Numar'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'message',
        label: this.translatorService.Tranlate('ALCOR_ERROR_CAUSE', 'Cauza'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };



  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false
    };

    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.alcorErrors}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(AlcorErrorLogList as any));
