import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationFilterType } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, IconButton, Card, CardContent, Tabs } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { Tab } from '@material-ui/core';

interface IOrganizationFiltersState {
  organizationFilters: OrganizationFilterType[];
  isLoading: boolean;
  organizationFilterTypes: IReferential[];
  selectedOrganizationFilterType: IReferential | null;
  organizationId: number;
}

type IOrganizationFiltersProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationFilters extends React.PureComponent<
  IOrganizationFiltersProps,
  IOrganizationFiltersState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationFilters: [],
    isLoading: false,
    organizationFilterTypes: [],
    selectedOrganizationFilterType: null,
    organizationId: 0
  } as IOrganizationFiltersState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationFilters();
      }
    );
  }

  loadOrganizationFilters = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.FilterType);

    const [orgFilterList, filterTypes] = await Promise.all([
      this.organizationService.GetOrganizationFilters(hoId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    let organizationFilters = orgFilterList;
    organizationFilters = organizationFilters.map((item: OrganizationFilterType) => {
      item.filterType = filterTypes.find(
        (filterType: IReferential) => filterType.id === item.filterTypeId
      )!;
      return item;
    });

    this.setState({
      organizationFilters: organizationFilters,
      organizationFilterTypes: filterTypes.filter((item) => item.isActive === true),
      isLoading: false,
      organizationId: hoId
    });
  };

  deleteOrgFilter = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationFilter(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadOrganizationFilters();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'filterType',
        label: this.translatorService.Tranlate('ORGANIZATION_FILTER_TYPE_LABEL', 'Tip dosar'),
        options: {
          sort: false,
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgFilter(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgFilter = async () => {
    try {
      await this.organizationService.AddOrganizationFilter({
        filterTypeId: this.state.selectedOrganizationFilterType!.id,
        organizationId: this.state.organizationId
      } as OrganizationFilterType);

      this.setState(
        {
          isLoading: true,
          selectedOrganizationFilterType: null
        },
        () => {
          this.loadOrganizationFilters();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteChange = (newValue: IReferential | null) => {
    this.setState({ selectedOrganizationFilterType: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgFilter}>
              <Autocomplete
                id="filterTypes"
                className="m-2"
                options={this.state.organizationFilterTypes}
                value={this.state.selectedOrganizationFilterType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleAutocompleteChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationFilterTypes"
                    value={this.state.selectedOrganizationFilterType}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATION_FILTER_TYPE_LABEL',
                      'Filtru'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('ORGANIZATIONS_TAB_FILTERS', 'Filtre')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationFilters}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationFilters as any));
