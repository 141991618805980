import * as React from 'react';

import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppActionCreators, ApplicationState, AppState } from '../../store';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { ScaleLoader } from 'react-spinners';
import { Button, Grid, IconButton, List, ListItem } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { withRouter } from 'react-router';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

export interface ExternalAppointmentNewInNotificationProps {
  onClick?: () => void;
}

type AppointmentNewInNotificationProps = { appState: AppState } & typeof AppActionCreators &
  RouteComponentProps &
  ExternalAppointmentNewInNotificationProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAppointmentNewInNotificationState {}

class AppointmentNewInNotification extends React.PureComponent<
  AppointmentNewInNotificationProps,
  IAppointmentNewInNotificationState
> {
  private caseService!: ICaseService;

  static contextType = ApplicationContext;

  state = {} as IAppointmentNewInNotificationState;

  onClick = async (id: number, appointmentStatusHistoryId: number, moveToViewed: boolean, notNew: boolean) => {
    try {
      await this.caseService.SetAppointmentNotNew(id, appointmentStatusHistoryId, moveToViewed, notNew);

      this.props.MarkAsViewedNewInAppointment(id);

      const currentPath = this.props.history.location.pathname.toLowerCase();
      this.props.history.push('/appointment/' + id);

      if (currentPath.indexOf('/appointment/') !== -1) {
        this.props.history.go(0);
      }

      if (this.props.onClick !== undefined) {
        this.props.onClick();
      }
    } catch (err) {
      console.log(err);
    }
  };

  IsAppointmentNotNew = async (id: number, appointmentStatusHistoryId: number, moveToViewed: boolean, notNew: boolean) => {
    try {
      await this.caseService.SetAppointmentNotNew(id, appointmentStatusHistoryId, moveToViewed, notNew);

      if (notNew) {
        this.props.RemoveNewInAppointment(id);
      }

    } catch (err) {
      console.log(err);
    }
  };

  public render() {
    this.caseService = (this.context as AppContext).caseService;

    return (
      <React.Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.appState.isNewInAppointmentsLoading} />
        </div>
        {!this.props.appState.isNewInAppointmentsLoading && this.props.appState.newInAppointments.length === 0 ? (
          <div className="text-center">
            <div className="avatar-icon-wrapper  m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper  m-0 d-140">
                <FontAwesomeIcon
                  icon={['far', 'calendar']}
                  className="d-flex align-self-center display-1 "
                />              
              </div>
            </div>
          </div>
        ) : null}

        {!this.props.appState.isNewInAppointmentsLoading && this.props.appState.newInAppointments.length !== 0 ? (
          <List className="p-0">
            {this.props.appState.newInAppointments.map((item, index) => (
              <ListItem divider key={index} style={{ backgroundColor: !item.appointmentStatusHistory.isViewed ? '#f7f7fc' : 'inherit' }}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <div
                      className={`p-0 ${!item.appointmentStatusHistory.isViewed ? 'font-weight-bold' : ''}`}
                    >
                      {index + 1}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className={`text-primary p-0 ${!item.appointmentStatusHistory.isViewed ? 'font-weight-bold' : ''}`}
                      onClick={(e) => this.onClick(item.id, item.appointmentStatusHistory.id, true, false)}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.id}
                    </div>
                    <div className={`${!item.appointmentStatusHistory.isViewed ? 'font-weight-bold' : ''}`}>
                      {item.appointmentStatusHistory.appointmentStatus!.displayName}
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <div className={`${!item.appointmentStatusHistory.isViewed ? 'font-weight-bold' : ''}`}>
                      {item.appointmentCategoryType!.displayName}
                    </div>
                    <div className="font-size-sm text-black-50">
                      {isNullOrUndefined(item.vehicle) ? '' : item.vehicle.plateNumber}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="font-size-sm text-black-50">
                      {moment
                        .utc(item.appointmentStatusHistory.date)
                        .local()
                        .toDate()
                        .toLocaleString(this.props.appState.language)}
                    </div>
                  </Grid>
                  <Grid item container direction="column" xs={1}>
                    <IconButton
                      aria-label="delete"
                      color="inherit"
                      className="text-danger"
                      size="small"
                      onClick={(e) => {
                        this.IsAppointmentNotNew(item.id, item.appointmentStatusHistory.id, false, true);
                      }}
                    >
                      <DeleteOutlineTwoToneIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : null}
      </React.Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalAppointmentNewInNotificationProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  AppActionCreators,
  mergeProps
)(withRouter(AppointmentNewInNotification as any));
