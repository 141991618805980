import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { withSnackbar, ProviderContext } from 'notistack';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import { CaseCalculationAlternativePart, CaseCalculationHistory, CaseCalculationPart, TariffPercentResponse } from '../../interfaces/Case';
import { PartToBeOrdered } from '../../interfaces/Order';
import Referentials from '../../helpers/Referentials.json';
import { AlternativeCode, CalculationSectionCodeEnum, ReferentialCode } from '../../helpers/Constants';
import { IReferential } from '../../interfaces/IReferential';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import MaterialTable, { Column, Icons, EditComponentProps } from 'material-table';
import { forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Organization } from '../../interfaces/Organization';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import * as R from 'ramda';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { Utils } from '../../helpers/Utils';

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />)
} as Icons;

export interface ExternalUpdateOrderPartProps {
  partDetails: PartToBeOrdered;
  currency: string;
  workflowId: any;
  workflowSuppliersList: any[];
  additionTariff: TariffPercentResponse;
  discountTariff: TariffPercentResponse;
  onSave: () => void;
}

type UpdateOrderPartProps = ExternalUpdateOrderPartProps & {
  appState: AppState;
  caseSettings: CaseSettingsState;
} & ProviderContext &
  RouteComponentProps;

interface IUpdateOrderPartState {
  isLoading: boolean;
  orderpartsList: PartToBeOrdered[];
  qualityParts: IReferential[];
  selectedQualityPart: IReferential[];
  workflowSuppliers: Organization[];
  alternativeParts: CaseCalculationAlternativePart[];
  clearStateAlternativeParts: boolean;
  partLoader: boolean;
  calculationParts: CaseCalculationPart[];
  sections: IReferential[];
}

class UpdateOrderPart extends React.PureComponent<UpdateOrderPartProps, IUpdateOrderPartState> {
  private translatorService!: ITranslatorService;
  private orderService!: IOrderService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;
  private caseService!: ICaseService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    orderpartsList: [],
    qualityParts: [],
    selectedQualityPart: [],
    workflowSuppliers: [],
    alternativeParts: [],
    clearStateAlternativeParts: false,
    partLoader: false,
    calculationParts: [],
    sections: []
  } as IUpdateOrderPartState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: false
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const refQualityPart = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarPartCategory
    );

    const [qualityParts, calculationParts, sections] = await Promise.all([
      this.appReferentialService.Get(refQualityPart!.baseUrl),
      this.props.partDetails!.caseCalculationHistoryId ? this.caseService.GetCaseCalculationParts(this.props.partDetails!.caseCalculationHistoryId)  : ([] as CaseCalculationPart[]),
      this.caseService.GetCalculationSections()
    ]);

    const workflowSuppliersList = this.props.workflowSuppliersList;
    const suppliersInit =
      workflowSuppliersList.length > 0
        ? await this.organizationService.GetUserOrganizationsByIds(
            Array.from(new Set(workflowSuppliersList.map((item) => item.supplierId)))
          )
        : [];
    const suppliers = suppliersInit.filter((item) => item.isActive === true);
    console.log('calculationParts', calculationParts);
    console.log('groupedUniqueId', this.props.partDetails!.groupedUniqueId);
    const calculationPartsCopy = calculationParts.filter((item) => (item.groupedUniqueId !== null && item.groupedUniqueId !== this.props.partDetails!.groupedUniqueId) || (item.groupedUniqueId === null && item.id !== this.props.partDetails!.caseCalculationPartId));
    const selectedCalculationPart = calculationParts.find((item) => item.groupedUniqueId === this.props.partDetails!.groupedUniqueId && item.id === this.props.partDetails!.caseCalculationPartId);
    console.log('calculationPartsCopy', calculationPartsCopy);
    console.log('selectedCalculationPart', selectedCalculationPart);
    console.log('calculationPartsResult', calculationPartsCopy.concat([selectedCalculationPart!]));
    this.setState({
      orderpartsList: [this.props.partDetails!],
      qualityParts: qualityParts,
      workflowSuppliers: suppliers,
      isLoading: false,
      calculationParts: calculationPartsCopy.concat([selectedCalculationPart!]),
      sections: sections
    });
  };

  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        columns={this.getColumns()}
        data={this.state.orderpartsList}
        options={{
          draggable: false,
          showTitle: false,
          paging: false,
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          sorting: false,
          grouping: false,
          toolbar: false
        }}
        localization={this.getLocalization()}
        editable={{
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData)
        }}
      />
    );
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza')
        }
      }
    };
  };

  onRowUpdate = (newData: PartToBeOrdered, oldData?: PartToBeOrdered): Promise<void> =>
    new Promise(async (resolve, reject) => {
      const quality = this.state.qualityParts.find((item) => item.id === newData.carPartCategoryId);
      const supplier = this.state.workflowSuppliers.find((item) => item.id === newData.supplierId);
      newData.carPartCategory = !R.isNil(quality) ? quality : null;
      newData.supplier = !R.isNil(supplier) ? supplier : null;

      if (!this.validateRef(newData)) {
        reject();
        return;
      }

      if (oldData) {
        try {
          const item = { ...newData };
          item.alternativePartQuality = null;
          item.carPartCategory = null;
          item.supplier = null;
          this.setState((prevState: IUpdateOrderPartState) => {
            const orderpartsList = [...this.state.orderpartsList];
            orderpartsList[orderpartsList.indexOf(oldData)] = newData;

            return { ...prevState, orderpartsList: orderpartsList };
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  validateRef = (orderPart: PartToBeOrdered): boolean => {
    if (
      orderPart.guideNumber === '' ||
      orderPart.guideNumber === undefined ||
      orderPart.guideNumber === null
    ) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }

    if (orderPart.name === '' || orderPart.name === undefined || orderPart.name === null) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ORDER_PART_ERROR_NAME_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }

    const price =
      orderPart.alternativePartQualityId === null
        ? orderPart.originalPrice
        : orderPart.alternativePrice;
    if ((price !== null && price.toString() === '') || price === undefined || price === null) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ORDER_PART_ERROR_ORIG_PRICE_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    if (price < 0) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ORDER_PART_ERROR_ORIG_PRICE_INVALID', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }

    if (
      orderPart.pieceNr.toString() === '' ||
      orderPart.pieceNr === undefined ||
      orderPart.pieceNr === null
    ) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ORDER_PART_ERROR_UNITS_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    const discount =
      orderPart.alternativePartQualityId === null
        ? orderPart.discount
        : orderPart.alternativePartDiscount;
    if ((discount !== null && discount < 0) || (discount !== null && discount > 100)) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ORDER_PART_ERROR_DISCOUNT_INVALID', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }

    return true;
  };

  handlePriceChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    if (row.alternativePartQualityId === null) {
      row.originalPrice = event.target.value;
    } else {
      row.alternativePrice = event.target.value;
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handlePieceNrChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    row.pieceNr = event.target.value === null ? 0 : parseFloat(event.target.value);

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleDiscountChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    if (row.alternativePartQualityId === null) {
      row.discount = event.target.value;
    } else {
      row.alternativePartDiscount = event.target.value;
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleProducerChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    row.producer = event.target.value;

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleDeliveryTimeChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    row.deliveryTime = event.target.value;

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleStockChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    if (row.alternativePartQualityId === null) {
      row.availableQuantity = event.target.value;
    } else {
      row.alternativePartStockQuantity = event.target.value;
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleLocationChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    if (row.alternativePartQualityId === null) {
      row.location = event.target.value;
    } else {
      row.alternativePartStockLocation = event.target.value;
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleRemarksChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    if (row.alternativePartQualityId === null) {
      row.remarks = event.target.value;
    } else {
      row.alternativePartRemarks = event.target.value;
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  handleQualityChange = (event: any, gridProps: any) => {
    const row = gridProps.rowData;
    const quality = this.state.qualityParts.find((item) => item.id === event.target.value);
    if (row.alternativePartQualityId === null) {
      row.carPartCategoryId = !R.isNil(quality) ? quality.id : null;
      row.carPartCategory = !R.isNil(quality) ? quality : null;
    } else {
      row.alternativePartQualityId = !R.isNil(quality) ? quality.id : null;
      row.alternativePartQuality = !R.isNil(quality) ? quality : null;
    }

    gridProps.onChange(event.target.value);

    gridProps.onRowDataChange({
      ...gridProps.rowData
    });
  };

  displayOrderPartOtherDetails = (supplier: any, id: any) => {
    const orderPart = this.state.orderpartsList.find((item) => item.id === id);
    return (
      <Tooltip
        title={
          <div>
            <div>
              {this.translatorService.Tranlate('ORDER_PART_DETAILS_PRODUCER_LABEL', 'Producator') +
                ': ' +
                (R.isNil(orderPart!.producer) ? '' : orderPart!.producer)}
            </div>
            <div>
              {this.translatorService.Tranlate(
                'ORDER_PART_DETAILS_DELIVERY_TIME_LABEL',
                'Livrare'
              ) +
                ': ' +
                (R.isNil(orderPart!.deliveryTime) ? '' : orderPart!.deliveryTime)}
            </div>
            <div>
              {this.translatorService.Tranlate('ORDER_PART_DETAILS_STOCK_LABEL', 'Disponibil') +
                ': ' +
                (orderPart!.alternativePartQualityId === null
                  ? orderPart!.availableStock
                  : orderPart!.alternativePartStockQuantity === null
                  ? ''
                  : orderPart!.alternativePartStockQuantity)}
            </div>
            <div>
              {this.translatorService.Tranlate('ORDER_PART_DETAILS_LOCATION_LABEL', 'Locatie') +
                ': ' +
                (orderPart!.alternativePartQualityId === null
                  ? orderPart!.location
                  : orderPart!.alternativePartStockLocation)}
            </div>
          </div>
        }
      >
        <div>
          <span>{(R.isNil(supplier) ? 'N/A' : supplier.name) + ' '}</span>
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
        </div>
      </Tooltip>
    );
  };

  renderOrderPartDetailsEditable = (props: EditComponentProps<any>) => {
    return (
      <div>
        <FormControl>
          <InputLabel id="demo-customized-select-label">
            {this.translatorService.Tranlate('ORDER_PART_DETAILS_SUPPLIER_LABEL', 'Furnizor')}
          </InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            fullWidth={true}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          >
            {this.state.workflowSuppliers.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={this.translatorService.Tranlate('ORDER_PART_DETAILS_PRODUCER_LABEL', 'Producator')}
          name="producer"
          value={props.rowData.producer}
          fullWidth
          onChange={(e) => this.handleProducerChange(e, props)}
        />
        <TextField
          label={this.translatorService.Tranlate(
            'ORDER_PART_DETAILS_DELIVERY_TIME_LABEL',
            'Livrare'
          )}
          name="deliveryTime"
          value={props.rowData.deliveryTime}
          fullWidth
          onChange={(e) => this.handleDeliveryTimeChange(e, props)}
        />
        <TextField
          label={this.translatorService.Tranlate('ORDER_PART_DETAILS_STOCK_LABEL', 'Disponibil')}
          name="availableStock"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={
            props.rowData.alternativePartQualityId === null
              ? props.rowData.availableQuantity
              : props.rowData.alternativePartStockQuantity !== null
              ? props.rowData.alternativePartStockQuantity
              : ''
          }
          fullWidth
          onChange={(e) => this.handleStockChange(e, props)}
        />
        <TextField
          label={this.translatorService.Tranlate('ORDER_PART_DETAILS_LOCATION_LABEL', 'Locatie')}
          name="location"
          value={
            props.rowData.alternativePartQualityId === null
              ? props.rowData.location
              : props.rowData.alternativePartStockLocation !== null
              ? props.rowData.alternativePartStockLocation
              : ''
          }
          fullWidth
          onChange={(e) => this.handleLocationChange(e, props)}
        />
      </div>
    );
  };


  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator={false}
        decimalScale={2}
        isNumericString
      />
    );
  };
  
  getColumns = () => {
    return [
      {
        field: 'id',
        hidden: true,
        title: ''
      } as Column<any>,
      {
        field: 'guideNumber',
        title: this.translatorService.Tranlate('ORDER_PART_DETAILS_CODE_LABEL', 'Cod'),
        render: (rowData: any) => {
          return (
            <TextField
              name="cod"
              type="text"
              InputProps={{ inputProps: { min: 0 } }}
              value={rowData!.guideNumber}
              fullWidth
              disabled={true}
            />
          );
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <TextField
              name="cod"
              type="text"
              InputProps={{ inputProps: { min: 0 } }}
              value={props.rowData!.guideNumber}
              fullWidth
              disabled={true}
            />
          );
        }
      } as Column<any>,
      {
        field: 'name',
        title: this.translatorService.Tranlate('ORDER_PART_DETAILS_NAME_LABEL', 'Denumire')
      } as Column<any>,
      {
        field: 'carPartCategoryId',
        title: this.translatorService.Tranlate('ORDER_PART_DETAILS_QUALITY_LABEL', 'Calitate'),
        render: (rowData: any) => {
          if (rowData.alternativePartQualityId === null) {
            return rowData.carPartCategory.displayName;
          } else {
            return rowData.alternativePartQuality.displayName;
          }
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              value={
                props.rowData.alternativePartQualityId === null
                  ? props.value
                  : props.rowData.alternativePartQualityId
              }
              onChange={(e) => this.handleQualityChange(e, props)}
            >
              {this.state.qualityParts.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'originalPrice',
        title: this.translatorService.Tranlate(
          'ORDER_PART_DETAILS_ORIGINAL_PRICE_LABEL',
          'Pret unitar'
        ),
        render: (rowData: any) => {
          if (rowData.alternativePartQualityId === null) {
            return (
              Utils.countryNumberFormat((Math.round(rowData.originalPrice * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) + ' ' + this.props.currency
            );
          } else {
            return (
              Utils.countryNumberFormat((Math.round(rowData.alternativePrice * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
              ' ' +
              this.props.currency
            );
          }
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <TextField
              name="price"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={
                props.rowData.alternativePartQualityId === null
                  ? props.rowData.originalPrice
                  : props.rowData.alternativePrice
              }
              fullWidth
              onChange={(e) => this.handlePriceChange(e, props)}
            />
          );
        }
      } as Column<any>,
      {
        field: 'pieceNr',
        title: this.translatorService.Tranlate('ORDER_PART_DETAILS_PIECE_NR_LABEL', 'Unitati'),
        render: (rowData: any) => {
          return rowData.pieceNr;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <TextField
            name="pieceNr"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={props.rowData.pieceNr}
            fullWidth
            onChange={(e) => this.handlePieceNrChange(e, props)}
          />
          );
        }
      } as Column<any>,
      {
        field: 'discount',
        title: this.translatorService.Tranlate('ORDER_PART_DETAILS_DISCOUNT_LABEL', 'Discount (%)'),
        render: (rowData: any) => {
          if (rowData.alternativePartQualityId === null) {
            return rowData.discount;
          } else {
            return rowData.alternativePartDiscount;
          }
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <TextField
              name="discount"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              value={
                props.rowData.alternativePartQualityId === null
                  ? props.rowData.discount
                  : props.rowData.alternativePartDiscount
              }
              fullWidth
              onChange={(e) => this.handleDiscountChange(e, props)}
            />
          );
        }
      } as Column<any>,
      {
        field: '',
        title: this.translatorService.Tranlate(
          'ORDER_PART_DETAILS_TOTAL_PRICE_LABEL',
          'Pret total'
        ),
        render: (rowData: any) => {
          if (rowData.alternativePartQualityId === null) {
            if (R.isNil(rowData.discount)) {
              return (
                Utils.countryNumberFormat((Math.round(rowData.pieceNr * rowData.originalPrice * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                ' ' +
                this.props.currency
              );
            }
            return (
              Utils.countryNumberFormat((
                Math.round(
                  (rowData.pieceNr * rowData.originalPrice -
                    (rowData.pieceNr * rowData.originalPrice * rowData.discount) / 100) *
                    100
                ) / 100
              ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
              ' ' +
              this.props.currency
            );
          } else {
            if (R.isNil(rowData.alternativePartDiscount)) {
              return (
                Utils.countryNumberFormat((Math.round(rowData.pieceNr * rowData.alternativePrice * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                ' ' +
                this.props.currency
              );
            }
            return (
              Utils.countryNumberFormat((
                Math.round(
                  (rowData.pieceNr * rowData.alternativePrice -
                    (rowData.pieceNr * rowData.alternativePrice * rowData.alternativePartDiscount) /
                      100) *
                    100
                ) / 100
              ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
              ' ' +
              this.props.currency
            );
          }
        }
      } as Column<any>,
      {
        field: 'supplierId',
        title: this.translatorService.Tranlate('ORDER_PART_OTHER_DETAILS_LABEL', 'Detalii'),
        render: (rowData: any) => {
          return (
            <div>
              <InputLabel>
                {this.translatorService.Tranlate('ORDER_PART_DETAILS_SUPPLIER_LABEL', 'Furnizor')}
              </InputLabel>
              {this.displayOrderPartOtherDetails(rowData.supplier, rowData.id)}
            </div>
          );
        },
        editComponent: (props: EditComponentProps<any>) => {
          return this.renderOrderPartDetailsEditable(props);
        }
      } as Column<any>,
      {
        field: 'remarks',
        title: this.translatorService.Tranlate('ORDER_PART_DETAILS_REMARKS_LABEL', 'Observatii'),
        render: (rowData: any) => {
          if (rowData.alternativePartQualityId === null) {
            return rowData.remarks;
          } else {
            return rowData.alternativePartRemarks;
          }
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <TextField
              name="remarks"
              value={
                props.rowData.alternativePartQualityId === null
                  ? props.rowData.remarks
                  : props.rowData.alternativePartRemarks !== null
                  ? props.rowData.alternativePartRemarks
                  : ''
              }
              fullWidth
              onChange={(e) => this.handleRemarksChange(e, props)}
            />
          );
        }
      } as Column<any>
    ];
  };

  getCalculationParts = (alternativePart: CaseCalculationAlternativePart,  calculationPartIndex: number, part: PartToBeOrdered): CaseCalculationPart[] => {
    const calculationParts = this.state.calculationParts;
    if (calculationPartIndex !== -1) {
      calculationParts[calculationPartIndex] = {
        ...calculationParts[calculationPartIndex],
        alternativePrice: alternativePart.alternativePrice,
        alternativePartDiscount: alternativePart.alternativePartDiscount,
        alternativeGuideNumber: alternativePart.alternativeGuideNumber,
        alternativePartCode: alternativePart.alternativePartCode,
        alternativePartQuality: alternativePart.alternativePartQuality,
        alternativePartQualityId: alternativePart.alternativePartQualityId,
        alternativePartName: alternativePart.name,
        alternativePartStockLocation: alternativePart.stockLocation,
        alternativePartStockQuantity: alternativePart.availableQuantity,
        supplier: alternativePart.supplier,
        supplierId: alternativePart.supplierId,
        producer: alternativePart.producer,
        deliveryTime: alternativePart.deliveryTime.toString(),
        validityTime: alternativePart.validityTime?.toString() || null,
        codeType: alternativePart.codeType?.toString() || null,
        type: alternativePart.type?.toString() || null,
        orderCode: alternativePart.orderCode?.toString() || null,
        locationId: alternativePart.locationId || null,
        alternativePartRemarks: alternativePart.remarks,
        minQty: alternativePart.minQty,
        packing: alternativePart.packing,
        bundleItem: alternativePart.bundleItem,
        bundleNo: alternativePart.bundleNo,
        bundleQuantity: alternativePart.bundleQuantity,
        excludeFromOrder: alternativePart.excludeFromOrder,
        isChild: alternativePart.isChild,
        productId: alternativePart.productId || null,
        codEan: alternativePart.codEan?.toString() || null,
        id: part.caseCalculationPartId,
        alternativePriceWithoutDiscount: alternativePart.alternativePriceWithoutDiscount
      }
      if (part.package && part.package.length) {
        for (let j = 0; j < part.package.length; j++) {
          calculationParts.push({
            guideNumber: part.package[j].guideNumber,
            name: part.package[j].name,
            pieceNr: part.package[j].pieceNr,
            alternativePrice: part.package[j].alternativePrice,
            alternativePartDiscount: part.package[j].alternativePartDiscount,
            alternativeGuideNumber: part.package[j].alternativeGuideNumber,
            alternativePartCode: part.package[j].alternativePartCode,
            alternativePartQuality: part.package[j].alternativePartQuality,
            alternativePartQualityId: part.package[j].alternativePartQualityId,
            alternativePartName: part.package[j].name,
            alternativePartStockLocation: part.package[j].stockLocation,
            alternativePartStockQuantity: part.package[j].availableQuantity,
            supplier: part.package[j].supplier,
            supplierId: part.package[j].supplierId,
            producer: part.package[j].producer,
            deliveryTime: part.package[j].deliveryTime.toString(),
            validityTime: part.package[j].validityTime?.toString() || null,
            codeType: part.package[j].codeType?.toString() || null,
            type: part.package[j].type?.toString() || null,
            orderCode: part.package[j].orderCode?.toString() || null,
            locationId: part.package[j].locationId || null,
            alternativePartRemarks: part.package[j].remarks,
            minQty: part.package[j].minQty,
            packing: part.package[j].packing,
            bundleItem: part.package[j].bundleItem,
            bundleNo: part.package[j].bundleNo,
            bundleQuantity: part.package[j].bundleQuantity,
            excludeFromOrder: part.package[j].excludeFromOrder,
            isChild: part.package[j].isChild,
            productId: part.package[j].productId || null,
            codEan: part.package[j].codEan?.toString() || null,
            id: 0,
            caseCalculationHistoryId: part.caseCalculationHistory.id,
            calculationSectionId: this.state.sections.find((item) => item.code === CalculationSectionCodeEnum.PART)!.id,
            groupedUniqueId: part.groupedUniqueId,
            alternativeNetPrice: part.package[j].alternativeNetPrice,
            alternativePriceWithoutDiscount: part.package[j].alternativePriceWithoutDiscount
          } as CaseCalculationPart);
        }
      }

      calculationParts.forEach((item) => {
        item.calculationSection = null;
        item.carPartCategory = null;
        item.alternativePartQuality = null;
        item.supplier = null;
      });
    }
    return calculationParts;
  }

  save = async () => {
    try {
      const part = this.state.orderpartsList[0];
      const alternativePart = this.state.alternativeParts.find(
        (item) => item.selectedAlternativePart !== 9999999999999
      );
      console.log('Part', part);
      console.log('AlternativePart', alternativePart);
      if (this.state.alternativeParts.length > 0 && !R.isNil(alternativePart)) {
        if (!R.isNil(alternativePart)) {
          if (
            alternativePart!.availableQuantity !== null &&
            alternativePart!.availableQuantity < part.pieceNr
          ) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'ORDER_MAX_QUANTITY_ALERT_MSG',
                'Cantitatea disponibila este insuficienta!'
              ),
              { variant: 'error' }
            );
            return false;
          }
        }

        const currentPacking = alternativePart.packing?.split('|')[0];
        const secondItemOfPacking = alternativePart.packing?.split('|')[1];
        if (currentPacking && secondItemOfPacking) {
          const currentPackingNumber = parseFloat(currentPacking);
          if (
            part.pieceNr % currentPackingNumber != 0 ||
            part.pieceNr < alternativePart.minQty
          ) {
            this.props.enqueueSnackbar( this.translatorService.Tranlate(
              'MIN_PACKING_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie '
            ) +
              alternativePart.minQty +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!') + ' \n', {
                variant: 'error',
                style: { whiteSpace: 'pre-line' }
            });
            return;
          }
        } else {
          if (part.pieceNr < alternativePart.minQty) {
            this.props.enqueueSnackbar( this.translatorService.Tranlate(
              'MIN_PACKING_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie '
            ) +
              alternativePart.minQty +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!') + ' \n', {
                variant: 'error',
                style: { whiteSpace: 'pre-line' }
            });
            return;
          }
        }

        if (!Number.isInteger(part.pieceNr)) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'DECIMAL_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie un numar intreg fara zecimale'
            ),
            {
              variant: 'error'
            }
          );
          return;
        }

        if (!R.isNil(alternativePart.availableQuantity) && alternativePart.availableQuantity < part.pieceNr) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ORDER_MAX_QUANTITY_ALERT_MSG',
              'Cantitatea disponibila este insuficienta!'
            ),
            { variant: 'error' }
          );
          return;
        }
      
        part.alternativePartQuality = null;
        part.carPartCategory = null;
        part.supplier = null;

        let calculationParts = this.state.calculationParts;
        const calculationPartIndex = calculationParts.findIndex((x) => x.id === part.caseCalculationPartId);
        if (calculationPartIndex !== -1) {
          calculationParts = this.getCalculationParts(alternativePart, calculationPartIndex, part);
          await this.caseService.UpdateCalculationPartsAndCasePartToBeOrdered(
            calculationParts,
            "",
            "",
            part.caseCalculationHistory.id,
            part.caseCalculationHistory.partsLocationOrganizationId,
            this.props.appState.appUser!.organizationId,
            this.props.appState.appUser!.organizationId,
            this.props.appState.appUser!.organization!.name
          );
          part.caseCalculationHistory = null as unknown as CaseCalculationHistory;
          
          await this.orderService.UpdatePartToBeOrdered(part);

          this.props.onSave();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } else {
          console.log("Nu s-a gasit piesa alternativa!");
        }
       
      }
    } catch (err) {
      console.log(err);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderOrderPartsSection = () => {
    return (
      <Card className="mt-2">
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? this.renderTable() : null}
      </Card>
    );
  };

  interogateAlternativeParts = async (partDetails: PartToBeOrdered) => {
    try {
      this.setState({
        partLoader: true
      });
      const alternativeParts = await this.caseService.InterogateCaseCalculationsAlternativeParts(
        partDetails.id,
        partDetails.guideNumber,
        this.props.additionTariff.value,
        this.props.discountTariff.value,
        this.props.caseSettings.caseSettings!.workflow.id, 
        partDetails.caseCalculationHistory.partsLocationOrganizationId      
      );

      this.setState({
        alternativeParts: alternativeParts,
        partLoader: false
      });

    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  handleAlternativePartSelect = (
    id: number,
    originalPartid: number,
    alternativePart: CaseCalculationAlternativePart
  ) => {
    const caseCalculationOriginalPartsCopy = [...this.state.orderpartsList];
    const originalPartIndex = caseCalculationOriginalPartsCopy.findIndex(
      (item) => item.id === originalPartid
    );
    caseCalculationOriginalPartsCopy[originalPartIndex] = {
      ...caseCalculationOriginalPartsCopy[originalPartIndex],
      alternativePrice: alternativePart.alternativePrice,
      alternativePartDiscount: alternativePart.alternativePartDiscount,
      alternativeGuideNumber: alternativePart.alternativeGuideNumber,
      alternativePartCode: alternativePart.alternativePartCode,
      alternativePartQuality: alternativePart.alternativePartQuality,
      alternativePartQualityId: alternativePart.alternativePartQualityId,
      alternativePartName: alternativePart.name,
      alternativePartStockLocation: alternativePart.stockLocation,
      alternativePartStockQuantity: alternativePart.availableQuantity,
      supplier: alternativePart.supplier,
      supplierId: alternativePart.supplierId,
      producer: alternativePart.producer,
      deliveryTime: alternativePart.deliveryTime.toString(),
      validityTime: alternativePart.validityTime?.toString() || null,
      codeType: alternativePart.codeType?.toString() || null,
      type: alternativePart.type?.toString() || null,
      orderCode: alternativePart.orderCode?.toString() || null,
      locationId: alternativePart.locationId || null,
      alternativePartRemarks: alternativePart.remarks,
      minQty: alternativePart.minQty,
      packing: alternativePart.packing,
      bundleItem: alternativePart.bundleItem,
      bundleNo: alternativePart.bundleNo,
      bundleQuantity: alternativePart.bundleQuantity,
      excludeFromOrder: alternativePart.excludeFromOrder,
      isChild: alternativePart.isChild,
      productId: alternativePart.productId || null,
      codEan: alternativePart.codEan?.toString() || null,
      alternativePriceWithoutDiscount: alternativePart.alternativePriceWithoutDiscount
    };
    console.log('Selected Part', caseCalculationOriginalPartsCopy[originalPartIndex]);

    const currentPacking = caseCalculationOriginalPartsCopy[originalPartIndex].packing?.split('|')[0];
    const secondItemOfPacking = caseCalculationOriginalPartsCopy[originalPartIndex].packing?.split('|')[1];
    
    const caseCalculationAlternativePartCopy = [...this.state.alternativeParts];
    const specificAlternativeParts = this.state.alternativeParts
      .filter(
        (alternativePart) =>
          alternativePart.originalPartId === caseCalculationOriginalPartsCopy[originalPartIndex].id
      )
      .sort(function (a: any, b: any) {
        return a.id - b.id;
      });
    const findAlternativePartGroup = _.filter(specificAlternativeParts, function (object) {
      return (
        object.bundleNo != null &&
        object.bundleNo == caseCalculationOriginalPartsCopy[originalPartIndex].bundleNo &&
        object.alternativePartCode !=
          caseCalculationOriginalPartsCopy[originalPartIndex].alternativePartCode
      );
    });
    
    const idList: any = [];
    for (let i = 0; i < findAlternativePartGroup.length; i++) {
      idList.push(findAlternativePartGroup[i].id);
    }

    
    caseCalculationAlternativePartCopy.forEach((item) => {
      item.selectedAlternativePart =
        item.originalPartId === originalPartid
          ? item.id === id
            ? id
            : 9999999999999
          : item.selectedAlternativePart;
          item.partFromGroup =
      item.originalPartId === originalPartid
            ? idList.includes(item.id)
              ? item.id
              : 9999999999999
            : item.partFromGroup;
      if (
        item.originalPartId === originalPartid &&
        item.selectedAlternativePart !== 9999999999999
      ) {
        const initialCaseCalculationOriginalParts = {
          ...caseCalculationOriginalPartsCopy[originalPartIndex]
        };
        caseCalculationOriginalPartsCopy[originalPartIndex].package = [];
        if (currentPacking && secondItemOfPacking) {
          const currentPackingNumber = parseFloat(currentPacking);
          if (
            caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr % currentPackingNumber !=
              0 ||
            caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr <
              caseCalculationOriginalPartsCopy[originalPartIndex].minQty
          ) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'MIN_PACKING_ERROR_MSG',
                'Cantitatea comandata trebuie sa fie '
              ) +
                currentPackingNumber +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!'),
              { variant: 'error',
              autoHideDuration: 5000  }
            );
          }
        } else if (
          caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr <
          caseCalculationOriginalPartsCopy[originalPartIndex].minQty
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'MIN_PACKING_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie '
            ) +
              caseCalculationOriginalPartsCopy[originalPartIndex].minQty +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!'),
            { variant: 'error',
            autoHideDuration: 5000 }
          );
        }
        if (findAlternativePartGroup.length) {
          let displayPopup = true;
          let alternativePartString = '';
          for (let i = 0; i < findAlternativePartGroup.length; i++) {
            if (
              findAlternativePartGroup[i].alternativePartCode == AlternativeCode.TAXAMEDIU &&
              findAlternativePartGroup.length == 1
            ) {
              displayPopup = false;
            }
            alternativePartString +=
              findAlternativePartGroup[i].name +
              '(' +
              findAlternativePartGroup[i].alternativePrice +
              ' RON)' +
              '\n';
            caseCalculationOriginalPartsCopy[originalPartIndex].package.push({
              ...initialCaseCalculationOriginalParts,
              id: 0,
              originalPrice: 0,
              alternativePrice: findAlternativePartGroup[i].alternativePrice,
              alternativePartDiscount: findAlternativePartGroup[i].alternativePartDiscount,
              alternativeGuideNumber: findAlternativePartGroup[i].alternativeGuideNumber,
              alternativePartCode: findAlternativePartGroup[i].alternativePartCode,
              alternativePartQuality: findAlternativePartGroup[i].alternativePartQuality,
              alternativePartQualityId: findAlternativePartGroup[i].alternativePartQualityId,
              alternativePartName: findAlternativePartGroup[i].name,
              alternativePartStockLocation: findAlternativePartGroup[i].stockLocation,
              alternativePartStockQuantity: findAlternativePartGroup[i].availableQuantity,
              supplier: findAlternativePartGroup[i].supplier,
              supplierId: findAlternativePartGroup[i].supplierId,
              producer: findAlternativePartGroup[i].producer,
              deliveryTime: findAlternativePartGroup[i].deliveryTime.toString(),
              validityTime: findAlternativePartGroup[i].validityTime?.toString() || null,
              codeType: findAlternativePartGroup[i].codeType?.toString() || null,
              type: findAlternativePartGroup[i].type?.toString() || null,
              orderCode: findAlternativePartGroup[i].orderCode?.toString() || null,
              locationId: findAlternativePartGroup[i].locationId || null,
              alternativePartRemarks: findAlternativePartGroup[i].remarks,
              minQty: findAlternativePartGroup[i].minQty,
              packing: findAlternativePartGroup[i].packing,
              bundleItem: findAlternativePartGroup[i].bundleItem,
              bundleNo: findAlternativePartGroup[i].bundleNo,
              bundleQuantity: findAlternativePartGroup[i].bundleQuantity,
              excludeFromOrder: true,
              productId: findAlternativePartGroup[i].productId || null,
              codEan: findAlternativePartGroup[i].codEan || null,
              alternativePriceWithoutDiscount: findAlternativePartGroup[i].alternativePriceWithoutDiscount
            });
          }
          if (displayPopup) {
            this.props.enqueueSnackbar(
              ' Componente adaugate la comanda: \n' + alternativePartString,
              {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                autoHideDuration: 5000 
              }
            );
          }
        }

        if (!Number.isInteger(caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr)) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'DECIMAL_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie un numar intreg fara zecimale'
            ),
            {
              variant: 'error',
              autoHideDuration: 5000 
            }
          );
        }

        if (
          item.availableQuantity !== null &&
          item.availableQuantity < caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ORDER_MAX_QUANTITY_ALERT_MSG',
              'Cantitatea disponibila este insuficienta!'
            ),
            { variant: 'error' }
          );
        }
      }
    });

    console.log('caseCalculationAlternativePartCopy', caseCalculationAlternativePartCopy);
    console.log('caseCalculationOriginalPartsCopy', caseCalculationOriginalPartsCopy);
    this.setState({
      alternativeParts: caseCalculationAlternativePartCopy,
      orderpartsList: caseCalculationOriginalPartsCopy
    });
  };

  handleAlternativePartChecked = (selected: number, id: number, partFromGroup: number) => {
    return selected === id || partFromGroup == id;
  };

  clearSelectedAlternativeParts = async (originalPartId: number) => {
    let alternativePartsCopy = [...this.state.alternativeParts];
    alternativePartsCopy = alternativePartsCopy.map((item) => {
      item.selectedAlternativePart =
        item.originalPartId === originalPartId ? 9999999999999 : item.selectedAlternativePart;
      item.partFromGroup =
        item.originalPartId === originalPartId ? 9999999999999 : item.partFromGroup;
      return item;
    });

    this.setState({
      alternativeParts: alternativePartsCopy,
      clearStateAlternativeParts: true
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.orderService = (this.context as AppContext).orderService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.caseService = (this.context as AppContext).caseService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;

    return (
      <React.Fragment>
        {this.renderOrderPartsSection()}
        <Card className="mt-2">
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <Box m={1} p={1}>
              <Button
                onClick={(e) => this.interogateAlternativeParts(this.props.partDetails!)}
                className="mb-3 float-right bg-success text-white"
                size="small"
                variant="contained"
                color="default"
              >
                {this.translatorService.Tranlate('INTEROGATE', 'Interogheaza')}
              </Button>
              <div className="table-responsive">
                  <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                    <ScaleLoader
                      color={'var(--primary)'}
                      loading={this.state.partLoader}
                    />
                  </div>
                  {this.state.partLoader ? null : (
                    <table className="table table-striped table-hover table-bordered mb-3">
                      <thead
                        style={{
                          display: 'table',
                          width: '100%',
                          tableLayout: 'fixed'
                        }}
                        className="thead-light"
                      >
                        <tr
                          style={{
                            display: 'table',
                            width: '100%',
                            tableLayout: 'fixed'
                          }}
                        >
                          <th className="text-center" scope="col">
                            <Tooltip
                              title={
                                this.state.alternativeParts.length > 0 &&
                                this.state.alternativeParts.some(
                                  (el) => el.selectedAlternativePart !== 9999999999999
                                )
                                  ? this.translatorService.Tranlate(
                                      'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CLEAR_LABEL',
                                      'Goleste'
                                    )
                                  : ''
                              }
                            >
                              <div>
                                <Button
                                  disabled={
                                    this.state.alternativeParts.length > 0 &&
                                    this.state.alternativeParts.some(
                                      (el) => el.selectedAlternativePart !== 9999999999999
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={(e) =>
                                    this.clearSelectedAlternativeParts(this.props.partDetails!.id)
                                  }
                                  className="ml-1 min min-w-auto"
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                >
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={['fas', 'ban']} />
                                  </span>
                                </Button>
                              </div>
                            </Tooltip>
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_NAME_LABEL',
                              'Nume'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CODE_LABEL',
                              'Cod'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
                              'Pret'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_QUALITY_LABEL',
                              'Calitate'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_DELIVERY_LABEL',
                              'Livrare'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_OWNER_LABEL_ACRONYM',
                              'Producator'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_SUPLIER_LABEL',
                              'Furnizor'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_QUANTITY_LABEL',
                              'Cantitate'
                            )}
                          </th>
                          <th scope="col">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_STOCK_LOCATION_LABEL',
                              'Locatie'
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          display: 'block',
                          overflow: 'overlay',
                          width: '100%',
                          tableLayout: 'fixed',
                          wordBreak: 'break-word'
                        }}
                      >
                        {this.state.alternativeParts.length > 0 ? (
                          this.state.alternativeParts.map((alternativePart, alternativePartindex) => {
                            return (
                              <tr
                                key={alternativePart.id}
                                style={{
                                  display: 'table',
                                  width: '100%',
                                  tableLayout: 'fixed'
                                }}
                              >
                                <td className="text-center">
                                  <Radio
                                    checked={this.handleAlternativePartChecked(
                                      alternativePart.selectedAlternativePart,
                                      alternativePart.id,
                                      alternativePart.partFromGroup
                                    )}
                                    onChange={(e) =>
                                      this.handleAlternativePartSelect(
                                        alternativePart.id,
                                        alternativePart.originalPartId,
                                        alternativePart
                                      )
                                    }
                                    disabled={
                                      alternativePart.isChild
                                    }
                                    value={alternativePart.id}
                                    id={alternativePart.id.toString()}
                                    name={this.props.partDetails.id.toString()}
                                  />
                                </td>
                                <td>
                                  {!R.isNil(alternativePart.remarks) &&
                                  alternativePart.remarks !== '' ? (
                                    <Tooltip
                                      placement="top"
                                      title={
                                        <div>
                                          <div
                                            style={{
                                              fontSize: '16px'
                                            }}
                                            className="text-center font-weight-bold mb-2 text-danger"
                                          >
                                            {this.translatorService.Tranlate(
                                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_REMARKS_LABEL',
                                              'Observatii'
                                            )}
                                          </div>
                                          <div>{alternativePart.remarks}</div>
                                        </div>
                                      }
                                    >
                                      <div>{alternativePart.name}</div>
                                    </Tooltip>
                                  ) : (
                                    alternativePart.name
                                  )}
                                </td>
                                <td>{alternativePart.alternativeGuideNumber}</td>
                                <td>
                                {R.isNil(alternativePart!.alternativePartDiscount)
                                  ? Utils.countryNumberFormat(
                                      (Math.round(alternativePart.alternativePrice * 100) / 100).toFixed(2),
                                      this.props.appState.appUser?.organization?.country ?? null
                                    )
                                  : Utils.countryNumberFormat(
                                      (
                                        Math.round(
                                          alternativePart.alternativePrice * (1 - alternativePart!.alternativePartDiscount / 100) * 100
                                        ) / 100
                                      ).toFixed(2),
                                      this.props.appState.appUser?.organization?.country ?? null
                                    )
                                }
                                </td>
                                <td>
                                  {R.isNil(alternativePart.alternativePartQuality)
                                    ? ''
                                    : alternativePart.alternativePartQuality.displayName}
                                </td>
                                <td>{alternativePart.deliveryTime}</td>
                                <td>{alternativePart.producer}</td>
                                <td>{alternativePart.supplier!.name}</td>
                                <td>
                                  {isNaN(alternativePart.availableQuantity) === null
                                    ? null
                                    : alternativePart.availableQuantity}
                                </td>
                                <td>{alternativePart.stockLocation}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr
                            style={{
                              display: 'table',
                              width: '100%',
                              tableLayout: 'fixed'
                            }}
                          >
                            <td className="text-center">
                              <span>
                                {this.translatorService.Tranlate(
                                  'NO_RECORDS_TO_DISPLAY',
                                  'Nu exista inregistrari de afisat'
                                )}
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>) }
              </div>
              <Button
                onClick={this.save}
                className="mb-3 float-right"
                variant="contained"
                color="primary"
              >
                {this.translatorService.Tranlate('SAVE', 'Salveaza')}
              </Button>
            </Box>
          ) : null}
        </Card>
      </React.Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalUpdateOrderPartProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettings: state.caseSettings
  }),
  null,
  mergeProps
)(withSnackbar(UpdateOrderPart as any));
