import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Slider,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { DateLaborService, TariffType, TariffValue } from '../../../interfaces/Tariff';
import { ITariffService } from '../../../services/Interfaces/ITariffService';
import moment from 'moment';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { IReferential } from '../../../interfaces/IReferential';
import { ReferentialCode, RoleClaimsEnum, TariffCategoryCodeEnum } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import EditCircleTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteCircleTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-gb';
import 'moment/locale/ro';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}
interface TariffIntervalValue {
  start: number;
  end: number;
  value: number;
}

interface IEditTarifLaborTabState {
  tariffValues: TariffValue[];
  isLoading: boolean;
  activeAction: number;

  selectedTariffTypes: TariffType[];
  selectedBrands: IReferential[];
  selectedDate: Date | null;
  selectedValue: number;
  selectedOfferValue: number;
  selectedMarginValue: number;
  isVehicleAge: boolean;
  selectedSliderValues: number[];
  selectedValues: number[];
  selectedOfferValues: number[];
  selectedMarginValues: number[];

  minDate: Date;
  tariffTypeList: TariffType[];
  brandList: IReferential[];
  insurerList: IReferential[];
  selectedInsurer: IReferential[];
}

export interface ExternalEditTarifLaborTabProps {
  tariffId: number;
  tariffGroupTypeId: number;
  tariffGroupTypeName: string;
  tariffGroupTypeCode: string;
}

type EditTarifLaborTabProps = { appState: AppState } & ProviderContext &
  ExternalEditTarifLaborTabProps &
  RouteComponentProps<{ id: string }>;

const START = 0;
const END = 10;
const DEFAULT = 5;

class EditTarifLaborTabf extends React.PureComponent<
  EditTarifLaborTabProps,
  IEditTarifLaborTabState
> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private tariffService!: ITariffService;

  momutil = new MomentUtils();
  static contextType = ApplicationContext;

  defaultBrand = {
    name: 'All', //this.translatorService.Tranlate("SELECT_ALL_OPTION", "Toate"),
    displayName: 'All', //this.translatorService.Tranlate("SELECT_ALL_OPTION", "Toate"),
    id: -1,
    isActive: true
  } as IReferential;

  defaultInsurer = {
    name: 'None', //this.translatorService.Tranlate('SELECT_NONE_OPTION', 'Niciunul'),
    displayName: 'None', //this.translatorService.Tranlate('SELECT_NONE_OPTION', 'Niciunul'),
    id: -1,
    isActive: true
  } as IReferential;

  state = {
    tariffValues: [] as TariffValue[],
    isLoading: true,
    isVehicleAge: false,
    activeAction: 0,
    selectedTariffTypes: [] as TariffType[],
    selectedBrands: [this.defaultBrand] as IReferential[],
    minDate: new Date(),
    tariffTypeList: [] as TariffType[],
    brandList: [] as IReferential[],
    insurerList: [] as IReferential[],
    selectedInsurer: [this.defaultInsurer] as IReferential[],
    selectedSliderValues: [DEFAULT] as number[],
    selectedValues: [0, 0] as number[],
    selectedOfferValues: [0, 0] as number[],
    selectedValue: 0,
    selectedOfferValue: 0,
    selectedMarginValue: 0,
    selectedMarginValues: [0, 0] as number[]
  } as IEditTarifLaborTabState;

  componentDidMount = () => {
    moment.locale(this.props.appState.language);

    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  compareName = (a: IReferential, b: IReferential) => {
    const nameA = a === null ? '' : a.displayName!.toUpperCase();
    const nameB = b === null ? '' : b.displayName!.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  loadData = async () => {
    try {
      this.defaultBrand.name = this.translatorService.Tranlate('SELECT_ALL_OPTION', 'Toate');
      this.defaultBrand.displayName = this.translatorService.Tranlate('SELECT_ALL_OPTION', 'Toate');

      this.defaultInsurer.name = this.translatorService.Tranlate('SELECT_NONE_OPTION', 'Niciunul');
      this.defaultInsurer.displayName = this.translatorService.Tranlate(
        'SELECT_NONE_OPTION',
        'Niciunul'
      );

      const brandRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.CarBrands
      );
      const insurerRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.Insurer
      );

      const [tariffTypeList, brandList, insurerList, tariffValues] = await Promise.all([
        this.tariffService.GetTariffTypes(this.props.tariffGroupTypeId),
        this.appReferentialService.Get(brandRef!.baseUrl),
        this.appReferentialService.Get(insurerRef!.baseUrl),
        this.tariffService.GetTariffValues(this.props.tariffId, this.props.tariffGroupTypeId)
      ]);

      const brandList1 = brandList.sort(this.compareName);
      brandList.unshift(this.defaultBrand);
      
      const insurerList1 = _.cloneDeep(insurerList).sort(this.compareName);
      insurerList1.unshift(this.defaultInsurer);
            
      this.setState({
        tariffTypeList: tariffTypeList.filter((item) => item.isActive === true),
        tariffValues: tariffValues,
        brandList: brandList1.filter((item) => item.isActive === true),
        insurerList: insurerList1.filter((item) => item.isActive === true),
        isLoading: false
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleTariffTypeChange = (event: React.ChangeEvent<any>, values: TariffType[]) => {
    this.setState({ selectedTariffTypes: values });
  };

  saveTariffValues = async (activeButton: number) => {
    const tariffValuesToSave = [] as TariffValue[];

    let date = new Date();
    if (moment.isDate(this.state.selectedDate)) {
      date = moment.utc(this.state.selectedDate.toDateString()).local().toDate();
    } else {
      date = moment.utc(this.state.selectedDate).local().toDate();
    }

    for (const tariffType of this.state.selectedTariffTypes) {
      for (const insurer of this.state.selectedInsurer) {
        for (const brand of this.state.selectedBrands) {
          if (this.state.isVehicleAge) {
            for (let i = 0; i < this.state.selectedSliderValues.length; i++) {
              const tariffValue = {
                brandId: brand.id === this.defaultBrand.id ? null : brand.id,
                tariffId: this.props.tariffId,
                tariffTypeId: tariffType.id,
                date: date,
                value: this.state.selectedValues[i],
                offerValue: this.state.selectedOfferValues[i],
                marginValue: this.state.selectedMarginValues[i],
                startVehicleYear: i === 0 ? null : this.state.selectedSliderValues[i - 1],
                endVehicleYear: this.state.selectedSliderValues[i],
                insurerId: insurer.id === this.defaultInsurer.id ? null : insurer.id
              } as TariffValue;
              tariffValuesToSave.push(tariffValue);

              if (i === this.state.selectedSliderValues.length - 1) {
                tariffValuesToSave.push({
                  brandId: brand.id === this.defaultBrand.id ? null : brand.id,
                  tariffId: this.props.tariffId,
                  tariffTypeId: tariffType.id,
                  date: date,
                  value: this.state.selectedValues[i + 1],
                  offerValue: this.state.selectedOfferValues[i + 1],
                  marginValue: this.state.selectedMarginValues[i + 1], 
                  startVehicleYear: this.state.selectedSliderValues[i],
                  endVehicleYear: null,
                  insurerId: insurer.id === this.defaultInsurer.id ? null : insurer.id
                } as TariffValue);
              }
            }
          } else {
            const tariffValue = {
              brandId: brand.id === this.defaultBrand.id ? null : brand.id,
              tariffId: this.props.tariffId,
              tariffTypeId: tariffType.id,
              date: date,
              value: this.state.selectedValue,
              offerValue: this.state.selectedOfferValue,
              marginValue: this.state.selectedMarginValue,
              startVehicleYear: null,
              endVehicleYear: null,
              insurerId: insurer.id === this.defaultInsurer.id ? null : insurer.id
            } as TariffValue;
            tariffValuesToSave.push(tariffValue);
          }
        }
      }
    }
    try {
      await this.tariffService.UpdateTariffValues(tariffValuesToSave);

      this.setState(
        {
          isLoading: true,
          selectedTariffTypes: [],
          selectedValue: 0,
          selectedOfferValue: 0,
          selectedMarginValue: 0
        },
        () => {
          this.loadData();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDateChange = (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState({ selectedDate: date!.toDate() });
  };

  handleBrandChange = (newValue: IReferential[]) => {
    const allIndex = newValue.findIndex((item) => item.id === this.defaultBrand.id);

    if (allIndex === newValue.length - 1) {
      this.setState({ selectedBrands: [this.defaultBrand] });
      return;
    }
    if (allIndex === 0 && newValue.length > 1) {
      this.setState({ selectedBrands: [newValue[1]] });
      return;
    }

    this.setState({ selectedBrands: newValue });
  };

  handleInsurerChange = (newValue: IReferential[]) => {
    this.setState({ selectedInsurer: newValue });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        decimalScale={2}
        isNumericString
      />
    );
  };

  handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      this.setState({ selectedValue: 0 });
      return;
    }

    this.setState({
      selectedValue: event.target.value === null ? 0 : parseFloat(event.target.value)
    });
  };

  handleOfferValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      this.setState({ selectedOfferValue: 0 });
      return;
    }
    this.setState({
      selectedOfferValue: event.target.value === null ? 0 : parseFloat(event.target.value)
    });
  };

  handleMarginValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      this.setState({ selectedMarginValue: 0 });
      return;
    }
    this.setState({
      selectedMarginValue: event.target.value === null ? 0 : parseFloat(event.target.value)
    });
  };

  handleValuesChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValues = [...this.state.selectedValues];
    if (event.target.value === '') {
      newValues[index] = 0;
    } else {
      newValues[index] = event.target.value === null ? 0 : parseFloat(event.target.value);
    }

    this.setState({ selectedValues: newValues });
  };

  handleOfferValuesChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValues = [...this.state.selectedOfferValues];
    if (event.target.value === '') {
      newValues[index] = 0;
    } else {
      newValues[index] = event.target.value === null ? 0 : parseFloat(event.target.value);
    }
    newValues[index] = event.target.value === null ? 0 : parseFloat(event.target.value);

    this.setState({ selectedOfferValues: newValues });
  };

  handleMarginValuesChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValues = [...this.state.selectedMarginValues];
    if (event.target.value === '') {
      newValues[index] = 0;
    } else {
      newValues[index] = event.target.value === null ? 0 : parseFloat(event.target.value);
    }
    newValues[index] = event.target.value === null ? 0 : parseFloat(event.target.value);

    this.setState({ selectedMarginValues: newValues });
  };

  handleChange = (event: any, newvalue: number | number[]) => {
    const newValues = newvalue as number[];

    this.setState({ selectedSliderValues: newValues });

    return;
  };

  getMarks = (total: number) => {
    const result: any[] = [];
    for (let i = 0; i <= total; i++) {
      result.push({
        value: i,
        label: i.toString()
      });
    }

    return result;
  };

  removeSliderValue = () => {
    if (this.state.selectedSliderValues.length === 1) {
      return;
    }

    const values = [...this.state.selectedSliderValues];
    const tValues = [...this.state.selectedValues];
    const tOfferValues = [...this.state.selectedOfferValues];
    const tMarginValues = [...this.state.selectedMarginValues];

    values.pop();
    tValues.pop();
    tOfferValues.pop();

    this.setState({
      selectedSliderValues: values,
      selectedValues: tValues,
      selectedOfferValues: tOfferValues,
      selectedMarginValues: tMarginValues
    });
  };

  addSliderValue = () => {
    this.setState({
      selectedSliderValues: [...this.state.selectedSliderValues, END],
      selectedValues: [...this.state.selectedValues, 0],
      selectedOfferValues: [...this.state.selectedOfferValues, 0],
      selectedMarginValues: [...this.state.selectedMarginValues, 0]
    });
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  renderEditTariffValues = (activeButton: number) => {
    return (
      <ValidatorForm onSubmit={() => this.saveTariffValues(activeButton)} instantValidate={true}>
        <Divider />
        <Box m={1} p={0} className="m-4">
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={LocalizedUtils}
            locale={this.props.appState.language}
          >
            <DatePicker
              fullWidth
              disabled={activeButton === 1 ? true : false}
              variant="inline"
              format={this.props.appState.dateFormat.toUpperCase()}
              margin="normal"
              id="date-picker-inline"
              label={this.translatorService.Tranlate('TARIFF_EDIT_LABOR_TAB_DATE_LABEL', 'De la')}
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
              autoOk={true}
              minDate={activeButton === 1 ? null : this.state.minDate}
              //clearLabel = {false}
              // KeyboardButtonProps={{
              //     'aria-label': 'change date',
              // }}
            />
          </MuiPickersUtilsProvider>
          {this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS.toString() ? null : (
              <Autocomplete
                id="insurer"
                multiple
                options={this.state.insurerList}
                value={this.state.selectedInsurer || ''}
                onChange={(e: any, newValue: IReferential[]) => this.handleInsurerChange(newValue)}
                getOptionLabel={(option: IReferential) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="insurer"
                    value={this.state.selectedInsurer[0] || ''}
                    label={this.translatorService.Tranlate(
                      'TARIFF_EDIT_LABOR_TAB_INSURER_LABEL',
                      'Asigurator'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                    ]}
                  />
                )}
              />
            )
          }
        
          <Autocomplete
            id="tariffType"
            multiple
            filterSelectedOptions
            options={this.state.tariffTypeList}
            getOptionLabel={(option: TariffType) => option.displayName}
            value={this.state.selectedTariffTypes}
            onChange={(e: any, newValue: TariffType[]) => this.handleTariffTypeChange(e, newValue)}
            renderInput={(params: RenderInputParams) => (
              <TextValidator
                {...params}
                name="tariffType"
                fullWidth
                value={this.state.selectedTariffTypes[0] || ''}
                label={this.props.tariffGroupTypeName}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
          {this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS.toString() || 
           this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS_ASIGURATOR.toString()  || 
           this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS_ALT_PART.toString() ||
           this.hasRight(RoleClaimsEnum.AdminService) ? null : (
            <Autocomplete
              id="brand"
              multiple
              options={this.state.brandList}
              value={this.state.selectedBrands || ''}
              onChange={(e: any, newValue: IReferential[]) => this.handleBrandChange(newValue)}
              getOptionLabel={(option: IReferential) => option.name || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="brand"
                  value={this.state.selectedBrands[0] || ''}
                  label={this.translatorService.Tranlate(
                    'TARIFF_EDIT_LABOR_TAB_BRAND_LABEL',
                    'Marca'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                  ]}
                />
              )}
            />
          )}
        
          {this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS.toString() || 
            this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS_ASIGURATOR.toString() || 
            this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS_ALT_PART.toString()  ||
            this.hasRight(RoleClaimsEnum.AdminService) ? null : (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isVehicleAge}
                    onChange={(e: any) =>
                      this.setState({
                        isVehicleAge: e.currentTarget.checked
                      })
                    }
                    color="primary"
                    id="isActive"
                    inputProps={{
                      'aria-label': 'primary checkbox'
                    }}
                  />
                }
                label={this.translatorService.Tranlate(
                  'TARIFF_EDIT_LABOR_TAB_HAS_INTERVAL_LABEL',
                  'Stare'
                )}
              />
            </div>
          )}

          {!this.state.isVehicleAge ? null : (
            <Grid container alignContent="center" className="mt-4" alignItems="center" spacing={4}>
              <Grid item xs={8}>
                <Slider
                  className="ml-2 mr-2"
                  disabled={!this.state.isVehicleAge}
                  value={this.state.selectedSliderValues}
                  onChange={this.handleChange}
                  valueLabelDisplay="on"
                  aria-labelledby="range-slider"
                  max={END}
                  step={1}
                  min={START}
                  marks={this.getMarks(END)}
                  track={false}
                />
              </Grid>
              <Grid item xs={4}>
                <IconButton onClick={this.removeSliderValue}>
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={this.addSliderValue}>
                  <AddCircleTwoToneIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}

          <Grid container alignContent="center" className="" alignItems="center" spacing={0}>
            {!this.state.isVehicleAge
              ? null
              : this.state.selectedValues.map((value: number, index: number) => (
                  <React.Fragment key={index}>
                    <Grid item xs={6} key={index}>
                      <TextValidator
                        key={index}
                        name="value"
                        className="w-50"
                        id="name"
                        type="value"
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleValuesChange(e, index)
                        }
                        label={
                          this.translatorService.Tranlate(
                            'TARIFF_EDIT_LABOR_TAB_VALUE_LABEL',
                            'Valoare'
                          ) +
                          '(' +
                          index +
                          ')'
                        }
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextValidator
                        key={index}
                        name="offerValue"
                        className="w-50"
                        id="name"
                        type="value"
                        value={this.state.selectedOfferValues[index]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleOfferValuesChange(e, index)
                        }
                        label={
                          this.translatorService.Tranlate(
                            'TARIFF_EDIT_LABOR_TAB_OFFER_VALUE_LABEL',
                            'Valoare'
                          ) +
                          '(' +
                          index +
                          ')'
                        }
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
          </Grid>

          {this.state.isVehicleAge ? null : (
            <Grid container alignContent="center" className="mt-1" alignItems="center" spacing={0}>
              {
                this.props.tariffGroupTypeCode === TariffCategoryCodeEnum.ADAOS.toString() ? (
                  <>
                    <Grid item xs={4}>
                      <TextValidator
                        name="value"
                        className="w-50 mt-2"
                        id="name"
                        type="value"
                        value={this.state.selectedValue}
                        onChange={this.handleValueChange}
                        label={this.translatorService.Tranlate(
                          'TARIFF_EDIT_LABOR_TAB_STATIC_VALUE_LABEL',
                          'Valoare'
                        )}
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextValidator
                        name="value"
                        className="w-50 mt-2"
                        id="name"
                        type="value"
                        value={this.state.selectedMarginValue}
                        onChange={this.handleMarginValueChange}
                        label={this.translatorService.Tranlate(
                          'TARIFF_EDIT_LABOR_TAB_MARGIN_VALUE_LABEL',
                          'Valoare Deviz'
                        )}
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextValidator
                        name="value"
                        className="w-50 mt-2"
                        id="name"
                        type="value"
                        value={this.state.selectedOfferValue}
                        onChange={this.handleOfferValueChange}
                        label={this.translatorService.Tranlate(
                          'TARIFF_EDIT_LABOR_TAB_PROCENT_VALUE_LABEL',
                          'Procent'
                        )}
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <TextValidator
                        name="value"
                        className="w-50"
                        id="name"
                        type="value"
                        value={this.state.selectedValue}
                        onChange={this.handleValueChange}
                        label={this.translatorService.Tranlate(
                          'TARIFF_EDIT_LABOR_TAB_VALUE_LABEL',
                          'Valoare'
                        )}
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextValidator
                        name="value"
                        className="w-50"
                        id="name"
                        type="value"
                        value={this.state.selectedOfferValue}
                        onChange={this.handleOfferValueChange}
                        label={this.translatorService.Tranlate(
                          'TARIFF_EDIT_LABOR_TAB_OFFER_VALUE_LABEL',
                          'Valoare'
                        )}
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                        InputProps={{
                          inputComponent: this.NumberFormatCustom
                        }}
                      />
                    </Grid>
                  </>
                )

              }
          
            </Grid>
          )}
          <div className="text-right m-2">
            <Button
              className="m-2"
              variant="outlined"
              color="primary"
              onClick={() => {
                this.setState({ activeAction: 0 });
              }}
            >
              {this.translatorService.Tranlate('TARIFF_EDIT_LABOR_TAB_CANCEL_BUTTON', 'Anuleaza')}
            </Button>
            <Button className="m-2" variant="contained" color="primary" type="submit">
              {this.translatorService.Tranlate('TARIFF_EDIT_LABOR_TAB_SAVE_BUTTON', 'Salveaza')}
            </Button>
          </div>
        </Box>
      </ValidatorForm>
    );
  };

  structureTariffValuesAccordingToDate = () => {
    const dataLaborServices = [] as DateLaborService[];

    this.state.tariffValues.forEach((tariffValue: TariffValue) => {
      const index = dataLaborServices.findIndex(
        (item: DateLaborService) => item.date.toString() === tariffValue.date.toString()
      );

      if (index !== -1) {
        dataLaborServices[index].tariffValues.push(tariffValue);
      } else {
        dataLaborServices.push({
          date: tariffValue.date,    
          tariffValues: [tariffValue]
        });
      }
    });
    //dataLaborServices = dataLaborServices.sort().reverse();

    return dataLaborServices;
  };

  renderTariffValues = (list: TariffValue[]) => {
    list = list.sort((a: TariffValue, b: TariffValue) => {
      return this.compareName(a.brand!, b.brand!);
    });
    return this.props.tariffGroupTypeCode == TariffCategoryCodeEnum.ADAOS.toString() ? (list.map((item) => (
      <p key={item.id}>
        {(item.brand === null ? this.defaultBrand.displayName : item.brand.displayName) +
          ' ' +
          this.renderVehicleAge(item) +
          ' - ' +
          item.value +
          ' (' +
          item.marginValue +
          ') ' + 
          item.offerValue}
      </p>
    ))) : (list.map((item) => (
      <p key={item.id}>
        {(item.brand === null ? this.defaultBrand.displayName : item.brand.displayName) +
          ' ' +
          this.renderVehicleAge(item) +
          ' - ' +
          item.value +
          ' (' +
          item.offerValue +
          ')'}
      </p>
    )));
  };

  renderVehicleAge = (tv: TariffValue) => {
    if (tv.startVehicleYear === null && tv.endVehicleYear === null) {
      return '';
    }
    const start = tv.startVehicleYear === null ? '0' : tv.startVehicleYear;
    const end = tv.endVehicleYear === null ? '' : tv.endVehicleYear;

    return '(' + start + '<' + end + ')';
  };

  getDistinctTariffTypes = (tariffValues: TariffValue[]) => {
    return tariffValues
      .map((item) => item.tariffType)
      .filter((tt, index, arr) => arr.findIndex((i) => i.id === tt.id) == index);
  };

  getDistinctInsurer = (dateLS: DateLaborService) => {
    return _.map(_.groupBy(dateLS.tariffValues, 'insurerId'), 
      (items, insurerId) => ({
          insurer: items[0].insurer,
          tariffValues: items,
      }));
  };

  deleteTariff = async (date: Date) => {
    try {
      const dateLocal = moment.utc(date.toDateString()).local().toDate();
      await this.tariffService.DeleteTariffValues(this.props.tariffId, dateLocal);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadData();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  showTimelineTariff = () => {
    const dataLaborServices = this.structureTariffValuesAccordingToDate();
    if (dataLaborServices.length === 0) {
      return (
        <b>
          {this.translatorService.Tranlate(
            'TARIFF_EDIT_LABOR_TAB_NO_DATA',
            'Nu exista date de afisat!'
          )}
        </b>
      );
    }

    return dataLaborServices.map((dateTariff) => (
      <div key={dateTariff.date.toString()} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon" />
          <h4 className="timeline-item--label mb-2 font-weight-bold">
            {moment(dateTariff.date).toDate().toLocaleDateString(this.props.appState.language)}
            {moment(dateTariff.date).toDate() > new Date() ? (
              <Tooltip
                title={this.translatorService.Tranlate(
                  'TARIFF_EDIT_LABOR_TAB_DELETE_BUTTON',
                  'Sterge'
                )}
              >
                <IconButton
                  aria-label="edit"
                  color="default"
                  size="small"
                  className=""
                  onClick={() => this.deleteTariff(moment(dateTariff.date).toDate())}
                >
                  <DeleteCircleTwoToneIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </h4>
          {this.getDistinctInsurer(dateTariff).map((item) => {
            return (
              <>
              <h4 className="timeline-item--label mb-2 mt-2 font-weight-bold">
                {item.insurer ? item.insurer!.displayName : this.translatorService.Tranlate('SELECT_NONE_OPTION', 'Niciunul')}
              </h4>
              <Grid container>
                {this.getDistinctTariffTypes(item.tariffValues).map((tt) => {
                  const tariffValues = item.tariffValues.filter(
                    (item) => item.tariffTypeId === tt.id
                  );

                  return (
                    <Grid item xs={2} key={tt.id}>
                      <h6> {tt.displayName} </h6>
                      {this.renderTariffValues(tariffValues)}
                    </Grid>
                  );
                })}
              </Grid>
              </>
            );
          })}
         
        </div>
      </div>
    ));
  };

  editInsertTarifLaborButton = (button: number) => {
    const dataLaborServices = this.structureTariffValuesAccordingToDate();

    if (button === 2) {
      this.setState({
        activeAction: button,
        selectedDate:
          dataLaborServices.length > 0
            ? moment(dataLaborServices[0].date).add(1, 'days').toDate()
            : new Date(),
        minDate:
          dataLaborServices.length > 0
            ? moment(dataLaborServices[0].date).add(1, 'days').toDate()
            : new Date()
      });
    } else {
      this.setState({
        activeAction: button,
        selectedDate: dataLaborServices.length > 0 ? dataLaborServices[0].date : new Date(),
        minDate:
          dataLaborServices.length > 0
            ? moment(dataLaborServices[0].date).add(1, 'days').toDate()
            : new Date()
      });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.tariffService = (this.context as AppContext).tariffService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box mt={1} pt={1}>
          {this.state.tariffValues.length > 0 ? (
            <Card className="card-box p-1 mb-4">
              <div className="m-1 text-right">
                <Tooltip
                  title={this.translatorService.Tranlate(
                    'TARIFF_EDIT_LABOR_TAB_UPDATE_BUTTON',
                    'Actualizeaza'
                  )}
                >
                  <IconButton
                    aria-label="edit"
                    color="default"
                    className="m-2"
                    onClick={() => this.editInsertTarifLaborButton(1)}
                  >
                    <EditCircleTwoToneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={this.translatorService.Tranlate(
                    'TARIFF_EDIT_LABOR_TAB_ADD_BUTTON',
                    'Adauga'
                  )}
                >
                  <IconButton
                    aria-label="add"
                    color="default"
                    className="m-2"
                    onClick={() => this.editInsertTarifLaborButton(2)}
                  >
                    <AddCircleTwoToneIcon />
                  </IconButton>
                </Tooltip>
              </div>

              {this.state.activeAction !== 0
                ? this.renderEditTariffValues(this.state.activeAction)
                : null}
            </Card>
          ) : (
            <Card className="card-box p-1 mb-4">
              <div className="m-1 text-right">
                {/* <Button className={this.state.activeAction === 2 ? "text-white shadow-none m-2 w-25" : "text-primary border border-primary shadow-none m-2 w-25"} variant="contained" color={this.state.activeAction === 2 ? "primary" : undefined} onClick={() => this.editInsertTarifLaborButton(2)}>{this.translatorService.Tranlate("TARIFF_EDIT_LABOR_TAB_ADD_BUTTON", "Adauga")}</Button> */}
                <Tooltip
                  title={this.translatorService.Tranlate(
                    'TARIFF_EDIT_LABOR_TAB_ADD_BUTTON',
                    'Adauga'
                  )}
                >
                  <IconButton
                    aria-label="add"
                    color="default"
                    className="m-2"
                    onClick={() => this.editInsertTarifLaborButton(2)}
                  >
                    <AddCircleTwoToneIcon />
                  </IconButton>
                </Tooltip>
              </div>

              {this.state.activeAction !== 0
                ? this.renderEditTariffValues(this.state.activeAction)
                : null}
            </Card>
          )}
        </Box>

        <Box>
          <Card className="card-box p-1 mb-4">
            <Grid item xs={12} container direction="column" className="ml-0" spacing={1}>
              <Grid item xs className="">
                <div className="timeline-list  timeline-list--primary m-2 p-3">
                  {' '}
                  {this.showTimelineTariff()}{' '}
                </div>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalEditTarifLaborTabProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditTarifLaborTabf as any));
