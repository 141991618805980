import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import moment from 'moment';
import { Card, Grid, Paper, Button } from '@material-ui/core';
import axios from 'axios';

import { ApplicationContext } from '../../../context/Contexts';
import { VehicleFilter } from '../../../interfaces/Dashboard/VehicleFilter';
import { VehicleResult } from '../../../interfaces/Dashboard/VehicleResult';
import VehicleDashboardAppliedFilters from './VehicleDashboardAppliedFilters';
import VehicleBarChart from './VehicleBarChart';
import VehicleAverages from './VehicleAverages';
import VehicleAverageAgeBarChart from './VehicleAverageAgeBarChart';
import VehicleMap from './VehicleMap';
import VehicleDonutChart from './VehicleDonutChart';
import VehicleConditionTable from './VehicleConditionTable';
import VehicleStockTable from './VehicleStockTable';
import Loader from '../../Loader';
import isNil from 'lodash/isNil';
import DashboardFilters from '../DashboardFilters';
import {
  vehicleDashboardReducer,
  RESET_INVENTORY_FILTERS,
  SET_FUEL_ID,
  SET_INVENTORY_AGE_FILTER,
  SET_LOCATION_ID,
  SET_MAKEID,
  SET_MODELID,
  SET_STATUS_ID,
  SET_TECHNICAL_CONDITION_ID,
  SET_TRACTION_TYPE_ID,
  SET_TRANSMISSION_TYPE_ID,
  SET_VALUE_BY_NAME
} from './vehicleDashboardReducer';
import { useSelector } from 'react-redux';
import { PptService } from '../../../services/PptService';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { numberFormat } from '../../../utils/numberFormat';
import _ from 'lodash';
// import { creatPpt, getFilterInfo } from '../../../utils/pptFunctions';

const VehicleDashboard: React.FC = () => {
  const [filters, dispatch] = useReducer(vehicleDashboardReducer, {
    startDate: moment().add(-3, 'month'),
    endDate: moment(),
    selectedOrganisations: [],
    makeId: undefined,
    modelId: undefined,
    fuelId: undefined,
    locationId: undefined,
    transmissionTypeId: undefined,
    tractionTypeId: undefined,
    inventoryAgeFilter: undefined,
    technicalConditionId: undefined,
    statusId: undefined
  });

  const [data, setData] = useState<VehicleResult>();

  const [updatedItems, setUpdatedItems] = useState<any[]>([]);

  const context = useContext(ApplicationContext);

  const constantsData = MUITranslations.GetDashboardTranslations(context.translatorService);

  const {
    app: { language }
  } = useSelector((state: any) => state);

  const setFiltersValueByName = useCallback((name: string, value: any) => {
    dispatch({ type: SET_VALUE_BY_NAME, name, value });
  }, []);

  const setMakeId = useCallback((makeId?: number) => {
    dispatch({ type: SET_MAKEID, makeId });
  }, []);

  const setModelId = useCallback((modelId?: number) => {
    dispatch({ type: SET_MODELID, modelId });
  }, []);

  const setTechnicalConditionId = useCallback((technicalConditionId?: number) => {
    dispatch({ type: SET_TECHNICAL_CONDITION_ID, technicalConditionId });
  }, []);

  const setStatusId = useCallback((statusId?: number) => {
    dispatch({ type: SET_STATUS_ID, statusId });
  }, []);

  const setInventoryAgeFilter = useCallback((inventoryAgeFilter?: number) => {
    dispatch({ type: SET_INVENTORY_AGE_FILTER, inventoryAgeFilter });
  }, []);

  const setFuelId = useCallback((fuelId?: number) => {
    dispatch({ type: SET_FUEL_ID, fuelId });
  }, []);

  const setTransmissionTypeId = useCallback((transmissionTypeId?: number) => {
    dispatch({ type: SET_TRANSMISSION_TYPE_ID, transmissionTypeId });
  }, []);

  const setTractionTypeId = useCallback((tractionTypeId?: number) => {
    dispatch({ type: SET_TRACTION_TYPE_ID, tractionTypeId });
  }, []);

  const setLocationId = useCallback((locationId?: number) => {
    dispatch({ type: SET_LOCATION_ID, locationId });
  }, []);

  const resetInventoryFilters = useCallback(() => {
    dispatch({ type: RESET_INVENTORY_FILTERS });
  }, []);

  const handleFuelTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const fuelId = data?.byFuelType[dataIndex].id;
        if (!isNil(fuelId)) {
          setFuelId(fuelId);
        }
      }
    },
    [data?.byFuelType, setFuelId]
  );

  const handleTractionTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const tractionTypeId = data?.byTractionType[dataIndex].id;
        if (!isNil(tractionTypeId)) {
          setTractionTypeId(tractionTypeId);
        }
      }
    },
    [data?.byTractionType, setTractionTypeId]
  );

  const handleTransmissionTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const transmissionTypeId = data?.byTransmissionType[dataIndex].id;
        if (!isNil(transmissionTypeId)) {
          setTransmissionTypeId(transmissionTypeId);
        }
      }
    },
    [data?.byTransmissionType, setTransmissionTypeId]
  );

  const handleInventoryAgeBarClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const inventoryAgeFilter = data?.byInventoryAge[dataIndex].id;
        if (!isNil(inventoryAgeFilter)) {
          setInventoryAgeFilter(inventoryAgeFilter);
        }
      }
    },
    [data?.byInventoryAge, setInventoryAgeFilter]
  );

  const handleMakeBarClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const makeId = data?.byMaker[dataIndex].id;
        if (!isNil(makeId)) {
          setMakeId(makeId);
        }
      }
    },
    [data?.byMaker, setMakeId]
  );

  const handleModelBarClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const modelId = data?.byModel[dataIndex].id;
        if (!isNil(modelId)) {
          setModelId(modelId);
        }
      }
    },
    [data?.byModel, setModelId]
  );

  const vehicleChartHeight = useMemo(() => {
    if (data?.byMaker && data.byMaker.length >= 450) {
      return `${data.byMaker.length * 30}`;
    }

    return '450px';
  }, [data?.byMaker]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getData = async () => {
      try {
        const filter: VehicleFilter = {
          startDate: filters.startDate.utc().toDate(),
          endDate: filters.endDate.utc().toDate(),
          organizationIds: !isNil(filters.locationId)
            ? [filters.locationId]
            : filters.selectedOrganisations.map(({ id }) => id),
          makeIds: !isNil(filters.makeId) ? [filters.makeId] : [],
          modelIds: !isNil(filters.modelId) ? [filters.modelId] : [],
          technicalConditionId: !isNil(filters.technicalConditionId)
            ? filters.technicalConditionId
            : null,
          statusIds: !isNil(filters.statusId) ? [filters.statusId] : [],
          inventoryAgeFilters: !isNil(filters.inventoryAgeFilter)
            ? [filters.inventoryAgeFilter]
            : [],
          fuelTypeIds: !isNil(filters.fuelId) ? [filters.fuelId] : [],
          tractionTypeIds: !isNil(filters.tractionTypeId) ? [filters.tractionTypeId] : [],
          transmissionTypeIds: !isNil(filters.transmissionTypeId)
            ? [filters.transmissionTypeId]
            : []
        };

        if (filter.startDate && filter.endDate && filter.organizationIds.length) {
          const result = await context.vehicleService.GetDashboardData(filter, source.token);

          if (result) {
            const finalResult: VehicleResult = {
              ...result,
              byMaker: !isNil(filters.makeId) ? data?.byMaker || [] : result.byMaker,
              byModel: !isNil(filters.modelId) ? data?.byModel || [] : result.byModel,
              byStatusType: !isNil(filters.statusId)
                ? data?.byStatusType || []
                : result.byStatusType,
              byTechnicalCondition: !isNil(filters.technicalConditionId)
                ? data?.byTechnicalCondition || []
                : result.byTechnicalCondition,
              byInOutAndTechnicalCondition: !isNil(filters.technicalConditionId)
                ? data?.byInOutAndTechnicalCondition || []
                : result.byInOutAndTechnicalCondition,
              byInventoryAge: !isNil(filters.inventoryAgeFilter)
                ? data?.byInventoryAge || []
                : result.byInventoryAge,
              byFuelType: !isNil(filters.fuelId) ? data?.byFuelType || [] : result.byFuelType,
              byTransmissionType: !isNil(filters.transmissionTypeId)
                ? data?.byTransmissionType || []
                : result.byTransmissionType,
              byTractionType: !isNil(filters.tractionTypeId)
                ? data?.byTractionType || []
                : result.byTractionType,
              byOrganization: filters.locationId
                ? data?.byOrganization || []
                : result.byOrganization
            };
            setData(finalResult);
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    getData();

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.endDate,
    filters.startDate,
    filters.selectedOrganisations,
    context.vehicleService,
    filters.makeId,
    filters.modelId,
    filters.technicalConditionId,
    filters.statusId,
    filters.inventoryAgeFilter,
    filters.fuelId,
    filters.transmissionTypeId,
    filters.tractionTypeId,
    filters.locationId,
    language
  ]);

  const handleCallback = (childData: any) => {
    setUpdatedItems(childData);
  };
  const checkIfUpdatedItemsIsLoaded = () => {
    if (!data?.byOrganization.length) {
      return false;
    }
    if (!updatedItems.length) {
      return true;
    }

    return false;
  };

  const geTechnicalConditionFormatTable = () => {
    const formatTable = [];
    formatTable.push({
      title: '',
      render: (rowData: any) => rowData.displayName
    });
    formatTable.push({
      title: context.translatorService.Tranlate('DASHBOARD_INTERIOR', 'Interior'),
      render: (rowData: any) => numberFormat(rowData.byInConditionValue, 0, undefined, '')
    });
    formatTable.push({
      title: context.translatorService.Tranlate('DASHBOARD_EXTERIOR', 'Exterior'),
      render: (rowData: any) => numberFormat(rowData.byOutCondition, 0, undefined, '')
    });
    formatTable.push({
      title: context.translatorService.Tranlate('DASHBOARD_MECHANICAL', 'Mecanica'),
      render: (rowData: any) => numberFormat(rowData.byTehnicalConditionValue, 0, undefined, '')
    });

    return formatTable;
  };

  return (
    <div>
      <Paper className="p-4 d-flex justify-content-center">
        <DashboardFilters filters={filters} setFiltersValueByName={setFiltersValueByName} />
        <Button
          className="m-2"
          variant="outlined"
          color="primary"
          disabled={checkIfUpdatedItemsIsLoaded()}
          onClick={() => {
            context.pptService = new PptService(context.translatorService, data);
            context.pptService.addFirstPage(filters, 'vehicle');
            context.pptService.addHorizontalChart(
              data?.byMaker,
              context.translatorService.Tranlate('DASHBOARD_MAKE', 'Producator')
            );
            context.pptService.addTable(constantsData.stockMap, updatedItems, '');
            context.pptService.addHorizontalChart(
              data?.byModel,
              context.translatorService.Tranlate('DASHBOARD_MODEL', 'Model')
            );
            context.pptService.addTable(
              geTechnicalConditionFormatTable(),
              data?.byInOutAndTechnicalCondition,
              context.translatorService.Tranlate(
                'DASHBOARD_TECHNICAL_INVENTORY_STATE',
                'Stare tehnica stoc'
              )
            );
            context.pptService.addTable(
              constantsData.stockTable,
              [
                {
                  id: 99,
                  displayName: context.translatorService.Tranlate(
                    'DASHBOARD_LACKING_INFORMATION',
                    'Cu informatii lipsa'
                  ),
                  value: data?.withMissingInfo
                },
                {
                  id: 98,
                  displayName: context.translatorService.Tranlate(
                    'DASHBOARD_INVENTORY_MORE_THAN_90_DAYS',
                    'In stoc de peste 90 zile'
                  ),
                  value: data?.byInventoryAge.length
                    ? _.sumBy(
                        data?.byInventoryAge.filter(({ id }) => id >= 4),
                        'value'
                      )
                    : 0
                },
                ...data!.byStatusType
              ],
              context.translatorService.Tranlate('DASHBOARD_INVENTORY_DETAILS', 'Detalii stoc')
            );
            const lessThan = context.translatorService.Tranlate(
              'DASHBOARD_LESS_THAN',
              'mai putin de'
            );
            const days = context.translatorService.Tranlate('DASHBOARD_DAYS', 'zile');
            const labels = data?.byInventoryAge
              .slice(0, -1)
              .map((inventoryAge) => `${lessThan} ${inventoryAge.displayName} ${days}`);
            context.pptService.addVerticalChart(
              data?.byInventoryAge.slice(0, -1),
              `${context.translatorService.Tranlate(
                'DASHBOARD_AVERAGE_INVENTORY_AGE',
                'Vehcime medie stoc'
              )}: ${data?.averageInventoryAge} ${context.translatorService.Tranlate(
                'DASHBOARD_DAYS',
                'zile'
              )}`,
              labels
            );
            context.pptService.addCircleChart(
              data?.byFuelType,
              context.translatorService.Tranlate('DASHBOARD_FUEL', 'Combustibil')
            );
            context.pptService.addCircleChart(
              data?.byTransmissionType,
              context.translatorService.Tranlate('DASHBOARD_TRANSMISSION', 'Transmisie')
            );
            context.pptService.addCircleChart(
              data?.byTractionType,
              context.translatorService.Tranlate('DASHBOARD_TRACTION', 'Tractiune')
            );
            context.pptService.exportPpt();
          }}
        >
          Export
        </Button>
      </Paper>
      {data ? (
        <>
          <div className="mt-4">
            <h4>{`${context.translatorService.Tranlate(
              'DASHBOARD_INVENTORY_STRUCTURE',
              'Structura stoc la data de'
            )} ${moment().toDate().toLocaleDateString(language)}`}</h4>
            <Grid container spacing={2}>
              <VehicleAverages
                count={data.count}
                averageMileage={data.averageMileage}
                averageVehicleAge={data.averageVehicleAge}
              />
              <VehicleDashboardAppliedFilters
                filters={filters}
                data={data}
                resetInventoryFilters={resetInventoryFilters}
                setFiltersValueByName={setFiltersValueByName}
              />
            </Grid>
          </div>
          <div className="mt-2">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card style={{ height: vehicleChartHeight, overflowY: 'auto' }}>
                  <VehicleBarChart
                    title={context.translatorService.Tranlate('DASHBOARD_MAKE', 'Producator')}
                    selectedId={filters.makeId}
                    items={data.byMaker}
                    handleBarClick={handleMakeBarClick}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className="h-100">
                  <VehicleMap
                    vehicleLocationCount={data.byOrganization}
                    setLocationId={setLocationId}
                    locationId={filters.locationId}
                    parentCallback={handleCallback}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ height: vehicleChartHeight, overflowY: 'auto' }}>
                  <VehicleBarChart
                    title={context.translatorService.Tranlate('DASHBOARD_MODEL', 'Model')}
                    selectedId={filters.modelId}
                    items={data.byModel}
                    handleBarClick={handleModelBarClick}
                  />
                </Card>
              </Grid>
            </Grid>
          </div>
          <div className="mt-2">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <VehicleConditionTable
                  items={data.byInOutAndTechnicalCondition}
                  technicalConditionId={filters.technicalConditionId}
                  setTechnicalCondtionId={setTechnicalConditionId}
                />
              </Grid>
              <Grid item xs={6}>
                <VehicleStockTable
                  byInventoryAge={data.byInventoryAge}
                  withMissingInfo={data.withMissingInfo}
                  items={data.byStatusType}
                  statusId={filters.statusId}
                  setStatusId={setStatusId}
                />
              </Grid>
            </Grid>
          </div>
          <div className="mt-2">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <VehicleAverageAgeBarChart
                    title={`${context.translatorService.Tranlate(
                      'DASHBOARD_AVERAGE_INVENTORY_AGE',
                      'Vehcime medie stoc'
                    )}: ${data.averageInventoryAge} ${context.translatorService.Tranlate(
                      'DASHBOARD_DAYS',
                      'zile'
                    )}`}
                    items={data.byInventoryAge}
                    handleBarClick={handleInventoryAgeBarClick}
                  />
                </Card>
              </Grid>
            </Grid>
          </div>
          <div className="mt-2">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card className="h-100">
                  <VehicleDonutChart
                    title={context.translatorService.Tranlate('DASHBOARD_FUEL', 'Combustibil')}
                    items={data.byFuelType}
                    handleDonutClick={handleFuelTypeDonutClick}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className="h-100">
                  <VehicleDonutChart
                    title={context.translatorService.Tranlate(
                      'DASHBOARD_TRANSMISSION',
                      'Transmisie'
                    )}
                    items={data.byTransmissionType}
                    handleDonutClick={handleTransmissionTypeDonutClick}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className="h-100">
                  <VehicleDonutChart
                    title={context.translatorService.Tranlate('DASHBOARD_TRACTION', 'Tractiune')}
                    items={data.byTractionType}
                    handleDonutClick={handleTractionTypeDonutClick}
                  />
                </Card>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default VehicleDashboard;
