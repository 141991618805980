import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Organization, OrganizationJobType, OrganizationMaintenancePackage } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, Tabs, Tab, IconButton } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { IReferential } from '../../../interfaces/IReferential';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

interface IOrganizationMaintenancePackagesState {
  organizationMaintenancePackages: OrganizationMaintenancePackage[];
  isLoading: boolean;
  maintenancePackages: IReferential[];
  selectedMaintenancePackage: IReferential[];
  organizationId: number;
}

type IOrganizationMaintenancePackagesProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string;}>;

class OrganizationMaintenancePackages extends React.PureComponent<IOrganizationMaintenancePackagesProps, IOrganizationMaintenancePackagesState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationMaintenancePackages: [],
    isLoading: false,
    maintenancePackages: [],
    organizationId: 0,
    selectedMaintenancePackage: []
  } as IOrganizationMaintenancePackagesState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationMaintenancePackages();
      }
    );
  }

  loadOrganizationMaintenancePackages = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.MaintenancePackage
    );

    const [organizationMaintenancePackagesList, maintenancePackages] = await Promise.all([
      this.organizationService.GetOrganizationMaintenancePackages(hoId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    console.log('organizationMaintenancePackagesList', organizationMaintenancePackagesList);
    console.log('maintenancePackages', maintenancePackages);


    organizationMaintenancePackagesList.forEach((ojt) => {
      const maintenancePackage = maintenancePackages.find((item) => item.id === ojt.maintenancePackageId);

      ojt.maintenancePackage = isNullOrUndefined(maintenancePackage) ? null : maintenancePackage;
    });

    this.setState({
      organizationMaintenancePackages: organizationMaintenancePackagesList,
      maintenancePackages: maintenancePackages.filter((item) => item.isActive === true),
      isLoading: false,
      organizationId: hoId
    });
  };

  
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'maintenancePackage',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_TAB_MAINTENANCE_PACKAGE',
          'Pachet Mentenanta'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Organization) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PARTNERS_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgMaintenancePackage(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  deleteOrgMaintenancePackage = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationMaintenancePackage(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadOrganizationMaintenancePackages();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  addOrganizationMaintenancePackage = async () => {
    try {
      if (
        this.state.organizationMaintenancePackages.findIndex(
          (item) =>
            this.state.selectedMaintenancePackage.findIndex((itemm) => itemm.id === item.maintenancePackageId) !==
            -1
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_PARTNER_EXISTS', 'Partenerul exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      const organizationMaintenancePackages = [];
      for (const maintenancePackage of this.state.selectedMaintenancePackage) {
        organizationMaintenancePackages.push({
          id: 0,
          organizationId: this.state.organizationId,
          maintenancePackageId: maintenancePackage!.id,
          maintenancePackage: maintenancePackage
        } as OrganizationMaintenancePackage);
      }

      await this.organizationService.AddOrganizationMaintenancePackage(organizationMaintenancePackages);

      this.setState(
        {
          isLoading: true,
          selectedMaintenancePackage: []
        },
        () => {
          this.loadOrganizationMaintenancePackages();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  handleOrganizationMaintenancePackageChange = (newValue: any[]) => {
    this.setState({ selectedMaintenancePackage: newValue });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrganizationMaintenancePackage}>
              <Autocomplete
                id="maintenancePackages"
                className="m-2"
                multiple
                options={this.state.maintenancePackages.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedMaintenancePackage}
                onChange={(e: any, newValue: any[]) => this.handleOrganizationMaintenancePackageChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="jobType"
                    value={this.state.selectedMaintenancePackage}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATIONS_TAB_MAINTENANCE_PACKAGE',
                      'Pachet Mentenanta'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('ORGANIZATIONS_TAB_MAINTENANCE_PACKAGES', 'Pachete Mentenanta')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationMaintenancePackages}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationMaintenancePackages as any));
