import * as React from 'react';
import { Route, Switch } from 'react-router';
import Home from './components/Home';
import Login from './components/auth/LogIn';
import LoginCallBack from './components/auth/LogInCallBack';
import RenewCallBack from './components/auth/RenewCallBack';
import AppUser from './components/admin/users/AppUsers';
import UserDetails from './components/admin/users/UserDetails';
import EditRole from './components/admin/users/EditRole';
import AppReferentials from './components/admin/listOfValues/AppReferentials';
import OtherListsOfValues from './components/admin/otherLists/OtherListsOfValues';

import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/nl';
import 'moment/locale/ro';
import 'moment/locale/pl';
import 'moment/locale/sk';

import { IAuthService } from './services/Interfaces/IAuthService';
import { ApplicationContext, AppContext } from './context/Contexts';
import { History } from 'history';
import { connect } from 'react-redux';
import { ApplicationState, AppActionCreators, AppState } from './store';
import MuiTheme from './theme';
import { ThemeProvider, Snackbar } from '@material-ui/core';
import { LeftSidebar } from './layout-blueprints/index';
import { RoleClaimsEnum } from './helpers/Constants';
import './custom.css';
import UserProfile from './components/userProfile/userProfile';
import { ITranslatorService } from './services/Interfaces/ITranslatorService';
import OrganizationAdmin from './components/admin/organizations/OrganizationAdmin';
import HOOrganizations from './components/admin/organizations/HOOrganizations';
import EditOrganization from './components/admin/organizations/EditOrganization';
import GtXML from './components/admin/xmlDocument/GtXML';
import NewCase from './components/newCase/NewCase';
import WorkflowAdmin from './components/admin/workflows/WorkflowAdmin';
import Cases from './components/cases/Cases';
import CaseDetails from './components/cases/CaseDetails';
import Dashboards from './components/dashboards/Dashboards';
import Orders from './components/orders/Orders';
import Vehicles from './components/vehicles/Vehicles';
import DetailsOfCarForm from './components/vehicles/DetailsOfCarForm';
import CertificateOfControlForm from './components/vehicles/CertificateOfControl';
import UserOrganizationInterfaces from './components/settings/UserOrganizationInterfaces';
import VehiclesHistory from './components/vehicles/VehiclesHistory';
import CarClassRentalPrice from './components/admin/car-class-rent-price/CarClassRentalPrice';
import Billing from './components/billing/Billing';
import DgpciBilling from './components/billing/DgpciBilling';
import OrderPartsHistory from './components/order-parts-history/OrderPartsHistory';
import MakeList from './components/nomenclature/make/MakeList';
import ModelList from './components/nomenclature/model/ModelList';
import FuelTypeList from './components/nomenclature/fuelType/FuelTypeList';
import VersionList from './components/nomenclature/version/VersionList';
import BodyTypeList from './components/nomenclature/bodyType/BodyTypeList';
import BodyConditionTypeList from './components/nomenclature/bodyConditionType/BodyConditionTypeList';
import WheelConditionTypeList from './components/nomenclature/wheelConditionType/WheelConditionTypeList';
import InvoiceRangeList from './components/nomenclature/invoiceRange/InvoiceRangeList';
import ProvenanceList from './components/nomenclature/provenance/ProvenanceList';
import ExchangeRateList from './components/nomenclature/exchangeRateList/ExchangeRateList';
import AlcorErrorLogList from './components/nomenclature/alcorErrorLog/AlcorErrorLogList';
import FinancialParameterInfoList from './components/nomenclature/financialParameterInfo/FinancialParameterInfoList';
import SellerDataList from './components/nomenclature/sellerData/SellerDataList';
import MavoVehicles from './components/mavo-vehicles/MavoVehicles';
import MavoVehicleDetailsForm from './components/mavo-vehicles/MavoVehicleDetailsForm';
import MavoVehicleStockDetailsForm from './components/mavo-vehicles/MavoVehicleStockDetailsForm';
import MavoVehiclesAuctions from './components/mavo-vehicles/MavoVehiclesAuctions';
import MavoVehicleDetailsViewForm from './components/mavo-vehicles/MavoVehicleDetailsViewForm';
import MavoVehiclesUserAuctions from './components/mavo-vehicles/MavoVehiclesUserAuctions';
import MavoVehiclesAdminAuctions from './components/mavo-vehicles/MavoVehiclesAdminAuctions';
import MavoVehiclesOrders from './components/mavo-vehicles/MavoVehiclesOrders';
import PoEditor from './components/admin/poeditor/PoEditor';
import MaintenanceCupiOptionalPartComponent from './components/admin/maintenance-cupi-optional-part/MaintenanceCupiOptionalPartComponent';
import VoInvoiceHistory from './components/mavo-vehicles/VoInvoiceHistory';
import MavoAppUsers from './components/admin/users/MavoAppUsers';
import MavoUserDetails from './components/admin/users/MavoUserDetails';
import AlcorParameterInfoList from './components/nomenclature/alcorParameterInfo/AlcorParameterInfoList';
import UserManual from './components/user-manual/UserManual';
import MavoHOOrganizations from './components/admin/organizations/MavoHOOrganizations';
import MavoOrganizationAdmin from './components/admin/organizations/MavoOrganizationAdmin';
import ModelCarClass from './components/admin/model-car-class/ModelCarClass';
import AgendaPage from './components/agenda/AgendaPage';
import AppointmentPage from './components/appointment/AppointmentPage';
import AddNewAppointmentPage from './components/appointment/AddNewAppointmentPage';
import MaintenanceProducerComponent from './components/admin/maintenance-producer/MaintenanceProducerComponent';
import EvaluationPage from './components/evaluation/EvaluationPage';
import EvaluationCarFile from './components/evaluation/EvaluationCarFile';
import EvaluationHistory from './components/evaluation/EvaluationHistory';
import GTWSBilling from './components/billing/GTWSBilling';

type AppProps = AppState & typeof AppActionCreators & History;

class App extends React.PureComponent<AppProps> {
  private authService!: IAuthService;
  private translatorService!: ITranslatorService;

  static contextType = ApplicationContext;

  public async componentDidMount() {
    this.authService = (this.context as AppContext).authenticationService;
    this.translatorService = (this.context as AppContext).translatorService;

    if (
      this.props.location!.pathname === '/callback' ||
      this.props.location!.pathname === '/renewcallback'
    ) {
      return;
    }

    await this.authService.checkState();

    this.translatorService.LoadLanguage();
    await this.props.SetUser();
    await this.props.LoadCurrencyRates();

    this.updateMomentLanguage();
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.language !== this.props.language) {
      this.updateMomentLanguage();
    }
  }

  public updateMomentLanguage = () => {
    const language = this.props.language;
    const languageList: any = { ro: 'ro', cz: 'cs', pl: 'pl', nl: 'nl', en: 'en', sk: 'sk'};

    
    moment.locale(language ? languageList[language] : 'ro');
    moment.updateLocale(language ? languageList[language] : 'ro', { week: { dow: 1, doy: 4 } });

    };

  private handleCloseMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.Message(false);
  };

  public hasRight = (right: string): boolean => {
    return this.props.user!.profile.role.includes(right);
  };

  public renderUserRoutes = () => {
    if (this.props.user === null) {
      return;
    }

    return (
      <React.Fragment>
        {this.hasRight(RoleClaimsEnum.Home) ? <Route exact path="/" component={Home} /> : null}
        {this.hasRight(RoleClaimsEnum.NewCase) ? (
          <Route exact path="/newCase" component={NewCase} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Agenda) ? (
          <Route exact path="/newCase/:id" component={NewCase} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Case) ? (
          <Route exact path="/cases" component={Cases} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Case) ? (
          <Route exact path="/cases/:id" component={CaseDetails} />
        ) : null}
        {/* { this.hasRight(RoleClaimsEnum.Case) ? <Route exact path='/cases/:id/comments' component={CaseDetails} /> : null } */}
        {this.hasRight(RoleClaimsEnum.Case) ? (
          <Route exact path="/cases/:id/:formId" component={CaseDetails} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Case) ? (
          <Route exact path="/cases/:id/:formId/:calcId" component={CaseDetails} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Case) ? (
          <Route exact path="/cases/:id/:formId/:calcId/callback" component={CaseDetails} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Dashboard) ? (
          <Route exact path="/dashboards" component={Dashboards} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Evaluation) ? (
          <Route exact path="/valuation" component={EvaluationPage} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Evaluation) ? (
          <Route exact path="/valuation/history" component={EvaluationHistory} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Case) ? (
          <Route exact path="/valuation/file/:id" component={EvaluationCarFile} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Order) ? (
          <Route exact path="/orders" component={Orders} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Order) ? (
          <Route exact path="/order-parts-history" component={OrderPartsHistory} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Vehicle) ? (
          <Route exact path="/vehicles" component={Vehicles} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Agenda) ? (
          <Route exact path="/agenda" component={AgendaPage} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Agenda) ? (
          <Route exact path="/appointment/:id" component={AppointmentPage} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Agenda) ? (
          <Route exact path="/appointment" component={AddNewAppointmentPage} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Vehicle) ? (
          <Route exact path="/vehicles-history" component={VehiclesHistory} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Vehicle) ? (
          <Route exact path="/vehicle/:id/:type" component={DetailsOfCarForm} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Vehicle) ? (
          <Route exact path="/vehicle/:id" component={DetailsOfCarForm} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Vehicle) ? (
          <Route exact path="/certificate" component={CertificateOfControlForm} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.Settings) ? (
          <Route exact path="/settings/interfaces" component={UserOrganizationInterfaces} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminUsers) ? (
          <Route path="/admin/users" exact component={AppUser} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/admin/mavo/users" exact component={MavoAppUsers} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminUsers) ? (
          <Route path="/admin/users/:id" component={UserDetails} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/admin/mavo/users/:id" component={MavoUserDetails} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminUsers) ||
        this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/admin/roles" exact component={AppUser} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminUsers) ||
        this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/admin/roles/:id" component={EditRole} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminValueList) ? (
          <Route path="/admin/listOfValues" component={AppReferentials} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminValueList) ? (
          <Route path="/admin/otherListOfValues" component={OtherListsOfValues} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminOrganization) ||
        this.hasRight(RoleClaimsEnum.AdminCountryOrganization) ? (
          <Route path="/admin/hoorganizations" exact component={HOOrganizations} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminOrganization) ||
        this.hasRight(RoleClaimsEnum.AdminCountryOrganization) ||
        this.hasRight(RoleClaimsEnum.AdminService) ? (
          <Route path="/admin/hoorganizations/:id" exact component={OrganizationAdmin} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminOrganization) ||
        this.hasRight(RoleClaimsEnum.AdminCountryOrganization) ||
        this.hasRight(RoleClaimsEnum.AdminService) ? (
          <Route path="/admin/organizations/:id" component={EditOrganization} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminOrganization) ? (
          <Route
            path="/admin/hoorganizations/:id/workflow/:workflowId"
            exact
            component={WorkflowAdmin}
          />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminUsers) ||
        this.hasRight(RoleClaimsEnum.MavoAdministrator) ||
        this.hasRight(RoleClaimsEnum.AdminCountryOrganization) ? (
          <Route path="/admin/poeditor" exact component={PoEditor} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminOrganization) ? (
          <Route path="/admin/xml" exact component={GtXML} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminValueList) ? (
          <Route path="/admin/car-class-rent-price" component={CarClassRentalPrice} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminValueList) ? (
          <Route path="/admin/model-car-class" component={ModelCarClass} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.ManagerExport) || this.hasRight(RoleClaimsEnum.Admin) ? (
          <Route exact path="/billing" component={Billing} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.ManagerExport) || this.hasRight(RoleClaimsEnum.Admin) ? (
          <Route exact path="/billing-dgpci" component={DgpciBilling} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.ManagerExport) || this.hasRight(RoleClaimsEnum.Admin) ? (
          <Route exact path="/billing-ws" component={GTWSBilling} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminValueList) ? (
          <Route
            path="/admin/maintenance-cupi-part"
            component={MaintenanceCupiOptionalPartComponent}
          />
        ) : null}
        {this.hasRight(RoleClaimsEnum.AdminValueList) ? (
          <Route path="/admin/maintenance-producer" component={MaintenanceProducerComponent} />
        ) : null}
        <Route path="/profile" component={UserProfile} />

        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/fuelType" component={FuelTypeList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/version" component={VersionList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/bodyType" component={BodyTypeList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/bodyConditionType" component={BodyConditionTypeList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/wheelConditionType" component={WheelConditionTypeList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/invoice-range" component={InvoiceRangeList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/provenance" component={ProvenanceList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/currency" component={ExchangeRateList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ||
        this.hasRight(RoleClaimsEnum.MavoContab) ? (
          <Route path="/nomenclature/alcor-error-log" component={AlcorErrorLogList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ||
        this.hasRight(RoleClaimsEnum.MavoContab) ? (
          <Route path="/nomenclature/alcor-parameters" component={AlcorParameterInfoList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/financial-parameter" component={FinancialParameterInfoList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/nomenclature/seller-data" component={SellerDataList} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route exact path="/mavo-vehicles" component={MavoVehicles} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoVehicle) ? (
          <Route exact path="/mavoVehicle/:id" component={MavoVehicleDetailsForm} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoVehicle) ? (
          <Route exact path="/mavoVehicleStock/:id" component={MavoVehicleStockDetailsForm} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAgent) ? (
          <Route exact path="/auctions" component={MavoVehiclesAuctions} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAgent) ? (
          <Route exact path="/user/auctions" component={MavoVehiclesUserAuctions} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route exact path="/admin/auctions" component={MavoVehiclesAdminAuctions} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAgent) ||
        this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route exact path="/mavo/orders" component={MavoVehiclesOrders} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAgent) ||
        this.hasRight(RoleClaimsEnum.MavoContab) ||
        this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route exact path="/invoice-history" component={VoInvoiceHistory} />
        ) : null}
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/admin/mavo/hoorganizations" exact component={MavoHOOrganizations} />
        ) : null}

        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
          <Route path="/admin/mavo/hoorganizations/:id" exact component={MavoOrganizationAdmin} />
        ) : null}
        <Route exact path="/user-manual" component={UserManual} />
      </React.Fragment>
    );
  };

  public render() {
    return (
      <ThemeProvider theme={MuiTheme}>
        <LeftSidebar>
          <Switch>
            <Route path="/authentication" component={Login} />
            <Route path="/callback" component={LoginCallBack} />
            <Route path="/renewcallback" component={RenewCallBack} />
            {this.renderUserRoutes()}
            {/* <Redirect from="/*" to="/" /> */}
          </Switch>
          <Snackbar
            open={this.props.showMessage}
            autoHideDuration={1500}
            onClose={this.handleCloseMessage}
          ></Snackbar>
        </LeftSidebar>
      </ThemeProvider>
    );
  }
}

export default connect((state: ApplicationState) => state.app, AppActionCreators)(App as any);
