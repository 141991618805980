import React, { ChangeEvent } from 'react';
import { Box, TextField, Button, Typography } from '@material-ui/core';
import { ContactFormData } from '../agenda/agenda.types';
import { TranslatorService } from '../../services/TranslatorService';

interface ContactFormProps {
  formData: ContactFormData;
  onChange?: (formData: ContactFormData) => void;
  disabled?: boolean;
  type?:string;
}

const ContactForm: React.FC<ContactFormProps> = ({ formData, onChange, disabled, type}) => {
  const translatorService = new TranslatorService();
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: keyof ContactFormData
  ) => {
    if(!onChange){
      return;
    }
    const { value } = event.target;
    const newFormData = { ...formData, [field]: value };
    onChange(newFormData);
  };

  const handleSendClick = () => {
    // TODO: send data to carfix
  };


  const ContactFormDetails = () => {
    return <Box px={1} style={{ border: `2px solid ${disabled ? '#00000061' : '#1b6ec2'}`, borderRadius: 10, padding: 8, borderWidth: 2, display: 'flex', flexDirection: 'column', height:'90%'  }}>
      <Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_FIRST_NAME', "First Name") }: ${formData.firstName}`}</Typography>
      <Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_LAST_NAME', "Last Name") }: ${formData.lastName}`}</Typography>
      <Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_PHONE', "Phone No.") }: ${formData.phoneNo}`}</Typography>
      <Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_EMAIL', "Email") }: ${formData.email}`}</Typography>
      {/* <Button
        color="primary"
        onClick={handleSendClick}
        style={{ height: '30px', marginTop: '10px' }}
      >
        {translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_SEND_CARFIX', "Send to carfix")}
      </Button> */}
    </Box>
  }

  const AddContactForm = () =>{
    return <Box
        style={type === 'details' ? {
          display: 'flex',
          flexDirection: 'column',
        } : {
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          flexWrap: 'wrap',
        }}
      >
        <TextField
          label={translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_FIRST_NAME', "First Name")}
          style={{ marginTop: 0, marginBottom: 12 }}
          disabled={disabled}
          variant="outlined"
          margin="normal"
          value={formData.firstName}
          onChange={(e) => handleInputChange(e, 'firstName')}
        />
        <TextField
          label={translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_LAST_NAME', "Last Name")}
          style={{ marginTop: 0, marginBottom: 12 }}
          disabled={disabled}
          variant="outlined"
          margin="normal"
          value={formData.lastName}
          onChange={(e) => handleInputChange(e, 'lastName')}
        />
        <TextField
          label={translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_PHONE', "Phone No.")}
          style={{ marginTop: 0, marginBottom: 12 }}
          disabled={disabled}
          variant="outlined"
          margin="normal"
          value={formData.phoneNo}
          onChange={(e) => handleInputChange(e, 'phoneNo')}
        />
        <TextField
          label={translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_EMAIL', "Email")}
          style={{ marginTop: 0, marginBottom: 12 }}
          disabled={disabled}
          variant="outlined"
          margin="normal"
          value={formData.email}
          onChange={(e) => handleInputChange(e, 'email')}
        />
        {/* <Button
          color="primary"
          onClick={handleSendClick}
          style={{ height: '30px', marginTop: '10px' }}
        >
          {translatorService.Tranlate('APPOINTMENT_CONTACT_FORM_SEND_CARFIX1', "Send to carfix")}
        </Button> */}
      </Box>
  }

  return (<Box style={type === 'details' ? {width:'100%'}:{}}>
    <Typography variant="h5" style={{ marginBottom: '10px', marginTop: 16, textAlign: type === 'details' ? 'left' : 'unset' }}>
      {type === 'details' ? translatorService.Tranlate('APPOINTMENT_CLIENT_TITLE', 'Client') : translatorService.Tranlate('APPOINTMENT_CONTACT_TITLE', 'Contact')}
    </Typography>
    {type === 'details' ? ContactFormDetails(): AddContactForm()}
  </Box>
  );
};

export default ContactForm;
