import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  Button,
  IconButton,
  Grid,
  FormControlLabel,
  Switch,
  Divider,
  TextField,
  Input,
  Typography
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowPartner, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { isNull, isNullOrUndefined } from 'util';
import { CasePaymentHistory, CaseAttachment } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import {
  ParametreCode,
  ReferentialCode,
  AttachmentTypeCode,
  OrganizationTypeCode,
  OfferTypeCode,
  VehicleStatusCodeEnum,
  CurrencyCode
} from '../../helpers/Constants';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import FileSaver from 'file-saver';
import { OrganizationBankAccount } from '../../interfaces/Organization';
import { AppUser } from '../../interfaces/AppUser';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import moment from 'moment';
import { Utils } from '../../helpers/Utils';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import * as R from 'ramda';
import * as IbanTools from 'ibantools';
import { CurrencyEnum } from '../../enums/CurrencyEnum';
import isNil from 'lodash/isNil';
import { Cost, CostAttachment, VehicleCostRequest } from '../../interfaces/Vehicle';

interface ICaseDetailsCaseTabPaymentDetailsFormState {
  comment: string;
  organizations: WorkflowPartner[];
  selectedOrganization: WorkflowPartner | null;
  users: AppUser[];
  selectedUser: AppUser | null;
  attachments: CaseAttachment[];
  paymentTypeId: number | null;
  paymentType: IReferential | null;
  paymentRequestNoId: number | null;
  paymentRequestNo: IReferential | null;
  paymentAmount: number | null;
  paymentAmountWithoutVAT: number | null;
  paymentAmountVAT: number | null;
  currencyRate: number;
  secondPaymentAmount: number | null;
  secondPaymentAmountWithoutVAT: number | null;
  secondCurrency: string | null;
  hasFransiza: boolean;
  installments: number | null;
  bankAccount: string;
  bankName: string;
  IBANList: OrganizationBankAccount[];
  selectedIBAN: OrganizationBankAccount | null;
  isLoading: boolean;
  isLoading2: boolean;
  attachType: IReferential;
  paymentTypeList: IReferential[];
  paymentRequestNoList: IReferential[];
  isOrganizationsDisabled: boolean;
  isUsersDisabled: boolean;
  hasRights: boolean;
  isUserHidden: boolean;
  executing: boolean;
  userRoleId: number | null;
  currencyList: IReferential[];
  currencyType: IReferential | null;
  paymentHistory: CasePaymentHistory[];
  bankList: IReferential[];
  cursorBankAccount: {
    value: string;
    outerValue: string;
    selectionStart: number;
    selectionEnd: number;
  };
  checkIfCurrencyIsEurOrRon: boolean;
  showCostDetails: boolean;
  costTypes: IReferential[];
  costValues: Cost[];
  caseVehicleCosts: Cost[];
}

export interface ExternalCaseDetailsCaseTabPaymentDetailsFormProps {
  workflowForm: WorkflowSectionStepForm;
  withCurrency: boolean;
}

type ICaseDetailsCaseTabPaymentDetailsFormProps =
  ExternalCaseDetailsCaseTabPaymentDetailsFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabPaymentDetailsForm extends React.PureComponent<
  ICaseDetailsCaseTabPaymentDetailsFormProps,
  ICaseDetailsCaseTabPaymentDetailsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private workflowService!: IWorkflowService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private vehicleService!: IVehicleService;
  static contextType = ApplicationContext;
  state = {
    comment: '',
    organizations: [],
    selectedOrganization: null,
    users: [],
    selectedUser: null,
    attachments: [],
    paymentTypeId: null,
    paymentType: null,
    paymentRequestNoId: null,
    paymentRequestNo: null,
    paymentAmount: 0,
    paymentAmountWithoutVAT: 0,
    paymentAmountVAT: null,
    secondPaymentAmount: 0,
    secondPaymentAmountWithoutVAT: 0,
    secondCurrency: null,
    hasFransiza: false,
    installments: 0,
    IBANList: [],
    selectedIBAN: null,
    isLoading: false,
    isLoading2: false,
    attachType: {} as IReferential,
    paymentTypeList: [],
    paymentRequestNoList: [],
    isOrganizationsDisabled: false,
    isUsersDisabled: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    userRoleId: null,
    currencyList: [] as IReferential[],
    currencyType: null,
    paymentHistory: [] as CasePaymentHistory[],
    bankAccount: '',
    bankName: '',
    bankList: [] as IReferential[],
    cursorBankAccount: {
      value: '',
      outerValue: '',
      selectionStart: 0,
      selectionEnd: 0
    },
    checkIfCurrencyIsEurOrRon: false,
    showCostDetails: false,
    costTypes: [] as IReferential[],
    costValues: [] as Cost[],
    caseVehicleCosts: [] as Cost[],
    currencyRate: 1
  } as ICaseDetailsCaseTabPaymentDetailsFormState;

  input = React.createRef<HTMLInputElement>();
  inputTextValidator = React.createRef<TextValidator>();

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    ValidatorForm.addValidationRule('isIbanValid', (value) => {
      if (!value) {
        return true;
      }
      const ibanValidationResult = IbanTools.validateIBAN(value.replace(/\s/g, ''));
      return ibanValidationResult.valid;
    });

    if (this.input.current) {
      this.input!.current!.value = this.state.cursorBankAccount.outerValue;
    }
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCasePaymentDetailsForm();
      }
    );
  }

  public componentDidUpdate(
    prevProps: ICaseDetailsCaseTabPaymentDetailsFormProps,
    prevState: ICaseDetailsCaseTabPaymentDetailsFormState
  ) {
    if (prevState?.currencyType?.code !== this.state.currencyType?.code) {
      const futureCostValues = [...this.state.costValues];
      futureCostValues.forEach((item) => {
        item.currency = this.state.currencyType?.displayName || '';
        item.secondCurrency = this.getSecondCurrency();
        item.secondValue = !item?.value
          ? null
          : this.setSecondValueBasedOnCaseCurrency(item?.value);
      });
      this.setState({ costValues: futureCostValues });
    }
    const { value, selectionStart, selectionEnd } = this.state.cursorBankAccount;
    if (prevState.cursorBankAccount.value !== value) {
      if (this.input && this.input.current) {
        this.input.current.value = value;

        this.input.current.selectionStart = selectionStart;
        this.input.current.selectionEnd = selectionEnd;
        this.input.current.focus();
      }
    }
  }
  updateOnblur() {
    const newBankName =
      this.state.bankList.find(
        (b) => b.code === this.state.cursorBankAccount.value.replace(/\s/g, '').substr(4, 4)
      )?.name || '';
    this.setState({
      bankName: newBankName,
      cursorBankAccount: {
        ...this.state.cursorBankAccount,
        outerValue: this.formatBankAccount(this.state.cursorBankAccount.value.replace(/\s/g, ''))
      }
    });
  }

  loadVehicleOffer = async () => {
    const isSaleOfferType = false;
    const offerTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OfferTye
    );

    const offerTypeList = await this.appReferentialService.Get(offerTypeRef!.baseUrl);

    const offerType = offerTypeList.find(
      (item) =>
        (item.code === OfferTypeCode.SELL && isSaleOfferType) ||
        (item.code === OfferTypeCode.BUY && !isSaleOfferType)
    )!;

    const [offers] = await Promise.all([
      R.isNil(this.props.caseSettingsState.case!.vehicleId)
        ? []
        : this.vehicleService.GetVehicleOffers(
            this.props.caseSettingsState.case!.vehicleId,
            this.props.caseSettingsState.case!.id,
            offerType.id
          )
    ]);
    return offers[0];
    //this.vehicleService.GetVehicleOffers(this.props.caseSettingsState.case?.vehicleId!, this.props.caseSettingsState.case!.id, OfferTypeCode.BUY);
  };

  getSecondCurrency = () => {
    const indexRon = this.state.currencyList.findIndex((item) => item?.code === CurrencyCode.RON);
    const indexEur = this.state.currencyList.findIndex((item) => item?.code === CurrencyCode.EUR);
    if (this.state.currencyType?.code === CurrencyCode.EUR) {
      return this.state.currencyList[indexRon].displayName;
    }

    return this.state.currencyType?.code === CurrencyCode.RON
      ? this.state.currencyList[indexEur]?.displayName
      : '';
  };

  loadCasePaymentDetailsForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );
    const refPaymentType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PaymentType
    );
    const refPaymentRequestNo = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PaymentRequestNo
    );
    const refCurrencyType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Currency
    );
    const refBanks = Referentials.referential.find((item) => item.code === ReferentialCode.Bank);

    const serviceOrg = this.props.caseSettingsState.case!.casePartners.filter(
      (item) => item.organization!.organizationType!.code == OrganizationTypeCode.REP
    );

    const casee = this.props.caseSettingsState.case;
    const [
      workflowPartners,
      bankAccountList,
      attachmentTypes,
      currentCasePayment,
      paymentTypeList,
      paymentRequestNoList,
      currencyList,
      paymentHistoryList,
      banks
    ] = await Promise.all([
      this.workflowService.GetUserWorkflowPartners(
        this.props.caseSettingsState.case!.caseWorkflow.workflowId
      ),
      serviceOrg.length > 0
        ? this.organizationService.GetUserOrganizationBankAccounts(serviceOrg[0].organizationId)
        : [],
      this.appReferentialService.Get(ref!.baseUrl),
      this.caseService.GetCurrentCasePayment(casee!.id, this.props.workflowForm.id),
      this.appReferentialService.Get(refPaymentType!.baseUrl),
      this.appReferentialService.Get(refPaymentRequestNo!.baseUrl),
      this.appReferentialService.Get(refCurrencyType!.baseUrl),
      this.caseService.GetCasePayments(casee!.id, this.props.workflowForm.id),
      this.appReferentialService.Get(refBanks!.baseUrl)
    ]);
    const organizations = await this.organizationService.GetUserOrganizationsByIds(
      workflowPartners.map((item) => item.partnerId)
    );
    const vehicleOffer = await this.loadVehicleOffer();

    const [caseVehicleCosts] = await Promise.all([
      this.vehicleService.GetCostRequests(caseId, null) as any as Cost[]
    ]);

    const currrency = this.props.caseSettingsState.case!.caseParameters.find(
      (item) => item.parameter!.code === ParametreCode.CURRENCY
    )!.value;
    const selectedCurrency =
      currentCasePayment === null || currentCasePayment.currency === null
        ? currrency
        : currentCasePayment.currency;
    const checkIfCurrencyIsEurOrRon =
      selectedCurrency == CurrencyCode.EUR || selectedCurrency == CurrencyCode.RON;
    workflowPartners.forEach((wp) => {
      const partner = organizations.find((item) => item.id === wp.partnerId);
      wp.partner = isNullOrUndefined(partner) ? null : partner;
    });

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    let selectedIban = null;
    if (currentCasePayment !== null) {
      const iban = bankAccountList.find(
        (item) => item.bankAccount === currentCasePayment.bankAccount
      );
      selectedIban = isNullOrUndefined(iban) ? null : iban;
    }

    const users = await this.appUserService.GetUsersInfo(
      paymentHistoryList.map((item) => item.createdBy)
    );
    paymentHistoryList.forEach((item) => {
      const user = users.find((u) => u.id === item.createdBy);
      item.createdByUser = isNullOrUndefined(user) ? null : user;
    });

    const bankAccount =
      currentCasePayment !== null
        ? currentCasePayment.bankAccount
        : casee?.caseClient?.bankAccount || '';
    const bankName =
      banks.find((b) => b.code === (bankAccount ?? '').replace(/\s/g, '').substr(4, 4))?.name || '';

    const futureCostTypes = await this.vehicleService.GetVehicleCostTypes();
    const futureCostValues = futureCostTypes.map((cost) => {
      return {
        caseId: this.props.match.params.id,
        vehicleId: this.props.caseSettingsState.case?.vehicleId || null,
        costTypeId: cost.id,
        costTypeCode: cost.code || '',
        files: [],
        value: null,
        currencyRate: this.state.currencyType
          ? Utils.GetCurrencyRate(
              this.props.appState.currencyRates,
              this.props.withCurrency ? this.state.currencyType!.code! : currrency
            )
          : null,
        currency: this.state.currencyType?.displayName || '',
        secondValue: null,
        secondCurrency: this.getSecondCurrency(),
        caseSectionStepFormId: this.props.workflowForm.id
      };
    });

    this.setState(
      {
        organizations: workflowPartners,
        IBANList: bankAccountList.filter((item) => item.currency!.displayName === currrency),
        attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
        isLoading: false,
        hasRights: hasRights,
        isUserHidden: !hasRights,
        comment: currentCasePayment === null ? '' : currentCasePayment.comment,
        attachments: [],
        paymentTypeId:
          currentCasePayment === null || currentCasePayment.paymentTypeId === null
            ? null
            : currentCasePayment.paymentTypeId,
        paymentType:
          currentCasePayment === null || currentCasePayment.paymentType === null
            ? null
            : currentCasePayment.paymentType,
        paymentAmount:
          currentCasePayment === null || currentCasePayment.paymentAmount === null
            ? 0
            : currentCasePayment.paymentAmount,
        paymentRequestNoId:
          currentCasePayment === null || currentCasePayment.paymentRequestNoId === null
            ? null
            : currentCasePayment.paymentRequestNoId,
        paymentRequestNo:
          currentCasePayment === null || currentCasePayment.paymentRequestNo === null
            ? null
            : currentCasePayment.paymentRequestNo,
        hasFransiza: currentCasePayment === null ? false : currentCasePayment.hasFransiza,
        installments:
          currentCasePayment === null || currentCasePayment.installments === null
            ? 0
            : currentCasePayment.installments,
        cursorBankAccount: {
          ...this.state.cursorBankAccount,
          outerValue: bankAccount
        },
        paymentRequestNoList: paymentRequestNoList,
        paymentTypeList: paymentTypeList,
        paymentAmountVAT: parseFloat(vatParameter[0]!.value),
        currencyType: currencyList.find((item) => item.code === selectedCurrency)!,
        currencyList: currencyList,
        paymentAmountWithoutVAT:
          !R.isNil(currentCasePayment) && currentCasePayment.paymentAmountWithoutVAT
            ? currentCasePayment.paymentAmountWithoutVAT
            : vehicleOffer?.offerValue,
        currencyRate: vehicleOffer?.currencyRate,
        secondPaymentAmountWithoutVAT:
          !R.isNil(currentCasePayment) && currentCasePayment.secondPaymentAmountWithoutVAT
            ? currentCasePayment.secondPaymentAmountWithoutVAT
            : vehicleOffer?.secondOfferValue,
        secondCurrency:
          !R.isNil(currentCasePayment) && currentCasePayment.secondCurrency
            ? currentCasePayment.secondCurrency
            : checkIfCurrencyIsEurOrRon
            ? selectedCurrency == CurrencyCode.EUR
              ? CurrencyCode.RON
              : CurrencyCode.EUR
            : null,
        paymentHistory: paymentHistoryList,
        bankList: banks,
        bankName: bankName,
        checkIfCurrencyIsEurOrRon: checkIfCurrencyIsEurOrRon,
        costTypes: futureCostTypes,
        costValues: futureCostValues,
        caseVehicleCosts: caseVehicleCosts
      },
      this.setCaseAllocation
    );
  };

  setCaseAllocation = async () => {
    if (!this.state.hasRights) {
      return;
    }

    const allocationResult = await FormHelpers.ParseAllocations(
      this.props.workflowForm.workflowFormActions,
      this.state.organizations,
      this.appUserService
    );

    if (isNullOrUndefined(allocationResult)) {
      const partner = this.state.organizations.find(
        (item) => item.partnerId === this.props.caseSettingsState.case!.caseStatus.assignedTo!.hoId
      );

      this.setState(
        {
          selectedOrganization: isNullOrUndefined(partner) ? null : partner,
          selectedUser: this.props.caseSettingsState.case!.caseStatus.assignedTo,
          organizations: this.state.organizations,
          isUsersDisabled: false,
          isOrganizationsDisabled: false
        },
        async () =>
          await this.loadUsers(isNullOrUndefined(partner) ? null : partner.partnerId, null)
      );
      return;
    }
    if (allocationResult.isLastUser || allocationResult.caseStatusId != null) {
      this.setState({ isUserHidden: true });
      return;
    }
    this.setState(
      {
        selectedOrganization: allocationResult.partner,
        selectedUser: allocationResult.user,
        organizations: allocationResult.partners,
        isUsersDisabled: !isNullOrUndefined(allocationResult.user),
        isOrganizationsDisabled: !isNullOrUndefined(allocationResult.partner),
        userRoleId: allocationResult.roleId
      },
      async () => {
        if (allocationResult.partner === null) {
          return;
        }
        await this.loadUsers(allocationResult.partner.partnerId, allocationResult.roleId);
      }
    );
  };

  loadUsers = async (organizationId: number | null, roleId: number | null) => {
    if (organizationId === null) {
      this.setState({
        isLoading2: false
      });
    } else {
      const users = await this.appUserService.GetUserAppUsers();
      this.setState({
        users: users.users.filter(
          (user) =>
            (user.organization!.hoId === organizationId ||
              user.organizationId === organizationId) &&
            ((roleId !== null && user.roleId === roleId) || roleId === null)
        ),
        isLoading2: false
      });
    }
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const attachments = [...this.state.attachments];
      attachments.push({
        id: this.state.attachments.length,
        caseId: this.props.caseSettingsState.case!.id,
        attachmentTypeId: this.state.attachType.id,
        attachmentType: this.state.attachType,
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        uploadDate: new Date(),
        carPhotos: false,
        originalFileName: e.target.files![0].name,
        caseRequestId: null,
        paymentId: null,
        serviceAppointmentId: null,
        caseClientId: null,
        isSelection: false
      });

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleUploadCostAttachment = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    try {
      const futureCostValues = [...this.state.costValues];
      futureCostValues[index].files.push({
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        originalFileName: e.target.files![0].name
      } as any as CostAttachment);

      this.setState({ costValues: futureCostValues });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteCostAttachment = async (index: number, indexAttachment: number) => {
    try {
      const futureCostValues = [...this.state.costValues];
      futureCostValues[index].files.splice(indexAttachment, 1);

      this.setState({
        costValues: futureCostValues
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9}>
          {this.state.attachments.length !== 0
            ? this.state.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        disabled={!this.state.hasRights}
                        onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        disabled={!this.state.hasRights}
                        onClick={(e) => this.handleDeleteAttachment(attach.id, attach.fileName)}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
        <Grid item xs={3} container justify="flex-end" direction="column">
          <input
            className="d-none"
            id={'contained-button-file'}
            onChange={(e) => {
              return this.handleUploadAttachment(e);
            }}
            type="file"
            disabled={!this.state.hasRights}
          />
          <label htmlFor={'contained-button-file'}>
            <Button
              variant="contained"
              color="primary"
              className="d-block text-center shadow-none"
              disabled={!this.state.hasRights}
              component="div"
            >
              {this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_FORM_ATTACH', 'Ataseaza')}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  };

  renderAttachmentBoxForCosts = (costIndex: number, attachments: CostAttachment[]) => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9}>
          {attachments.length !== 0
            ? attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        disabled={!this.state.hasRights}
                        onClick={(e) =>
                          this.downloadVehicleCostFile(attach.fileName, attach.originalFileName)
                        }
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        disabled={!this.state.hasRights}
                        onClick={(e) => this.handleDeleteCostAttachment(costIndex, index)}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
      </Grid>
    );
  };

  sendEmail = async (sendCost: boolean) => {
    if (sendCost) {
      this.state.costValues.forEach(async (cost) => {
        if (cost.value) {
          await this.vehicleService.AddCost(cost);
        }
      });
    }
    try {
      this.setState({ executing: true });
      const currrency = this.props.caseSettingsState.case!.caseParameters.find(
        (item) => item.parameter!.code === ParametreCode.CURRENCY
      )!.value;

      const paymentDetailsFormData: CasePaymentHistory = {
        id: 0,
        caseId: Number.parseInt(this.props.match.params.id),
        comment: this.state.comment,
        attachments: this.state.attachments,
        organizationId: this.state.isUserHidden
          ? this.props.caseSettingsState.case!.caseStatus.assignedTo!.organizationId
          : this.state.selectedOrganization!.partnerId,
        userToId: this.state.isUserHidden
          ? this.props.caseSettingsState.case!.caseStatus.assignedToId
          : this.state.selectedUser!.id!,
        paymentTypeId: this.state.paymentTypeId,
        paymentRequestNoId: this.state.paymentRequestNoId,
        paymentAmount: this.props.withCurrency == false ? this.state.paymentAmount : this.getSumWithVAT(), //this.state.paymentAmount,
        hasFransiza: this.state.hasFransiza,
        installments: this.state.installments,
        bankAccount: this.state.cursorBankAccount.outerValue, //isNullOrUndefined(this.state.selectedIBAN) ? "" : this.state.selectedIBAN!.bankAccount,
        caseSectionStepFormId: this.props.workflowForm.id,
        currency: this.props.withCurrency ? this.state.currencyType!.code! : currrency,
        currencyRate: this.state.checkIfCurrencyIsEurOrRon
          ? this.state.currencyRate
          : Utils.GetCurrencyRate(
              this.props.appState.currencyRates,
              this.props.withCurrency ? this.state.currencyType!.code! : currrency
            ),
        secondCurrency: this.state.checkIfCurrencyIsEurOrRon ? this.state.secondCurrency : null,
        secondPaymentAmount: this.state.checkIfCurrencyIsEurOrRon
          ? this.getSecondSumWithVAT()
          : null,
        secondPaymentAmountWithoutVAT: this.state.checkIfCurrencyIsEurOrRon
          ? this.state.secondPaymentAmountWithoutVAT
          : null,
        paymentAmountWithoutVAT: this.state.checkIfCurrencyIsEurOrRon
          ? this.state.paymentAmountWithoutVAT
          : null
      } as CasePaymentHistory;

      await this.caseService.AddCasePayment(paymentDetailsFormData);

      const vehicleId = this.props.caseSettingsState.case?.vehicleId;

      if (vehicleId) {
        await this.vehicleService.ChangeVehicleStatus({
          vehicleId,
          statusId: VehicleStatusCodeEnum.Available,
          comment: ''
        });
      }

      await this.loadCaseVehicleCosts();

      const selectedOrganization = this.state.selectedOrganization;
      const hoId =
        selectedOrganization === null
          ? null
          : selectedOrganization.partner!.hoId === null
          ? selectedOrganization.partnerId
          : selectedOrganization.partner!.hoId;

      await this.submitForm(
        paymentDetailsFormData.caseId,
        paymentDetailsFormData.caseSectionStepFormId,
        this.state.selectedUser
      );

      await this.setCaseAllocation();

      await this.loadPayments();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  loadPayments = async () => {
    const payments = await this.caseService.GetCasePayments(
      this.props.caseSettingsState.case!.id,
      this.props.workflowForm.id
    );

    const users = await this.appUserService.GetUsersInfo(payments.map((item) => item.createdBy));
    payments.forEach((item) => {
      const user = users.find((u) => u.id === item.createdBy);
      item.createdByUser = isNullOrUndefined(user) ? null : user;
    });

    this.setState({
      paymentHistory: payments
    });
  };

  loadCaseVehicleCosts = async () => {
    const [caseVehicleCosts] = await Promise.all([
      this.vehicleService.GetCostRequests(
        this.props.caseSettingsState.case!.id,
        null
      ) as any as Cost[]
    ]);

    this.setState({
      caseVehicleCosts: caseVehicleCosts
    });
  };
  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  handleOrganizationChange = (newValue: WorkflowPartner | null) => {
    this.setState(
      {
        selectedOrganization: newValue,
        selectedUser: null,
        users: [],
        isLoading2: true
      },
      async () => {
        await this.loadUsers(newValue === null ? null : newValue.partnerId, this.state.userRoleId);
      }
    );
  };

  handleUserChange = (newValue: AppUser | null) => {
    this.setState({ selectedUser: newValue });
  };
  handleBanckAccountChange = (newValue: OrganizationBankAccount | null) => {
    this.setState({ selectedIBAN: newValue });
  };

  formatBankAccount = (bankAccountValue: string) => {
    return bankAccountValue.length > 5
      ? bankAccountValue
          //.replace(/[^\dA-Z]/g, '')
          .replace(/(.{4})/g, '$1 ')
          .trim()
      : bankAccountValue;
  };

  handleBankAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //const newBankName = this.state.bankList.find(b => b.code === e.target.value.replace(/\s/g, '').substr(4, 4))?.name || '';
    //const newBankAccount = this.formatBankAccount(newValue);

    // save cursor position
    this.setState({
      ...this.state,
      cursorBankAccount: {
        ...this.state.cursorBankAccount,
        value: e.target.value,
        selectionStart: e.target.selectionStart!,
        selectionEnd: e.target.selectionEnd!
      }
    });

    //e.persist();
    //this.setState({ bankName: newBankName });
    // e.target.selectionStart = newCursor;
    // e.target.selectionEnd = newCursor;
    //console.log("after: newCursor " + newCursor + " sStart : " + e.target.selectionStart + "  sEnd: " + e.target.selectionEnd);
    //});

    //e.target.selectionStart = newCursor;
    //e.target.selectionEnd = newCursor;
    //() => {
    //  e.target.selectionStart = oldSelection;
    //  e.target.selectionEnd = oldSelection;
    //  console.log("after: oldSelection " + oldSelection + "  cursorOffset: " + cursorOffset + "sstart : " + e.target.selectionStart + "  sEnd: " + e.target.selectionEnd);
    //});
  };

  handlePaymentTypeChange = (newValue: IReferential | null) => {
    this.setState({
      paymentType: newValue,
      paymentTypeId: newValue === null ? null : newValue.id
    });
  };

  handleRequestNoChange = (newValue: IReferential | null) => {
    this.setState({
      paymentRequestNo: newValue,
      paymentRequestNoId: newValue === null ? null : newValue.id
    });
  };

  handlePaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      paymentAmount: Number.isNaN(Number.parseFloat(e.target.value))
        ? null
        : Number.parseFloat(e.target.value)
    });
  };

  setSecondValueBasedOnCaseCurrency(value: number): number | null {
    // console.log(this.props.appState.currencyRates)
    let resultValue = null;
    if (
      this.state.currencyType?.code == CurrencyCode.EUR ||
      this.state.currencyType?.code == CurrencyCode.RON
    ) {
      resultValue = 0;
      const previousCurrencyCode = this.state.currencyType?.code;
      const futureCurrencyCode =
        this.state.currencyType?.code == CurrencyCode.EUR ? CurrencyCode.RON : CurrencyCode.EUR;
      if (!R.isNil(value) && previousCurrencyCode) {
        let previousCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          previousCurrencyCode
        );
        let futureCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          futureCurrencyCode
        );

        if (previousCurrencyCode == CurrencyEnum.EUR) {
          previousCurrencyRate = this.state.currencyRate;
        } else if (previousCurrencyCode == CurrencyCode.RON) {
          futureCurrencyRate = this.state.currencyRate;
        }

        resultValue = value * (previousCurrencyRate / futureCurrencyRate);

        return Number.parseFloat(resultValue.toFixed(2));
      }
    }

    return resultValue;
  }

  handlePaymentAmountWithoutVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      paymentAmountWithoutVAT: Number.isNaN(Number.parseFloat(e.target.value))
        ? null
        : Number.parseFloat(e.target.value),
      secondPaymentAmountWithoutVAT: this.setSecondValueBasedOnCaseCurrency(
        Number.parseFloat(e.target.value)
      )
    });
  };

  handlePaymentAmountVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      paymentAmountVAT: Number.isNaN(Number.parseFloat(e.target.value))
        ? null
        : Number.parseFloat(e.target.value)
    });
  };

  handleInstallmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      installments: Number.isNaN(Number.parseFloat(e.target.value))
        ? null
        : Number.parseFloat(e.target.value)
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  getSumWithVAT = () => {
    const sumWithoutVat = this.state.paymentAmountWithoutVAT
      ? this.state.paymentAmountWithoutVAT
      : 0;
    const vat = this.state.paymentAmountVAT ? this.state.paymentAmountVAT : 0;
    const vatValue = Number.parseFloat((sumWithoutVat * (vat / 100)).toFixed(2));
    return sumWithoutVat + vatValue;
  };

  getSecondSumWithVAT = () => {
    const sumWithoutVat = this.state.secondPaymentAmountWithoutVAT
      ? this.state.secondPaymentAmountWithoutVAT
      : 0;
    const vat = this.state.paymentAmountVAT ? this.state.paymentAmountVAT : 0;
    const vatValue = sumWithoutVat * (vat / 100);
    return Number.parseFloat((sumWithoutVat + vatValue).toFixed(2));
  };

  handleCurrencyTypeChange = (newValue: IReferential | null) => {
    if (newValue?.code) {
      let paymentAmountWithoutVAT = this.state.paymentAmountWithoutVAT;
      const previousCurrencyCode = this.state.currencyType?.code;

      const checkIfCurrencyIsEurOrRon =
        newValue?.code == CurrencyCode.EUR || newValue?.code == CurrencyCode.RON;

      if (!isNil(paymentAmountWithoutVAT) && previousCurrencyCode) {
        let previousCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          previousCurrencyCode
        );

        let futureCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          newValue.code
        );

        if (newValue?.code == CurrencyCode.EUR || newValue?.code == CurrencyCode.RON) {
          if (newValue?.code == CurrencyEnum.EUR) {
            futureCurrencyRate = this.state.currencyRate;
          } else if (newValue?.code == CurrencyCode.RON) {
            previousCurrencyRate = this.state.currencyRate;
          }
        }

        paymentAmountWithoutVAT =
          paymentAmountWithoutVAT * (previousCurrencyRate / futureCurrencyRate);
      }

      this.setState({
        ...this.state,
        currencyType: newValue,
        paymentAmountWithoutVAT: paymentAmountWithoutVAT,
        checkIfCurrencyIsEurOrRon: checkIfCurrencyIsEurOrRon,
        secondCurrency:
          newValue?.code == CurrencyCode.EUR || newValue?.code == CurrencyCode.RON
            ? newValue?.code == CurrencyCode.EUR
              ? CurrencyCode.RON
              : CurrencyCode.EUR
            : null
      });
    }
  };

  showPaymentHistoryTimeline = () => {
    return this.state.paymentHistory.map((payment, index) => (
      <div key={index} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <p className="timeline-item--content font-weight-bold">
            {moment.utc(payment.dateCreation).local().toDate().toLocaleString() +
              ' (' +
              payment.createdByUser!.email +
              ')'}
          </p>
          <p className="timeline-item--label mb-2 font-weight-bold mt-3">
            {this.translatorService.Tranlate(
              'CASE_PAYMENT_DETAILS_TIMELINE_TOTAL_SUM',
              'Suma totala'
            )}
            :{payment.paymentAmount} {payment.currency}
            {payment.secondPaymentAmount
              ? '(' + payment.secondPaymentAmount + payment.secondCurrency + ')'
              : ''}
          </p>

          <p className="timeline-item--label mb-2 font-weight-bold">
            {this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_TIMELINE_CURRENCY', 'Moneda')}:
            {payment.currency}
          </p>

          {payment.bankAccount ? (
            <p className="timeline-item--label mb-2 font-weight-bold">
              {this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_TIMELINE_BANK_ACCOUNT',
                'Cont bancar'
              )}
              :{payment.bankAccount}
            </p>
          ) : (
            ''
          )}

          <div>{this.renderPaymentAttachmentBox(payment)}</div>
        </div>
      </div>
    ));
  };

  renderPaymentAttachmentBox = (paymentRequest: CasePaymentHistory) => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9}>
          {paymentRequest.attachments.length !== 0
            ? paymentRequest.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left" key={index}>
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
      </Grid>
    );
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const val = e.target.value;
    const futureCostValues = [...this.state.costValues];
    futureCostValues[index].value = Number.isNaN(Number.parseFloat(val))
      ? null
      : Number.parseFloat(val);
    futureCostValues[index].secondValue = Number.isNaN(Number.parseFloat(val))
      ? null
      : this.setSecondValueBasedOnCaseCurrency(Number.parseFloat(val));
    this.setState({ costValues: futureCostValues });
  };

  getLabel = (item: Cost) => {
    if (!item.value) {
      return '';
    }
    const valueWithoutVat = this.translatorService.Tranlate(
      'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_WITHOUT_VAT_LABEL',
      'Suma fara TVA'
    );
    return `${valueWithoutVat} | ${item?.value?.toFixed(2)} ${item?.currency}${
      item?.secondCurrency && `( ${item?.secondValue} ${item?.secondCurrency})`
    }`;
  };

  uploadAttachment = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    try {
      const futureCostValues = [...this.state.costValues];
      // futureCostValues[index].files = e.target.files![0];
      this.setState({ costValues: futureCostValues });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  deleteAttachment = (index: number) => {
    const futureCostValues = [...this.state.costValues];
    // futureCostValues[index].files = null;
    this.setState({ costValues: futureCostValues });
  };

  renderCostType = (cost: IReferential, index: number) => {
    const indexLang = cost.translations.findIndex(
      (lang) => lang.language === this.translatorService.GetLanguage()
    );
    return (
      <Grid container>
        <Grid className="mt-2">
          <Typography>{cost.translations[indexLang].name}</Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} className="mt-2">
            <TextField
              fullWidth
              id={`outlined-${index}`}
              size="small"
              value={this.state.costValues[index].value}
              disabled={!this.state.hasRights}
              label={this.getLabel(this.state.costValues[index])}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, index)}
              InputProps={{
                inputComponent: this.NumberFormatCustom,
                style: { fontWeight: 500 }
              }}
            />
          </Grid>
          {this.state.costValues[index].files.length ? (
            <Grid item xs={4} className="mt-2">
              {this.state.costValues[index].files.length !== 0
                ? this.state.costValues[index].files.map((attach, indexAttachment) => {
                    return (
                      <div key={indexAttachment} className="d-flex flex-row w-100">
                        <div className="w-75 text-left">
                          <Button
                            color="primary"
                            className="text-primary text-left"
                            disabled={!this.state.hasRights}
                            onClick={(e) =>
                              this.downloadVehicleCostFile(attach.fileName, attach.originalFileName)
                            }
                          >
                            <span
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '100px'
                              }}
                            >
                              {attach.originalFileName}
                            </span>
                          </Button>
                        </div>
                        <div className="w-25">
                          <IconButton
                            aria-label="delete"
                            color="inherit"
                            className="text-danger"
                            size="small"
                            disabled={!this.state.hasRights}
                            onClick={(e) => this.handleDeleteCostAttachment(index, indexAttachment)}
                          >
                            <DeleteOutlineTwoToneIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Grid>
          ) : (
            <Grid item xs={4} className="mt-2">
              <Grid item container justify="flex-end" direction="column">
                <input
                  className="d-none"
                  id={`contained-button-file-${index}`}
                  onChange={(e) => {
                    return this.handleUploadCostAttachment(e, index);
                  }}
                  type="file"
                  disabled={!this.state.hasRights}
                />
                <label htmlFor={`contained-button-file-${index}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="d-block text-center shadow-none"
                    disabled={!this.state.hasRights}
                    component="div"
                  >
                    {this.translatorService.Tranlate(
                      'CASE_PAYMENT_DETAILS_FORM_ATTACH',
                      'Ataseaza'
                    )}
                  </Button>
                </label>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* {this.renderAttachmentBoxForCosts(index, this.state.costValues[index].files)} */}
      </Grid>
    );
  };

  downloadVehicleCostFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.vehicleService.GetVehicleCostAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderCostDetails = () => {
    return (
      <>
        <Grid container alignContent="center" alignItems="center">
          <Typography variant="h4">
            {this.translatorService.Tranlate('COSTS', 'Costuri')}
          </Typography>
        </Grid>
        <Grid container alignContent="center" alignItems="center">
          <Typography>
            {this.translatorService.Tranlate('ADD_COSTS', 'Adauga cost aditional')}{' '}
          </Typography>
          <AddCircleOutlineIcon
            onClick={() => {
              this.state.hasRights
                ? this.setState({ showCostDetails: !this.state.showCostDetails })
                : '';
            }}
            style={{ marginLeft: 10 }}
            color="primary"
          />
        </Grid>

        {this.state.showCostDetails &&
          this.state.hasRights &&
          this.state.costTypes.map((type, index) => this.renderCostType(type, index))}

        {this.state.caseVehicleCosts && this.state.caseVehicleCosts.length && !this.state.hasRights
          ? this.renderCostDetailsFromCase()
          : ''}
      </>
    );
  };

  renderCostTypeFromCase = (cost: Cost, index: number) => {
    const indexLang = cost?.costType?.translations.findIndex(
      (lang) => lang.language === this.translatorService.GetLanguage()
    );
    if (indexLang === undefined || indexLang < 0) {
      return <></>;
    }

    return (
      <Grid key={index} container>
        <Grid className="mt-2">
          <Typography>{cost?.costType?.translations[indexLang].name}</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6} className="mt-2">
            <TextField
              fullWidth
              id={`outlined-${index}`}
              size="small"
              value={cost.value}
              label={this.getLabel(cost)}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} className="mt-2">
            {cost.attachments && cost.attachments.length !== 0
              ? cost.attachments.map((attach, index) => {
                  return (
                    <div key={index} className="d-flex flex-row w-100">
                      <div className="w-75 text-left" key={index}>
                        <Button
                          color="primary"
                          className="text-primary text-left"
                          onClick={(e) =>
                            this.downloadVehicleCostFile(attach.fileName, attach.originalFileName)
                          }
                        >
                          <span className="crop">{attach.originalFileName}</span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCostDetailsFromCase() {
    return (
      <div className="mt-4">
        {this.state?.caseVehicleCosts &&
          this.state?.caseVehicleCosts?.map((cost, index) =>
            this.renderCostTypeFromCase(cost, index)
          )}
      </div>
    );
  }
  renderPaymentDetailsWithCurrencyForm = () => {
    const caseHasVAT = this.state.paymentAmountVAT !== 0;

    const priceLabel = caseHasVAT
      ? this.translatorService.Tranlate(
          'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_WITH_VAT_LABEL',
          'Suma cu TVA'
        )
      : this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_TIMELINE_TOTAL_SUM', 'Suma cu TVA');

    return isNullOrUndefined(this.state.comment) ? null : (
      <ValidatorForm
        onSubmit={(e) => {
          this.sendEmail(true);
        }}
      >
        <div className="m-3 text-center">
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="textComment"
            name="textComment"
            className="mb-3"
            multiline
            rows={8}
            placeholder={this.translatorService.Tranlate(
              'CASE_PAYMENT_DETAILS_FORM_WRITE_MESSAGE_PLACEHOLDER',
              'Write your message...'
            )}
            value={this.state.comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ comment: e.target.value });
            }}
            variant="outlined"
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
          />

          {caseHasVAT && (
            <>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="sumWithoutVAT"
                name="sumWithoutVAT"
                className="mb-3"
                //  type="number"
                label={
                  this.state.checkIfCurrencyIsEurOrRon && this.state.secondPaymentAmountWithoutVAT
                    ? this.translatorService.Tranlate(
                        'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_WITHOUT_VAT_LABEL',
                        'Suma fara TVA'
                      ) +
                      ' | ' +
                      this.state.paymentAmountWithoutVAT?.toFixed(2) +
                      ' ' +
                      this.state.currencyType?.code +
                      ' (' +
                      this.state.secondPaymentAmountWithoutVAT?.toFixed(2) +
                      ' ' +
                      this.state.secondCurrency +
                      ')'
                    : this.translatorService.Tranlate(
                        'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_WITHOUT_VAT_LABEL',
                        'Suma fara TVA'
                      )
                }
                value={this.state.paymentAmountWithoutVAT}
                onChange={this.handlePaymentAmountWithoutVATChange}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />

              <TextValidator
                fullWidth
                disabled={true}
                id="VAT"
                name="vat"
                className="mb-3"
                //  type="number"
                label={this.translatorService.Tranlate(
                  'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_VAT_LABEL',
                  'TVA'
                )}
                value={this.state.paymentAmountVAT}
                onChange={this.handlePaymentAmountVATChange}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </>
          )}
          <TextValidator
            fullWidth
            disabled={caseHasVAT ? true : !this.state.hasRights}
            id="sumWithVAT"
            name="sumWithVAT"
            className="mb-3"
            //  type="number"
            label={
              this.state.checkIfCurrencyIsEurOrRon && this.getSecondSumWithVAT()
                ? priceLabel +
                  ' | ' +
                  this.getSumWithVAT().toFixed(2) +
                  ' ' +
                  this.state.currencyType?.code +
                  ' (' +
                  this.getSecondSumWithVAT().toFixed(2) +
                  ' ' +
                  this.state.secondCurrency +
                  ')'
                : priceLabel
            }
            value={this.getSumWithVAT()}
            onChange={this.handlePaymentAmountChange}
            validators={['required', 'minNumber:1']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
            InputProps={{
              inputComponent: this.NumberFormatCustom
            }}
          />

          <Autocomplete
            disabled={!this.state.hasRights}
            id="currency"
            className="m-0 mb-3"
            options={this.state.currencyList}
            disableClearable
            value={this.state.currencyType}
            onChange={(e: any, newValue: IReferential | null) =>
              this.handleCurrencyTypeChange(newValue)
            }
            getOptionLabel={(option: IReferential) => option.displayName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="currency"
                value={this.state.currencyType}
                label={this.translatorService.Tranlate(
                  'CASE_PAYMENT_DETAILS_FORM_PAYMENT_CURRENCY_LABEL',
                  'Moneda'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                // required={true}
              />
            )}
          />
          <TextValidator
            ref={this.inputTextValidator}
            disabled={!this.state.hasRights}
            className="mb-3"
            name="bankAccount"
            label={
              this.state.bankName !== ''
                ? this.state.bankName
                : this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_FORM_IBAN_LABEL', 'IBAN')
            }
            fullWidth
            value={this.state.cursorBankAccount.outerValue || ''}
            onBlur={(e) => this.updateOnblur()}
            validators={['isIbanValid']}
            errorMessages={[
              this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_IBAN_INVALID_WARNING',
                'IBAN-ul este invalid'
              )
            ]}
            InputLabelProps={{
              className:
                this.state.cursorBankAccount.outerValue !== '' ? 'MuiInputLabel-shrink' : ''
            }}
            InputProps={{
              inputComponent: ({ inputRef: {}, ...rest }) => {
                return (
                  <input
                    maxLength={34}
                    ref={this.input}
                    {...rest}
                    type="text"
                    onChange={(e) => this.handleBankAccountChange(e)}
                  />
                );
              }
            }}
          />
          {this.renderAttachmentBox()}
          {this.renderCostDetails()}
          {this.renderAssignementForm()}
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  rendePaymentDetailsForm = () => {
    return isNullOrUndefined(this.state.comment) ? null : (
      <ValidatorForm
        onSubmit={(e) => {
          this.sendEmail(false);
        }}
      >
        <div className="m-3 text-center">
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="textComment"
            name="textComment"
            className="mb-3"
            multiline
            rows={8}
            placeholder={this.translatorService.Tranlate(
              'CASE_PAYMENT_DETAILS_FORM_WRITE_MESSAGE_PLACEHOLDER',
              'Write your message...'
            )}
            value={this.state.comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ comment: e.target.value });
            }}
            variant="outlined"
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
          />

          <Autocomplete
            disabled={!this.state.hasRights}
            id="paymentType"
            className="mb-3"
            options={this.state.paymentTypeList}
            value={this.state.paymentType}
            onChange={(e: any, newValue: IReferential | null) =>
              this.handlePaymentTypeChange(newValue)
            }
            getOptionLabel={(option: IReferential) => option.displayName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="paymentType"
                value={this.state.paymentType}
                label={this.translatorService.Tranlate(
                  'CASE_PAYMENT_DETAILS_FORM_PAYMENT_TYPE_LABEL',
                  'Tip Plata'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />

          {/* <Autocomplete
            disabled={!this.state.hasRights}
            id="paymentRequestNo"
            className="mb-3"
            options={this.state.paymentRequestNoList}
            value={this.state.paymentRequestNo}
            onChange={(e: any, newValue: IReferential | null) =>
              this.handleRequestNoChange(newValue)
            }
            getOptionLabel={(option: IReferential) => option.displayName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="paymentRequestNo"
                value={this.state.paymentRequestNo}
                label={this.translatorService.Tranlate(
                  'CASE_PAYMENT_DETAILS_FORM_REQUIREMENT_NUMBER_LABEL',
                  'Numar Cerinta'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          /> */}
          <div className="text-left">
            <FormControlLabel
              disabled={!this.state.hasRights}
              className="ml-0"
              control={
                <Switch
                  checked={this.state.hasFransiza}
                  onChange={(e) => this.setState({ hasFransiza: e.currentTarget.checked })}
                  color="primary"
                  id="isActive"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              labelPlacement="start"
              label={this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_HAS_FRANSIZA_LABEL',
                'Are fransiza'
              )}
            />
          </div>
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="paymentAmount"
            name="paymentAmount"
            className="mb-3"
            //  type="number"
            label={this.translatorService.Tranlate(
              'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_LABEL',
              'Write your message...'
            )}
            value={this.state.paymentAmount}
            onChange={this.handlePaymentAmountChange}
            validators={['required', 'minNumber:1']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
            InputProps={{
              inputComponent: this.NumberFormatCustom
            }}
          />

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="installments"
            name="installments"
            className="mb-3"
            // type="number"
            label={this.translatorService.Tranlate(
              'CASE_PAYMENT_DETAILS_FORM_INSTALLMENTS_LABEL',
              'Write your message...'
            )}
            value={this.state.installments}
            onChange={this.handleInstallmentChange}
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
            InputProps={{
              inputComponent: this.NumberFormatCustom
            }}
          />
          <TextValidator
            ref={this.inputTextValidator}
            disabled={!this.state.hasRights}
            className="mb-3"
            name="bankAccount"
            label={
              this.state.bankName !== ''
                ? this.state.bankName
                : this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_FORM_IBAN_LABEL', 'IBAN')
            }
            fullWidth
            value={this.state.cursorBankAccount.outerValue || ''}
            onBlur={(e) => this.updateOnblur()}
            validators={['isIbanValid']}
            errorMessages={[
              this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_IBAN_INVALID_WARNING',
                'IBAN-ul este invalid'
              )
            ]}
            InputLabelProps={{
              className:
                this.state.cursorBankAccount.outerValue !== '' ? 'MuiInputLabel-shrink' : ''
            }}
            InputProps={{
              inputComponent: ({ inputRef: {}, ...rest }) => {
                return (
                  <input
                    maxLength={34}
                    ref={this.input}
                    {...rest}
                    type="text"
                    onChange={(e) => this.handleBankAccountChange(e)}
                  />
                );
              }
            }}
          />

          {this.renderAttachmentBox()}
          {this.renderAssignementForm()}
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('PAYMENT_DETAILS_SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  renderAssignementForm = () => {
    if (this.state.isUserHidden) {
      return null;
    }

    return (
      <React.Fragment>
        <Autocomplete
          disabled={this.state.isOrganizationsDisabled || !this.state.hasRights}
          id="workflowPartner"
          className="mb-3"
          options={this.state.organizations.sort(function (a, b) {
            return a.partner!.displayName.localeCompare(b.partner!.displayName);
          })}
          value={this.state.selectedOrganization}
          onChange={(e: any, newValue: WorkflowPartner | null) =>
            this.handleOrganizationChange(newValue)
          }
          getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option: WorkflowPartner) => option.partner!.displayName
          })}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="workflowPartner"
              value={this.state.selectedOrganization}
              label={this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_ORGANIZATION_LABEL',
                'Organizatie'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          )}
        />

        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading2} />
        </div>
        {!this.state.isLoading2 ? (
          <Autocomplete
            disabled={this.state.isUsersDisabled || !this.state.hasRights}
            id="user"
            className="mb-3"
            options={this.state.users.sort(function (a, b) {
              return a.userName.localeCompare(b.userName);
            })}
            value={this.state.selectedUser}
            onChange={(e: any, newValue: AppUser | null) => this.handleUserChange(newValue)}
            getOptionLabel={(option: AppUser) => option.userName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="user"
                value={this.state.selectedUser}
                label={this.translatorService.Tranlate(
                  'CASE_PAYMENT_DETAILS_FORM_USER_LABEL',
                  'Utilizator'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        ) : null}
      </React.Fragment>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">
            {this.props.withCurrency === false
              ? this.rendePaymentDetailsForm()
              : this.renderPaymentDetailsWithCurrencyForm()}

            <Divider></Divider>

            {this.state.paymentHistory.length > 0 ? (
              <div className="ml-4 mt-4 timeline-container timeline-list timeline-list--dark text-left">
                {this.showPaymentHistoryTimeline()}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabPaymentDetailsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabPaymentDetailsForm as any));
