import * as React from 'react';

import { IAuthService } from '../../services/Interfaces/IAuthService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppActionCreators, ApplicationState, AppState } from '../../store';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { ScaleLoader } from 'react-spinners';
import { Button, Grid, IconButton, ListItem } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { withRouter } from 'react-router';
import moment from 'moment';
import StarIcon from '@material-ui/icons/Star';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ExternalCaseNotificationsProps {
  onClick?: () => void;
}

type CaseTaskProps = { appState: AppState } & typeof AppActionCreators &
  RouteComponentProps &
  ExternalCaseNotificationsProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICaseTaskState {}

class CaseTasks extends React.PureComponent<CaseTaskProps, ICaseTaskState> {
  private authService!: IAuthService;
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {} as ICaseTaskState;

  onClick = (id: number) => {
    const currentPath = this.props.history.location.pathname.toLowerCase();
    this.props.history.push('/cases/' + id);

    if (currentPath.indexOf('/cases/') !== -1) {
      this.props.history.go(0);
    }

    if (this.props.onClick !== undefined) {
      this.props.onClick();
    }
  };

  handleDeleteFromTaskList = async (id: number) => {
    try {
      await this.caseService.RemoveUserTask(id);

      this.props.RemoveTask(id);
    } catch (err) {
      console.log(err);
    }
  };

  public render() {
    this.authService = (this.context as AppContext).authenticationService;
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <React.Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.appState.isTasksLoading} />
        </div>

        {!this.props.appState.isTasksLoading && this.props.appState.tasks.length === 0 ? (
          <div className="text-center">
            <div className="avatar-icon-wrapper  m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper  m-0 d-140">
                {/* <FontAwesomeIcon icon={['far', 'user']} className="d-flex align-self-center display-1 " /> */}
                <FontAwesomeIcon
                  icon={['far', 'list-alt']}
                  className="d-flex align-self-center display-1 "
                />
              </div>
            </div>
          </div>
        ) : null}
        {!this.props.appState.isTasksLoading && this.props.appState.tasks.length !== 0 ? (
          <PerfectScrollbar>
            {this.props.appState.tasks.map((item, index) => (
              <ListItem divider key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Button
                      color="primary"
                      className="text-primary justify-content-start p-0"
                      onClick={(e) => this.onClick(item.id)}
                    >
                      {item.id}
                    </Button>
                    <div>{item.caseStatus.caseStatus!.displayName}</div>
                    <div className="font-size-sm text-black-50">
                      {isNullOrUndefined(item.caseEvent) ? '' : item.caseEvent.caseNumber}
                    </div>
                  </Grid>

                  <Grid item xs={3}>
                    <div>{isNullOrUndefined(item.caseType) ? '' : item.caseType.displayName}</div>
                    <div className="font-size-sm text-black-50">
                      {isNullOrUndefined(item.caseVehicle) ? '' : item.caseVehicle.plateNumber}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      {isNullOrUndefined(item.organizationOwner) ? '' : item.organizationOwner.name}
                    </div>
                    <div className="font-size-sm text-black-50">
                      {moment
                        .utc(item.dateCreation)
                        .local()
                        .toDate()
                        .toLocaleString(this.props.appState.language)}
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        this.handleDeleteFromTaskList(item.id);
                      }}
                    >
                      <StarIcon style={{ color: '#FFFF00' }} fontSize="small"></StarIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </PerfectScrollbar>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  AppActionCreators
)(withRouter(CaseTasks as any));
