import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Box,
  TextField,
  Card,
  CardContent,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
  Chip
} from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { OrganizationInterface } from '../../../interfaces/Organization';
import { withSnackbar, ProviderContext } from 'notistack';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import MaterialTable, { Column, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { WorkflowInterface } from '../../../interfaces/Workflow';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;
interface IWorkflowInterfacesState {
  interfaces: WorkflowInterface[];
  isLoading: boolean;
  workflowId: number;
}
type WorkflowInterfacesProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;
class WorkflowInterfaces extends React.PureComponent<
  WorkflowInterfacesProps,
  IWorkflowInterfacesState
> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;
  static contextType = ApplicationContext;
  state = {
    interfaces: [],
    isLoading: true,
    workflowId: 0
  } as IWorkflowInterfacesState;
  componentDidMount = () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadWorkflowInterfaces();
      }
    );
  };
  loadWorkflowInterfaces = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);
    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }
    try {
      const data = await this.workflowService.GetWorkflowInterfaces(workflowId);
      this.setState({
        interfaces: data,
        isLoading: false,
        workflowId: workflowId
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  getColumns = () => {
    return [
      {
        field: 'clientAccess',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_CLIENT_ACCESS_HEADER',
          'Acces client'
        ),
        render: (rowData: any) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={rowData.clientAccess}
                  value={rowData.isActive}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  disabled
                />
              }
              label={''}
            />
          );
        },
        editComponent: (props) => (
          <FormControlLabel
            control={
              <Switch
                checked={props.value}
                value={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={''}
          />
        )
      } as Column<any>,
      {
        field: 'supportsOAuth2',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_CLIENT_OAUTH2_HEADER',
          'OAuth2'
        ),
        render: (rowData: any) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={rowData.supportsOAuth2}
                  value={rowData.supportsOAuth2}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  disabled
                />
              }
              label={''}
            />
          );
        },
        editComponent: (props) => (
          <FormControlLabel
            control={
              <Switch
                checked={props.value}
                value={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={''}
          />
        )
      } as Column<any>,
      {
        field: 'aftSupplier',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_AFT_SUPPLIER_HEADER',
          'Uleiul Grupului'
        ),
        render: (rowData: any) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={rowData.aftSupplier}
                  value={rowData.isActive}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  disabled
                />
              }
              label={''}
            />
          );
        },
        editComponent: (props) => (
          <FormControlLabel
            control={
              <Switch
                checked={props.value}
                value={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={''}
          />
        )
      } as Column<any>,
      {
        field: 'smallPartPercentage',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_SMALL_PART_PERCENTAGE_HEADER', 'Piese mici %'),
        searchable: true
      } as Column<any>,
      {
        field: 'code',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_CODE_HEADER', 'Cod'),
        searchable: true
      } as Column<any>,
      {
        field: 'url',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_URL_HEADER', 'URL'),
        searchable: true
      } as Column<any>,
      {
        field: 'port',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_PORT_HEADER', 'Port'),
        searchable: true
      } as Column<any>,
      {
        field: 'refreshTokenValidUntil',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_STATUS_HEADER', 'Status'),
        editable: 'never',
        render: (rowData: OrganizationInterface) => {
          if (!rowData.supportsOAuth2) {
            return (
              <Chip
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_STATUS_ACTIVE',
                  'Activa'
                )}
                style={{ backgroundColor: '#2e7d32', color: 'white' }}
              />
            );
          }
          if (!rowData.refreshTokenValidUntil) {
            return (
              <Chip
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_STATUS_INACTIVE',
                  'Inactiva'
                )}
                style={{ backgroundColor: '#d32f2f', color: 'white' }}
              />
            );
          }
          if (
            rowData.refreshTokenValidUntil &&
            moment.utc(rowData.refreshTokenValidUntil).isAfter(moment.utc())
          ) {
            return (
              <Chip
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_STATUS_ACTIVE',
                  'Activa'
                )}
                style={{ backgroundColor: '#2e7d32', color: 'white' }}
              />
            );
          }
          return (
            <Chip
              label={this.translatorService.Tranlate(
                'ORGANIZATION_INTERFACE_STATUS_INACTIVE',
                'Inactiva'
              )}
              style={{ backgroundColor: '#d32f2f', color: 'white' }}
            />
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editComponent: (props: any) => {
          if (!props.rowData.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      } as Column<OrganizationInterface>,
      {
        field: 'userName',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_UER_HEADER', 'Utilizator'),
        searchable: true,
        render: (props: OrganizationInterface) => {
          if (props.clientAccess) {
            return '-';
          }
          return props.userName;
        },
        editComponent: (props: any) => {
          if (props.rowData.clientAccess) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      },
      {
        field: 'password',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_PASSWORD_HEADER', 'Parola'),
        render: (props: OrganizationInterface) => {
          if (props.clientAccess) {
            return '-';
          }
          return '***';
        },
        editComponent: (props: any) => {
          if (props.rowData.clientAccess) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="password"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      } as Column<any>,
      {
        field: 'customerId',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_CUSTOMER_ID_HEADER',
          'Client Id'
        ),
        searchable: true,
        render: (props: OrganizationInterface) => {
          if (props.clientAccess) {
            return '-';
          }
          if (props.supportsOAuth2) {
            return '***';
          }
          return props.customerId;
        },
        editComponent: (props: any) => {
          if (props.rowData.clientAccess) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      },
      {
        field: 'secondUser',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_SECOND_USER_HEADER',
          'Utilizator 2'
        ),
        searchable: true,
        render: (props: OrganizationInterface) => {
          if (props.clientAccess || props.supportsOAuth2) {
            return '-';
          }
          return props.secondUser;
        },
        editComponent: (props: any) => {
          if (props.rowData.clientAccess || props.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      }
      // {
      //   field: 'dateModification',
      //   title: this.translatorService.Tranlate("ORGANIZATION_INTERFACE_MODIFIED_DATE_HEADER", "Data modificare"),
      //   defaultSort: "desc",
      //   searchable: true,
      //   render: (rowData: any) => {
      //     return (moment.utc(rowData.dateModification).local().toDate().toLocaleString(this.props.appState.language));
      //   },
      //   editComponent: (props: EditComponentProps<any>) => {
      //     return (<Label>{new Date().toLocaleString(this.props.appState.language)}</Label>)
      //   }
      // } as Column<any>
    ];
  };
  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };
  onRowUpdate = (newData: WorkflowInterface, oldData?: WorkflowInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.UpdateWorkflowInterface(newData);
          await this.loadWorkflowInterfaces();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });
  onRowDelete = (oldData: WorkflowInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.RemoveWorkflowInterface(oldData.id);
          await this.loadWorkflowInterfaces();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });
  onRowAdd = (newData: WorkflowInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        newData.workflowId = this.state.workflowId;
        await this.workflowService.AddWorkflowInterface(newData);
        await this.loadWorkflowInterfaces();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });
  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        title=" "
        columns={this.getColumns()}
        data={this.state.interfaces}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.onRowDelete(oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;
    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('WORKFLOW_TAB_INTERFACES', 'Interfete')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
        </Box>
      </Fragment>
    );
  }
}
const mergeProps = (stateProps: any, dispatchProps: any, ownProps: WorkflowInterfacesProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(WorkflowInterfaces as any));
