import React, { Fragment } from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { ProviderContext, withSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { CaseRequest, CaseStatusHistory } from '../../interfaces/Case';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import { ReferentialCode } from '../../helpers/Constants';
import { isNullOrUndefined } from 'util';
import CaseDetailsCaseRequests from '../cases/CaseDetailsCaseRequests';
import { Box, Card, CardContent } from '@material-ui/core';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';

interface ICaseStatusHistoryState {
  caseStatusHistory: CaseStatusHistory[];
  caseRequests: CaseRequest[];
  isLoading: boolean;
}

type ICaseStatusHistoryProps = AppState & ProviderContext & RouteComponentProps<{ id: string }>;

class CaseDetailsHistoryTab extends React.PureComponent<
  ICaseStatusHistoryProps,
  ICaseStatusHistoryState
> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private caseService!: ICaseService;
  private vehicleService!: IVehicleService;

  static contextType = ApplicationContext;
  state = {
    caseStatusHistory: [],
    caseRequests: [],
    isLoading: false
  } as ICaseStatusHistoryState;

  public componentDidMount() {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseStatusHistory();
      }
    );
  }

  loadCaseStatusHistory = async () => {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }
    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);

    const [caseStatusHistory, caseRequests, vehicleCosts] = await Promise.all([
      this.caseService.GetCaseStatusHistory(caseId),
      this.caseService.GetCaseRequests(caseId, null),
      this.vehicleService.GetCostRequests(caseId, null)
    ]);

    for (const vehicleCost of vehicleCosts) {
      const caseRequest = {
        attachments: vehicleCost.attachments,
        id: vehicleCost.id,
        userId: vehicleCost.userId,
        caseSectionStepFormId: vehicleCost.caseSectionStepFormId,
        vehicleId: vehicleCost.vehicleId,
        caseId: vehicleCost.caseId,
        value: vehicleCost.value,
        date: vehicleCost.date,
        isVehicleCost: true,
        costType: vehicleCost.costType,
        currencyRate: vehicleCost.currencyRate,
        currency: vehicleCost.currency,
        secondValue: vehicleCost.secondValue,
        secondCurrency: vehicleCost.secondCurrency,
        to: null
      } as any as CaseRequest;
      caseRequests.push(caseRequest);
    }

    const userIds = caseStatusHistory.map((item) => item.assignedToId);
    userIds.push(...caseStatusHistory.map((item) => item.userId));
    userIds.push(...caseRequests.map((item) => item.userId));

    const users = await this.appUserService.GetUsersInfo(Array.from(new Set(userIds)));

    for (const i of caseStatusHistory) {
      const user = users.find((u) => u.id === i.userId);
      const assignedTo = users.find((u) => u.id === i.assignedToId);

      i.user = isNullOrUndefined(user) ? null : user;
      i.assignedTo = isNullOrUndefined(assignedTo) ? null : assignedTo;
    }
    for (const i of caseRequests) {
      const user = users.find((u) => u.id === i.userId);
      i.user = isNullOrUndefined(user) ? null : user;
    }

    this.setState({
      isLoading: false,
      caseStatusHistory: caseStatusHistory.sort(function (a: any, b: any) {
        return b.date - a.date;
      }),
      caseRequests: caseRequests.sort(function (a: any, b: any) {
        return b.date - a.date;
      })
    });
  };

  showTimelineTariff = () => {
    const caseStatusHistory = this.state.caseStatusHistory;

    if (caseStatusHistory.length === 0) {
      return (
        <b>
          {this.translatorService.Tranlate(
            'TARIFF_EDIT_LABOR_TAB_NO_DATA',
            'Nu exista date de afisat!'
          )}
        </b>
      );
    }

    return caseStatusHistory.map((caseStatus, index) => (
      <div key={caseStatus.date.toString()} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <p className="timeline-item--label mb-2 font-weight-bold">
            {caseStatus.caseStatus!.displayName + ' (' + caseStatus.assignedTo!.userName + ')'}
          </p>
          <p className="timeline-item--content font-weight-bold">
            {moment.utc(caseStatus.date).local().toDate().toLocaleString(this.props.language) +
              ' (' +
              caseStatus.user!.userName +
              ')'}
          </p>
          <div>
            {index != 0
              ? this.renderCaseRequests(caseStatus.date, caseStatusHistory[index - 1].date)
              : this.renderCaseRequestsForFirstStatus(caseStatus.date)}
          </div>
        </div>
      </div>
    ));
  };

  renderCaseRequests = (startDate: Date, endDate: Date) => {
    const req = this.state.caseRequests.filter(
      (item) => startDate <= item.date && item.date <= endDate
    );
    const formIds = Array.from(new Set(req.map((r) => r.caseSectionStepFormId)));
    return formIds.map((item, index) => (
      <CaseDetailsCaseRequests
        key={index}
        {...this.props}
        formId={item}
        caseRequests={req.filter((r) => r.caseSectionStepFormId === item)}
        carfixOfferComments={false}
      />
    ));
  };

  renderCaseRequestsForFirstStatus = (startDate: Date) => {
    const req = this.state.caseRequests.filter(
      (item) => startDate <= item.date && new Date(item.date) < new Date()
    );

    const formIds = Array.from(new Set(req.map((r) => r.caseSectionStepFormId)));
    return formIds.map((item, index) => (
      <CaseDetailsCaseRequests
        key={index}
        {...this.props}
        formId={item}
        caseRequests={req.filter((r) => r.caseSectionStepFormId === item)}
        carfixOfferComments={false}
      />
    ));
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <Box className="pt-3">
            <Card className="card-box  overflow-visible">
              <CardContent className="p-3">
                <div className="m-4 p-4">
                  <div className="timeline-list  timeline-list--primary">
                    {' '}
                    {this.showTimelineTariff()}{' '}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Box>
        ) : null}
      </Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.app)(
  withSnackbar(CaseDetailsHistoryTab as any)
);
