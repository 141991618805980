import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
// Components
import * as R from 'ramda';
import { getHour, getHourForAppointment } from '../../utils/appointments';
import { AppointmentType } from '../../types/Agenda';;
import Loader from '../Loader';
import {  rest } from 'lodash';
import ClientAutocomplete from '../appointment/ClientAutocomplete';
import CarDetails from '../appointment/CarDetails';
import JobsMultipleSelection from '../appointment/JobsMultipleSelection';
import { FormControlLabel,  Switch } from '@material-ui/core';
import JobDetails from '../appointment/JobDetails';
import { AppointmentCalendar } from '../appointment/AppointmentCalendar';
import { Job } from '../agenda/agenda.types';
import { addAppointment, getAppointmentByCaseId, getJobListRequest } from '../agenda/agenda.requests';
import { Button } from 'reactstrap';
import { WorkflowPartner, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { AppState, ApplicationState, CaseSettingsActionCreators, CaseSettingsState } from '../../store';
import { ProviderContext, withSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { connect } from 'react-redux';
import { AppUser } from '../../interfaces/AppUser';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import moment from 'moment';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { Autocomplete } from '@material-ui/lab';
import { TextValidator } from 'react-material-ui-form-validator';
import { ChangeCaseStatusRequest } from '../../interfaces/Case';
import { CaseStatusCodeEnum, ReferentialCode } from '../../helpers/Constants';
import _ from 'lodash';
import { IReferential } from '../../interfaces/IReferential';
import Referentials from '../../helpers/Referentials.json';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ICaseDetailsCaseServiceAddAppointmentFormState {
  isLoading: boolean,
  appointment: AppointmentType,
  date: Date | null,
  hour: string | null,
  hasRights: boolean,
  selectedUser: AppUser | null;
  organizations: WorkflowPartner[]; 
  isUserHidden:boolean;
  isUsersDisabled:boolean;
  isLoading2:boolean;
  users: AppUser[];
  executing: boolean;
  caseStatuses: IReferential[];
  isCarInService: boolean;
  requiresServiceScheduling:boolean;
}

export interface ExternalCaseDetailsCaseServiceAddAppointmentFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseServiceAddAppointmentFormProps =
  ExternalCaseDetailsCaseServiceAddAppointmentFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseServiceAddAppointmentForm extends React.PureComponent<
  ICaseDetailsCaseServiceAddAppointmentFormProps,
  ICaseDetailsCaseServiceAddAppointmentFormState
> {

  private caseService!: ICaseService;
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    isLoading: false,
    appointment:{} as AppointmentType,
    date:new Date(),
    hour: getHourForAppointment(),
    hasRights: false,
    selectedUser: null,
    organizations: [],
    isUserHidden:false,
    isUsersDisabled:false, 
    isLoading2: false,
    users:[],
    executing: false,
    caseStatuses: [],
    isCarInService:false,
    requiresServiceScheduling:true
  } as ICaseDetailsCaseServiceAddAppointmentFormState;

  public componentDidMount() {
    this.getAppointment()
  }


  getAppointment = async () => {
    this.setState({ isLoading: true });
    const refCaseStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );
    const [
      res,
      caseStatuses
    ] = await Promise.all([
      getAppointmentByCaseId(Number.parseInt(this.props.match.params.id)),
      this.appReferentialService.Get(refCaseStatus!.baseUrl)
    ]);

    const appointment = res.data as AppointmentType
    if (res.error || !appointment) {
      this.setState({ isLoading: false });
      return;
    }

    
    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );
    this.setState({ 
      isLoading: false, 
      appointment: appointment, 
      date: appointment.parentId ? new Date(appointment.date): new Date(),
      hour: appointment.parentId ? getHour(new Date(appointment.date)) : getHourForAppointment(),
      hasRights: hasRights,
      caseStatuses: caseStatuses,
      isCarInService: appointment?.date === null,
      requiresServiceScheduling: appointment?.date !== null
    }, this.setCaseAllocation);
  }

  setCaseAllocation = async () => {
    if (!this.state.hasRights) {
      return;
    }

    const allocationResult = await FormHelpers.ParseAllocations(
      this.props.workflowForm.workflowFormActions,
      this.state.organizations,
      this.appUserService
    );
    if (R.isNil(allocationResult)) {
      const partner = this.state.organizations.find(
        (item) => item.partnerId === this.props.caseSettingsState.case!.caseStatus.assignedTo!.hoId
      );
      this.setState(
        {
          selectedUser: this.props.caseSettingsState.case!.caseStatus.assignedTo,
          organizations: this.state.organizations,
        },
        async () => await this.loadUsers(R.isNil(partner) ? null : partner.partnerId, null)
      );
      return;
    }
    if (allocationResult.isLastUser || allocationResult.caseStatusId != null) {
      this.setState({ isUserHidden: true });
      return;
    }
    this.setState(
      {
        selectedUser: allocationResult.user,
        organizations: allocationResult.partners,
        isUsersDisabled: !R.isNil(allocationResult.user),
      },
      async () => {
        if (allocationResult.partner === null) {
          return;
        }
        await this.loadUsers(allocationResult.partner.partnerId, allocationResult.roleId);
      }
    );
  };

  loadUsers = async (organizationId: number | null, roleId: number | null) => {
    if (organizationId === null) {
      this.setState({
        isLoading2: false
      });
    } else {
      const users = await this.appUserService.GetUserAppUsers();

      this.setState({
        users: users.users.filter(
          (user) =>
            (user.organization!.hoId === organizationId ||
              user.organizationId === organizationId) &&
            ((roleId !== null && user.roleId === roleId) || roleId === null)
        ),
        isLoading2: false
      });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  getPayloadFormat = async (carInService: boolean) =>{
    const response = await getJobListRequest();
    const responseData = response.data as Job[];
    const selectedJobs = this.state.appointment.appointmentJobTypes.map((job)=>{
      const indexJob = responseData.findIndex((item)=> item.id === job.jobTypeId);
      return {id:job.jobTypeId, name:responseData[indexJob].displayName}}
      )
    const finalDate = this.state.date ?? null;
    const splitHour = this.state.hour != null ? this.state.hour.split(':') : null;
    if (finalDate && splitHour) {
      finalDate.setHours(parseInt(splitHour[0]));
      finalDate.setMinutes(parseInt(splitHour[1]));
    }
    const payload = {
      serviceId: this.state.appointment.serviceId,
      appointmentClient:this.state.appointment.appointmentClient,
      appointmentJobTypes: selectedJobs,
      jobDetails: this.state.appointment.jobDetails,
      vehicle: {
        vin: this.state.appointment.vehicle.vin || null,
        plateNumber: this.state.appointment.vehicle?.plateNumber || null,
        make: this.state.appointment.vehicle?.identifiedMake || null,
        model: this.state.appointment.vehicle?.identifiedModel || null,
        carBody: this.state.appointment.vehicle?.identifiedCarBody || null,
        fuelType: this.state.appointment.vehicle?.identifiedFuelType || null,
        registrationDate: this.state.appointment.vehicle.registrationDate,
        engineSize: this.state.appointment.vehicle.engineSize,
        power: this.state.appointment.vehicle.power,
        numberOfKilometers: this.state.appointment.vehicle.numberOfKilometers,
      },
      date: finalDate != null ? moment(finalDate).format() : null,
      appointmentCategoryTypeId: 2,
      workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
      parentId:this.state.appointment.id,
      caseId:this.state.appointment.caseId,
      carInService: carInService
    }
    return payload;
  }

  handleSwitchChange = (checked: boolean, isCarInService:boolean) => {
    this.setState({
      isCarInService: isCarInService ? checked : !checked,
      requiresServiceScheduling: isCarInService ? !checked : checked
    });
  };
  
  addNewAppointment = async() => {
    if(this.state.isCarInService){
      this.carInService();
      return;
    }
    this.setState({ executing: true });
    const payload = await this.getPayloadFormat(false);
    const res = await addAppointment(payload)
    if (res.error) {
      this.setState({ executing: false });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      return;
    }

    await this.submitForm(
      Number.parseInt(this.props.match.params.id),
      this.props.workflowForm.id,
      this.state.selectedUser
    );

    this.setState(
      {
        appointment:{} as AppointmentType,
        selectedUser: null,
        executing:false
      }
    );
  }

  carInService = async () => {
    //Set date and hour to null 
    this.setState({ date: null, hour: null, executing: true });

    //Create new appointment with car in service = true
    const payload = await this.getPayloadFormat(true);
    const res = await addAppointment(payload);
    if (res.error) {
      this.setState({ executing: false });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });

      return;
    }

    //Update case status
    const status = _.find(this.state.caseStatuses, function (caseStatus) {
      return caseStatus.code === CaseStatusCodeEnum.PROGRAMARE_REPARATIE_CONFIRMATA;
    });

    const caseStatusRequest = {
      caseId: this.props.caseSettingsState.case!.id,
      statusId: status!.id
    } as ChangeCaseStatusRequest;
    const currentStatus = await this.caseService.ChangeCaseStatus(caseStatusRequest);
    
    this.props.SetCaseStatus(currentStatus);
  }

  handleUserChange = (newValue: AppUser | null) => {
    this.setState({ selectedUser: newValue });
  };

  displayInfo = (info: string) => {
    return (
      <Tooltip
        onClick={(e) => { e.preventDefault() }}
        {...rest}
        disableHoverListener={!this.state.hasRights || this.state.executing}
        title={info}
        placement='right'
      >
        <div style={{marginLeft:12}}>
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
        </div>
      </Tooltip>
    );
  };

  public render() {
  this.caseService = (this.context as AppContext).caseService;
  this.translatorService = (this.context as AppContext).translatorService;
  this.appReferentialService = (this.context as AppContext).referentialService;

  return <>{this.state.isLoading ? <Loader /> :
    <div style={{ padding: 16 }}>
      <Box style={{ display: 'flex', gap: '20px' }}>
        <ClientAutocomplete disabled value={this.state.appointment?.appointmentClient || null} />
      </Box>
      <div className='appointmentRepairDetailsContainer'>
        <Box >
          <Typography variant="h5" style={{ marginBottom: '10px',}}>
            {this.translatorService.Tranlate('APPOINTMENT_CAR_TITLE', 'Car')}
          </Typography>
          {this.state.appointment?.vehicle && <CarDetails disabled selectedCar={this.state.appointment?.vehicle} />}
        </Box>
        <JobDetails disabled details={this.state.appointment?.jobDetails} />
      </div>
      <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop: 16, marginBottom: 16 }}>
        <JobsMultipleSelection
          selectedValues={this.state.appointment?.appointmentJobTypes || []}
          disabled
        />
      </Box>

      <Box style={{ width: 'fit-content' }}>
        <Typography variant="h5" style={{ marginBottom: '10px', }}>
          {this.translatorService.Tranlate('APPOINTMENT_CAR_REPAIR_TITLE', 'Reparație mașină')}
        </Typography>
        <Box style={{display:'flex', flexDirection:'column'}}>
          <FormControlLabel
            style={{ width: 'fit-content' }}
            disabled={!this.state.hasRights || this.state.executing}
            control={
              <Switch
                checked={this.state.isCarInService}
                onChange={(e, checked) => this.handleSwitchChange(checked, true)}
                color="primary"
                id="isCarInService"
                
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6">
                  {this.translatorService.Tranlate('APPOINTMENT_CAR_IS_IN_SERVICE', 'Mașina este în service')}
                </Typography>
                {this.displayInfo(this.translatorService.Tranlate('APPOINTMENT_CAR_IS_IN_SERVICE_INFO', 'Selectează dacă mașina rămâne în service pe perioada reparației și nu este nevoie de o programare'))}
              </div>
            }
          />
          <FormControlLabel
            style={{ width: 'fit-content' }}
            disabled={!this.state.hasRights || this.state.executing}
            control={
              <Switch
                checked={this.state.requiresServiceScheduling}
                onChange={(e, checked) => this.handleSwitchChange(checked, false)}
                color="primary"
                id="requiresServiceScheduling"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6">
                  {this.translatorService.Tranlate('APPOINTMENT_REQUIRES_SERVICE_SCHEDULING', 'Necesită programare service')}
                </Typography>
                {this.displayInfo(this.translatorService.Tranlate('APPOINTMENT_REQUIRES_SERVICE_SCHEDULING_INFO', 'Selectează dacă este necesară o programare pentru reparația mașinii'))}
              </div>
            }
            />
        </Box>
      </Box>

      {this.state.requiresServiceScheduling && this.state.appointment.date !== null ? <Box style={{ display: 'flex', flexWrap: 'wrap',  marginTop: 16, marginBottom: 16 }}>
       <div style={{ flex: 1 }}>
          <AppointmentCalendar 
            disabled={!this.state.hasRights}
            day={this.state.date} 
            setDay={(day) => this.setState({ date: day })} 
            hour={this.state.hour} 
            setHour={(hour) => this.setState({ hour:hour })} 
            minDate={new Date()}
            customStyle={{
              calendarStyle:{marginTop:-335},
              hourDropdownStyle:{marginTop:-260}
            }}
            showDefaultCalendar={false}
          />
        </div>
      </Box>:<></>}
      {!this.state.isLoading2 && this.state.users.length > 0 ? (
        <Autocomplete
          disabled={this.state.isUsersDisabled || !this.state.hasRights}
          id="user"
          className="mb-3"
          options={this.state.users.sort(function (a, b) {
            return a.userName.localeCompare(b.userName);
          })}
          value={this.state.selectedUser}
          onChange={(e: any, newValue: AppUser | null) => this.handleUserChange(newValue)}
          getOptionLabel={(option: AppUser) => option.userName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="user"
              value={this.state.selectedUser}
              label={this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_USER_LABEL',
                'Utilizator'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          )}
        />
      ) : null}
      <div className="text-center">
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          onClick={() => this.addNewAppointment()}
          disabled={!this.state.hasRights || this.state.executing}
          >
          {this.translatorService.Tranlate('CASE_DETAILS_BUTTON_SAVE', 'Save')}
        </Button>
      </div>
    </div>
  }</>
}
};


const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseServiceAddAppointmentFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseServiceAddAppointmentForm as any));