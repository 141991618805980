import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Grid,
  DialogActions,
  Tooltip,
  Box,
  Modal,
  DialogContentText
} from '@material-ui/core';
import { Label } from 'reactstrap';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import {
  Workflow,
  WorkflowCalculation,
  WorkflowFormAction,
  WorkflowFormReport,
  WorkflowOptimization,
  WorkflowPartner,
  WorkflowSectionStep,
  WorkflowSectionStepForm
} from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { CarfixAlternativeCalculationRequest, CarfixAlternativeCalculationResponse, CaseAttachment, CaseCalculationHistory, CaseCalculationPart, CaseRequest, ChangeCaseStatusRequest, ClaimServiceAlternativeCalculationRequest, ClaimServiceCalculationRequest, ClaimServiceCalculationResponse, ManipulationTariffPercentResponse, TariffPercentRequest, TariffPercentResponse } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import {
  ReferentialCode,
  CalculationTypeCode,
  ParametreCode,
  ReportTypeCode,
  WorflowEditableReport,
  OrderStatusCode,
  CaseStatusCodeEnum,
  CalculationSectionCodeEnum,
  WorkflowFormActionTypeCode,
  AttachmentTypeCode,
  OrganizationTypeCode,
  TariffTypeGroupCodeEnum
} from '../../helpers/Constants';
import { IReferential } from '../../interfaces/IReferential';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  SelectableRows,
  MUIDataTableMeta
} from 'mui-datatables';
import moment from 'moment';
import { AppUser } from '../../interfaces/AppUser';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import CaseDetailsCaseTabCalculationDetails2 from './CaseDetailsCaseTabCalculationDetails2';
import CaseDetailsAlternativeCalculationDetails from './CaseDetailsAlternativeCalculationDetails';
import CaseDetailsValuation from './CaseDetailsValuation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CaseDetailsCaseTabCompareCalculations from './CaseDetailsCaseTabCompareCalculations';
import { CalculationRequest, CalculationResponse } from '../../interfaces/GtService';
import FileSaver from 'file-saver';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { IReportsService } from '../../services/Interfaces/IReportsService';
import { ReportParameter } from '../../interfaces/Reports';
import CertificateOfControl from '../vehicles/CertificateOfControl';
import Radar from '../radar/Radar';
import { Utils } from '../../helpers/Utils';
import { CaseTypeCode } from '../../helpers/Constants';
import _, { forEach } from 'lodash';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import { Order, PartToBeOrdered } from '../../interfaces/Order';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import * as R from 'ramda';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import CaseValuationHistory from './CaseValuationHistory';
import CaseDetailsCaseRequests from './CaseDetailsCaseRequests';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { ITariffService } from '../../services/Interfaces/ITariffService';

interface ICaseDetailsCaseTabCalculationsFormState {
  caseCalculationHistory: CaseCalculationHistory[];
  caseCalculationHistoryCopy: CaseCalculationHistory[];
  caseCalculationActionTypeList: IReferential[];
  workflowReports: WorkflowFormReport[];
  WorkflowOptimization: WorkflowOptimization[];
  calculationSectionList: IReferential[];
  isLoading: boolean;
  isDialogOpen: boolean;
  calculationHistoryIds: number[];
  openMenuItem: any | null;
  disableButtonForMoreThenOneRowChecked: boolean;
  disableButtonForMoreThenTwoRowsChecked: boolean;
  selectedCalculationButton: string | null;
  rowsSelected: any[];
  modified: boolean;
  isDialogModifiedOpen: boolean;
  isDialogAlternativeCalculationOpen: boolean;
  isDialogQualityControlOpen: boolean;
  isDialogRadarOpen: boolean;
  isValuationOpen: boolean;
  maxDeliveryTime: number;
  tmlEditable: boolean;
  workflowCalculation: WorkflowCalculation | null;
  hasRights: boolean;
  executing: boolean;
  orderList: Order[];
  unorderedParts: PartToBeOrdered[];
  caseStatuses: IReferential[];
  partsForFirstCalculation: CaseCalculationPart[];
  wfTransitionStepAction: WorkflowFormAction | null;
  insurerList: IReferential[];
  attachmentTypeList: IReferential[];
  manipulationTariff:  ManipulationTariffPercentResponse;
  serviceId: number | null;
  discretionaryPriceDealer?:number;
  discretionaryPriceEmployee?:number;
  accoutingValue?:number;
  showHistoryModal:boolean;
  additionTariff: TariffPercentResponse;
  discountTariff: TariffPercentResponse;
  caseRequests: CaseRequest[];
  packageCalculationId: number | null;
  ownerTariffPartner: WorkflowPartner | null;
  isMultipleTariffDialogOpen: boolean;
  selectedCalculationTypeId: number | null;
  tariffBranches: TariffBranch[];
  selectedTariffBranch: TariffBranch | null;
  isOwnTariffLoading: boolean;
  lastServiceCalculation: CaseCalculationHistory | null;
  isClientCommentOpen: boolean;
  orginalCalculationTypeId: number | null;
}
export interface ExternalCaseDetailsCaseTabCalculationsFormProps {
  stepFormId: number;
  workflowForm: WorkflowSectionStepForm;
}

interface TariffBranch {
  id: number;
  ownerId: number;
  tariffName: string;
  partnerId: number;
  children: TariffBranch[];
  path: string | null;
  label: string;
  checked: boolean | null;
}

type ICaseDetailsCaseTabCalculationsFormProps = ExternalCaseDetailsCaseTabCalculationsFormProps & {
  caseSettingsState: CaseSettingsState;
  vehicleState: VehicleState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string; calcId: string | undefined; formId: string | undefined }>;

class CaseDetailsCaseTabCalculationsForm extends React.PureComponent<
  ICaseDetailsCaseTabCalculationsFormProps,
  ICaseDetailsCaseTabCalculationsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private reportsService!: IReportsService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;
  private orderService!: IOrderService;
  private vehicleService!: IVehicleService;
  private workflowService!: IWorkflowService;
  private tariffService!: ITariffService;
  private ACTION_COMPARE = 'COMPARE';
  private ACTION_GT_VAL = 'GT_VAL';
  private RADAR = 'RADAR';
  private ACTION_INSURER_CALCULATION = 'INSURER_CALCULATION';

  static contextType = ApplicationContext;
  state = {
    caseCalculationHistory: [],
    caseCalculationHistoryCopy: [],
    caseCalculationActionTypeList: [],
    workflowReports: [],
    WorkflowOptimization: [],
    calculationSectionList: [],
    isLoading: true,
    isDialogOpen: false,
    calculationHistoryIds: [],
    openMenuItem: null,
    disableButtonForMoreThenOneRowChecked: true,
    disableButtonForMoreThenTwoRowsChecked: true,
    rowsSelected: [],
    selectedCalculationButton: null,
    modified: false,
    isDialogModifiedOpen: false,
    isValuationOpen: false,
    isDialogAlternativeCalculationOpen: false,
    isDialogQualityControlOpen: false,
    isDialogRadarOpen: false,
    maxDeliveryTime: 0,
    tmlEditable: false,
    workflowCalculation: null,
    hasRights: false,
    executing: false,
    orderList: [],
    unorderedParts: [],
    caseStatuses: [], 
    partsForFirstCalculation: [],
    wfTransitionStepAction: null,
    insurerList: [],
    attachmentTypeList: [],
    discretionaryPriceDealer: 0,
    discretionaryPriceEmployee: 0,
    accoutingValue: 0,
    showHistoryModal:false,
    manipulationTariff: {
      id: 0,
      value: 0
    } as ManipulationTariffPercentResponse,
    serviceId: null,
    additionTariff: {
      id: 0, 
      value: 0 
    } as TariffPercentResponse,
    discountTariff: {
      id: 0,
      value: 0
    } as TariffPercentResponse,
    caseRequests: [],
    packageCalculationId: null,
    ownerTariffPartner: null,
    isMultipleTariffDialogOpen: false,
    selectedCalculationTypeId: null,
    tariffBranches: [],
    selectedTariffBranch: null,
    isOwnTariffLoading: false,
    lastServiceCalculation: null,
    isClientCommentOpen: false,
    orginalCalculationTypeId: null
  } as ICaseDetailsCaseTabCalculationsFormState;

  public async componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    if (
      this.props.match.url.toLowerCase().endsWith('callback') &&
      !isNullOrUndefined(this.props.match.params.calcId)
    ) {
      await this.caseService.UpdateCalculationCallback(
        Number.parseInt(this.props.match.params.calcId),
        this.props.caseSettingsState.caseSettings!.workflow.id,
        this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId
      );

      this.caseService.UpdateCalculationPdfCallback(
        Number.parseInt(this.props.match.params.calcId),
        this.props.caseSettingsState.caseSettings!.workflow.id,
        this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId
      );

      this.props.history.push({
        pathname: `/cases/${caseId}/${this.props.match.params.formId}` //this.props.location.pathname.replace('/callback','')
      });
      return;
    }

    await this.loadData();
  }

  loadData = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    console.log('props', this.props);
    console.log('workflow', this.props.caseSettingsState.caseSettings!.workflow);
    console.log('organizationOwnerId', this.props.caseSettingsState.case?.organizationOwnerId);
    console.log('organizationId', this.props.appState.appUser!.organizationId);

    let priceEmployee = 0;
    let priceDealer = 0;
    let accountingVal = 0;

    if (this.props.appState?.appUser?.userRole?.code === 'MAVO_ADMIN') {
      const vehicleAdvert = await this.vehicleService?.GetVehicleAdvert(
        this.props.caseSettingsState.case!.vehicleId,
        caseId
      );

      if (vehicleAdvert) {
        priceDealer = vehicleAdvert?.priceWithVat - 0.1 * vehicleAdvert?.priceWithVat;
        priceEmployee = vehicleAdvert?.priceWithVat - 0.05 * vehicleAdvert?.priceWithVat;
      }

      const vehicle = await this.vehicleService.GetVehicle(
        this.props.caseSettingsState.case!.vehicleId as any
      );
      if (vehicle?.accountingValue) {
        accountingVal = vehicle.accountingValue;
      }
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const refCalculations = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CalculationType
    );
    const refCaseStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );
    const refReport = Referentials.referential.find((item) => item.code === ReferentialCode.Report);
    const refOptimization = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OptimizationType
    );
    const refInsurer = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Insurer
    );
    const refAttachmentType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );

    const caseCalculationActionTypeList = [] as IReferential[];
    // eslint-disable-next-line prefer-const
    let [caseCalculations, caseStatuses, calculationTypeList, insurerList, attachmentTypeList, calculationSections, ownerTariffPartner] =
      await Promise.all([
        this.caseService.GetCaseCalculationHistories(caseId),
        this.appReferentialService.Get(refCaseStatus!.baseUrl),
        // this.appReferentialService.Get(refReport!.baseUrl),
        // this.appReferentialService.Get(refOptimization!.baseUrl),
        this.appReferentialService.Get(refCalculations!.baseUrl),
        this.appReferentialService.Get(refInsurer!.baseUrl),
        this.appReferentialService.Get(refAttachmentType!.baseUrl),
        this.caseService.GetCalculationSections(),
        this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration ? this.workflowService.GetOwnerTariffPartner(this.props.caseSettingsState.caseSettings!.workflow.id) : null
      ]);

    const caseCalculationCopy =  _.sortBy(_.cloneDeep(caseCalculations), 'date').reverse();
    console.log(
      'caseCalculationCopy', caseCalculationCopy
    );
    const packageCalculation = caseCalculationCopy.find((item) => item.maintenancePackageId !== null);

    const [unorderedParts, orderList] = await Promise.all([
      this.orderService.GetPartsToBeOrdered(this.props.caseSettingsState.case!.id),
      this.orderService.GetOrdersWithParts(this.props.caseSettingsState.case!.id)
    ]);

    let manipulationTariff = {
      id: 0,
      value: 0
    } as ManipulationTariffPercentResponse;

    console.log('unorderedParts', unorderedParts);
    console.log('orderList', orderList);
    const form = this.getWorkflowForm(this.props.stepFormId);
    const wfStepActions = this.props.workflowForm.workflowFormActions;
    const wfTransitionStepAction =
      wfStepActions.find(
        (item) =>
          item.actionType?.code === WorkflowFormActionTypeCode.STATUS_TRANSITION &&
          this.props.caseSettingsState.case!.caseStatus.caseStatusId === item.fromStatusId
      ) || (null);

    const workflowCalculation = FormHelpers.GetCalculation(
      form!.workflowCalculations,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const currentOrganizationId = this.props.appState.appUser!.organizationId;

    if (this.props.appState.appUser!.organization!.organizationType!.hideAlternative) {
      caseCalculations = _.cloneDeep(_.filter(caseCalculations, function(caseCalculation) {
        return caseCalculation.calculationType.code == CalculationTypeCode.ORG || (
          caseCalculation.calculationType.code == CalculationTypeCode.ALT &&
          caseCalculation.partsOrganizationId == currentOrganizationId
        );
      }));
    }

    if (this.props.appState.appUser!.organization!.organizationType!.hideInsurerCalculation) {
      console.log('before', caseCalculations);
      caseCalculations = _.cloneDeep(_.filter(caseCalculations, function(caseCalculation) {
        return (
          (caseCalculation.calculationType.code == CalculationTypeCode.ORG && !caseCalculation.insurerCalculation)
          || (
            caseCalculation.calculationType.code == CalculationTypeCode.ALT &&
            caseCalculation.partsOrganizationId == currentOrganizationId
          )
        );
      }));
      console.log('after', caseCalculations);
    }

    caseCalculations = _.sortBy(caseCalculations, 'date').reverse();

    let serviceId: number | null = null;
    let lastServiceCalculation: CaseCalculationHistory | null = null;
    // const workflowOptimization = form!.workflowFormOptimizations;
    if (caseCalculations.length !== 0) {
      const users = await this.appUserService.GetUsersInfo(
        Array.from(new Set(caseCalculations.map((item) => item.userId)))
      );
      caseCalculations.forEach((calc) => {
        calc.user = users.find((item) => item.id === calc.userId)!;
      });

      const serviceCalculation = _.find(caseCalculations, function(calculation)  {
        return calculation.user!.organization!.organizationTypeCode === OrganizationTypeCode.REP && !calculation.insurerCalculation;
      });

      console.log('serviceCalculation', serviceCalculation);
      serviceId = serviceCalculation ? serviceCalculation!.organizationId : null;
      lastServiceCalculation = serviceCalculation ? serviceCalculation : null;

      if (this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration) {
        manipulationTariff = await this.caseService.GetManipulationPercent({
          caseId: this.props.caseSettingsState.case!.id,
          formId: this.props.stepFormId,
          vehicleId: this.props.caseSettingsState.case!.vehicleId,
          workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
          workflowOrganizationOwnerId: this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId,
          organizationId: this.props.appState.appUser!.organizationId,
          organizationName: this.props.appState.appUser!.organization!.name,
          takeOrganizationTariff: this.props.appState.appUser!.organization!.organizationType!.takeOrganizationTariff,
          insurerId: null,
          serviceId: serviceId,
          ownTariffId: null
        } as CalculationRequest);
      }
    }

    console.log('manipulationTariff', manipulationTariff);
    const [additionTariff, discountTariff, caseRequests] = await Promise.all([
      this.caseService.GetTariffPercent({
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        insurerId: this.getInsurerIdInfo(insurerList),
        tariffCode: TariffTypeGroupCodeEnum.ADD_PARTS
      } as TariffPercentRequest),
      this.caseService.GetTariffPercent({
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        insurerId: this.getInsurerIdInfo(insurerList),
        tariffCode: TariffTypeGroupCodeEnum.PSE_ALT
      } as TariffPercentRequest),
      !isNullOrUndefined(workflowCalculation) && workflowCalculation.viewClientCommentsHistory ? this.caseService.GetCaseRequests(caseId, this.props.workflowForm.id) : []
    ]);
    console.log('additionTariff', additionTariff);
    console.log('discountTariff', discountTariff);

    if (!isNullOrUndefined(workflowCalculation)) {
      if (workflowCalculation.originalCalculation) {
        caseCalculationActionTypeList.push(calculationTypeList[0]);
      }
      if (workflowCalculation.alternativeCalculation) {
        caseCalculationActionTypeList.push(calculationTypeList[1]);
      }
      if (workflowCalculation.gtValuation) {
        caseCalculationActionTypeList.push({
          id: 3,
          displayName: this.translatorService.Tranlate('CASE_CALCULATION_MARKET_VALUE', 'Market Value'),
          code: this.ACTION_GT_VAL
        } as IReferential);
      }
      if (workflowCalculation.compare) {
        caseCalculationActionTypeList.push({
          id: 4,
          displayName: this.translatorService.Tranlate('CASE_CALCULATION_FORM_COMPARE', 'Compara'),
          code: this.ACTION_COMPARE
        } as IReferential);
      }
      if (workflowCalculation.radar) {
        caseCalculationActionTypeList.push({
          id: 5,
          displayName: this.translatorService.Tranlate(
            'WORKFLOW_CALCULATION_TAB_RADAR_LABEL',
            'Radar'
          ),
          code: this.RADAR
        } as IReferential);
      }

      if (workflowCalculation.insurerCalculation) {
        caseCalculationActionTypeList.push({
          id: calculationTypeList[0].id,
          displayName: this.translatorService.Tranlate(
            'CALCULATION_TAB_INSURER_CALCULATION_LABEL',
            'Actualizare OE'
          ),
          code: this.ACTION_INSURER_CALCULATION
        } as IReferential);
      }
    }

    const wr = FormHelpers.GetReports(
      form!.workflowFormReports,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    // const wo = workflowOptimization.filter(item =>
    //     item.workflowPartner!.partnerId === this.props.appState.appUser!.hoId
    //     && ((item.userRoleId === this.props.appState.appUser!.roleId) || isNullOrUndefined(item.userRoleId)));
    let calculationParts =  ([] as CaseCalculationPart[]);
    if (caseCalculations.length > 0) {
      const firstCalculation = caseCalculations[0];
      calculationParts = firstCalculation.id
        ? await this.caseService.GetCaseCalculationParts(firstCalculation.id)
        : ([] as CaseCalculationPart[]);
    }

    const partSection = calculationSections.find((item) => item.code === CalculationSectionCodeEnum.PART)!;
    console.log('Case Calculation', caseCalculations);
    console.log('ownerTariffPartner', ownerTariffPartner);
    this.setState({
      caseCalculationHistory: caseCalculations,
      caseCalculationHistoryCopy: caseCalculationCopy,
      caseCalculationActionTypeList: caseCalculationActionTypeList,
      workflowReports: wr,
      calculationSectionList: calculationSections.sort(function (a, b) {
        return a.id - b.id;
      }),
      maxDeliveryTime: !isNullOrUndefined(workflowCalculation)
        ? !isNullOrUndefined(workflowCalculation.defaultMaxDeliveryTime)
          ? workflowCalculation.defaultMaxDeliveryTime
          : 0
        : 0,
      tmlEditable: !isNullOrUndefined(workflowCalculation)
        ? !isNullOrUndefined(workflowCalculation.tmlEditable)
          ? workflowCalculation.tmlEditable
          : false
        : false,
      isLoading: false,
      workflowCalculation: workflowCalculation == undefined ? null : workflowCalculation,
      hasRights: hasRights,
      orderList: orderList,
      unorderedParts: unorderedParts,
      caseStatuses: caseStatuses,
      partsForFirstCalculation: calculationParts.filter(
        (item) => item.calculationSectionId === partSection!.id
      ),
      wfTransitionStepAction: wfTransitionStepAction,
      insurerList: insurerList,
      attachmentTypeList: attachmentTypeList,
      manipulationTariff: manipulationTariff,
      serviceId: serviceId, 
      discretionaryPriceDealer:priceDealer,
      discretionaryPriceEmployee: priceEmployee,
      accoutingValue:accountingVal,
      additionTariff: additionTariff,
      discountTariff: discountTariff,
      caseRequests: caseRequests.filter(x => x.isExternal == true),
      packageCalculationId: packageCalculation ? packageCalculation!.id : null,
      ownerTariffPartner: ownerTariffPartner,
      lastServiceCalculation: lastServiceCalculation
    });
  };

  getWorkflowForm = (id: number): WorkflowSectionStepForm | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        const form = step.workflowSectionStepForms.find((form) => form.id === id);
        if (!isNullOrUndefined(form)) {
          return form;
        }
      }
    }

    return null;
  };

  onRowClick = (e: any, id: number) => {
    // let calculationHistoryIds = [...this.state.calculationHistoryIds];
    // calculationHistoryIds.push(...id);
    // console.log(calculationHistoryIds)
    this.setState({ isDialogOpen: true, calculationHistoryIds: Array.from([id]) });
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'calculationType',
        label: this.translatorService.Tranlate(
          'CASE_CALCULATION_FORM_CALCULATION_TYPE_HEADER',
          'Tip Calculatie'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className="text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value.displayName}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'date',
        label: this.translatorService.Tranlate(
          'CASE_CALCULATION_FORM_CALCULATION_DATE_HEADER',
          'Data Calculatie'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'amountPiecesWithDiscount',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amountLaborWithDiscount',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amountPaintingMaterialsWithDiscount',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amountPaintingLaborWithDiscount',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amount',
        label: this.translatorService.Tranlate('CASE_CALCULATION_FORM_AMOUNT_HEADER', 'Valoare'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const TVA = this.props.caseSettingsState.case!.caseParameters.find(
              (item) => item.parameter!.code === ParametreCode.TVA
            )!.value;
            return (
              <Tooltip
                title={
                  <div>
                    <small>
                      {this.translatorService.Tranlate(
                        'CASE_CALCULATION_FORM_TOOLTIP_TITLE',
                        'Total defalcat'
                      )}
                    </small>
                    {this.state.calculationSectionList.map((item) => {
                      return (
                        <div className="d-flex justify-content-between" key={item.id}>
                          <small>{item.displayName}</small>
                          <small>
                            {Utils.countryNumberFormat((item.id === 1
                              ? tableMeta.rowData[3]
                              : item.id === 2
                              ? tableMeta.rowData[4]
                              : item.id === 3
                              ? tableMeta.rowData[5]
                              : item.id === 4
                              ? tableMeta.rowData[6]
                              : 0
                            ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                              ' ' +
                              tableMeta.rowData[8]}
                          </small>
                        </div>
                      );
                    })}
                    <div className="d-flex justify-content-between">
                      <small>
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_ADDITION_AMOUNT',
                          'Adaos GTM'
                        )}
                      </small>
                      <small>{Utils.countryNumberFormat(tableMeta.rowData[11].toFixed(2), this.props.appState.appUser?.organization?.country ?? null) + ' ' + tableMeta.rowData[8]}</small>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small className="mr-4">
                        {this.translatorService.Tranlate(
                          'CASE_CALCULATION_FORM_AMOUNT_WITHOUT_VAT_TOOLTIP',
                          'Baza de impozitare'
                        )}
                      </small>
                      <small>{Utils.countryNumberFormat(tableMeta.rowData[9].toFixed(2), this.props.appState.appUser?.organization?.country ?? null) + ' ' + tableMeta.rowData[8]}</small>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small>
                        {this.translatorService.Tranlate(
                          'CASE_CALCULATION_FORM_VAT_TOOLTIP',
                          'TVA'
                        ) +
                          ' (' +
                          TVA +
                          '%)'}
                      </small>
                      <small>{Utils.countryNumberFormat(tableMeta.rowData[10].toFixed(2), this.props.appState.appUser?.organization?.country ?? null) + ' ' + tableMeta.rowData[8]}</small>
                    </div>
                    <Divider style={{ background: '#ffffff' }} />
                    <div className="d-flex justify-content-between">
                      <small>
                        {this.translatorService.Tranlate(
                          'CASE_CALCULATION_FORM_TOTAL_TOOLTIP',
                          'TOTAL'
                        )}
                      </small>
                      <small>{Utils.countryNumberFormat(value.toFixed(2), this.props.appState.appUser?.organization?.country ?? null) + ' ' + tableMeta.rowData[8]}</small>
                    </div>
                  </div>
                }
              >
                <div>
                  <span>{Utils.countryNumberFormat(value.toFixed(2), this.props.appState.appUser?.organization?.country ?? null) + ' ' + tableMeta.rowData[8]}</span>
                </div>
              </Tooltip>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'currencyCode',
        label: this.translatorService.Tranlate('CASE_CALCULATION_FORM_CURRENCY_HEADER', 'Moneda'),
        options: {
          display: 'excluded',
          customBodyRender: (value: IReferential) => {
            return value;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'amountWithoutTVA',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amountTVA',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amountAddition',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'user',
        label: this.translatorService.Tranlate(
          'CASE_CALCULATION_FORM_CREATED_BY_HEADER',
          'Efectuat de'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: AppUser) => {
            return value.userName;
          }
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  closeDialog = async () => {
    if (this.state.modified) {
      this.setState({ isDialogModifiedOpen: true });
    } else {
      this.setState({ isDialogOpen: false, selectedCalculationButton: null });
    }
    await this.loadData();
  };

  closeAlternativeCalcDialog = async () => {
    // if (this.state.modified) {
    this.setState({ isDialogAlternativeCalculationOpen: false, rowsSelected: [] });
    // }
    await this.loadData();
  };

  closeQualityControlDialog = async () => {
    // if (this.state.modified) {
    this.setState({ isDialogQualityControlOpen: false, rowsSelected: [] });
    // }
    await this.loadData();
  };

  closeRadarDialog = async () => {
    // if (this.state.modified) {
    this.setState({ isDialogRadarOpen: false, rowsSelected: [] });
    // }
    await this.loadData();
  };

  closeMultipleTariffDialog = () => {
    this.setState({ isMultipleTariffDialogOpen: false, selectedCalculationTypeId: null });
  };

  loadOrganizationTariff = async () => {
    const tariffListBranches = [] as TariffBranch[];
    this.setState({ isOwnTariffLoading: true });
    try {
      const ownOrganizationTariffs = await this.tariffService.GetOwnTariffs(this.props.appState.appUser?.organizationId ?? 0);
      for (const x of ownOrganizationTariffs) {
        tariffListBranches.push({
          id: x!.id,
          ownerId: x!.ownerId,
          partnerId: x!.partnerId,
          tariffName: x!.name,
          children: [],
          path: null,
          label: x!.name,
          checked: false
        });
      }

      this.setState({ tariffBranches: tariffListBranches, isOwnTariffLoading: false });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      this.setState({ isOwnTariffLoading: false });
    }
    
  }

  handleCalculationsTypesButton = async (
    calculationTypeId: number,
    calculationTypeCode: string | null
  ) => {
    if (this.state.workflowCalculation!.hasMultipleTariff &&
      calculationTypeCode === CalculationTypeCode.ORG
    ) {
      this.setState({ 
        isMultipleTariffDialogOpen: true, 
        selectedCalculationTypeId: calculationTypeId
      }, async () => {
        await this.loadOrganizationTariff();
      });
      return;
    }

    if (calculationTypeCode === CalculationTypeCode.ORG) {
      const lastClientComment = this.state.caseRequests.length > 0 ? this.state.caseRequests[0] : null;
      if (this.state.workflowCalculation?.viewClientCommentsHistory && lastClientComment && !R.isEmpty(lastClientComment)) {
        this.setState({ 
          isClientCommentOpen: true, 
          orginalCalculationTypeId: calculationTypeId 
        });
        return;
      } else {
        await this.originalCalculationClick(calculationTypeId, null);
      }
      return;
    }

    if (calculationTypeCode === this.ACTION_INSURER_CALCULATION) {
      console.log(this.ACTION_INSURER_CALCULATION);
      console.log(calculationTypeId);
      await this.insurerCalculationClick(calculationTypeId);
      return;
    }

    if (calculationTypeCode === CalculationTypeCode.ALT) {
      if (
        this.state.rowsSelected.length === 0 &&
        !this.state.workflowCalculation?.alternativeCalculationWithoutOriginal
      ) {
        return;
      }

      await this.alternativeCalculationClick(calculationTypeId);
      return;
    }

    if (calculationTypeCode === this.ACTION_GT_VAL) {
      this.setState({ isValuationOpen: true });

      return;
    }

    if (calculationTypeCode === this.RADAR) {
      this.setState({ isDialogRadarOpen: true });

      return;
    }

    this.setState({
      selectedCalculationButton: calculationTypeCode,
      isDialogOpen: true,
      calculationHistoryIds: this.state.rowsSelected.map(
        (item) => this.state.caseCalculationHistory[item].id
      )
    });
  };

  alternativeCalculationClick = async (calculationTypeId: number) => {
    const unorderedParts = this.state.unorderedParts;
    if (unorderedParts.length > 0) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'UNORDERED_PARTS_WARNING',
          'Exista piese necomandate! Va rugam comandati piesele!'
        ),
        {
          variant: 'warning'
        }
      );
      return;
    }
    const orderList = this.state.orderList;
    const findOrder = _.find(orderList, function (order) {
      return order?.orderStatus?.code === OrderStatusCode.REP_DRAFT;
    });
    if (findOrder) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'ORDERS_IN_DRAFT_STATUS',
          'Exista comenzi cu statusul de Draft! Va rugam plasati comenzile!'
        ),
        {
          variant: 'warning'
        }
      );
      return;
    }

    let selectedCaseCalculationHistory = {} as CaseCalculationHistory;
    if (this.state.rowsSelected.length === 1) {
      selectedCaseCalculationHistory =
        this.state.caseCalculationHistory[this.state.rowsSelected[0]];
    }
    //if (this.state.rowsSelected.length === 0) {
    //  selectedCaseCalculationHistory = await this.caseService.CreateCalculationHistory({
    //    caseId: this.props.caseSettingsState.case!.id,
    //    formId: this.props.stepFormId,
    //    calculationId: null,
    //    calculationTypeId: calculationTypeId,
    //    workflowCalculationId: this.state.workflowCalculation!.id
    //  } as CalculationRequest)
    //}


    //Check if is Carfix integration and if we have packageCalculationId not null */
    if (this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration && this.state.packageCalculationId !== null) {
      //Check if the calculation is OE
      if (selectedCaseCalculationHistory.calculationType.code !== CalculationTypeCode.ORG) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('PLEASE_SELECT_THE_ORGINAL_CALCULATION', 'Vă rugăm să selectati o calculatie originală!'),
          { 
            variant: 'warning',
            autoHideDuration: 6000
          }
        );
        return;
      }

    }
    this.setState({
      selectedCalculationButton: null,
      isDialogAlternativeCalculationOpen: true,
      calculationHistoryIds:
        selectedCaseCalculationHistory.id !== null ? [selectedCaseCalculationHistory.id] : []
    });
  };

  originalCalculationClick = async (calculationTypeId: number, ownTariffId: number | null) => {
    this.setState({ isLoading: true });
    const result = await this.caseService.CreateCalculation({
      caseId: this.props.caseSettingsState.case!.id,
      formId: this.props.stepFormId,
      calculationId: null,
      calculationTypeId: calculationTypeId,
      workflowCalculationId: this.state.workflowCalculation!.id,
      vehicleId: this.props.caseSettingsState.case!.vehicleId,
      workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
      workflowOrganizationOwnerId:
        this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId,
      organizationId: this.props.appState.appUser!.organizationId,
      organizationName: this.props.appState.appUser!.organization!.name,
      takeOrganizationTariff: this.props.appState.appUser!.organization!.organizationType!.takeOrganizationTariff,
      insurerId: this.getInsurerId(),
      ownTariffId: ownTariffId
    } as CalculationRequest);
    if (!isNullOrUndefined(result.gtEstimateResponseDto.error)) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('ERROR_MSG', 'Eroare') +
          ': ' +
          result.gtEstimateResponseDto.error.errorInfo.description,
        { variant: 'error' }
      );
      this.setState({ isLoading: false });
      return;
    }

    if (this.props.appState.appUser!.organization!.changeCaseStatusBasedCalculation) {
      const status = _.find(this.state.caseStatuses, function (caseStatus) {
        return caseStatus.code === CaseStatusCodeEnum.OE;
      });
      const caseStatusRequest = {
        caseId: this.props.caseSettingsState.case!.id,
        statusId: status!.id
      } as ChangeCaseStatusRequest;
  
      const currentStatus = await this.caseService.ChangeCaseStatus(caseStatusRequest);
      this.props.SetCaseStatus(currentStatus);
  
    }
    window.location = result.gtEstimateResponseDto.codeUrl.url;
  };

  getInsurerId = () : number  | null => {
    if (isNullOrUndefined(this.state.workflowCalculation)) {
     return null;
    }

    if (!this.state.workflowCalculation.insurerCalculation) {
      return null;
    }
    
    if (R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      return null;
    }

    if (R.isNil(this.props.caseSettingsState.case!.caseEvent.insurerName)) {
      return null;
    }

    const insurerName = this.props.caseSettingsState.case!.caseEvent.insurerName;
    const insurer = _.find(this.state.insurerList, function (insurer) {
      return insurer.displayName.toLowerCase() === insurerName.toLowerCase();
    });
    console.log('insurer', insurer);
    if (R.isNil(insurer)) {
      return null;
    }
    return insurer.id;
  }

  getInsurerIdInfo = (insurerList: IReferential[]) : number  | null => {
    if (R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      return null;
    }

    if (R.isNil(this.props.caseSettingsState.case!.caseEvent.insurerName)) {
      return null;
    }

    const insurerName = this.props.caseSettingsState.case!.caseEvent.insurerName;
    const insurer = _.find(insurerList, function (insurer) {
      return insurer.displayName.toLowerCase() === insurerName.toLowerCase();
    });
    console.log('insurer', insurer);
    if (R.isNil(insurer)) {
      return null;
    }
    return insurer.id;
  }
  insurerCalculationClick = async (calculationTypeId: number) => {
    if (this.state.rowsSelected.length == 0) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('CALCULATION_PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'),
        { 
          variant: 'warning',
          autoHideDuration: 6000
        }
      );
      return;
    }

    const calculationId = this.state.caseCalculationHistory[this.state.rowsSelected[0]]?.id || null;
    this.setState({ isLoading: true });

    try {
      const result = await this.caseService.UpdateCalculationTariff({
        caseId: this.props.caseSettingsState.case!.id,
        formId: this.props.stepFormId,
        calculationId: null,
        parentId: calculationId,
        calculationTypeId: calculationTypeId,
        workflowCalculationId: this.state.workflowCalculation!.id,
        vehicleId: this.props.caseSettingsState.case!.vehicleId,
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        workflowOrganizationOwnerId:
          this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId,
        organizationId: this.props.appState.appUser!.organizationId,
        organizationName: this.props.appState.appUser!.organization!.name,
        takeOrganizationTariff: this.props.appState.appUser!.organization!.organizationType!.takeOrganizationTariff,
        insurerId: this.getInsurerIdInfo(this.state.insurerList),
        serviceId: this.state.ownerTariffPartner ? this.state.ownerTariffPartner!.partnerId : null,
        ownTariffId: null
      } as CalculationRequest) as CalculationResponse;

      this.setState({
        rowsSelected: [] 
      });

      if (!isNullOrUndefined(result.gtEstimateResponseDto.error)) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG', 'Eroare') +
            ': ' +
            result.gtEstimateResponseDto.error.errorInfo.description,
          { variant: 'error' }
        );
        this.setState({ isLoading: false});
        return;
      }

      if (!isNullOrUndefined(result.caseCalculationHistory)) {
        const newCalculationId = result.caseCalculationHistory.id;
        console.log('newCalculationId', newCalculationId);
        this.caseService.UpdateCalculationPdfCallback(
          newCalculationId,
          this.props.caseSettingsState.caseSettings!.workflow.id,
          this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId
        );
      }

      await this.loadData();
  
    } catch (error) {
      this.setState({ isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  reportClick = async (reportCode: string | null, name: string) => {
    this.setState({ openMenuItem: false });

    let fileBlob: Blob;
    if (reportCode === ReportTypeCode.DEVIZ_REP_AP) {
      const reportParameters = [
        {
          name: 'caseId',
          value: this.props.caseSettingsState.case!.id.toString()
        } as ReportParameter
      ];
      const calculation = this.state.caseCalculationHistoryCopy.find(x => x.calculationType.code === CalculationTypeCode.ALT);
      if (calculation) {
        reportParameters.push({
          name: 'calculationId',
          value: calculation.id.toString()
        } as ReportParameter);
      }

      if (this.state.lastServiceCalculation) {
        reportParameters.push({
          name: 'serviceCalculationId',
          value: this.state.lastServiceCalculation.id.toString()
        } as ReportParameter);
      }

      fileBlob = await this.reportsService.GetReport(reportCode!, reportParameters);
    } else if (reportCode === ReportTypeCode.ESTIMATE_OE) {
      fileBlob = await this.caseService.GetCaseCalculationReport(
        Number.parseInt(this.props.match.params.id),
        this.state.caseCalculationHistory[this.state.rowsSelected[0]].id
      );

      FileSaver.saveAs(fileBlob, name + '.pdf');
    } else if (
      reportCode == ReportTypeCode.DEVIZ_AUTOWORLD ||
      reportCode == ReportTypeCode.DEVIZ_CLIENT
    ) {
      const reportParameters = [
        {
          name: 'caseId',
          value: this.props.caseSettingsState.case!.id.toString()
        } as ReportParameter
      ];

      if (this.state.rowsSelected.length) {
        const calculationId =
          this.state.caseCalculationHistory[this.state.rowsSelected[0]]?.id || null;

        if (calculationId) {
          reportParameters.push({
            name: 'calculationId',
            value: this.state.caseCalculationHistory[this.state.rowsSelected[0]].id.toString()
          } as ReportParameter);
        }
      }
      fileBlob = await this.reportsService.GetReport(reportCode!, reportParameters);
    } else if (
      reportCode == ReportTypeCode.DEVIZ_REPARATIE
    ) {
      const reportParameters = [
        {
          name: 'caseId',
          value: this.props.caseSettingsState.case!.id.toString()
        } as ReportParameter,
        {
          name: 'organizationId',
          value: this.props.appState.appUser!.organization?.id.toString()
        } as ReportParameter
      ];
    
      if (this.state.rowsSelected.length) {
        const calculationId =
          this.state.caseCalculationHistory[this.state.rowsSelected[0]]?.id || null;

        if (calculationId) {
          reportParameters.push({
            name: 'calculationId',
            value: this.state.caseCalculationHistory[this.state.rowsSelected[0]].id.toString()
          } as ReportParameter);
        }
      }
      fileBlob = await this.reportsService.GetRepairReport(reportCode!, this.props.caseSettingsState.case!.id, reportParameters);
    } else {
      const reportParameters = [
        {
          name: 'caseId',
          value: this.props.caseSettingsState.case!.id.toString()
        } as ReportParameter
      ];

      if (this.props.caseSettingsState.case!.vehicleId !== null) {
        reportParameters.push({
          name: 'vehicleId',
          value: this.props.caseSettingsState.case!.vehicleId.toString()
        } as ReportParameter);
      }

      if (this.state.rowsSelected.length) {
        const calculationId =
          this.state.caseCalculationHistory[this.state.rowsSelected[0]]?.id || null;

        if (calculationId) {
          reportParameters.push({
            name: 'calculationId',
            value: this.state.caseCalculationHistory[this.state.rowsSelected[0]].id.toString()
          } as ReportParameter);
        }
      }

      fileBlob = await this.reportsService.GetReport(reportCode!, reportParameters);
      //FileSaver.saveAs(fileBlob, name + ".pdf");
    }

    if (
      reportCode == WorflowEditableReport.PROCES_VERBAL_PREDARE_PRIMIRE ||
      reportCode == WorflowEditableReport.FISA_EVALUARE_MASINA
    ) {
      if (reportCode == WorflowEditableReport.PROCES_VERBAL_PREDARE_PRIMIRE) {
        FileSaver.saveAs(fileBlob, name + '.doc');
      } else {
        FileSaver.saveAs(fileBlob, name + '.pdf');
      }
    } else {
      FileSaver.saveAs(fileBlob, name + '.pdf');
      //const file = new File([fileBlob], name + '.pdf', { type: 'application/pdf' });
      //const url = URL.createObjectURL(file);

     // window.open(url, '_blank');
    }
  };

  handleModifiedPropChanges = (modified: boolean) => {
    this.setState({ modified: modified });
  };

  closeModifiedDialog = async () => {
    this.setState({
      modified: false,
      isDialogModifiedOpen: false,
      isDialogOpen: false,
      selectedCalculationButton: null
    });
  };

  handleQualityControlButton = () => {
    this.setState({ isDialogQualityControlOpen: true });
  };

  handleConfirmChanges = async () => {
    this.setState({ executing: true });
    await this.submitForm(this.props.caseSettingsState.case!.id, this.props.workflowForm.id, null);
    this.setState({ executing: false });
  };

  getForms = (workflow: Workflow, code: string): WorkflowSectionStepForm | null => {
    let form = null;
    const sections = workflow
      .workflowSections!.filter((item) => item.isActive)
      .sort(this.sortFunction);
    // console.log('sections', sections);
    for (const section of sections) {
      const steps = section.workflowSectionSteps
        .filter((item) => item.isActive)
        .sort(this.sortFunction);
      // console.log('steps', steps);
      for (const step of steps) {
        const forms = step.workflowSectionStepForms
          .filter((item) => item.isActive)
          .sort(this.sortFunction);
        // console.log('forms', forms);
        const formIndex = forms.findIndex((item) => item.form.code === code);
        if (formIndex !== -1) {
          form = forms[formIndex];
        }
      }
    }
    return form;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  
  getWorkflowSectionStep = (workflowSectionStepId: number): WorkflowSectionStep | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        if (step.id == workflowSectionStepId) {
          return step;
        }
      }
    }
    return null;
  };
  
  public getErrorDetails(errorDetails: Map<string, string[]>): any {
    const text: string[] = [];
    for (const [property, error] of Object.entries(errorDetails)) {
      console.log(property);
      console.log(error);
      if (!R.isNil(error)) {
        text.push(`${property}:`);
        forEach(error, (value) => {
            text.push(`${value}`);
        });
      }
    }

    return (
      <div>
        {' '}
        {text.map((t, index) => (
          <div key={index}>{t}</div>
        ))}{' '}
      </div>
    );
  }

  lastOriginalCalculation = () : CaseCalculationHistory  => {
    const calculation = _.sortBy(this.state.caseCalculationHistory.filter(calculation => calculation.calculationType.code == CalculationTypeCode.ORG), 'date').reverse()[0];
    return calculation;
  }

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    const hasCalculationsOrEstimations =
      this.state.caseCalculationHistory.length > 0 ||
      this.props.caseSettingsState.case!.caseValuation != null;

    if (hasCalculationsOrEstimations) {
      /*
      Display pop-up info if we don't have an AM calculation done
      */
      const toStatusId = this.state.wfTransitionStepAction?.toStatusId;      
      const status = _.find(this.state.caseStatuses, function (caseStatus) {
        return caseStatus.code === CaseStatusCodeEnum.COM_PI;
      });
      
      if (this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration) {
        if (this.state.caseCalculationHistory.length > 0) {
          const firstCalculation = _.sortBy(this.state.caseCalculationHistory, 'date').reverse()[0];
          if (firstCalculation.calculationType.code == CalculationTypeCode.ORG) {
            return this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'REPAIR_CASE_CALCULATION_FORM_NO_AM_CALCULATION_DONE',
                'Vă rugăm să efectuaţi o calculaţie alternativă !'
              ),
              { variant: 'warning' }
            );
          }
        }
      }

      if (toStatusId == status!.id) {
        if (this.state.caseCalculationHistory.length > 0) {
          const firstCalculation = _.sortBy(this.state.caseCalculationHistory, 'date').reverse()[0];
          if (firstCalculation.calculationType.code == CalculationTypeCode.ORG) {
            return this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'CASE_CALCULATION_FORM_NO_AM_CALCULATION_DONE',
                'Pentru a trece in pasul de Comanda vă rugăm să efectuaţi o calculaţie alternativă !'
              ),
              { variant: 'warning' }
            );
          }
          if (firstCalculation.calculationType.code == CalculationTypeCode.ALT) {
            if (!firstCalculation.amount) {
              return this.props.enqueueSnackbar(
                this.translatorService.Tranlate(
                  'CASE_CALCULATION_FORM_AM__WARNING_CALCULATION_DONE',
                  'Vă rugăm să  interogati  piesele la furnizori!'
                ),
                { variant: 'warning' }
              );
            }
            const hasNoSupplierCalculationParts = _.find(this.state.partsForFirstCalculation, function(object) {
              return !object.supplierId && !object.isOwnStock;
            });    
            if (hasNoSupplierCalculationParts) {
              return this.props.enqueueSnackbar(
                this.translatorService.Tranlate(
                  'CASE_CALCULATION_FORM_AM__WARNING_CALCULATION_DONE',
                  'Vă rugăm să  interogati  piesele la furnizori!'
                ),
                { variant: 'warning' }
              );
            }

            const hasNoAlternativeGuideNumberCalculationParts = _.find(this.state.partsForFirstCalculation, function(object) {
              return !object.alternativeGuideNumber && !object.isOwnStock;
            });
            if (hasNoAlternativeGuideNumberCalculationParts) {
              return this.props.enqueueSnackbar(
                this.translatorService.Tranlate(
                  'CASE_CALCULATION_FORM_AM__WARNING_CALCULATION_DONE',
                  'Vă rugăm să  interogati  piesele la furnizori!'
                ),
                { variant: 'warning' }
              );
            }
      
          } 
        }
      }

      //Nu se muta in statusul comenzi piese
      /* Check if is PRC integration and if we have a case event */
      if (this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration && !R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
        const firstCalculation = _.sortBy(this.state.caseCalculationHistory, 'date').reverse()[0];
        /* Check if we have an ORG calculation done */
        if (firstCalculation.calculationType.code == CalculationTypeCode.ORG) {
          const updateOE = _.find(this.state.caseStatuses, function (caseStatus) {
            return caseStatus.code === CaseStatusCodeEnum.OE;
          });

          if (this.props.caseSettingsState.case!.caseStatus.caseStatusId == updateOE!.id) {
            this.setState({ isLoading: true });
            try {
              const result = await this.caseService.UpdateCalculationTariff({
                caseId: this.props.caseSettingsState.case!.id,
                formId: this.props.stepFormId,
                calculationId: null,
                parentId: firstCalculation.id,
                calculationTypeId: firstCalculation.calculationType.id,
                workflowCalculationId: this.state.workflowCalculation!.id,
                vehicleId: this.props.caseSettingsState.case!.vehicleId,
                workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
                workflowOrganizationOwnerId:
                  this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId,
                organizationId:  this.state.ownerTariffPartner ? this.state.ownerTariffPartner!.partnerId : this.props.appState.appUser!.organizationId,
                organizationName: this.props.appState.appUser!.organization!.name,
                takeOrganizationTariff: this.props.appState.appUser!.organization!.organizationType!.takeOrganizationTariff,
                insurerId: this.getInsurerIdInfo(this.state.insurerList),
                serviceId: this.state.ownerTariffPartner ? this.state.ownerTariffPartner!.partnerId : null,
                ownTariffId: null
              } as CalculationRequest) as CalculationResponse;
        
              if (!isNullOrUndefined(result.gtEstimateResponseDto.error)) {
                this.props.enqueueSnackbar(
                  this.translatorService.Tranlate('ERROR_MSG', 'Eroare') +
                    ': ' +
                    result.gtEstimateResponseDto.error.errorInfo.description,
                  { variant: 'error' }
                );
                this.setState({ isLoading: false});
                return;
              }
        
              if (!isNullOrUndefined(result.caseCalculationHistory)) {
                const newCalculationId = result.caseCalculationHistory.id;
                console.log('newCalculationId', newCalculationId);
                this.caseService.UpdateCalculationPdfCallback(
                  newCalculationId,
                  this.props.caseSettingsState.caseSettings!.workflow.id,
                  this.props.caseSettingsState.caseSettings!.workflow.organizationOwnerId
                );
              }
            } catch (error) {
              this.setState({ isLoading: false });
              this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
                variant: 'error'
              });
              return;
            }
          }
        }
      }

      //Carfix App Integration
      if (this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration) {
        try {
          const firstCalculation = _.sortBy(this.state.caseCalculationHistory, 'date').reverse()[0];
          console.log('Carfix App Integration');
          console.log('firstCalculation', firstCalculation);
          const request = {
            caseId: this.props.caseSettingsState.case!.id,
            calculationId: firstCalculation.id,
            workflowId: this.props.caseSettingsState.caseSettings!.workflow.id
          } as CarfixAlternativeCalculationRequest;
          const result = await this.caseService.CarfixAlternativeCalculation(request) as CarfixAlternativeCalculationResponse;
          if (!result.success) {
            this.props.enqueueSnackbar(this.translatorService.Tranlate(result.code, result.message), {
              variant: 'error'
            });
            return;
          }
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          return;
        }
      }

      let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

      if (newStatus === null) {
        newStatus = this.props.caseSettingsState.case!.caseStatus;
      }
      this.props.SetCaseStatus(newStatus);
      if (this.state.caseCalculationHistory.length > 0) {
        this.props.SetCaseCalculationHistory(this.state.caseCalculationHistory[0]);
      }
      if (appUser !== null) {
        this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
      }

      const hasRights = FormHelpers.HasRights(
        this.props.workflowForm.workflowFormPermissions,
        this.props.appState.appUser!,
        newStatus.caseStatusId
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      this.setState({ hasRights: hasRights });
    } else {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'CASE_CALCULATION_FORM_NO_CALCULATION_DONE',
          'Nu a fost efectuata nici o calculatie'
        ),
        { variant: 'error' }
      );
    }
  };

  getMuiTheme = () => createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                '@media (max-width:599.98px)': { 
                    borderBottom: "none",
                    padding: "1px",
                },
            },
        },
        MUIDataTableBodyCell: {
          stackedCommon: {
              '@media (max-width:599.98px)': { 
                  display: "block",
                  width: "100% !important",
                  whiteSpace: "normal",
                  height: "100%",
                //  fontSize: "12px",
                  paddingLeft: "5px",
                  paddingTop: "5px",
                  backgroundColor: "white !important"

              },
          },
          cellHide:{
              fontWeight: "bold",
              paddingTop: "15px",
          }
        },
        MuiTableRow: {
            root: {
              borderBottom: "1px solid rgba(224, 224, 224, 1)"
            }
        }
    } as any
  });

  showManipulation = (caseCalculationHistory: CaseCalculationHistory | null)  => {
    if (R.isNil(caseCalculationHistory))  {
      return false;
    }

    if (caseCalculationHistory.calculationType.code !== CalculationTypeCode.ALT) {
      return false;
    }

    if (caseCalculationHistory.manipulationPercent === 0) {
      return false;
    }

    return true;
  }

  displayValueWithCurrency = (value: number | null, calculationHistory: CaseCalculationHistory) => {
    let result = '0';
    if (!R.isNil(value)) {
      result = value.toFixed(2);
    }

    return result + ' ' + calculationHistory.currencyCode;
  };

  checkIntegrationRequirements = () => {
    //buton trimite oferta sa nu fie activ daca statusul e diferit de Calculatie A.M
    return this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration && (this.props.caseSettingsState.case!.caseStatus.caseStatus?.code !== CaseStatusCodeEnum.AM.toString());
  }

  findRecursive = (
    arr: TariffBranch[],
    id: number
  ): TariffBranch | null => {
    let result: TariffBranch | null = null;
    for (const entry in arr) {
      if (arr[entry].id == id) {
        result = arr[entry];
        break;
      }
      if (arr[entry].children?.length) {
        const partialResult = this.findRecursive(arr[entry].children, id);
        if (partialResult != null) {
          result = partialResult;
          break;
        }
      }
    }
    return result;
  };

  onDropDownChange = (currentNode: any, selectedNodes: any) => {
    // react-dropdown-tree-select doesn't have an intrinsic mechanism for keeping track of selected items, only provides what is selected
    // So we have to manually check/uncheck the leaves from this tree used by this component
    let newSelectionTariffBranch: TariffBranch | null = null;
    if (this.state.selectedTariffBranch) {
      const oldSelectionTariffBranch = this.findRecursive(
        this.state.tariffBranches,
        this.state.selectedTariffBranch.id
      );
      if (oldSelectionTariffBranch) {
        oldSelectionTariffBranch.checked = false;
      }
    }

    if (selectedNodes.length == 1) {
      const newPartialSelectionTariffBranch =
        this.findRecursive(
          this.state.tariffBranches,
          (selectedNodes[0] as TariffBranch)?.id
        ) || null;

      if (newPartialSelectionTariffBranch) {
        newPartialSelectionTariffBranch.checked = true;
        newSelectionTariffBranch = newPartialSelectionTariffBranch;
      }
    }

    this.setState({
      ...this.state,
      selectedTariffBranch: newSelectionTariffBranch
    });
  };

  onSubmitTariffForm = async () => {
    try {
      if (isNullOrUndefined(this.state.selectedTariffBranch)) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('TARIFF_MANDATORY', 'Tariful este obligatoriu!'), 
        {
          variant: 'error'
        });
        return;
      }
      this.setState({ isOwnTariffLoading: true });

      if (this.state.selectedCalculationTypeId) {
        await this.originalCalculationClick(this.state.selectedCalculationTypeId, this.state.selectedTariffBranch.id);
      }
      this.setState({ isOwnTariffLoading: false });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
    }
  };

  renderSelectTariffForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.onSubmitTariffForm();
        }}
      >
        <Grid container>
          <Grid item xs={12} className="mt-4">
            <div>
              {this.translatorService.Tranlate(
                'OWN_TARIFF_LABEL', 
                'Tariff'
              )} *
            </div>
            <div className="mt-1">
              <DropdownTreeSelect
                className="tariff-tree-select"
                data={this.state.tariffBranches}
                onChange={this.onDropDownChange}
                mode="radioSelect"
                texts={{
                  placeholder: this.translatorService.Tranlate(
                    'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CHOOSE_TEXT',
                    'Selecteaza'
                  )
                }}
                disabled={!this.state.hasRights}
              ></DropdownTreeSelect>
            </div>
          </Grid>
        </Grid> 
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeMultipleTariffDialog}
          >
            {this.translatorService.Tranlate('EDIT_SELECT_TARIFF_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_SELECT_TARIF_SAVE_BTN', 'Continua')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  handleCloseClientCommentModal = () => {
    this.setState({ 
      isClientCommentOpen: false,
      orginalCalculationTypeId: null
    });
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.reportsService = (this.context as AppContext).reportsService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.orderService = (this.context as AppContext).orderService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.tariffService = (this.context as AppContext).tariffService;
    console.log('workflowForm', this.props.workflowForm);
    const step = this.getWorkflowSectionStep(this.props.workflowForm.workflowSectionStepId);
    console.log('step', step?.workflowStep?.code == 'TR_ACC');
    const options = {
      viewColumns: false,
      filter: false,
      search: false,
      pagination: false,
      selectableRows: 'multiple' as SelectableRows,
      selectableRowsHeader: false,
      disableToolbarSelect: true,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 0,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected: any, allRows: any[]) => {
        const selected = allRows
          .map((item) => item.index)
          .sort(function (a, b) {
            return b - a;
          });
        if (allRows.length > 2) {
          this.setState({
            disableButtonForMoreThenOneRowChecked: true,
            disableButtonForMoreThenTwoRowsChecked: true,
            rowsSelected: selected
          });
        } else {
          if (allRows.length === 2) {
            this.setState({
              disableButtonForMoreThenOneRowChecked: true,
              disableButtonForMoreThenTwoRowsChecked: false,
              rowsSelected: selected
            });
          }

          if (allRows.length === 1) {
            this.setState({
              disableButtonForMoreThenOneRowChecked: false,
              disableButtonForMoreThenTwoRowsChecked: true,
              rowsSelected: selected
            });
          }
          if (allRows.length === 0) {
            this.setState({
              disableButtonForMoreThenOneRowChecked: true,
              disableButtonForMoreThenTwoRowsChecked: true,
              rowsSelected: selected
            });
          }
        }
      }
    };

    const offerTypeCode = this.props.caseSettingsState?.case?.caseType?.code;

    const caseParameters = this.props.caseSettingsState?.case?.caseParameters || [];
    let caseVatRate =
      caseParameters.find((x) => x.parameter?.code === ParametreCode.TVA)?.value || 0;
    caseVatRate = +caseVatRate;

    const caseValuation = this.props.caseSettingsState?.case?.caseValuation;

    const caseHasNoVAT = offerTypeCode === CaseTypeCode.AQUISITION && caseVatRate === 0;
    const vehicleHasNoVat =
      offerTypeCode === CaseTypeCode.SALE && this.props.vehicleState.vehicle?.purchaseVatRate === 0;

    const purchasePrice = (this.props.vehicleState.vehicle?.purchasePrice as number) || 0;

    let basePrice = 0;
    let tradeInPrice = 0;
    let offerPrice = 0;
    let retailPrice = 0;

    const basePriceLabel = this.translatorService.Tranlate(
      'VALUATION_BASE_PRICE_LABEL',
      'Pret de Baza (incl. TVA)'
    );
    const tradeInPriceLabel = 'Trade-in price (incl. VAT)';
    const offerPriceLabel = 'Offer price (incl. VAT)';
    const retailPriceLabel = 'Retail price (incl. VAT)';
    const accountingValueLabel = this.translatorService.Tranlate(
      'CASE_EVALUATION_ACCOUNTING_VALUE',
      'Valoare Contabila'
    );
    const dealerPriceLabel = this.translatorService.Tranlate(
      'CASE_EVALUATION_DEALER_PRICE',
      'Pret dealer'
    );
    const employPriceLabel = this.translatorService.Tranlate(
      'CASE_EVALUATION_EMPLOYEE_PRICE',
      'Pret angajat'
    );

    if (caseValuation) {
      basePrice = caseValuation.priceAsNew;
      tradeInPrice = caseValuation.tradeInPrice;
      offerPrice = caseValuation.offerPrice;
      retailPrice = caseValuation.retailPrice;

      //   if (caseHasNoVAT) {
      //     basePrice = Utils.getPriceWithoutVat(basePrice, caseValuation.vatRate);
      //     tradeInPrice = Utils.getPriceWithoutVat(tradeInPrice, caseValuation.vatRate);
      //     offerPrice = Utils.getPriceWithoutVat(offerPrice, caseValuation.vatRate);
      //     retailPrice = Utils.getPriceWithoutVat(retailPrice, caseValuation.vatRate);
      //   }

      //   if (vehicleHasNoVat) {
      //     tradeInPrice = Utils.getPriceWithoutVat(tradeInPrice, caseValuation.vatRate);
      //     offerPrice = Utils.getPriceWithoutVat(offerPrice, caseValuation.vatRate);
      //     retailPrice = Utils.getPriceWithoutVat(retailPrice, caseValuation.vatRate);

      //     tradeInPrice = Utils.getPriceWithMarginVat(tradeInPrice, +purchasePrice, caseVatRate);
      //     offerPrice = Utils.getPriceWithMarginVat(offerPrice, +purchasePrice, caseVatRate);
      //     retailPrice = Utils.getPriceWithMarginVat(retailPrice, +purchasePrice, caseVatRate);
      //   }
      // }

      // if (caseHasNoVAT) {
      //   tradeInPriceLabel = Utils.removeVatFromPriceLabel(tradeInPriceLabel);
      //   offerPriceLabel = Utils.removeVatFromPriceLabel(offerPriceLabel);
      //   retailPriceLabel = Utils.removeVatFromPriceLabel(retailPriceLabel);
      // }
    }

    const firstCalculation = this.state.caseCalculationHistoryCopy.length > 0 ? this.state.caseCalculationHistoryCopy[0] : null;
    console.log('firstCalculation', firstCalculation);
    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            <div className="d-flex flex-wrap m-2 mb-3 text-center">
              {this.state.caseCalculationActionTypeList
                .filter((x) => this.state.hasRights && !this.state.executing)
                .map((item) => (
                  <div className="w-20 mt-2 calculation-button" key={item.id}>
                    <Button
                      className="h-100 w-85 shadow-none"
                      disabled={
                        item.code === this.ACTION_COMPARE
                          ? this.state.disableButtonForMoreThenTwoRowsChecked
                          : item.code === CalculationTypeCode.ORG ||
                            item.code === CalculationTypeCode.ALT ||
                            item.code === this.ACTION_GT_VAL ||
                            item.code === this.RADAR ||
                            item.code === this.ACTION_INSURER_CALCULATION
                          ? false
                          : this.state.disableButtonForMoreThenOneRowChecked
                      }
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => this.handleCalculationsTypesButton(item.id, item.code)}
                    >
                      {item.displayName}
                    </Button>
                  </div>
                ))}
              {this.state.workflowCalculation !== null &&
              this.state.workflowCalculation!.qualityControlCertificate ? (
                <div className="w-20 mt-2 calculation-button">
                  <Button
                    className="h-100 w-15 shadow-none"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => this.handleQualityControlButton()}
                  >
                    {this.translatorService.Tranlate(
                      'CASE_CALCULATION_FORM_QUALITY_CONTROL_BUTTON',
                      'Control calitate'
                    )}
                  </Button>
                </div>
              ) : null}

              {this.state.workflowReports.length !== 0 ? (
                <div className="w-20 mt-2 calculation-button">
                  <Button
                    className="h-100 w-85 shadow-none"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      this.setState({ openMenuItem: e.currentTarget });
                    }}
                    disabled={!this.state.rowsSelected.length && !this.state.workflowReports.find((item) => item.report!.code === ReportTypeCode.DEVIZ_REP_AP)}
                  >
                    <span className="btn-wrapper--label">
                      {this.translatorService.Tranlate(
                        'CASE_CALCULATION_FORM_DOCUMENTS_BUTTON',
                        'Documente'
                      )}
                    </span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'angle-down']} />
                    </span>
                  </Button>

                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.openMenuItem}
                    keepMounted
                    open={Boolean(this.state.openMenuItem)}
                    onClose={(e) => {
                      this.setState({ openMenuItem: false });
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    {this.state.workflowReports.map((item) => {
                      if (
                        (item.report!.code === ReportTypeCode.ESTIMATE_OE && this.state.disableButtonForMoreThenOneRowChecked) ||
                        (item.report!.code === ReportTypeCode.ESTIMATE_OE && this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration && 
                        this.state.rowsSelected.length && this.state.caseCalculationHistory[this.state.rowsSelected[0]].calculationType.code == CalculationTypeCode.ALT.toString()) 
                      ) {
                        return null;
                      }
                      if (
                        (item.report!.code === ReportTypeCode.DEVIZ_AUTOWORLD &&
                          this.state.caseCalculationHistory.length == 0) ||
                        (item.report!.code === ReportTypeCode.DEVIZ_AUTOWORLD &&
                          this.state.disableButtonForMoreThenOneRowChecked)
                      ) {
                        return null;
                      }
                      if (
                        (item.report!.code === ReportTypeCode.DEVIZ_CLIENT &&
                          this.state.caseCalculationHistory.length == 0) ||
                        (item.report!.code === ReportTypeCode.DEVIZ_CLIENT &&
                          this.state.disableButtonForMoreThenOneRowChecked)
                      ) {
                        return null;
                      }
                      if (
                        (item.report!.code === ReportTypeCode.DEVIZ_REPARATIE && this.state.disableButtonForMoreThenOneRowChecked) ||
                        (item.report!.code === ReportTypeCode.DEVIZ_REPARATIE && this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration && 
                          this.state.rowsSelected.length && this.state.caseCalculationHistory[this.state.rowsSelected[0]].calculationType.code == CalculationTypeCode.ORG.toString()) 
                        ){
                        return null;
                      }
                      return (
                        <MenuItem
                          key={item.id}
                          onClick={(e) =>
                            this.reportClick(item.report!.code, item.report!.displayName)
                          }
                        >
                          {item.report!.displayName}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              ) : null}
            </div>
            {this.props.caseSettingsState.case!.caseValuation === null ? (
              ''
            ) : (
              <React.Fragment>
                <Divider />
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                  <h5 style={{ marginLeft: 8, marginTop: 8 }}>{this.translatorService.Tranlate('CASE_CALCULATION_EVALUATION_ROW', 'Evaluare')}</h5>
                    <Button
                      className="m-2"
                      variant="contained"
                      color="secondary"
                      size="medium"
                      onClick={() => {
                        this.setState({ showHistoryModal: true });
                      }}
                    >
                      {this.translatorService.Tranlate('CASE_CALCULATION_EVALUATION_HISTORY', 'Istoric Evaluari')}
                    </Button>
                </div>
        
                <Box className=" bg-secondary p-1" borderRadius={0} textAlign="center">
                  <Grid container>
                    <Grid item xs={3} style={{ lineHeight: '18px' }}>
                      <div>
                        <Label>{tradeInPriceLabel}</Label>
                      </div>
                      <div className="text-success">
                        <Label>{offerPriceLabel}</Label>
                      </div>
                      <div className="text-success">
                        <Label>{retailPriceLabel}</Label>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <h6>{tradeInPrice ? tradeInPrice.toFixed(2) : 0} €</h6>
                      <h6 className="text-success">{offerPrice ? offerPrice.toFixed(2) : 0} €</h6>
                      <h6 className="text-success">{retailPrice ? retailPrice.toFixed(2) : 0} €</h6>                   
                    </Grid>
                    <Grid item xs={3} className="d-flex justify-content-center align-items-center">
                      <Label>{basePriceLabel}</Label>
                    </Grid>
                    <Grid item xs={3} className="d-flex justify-content-center align-items-center">
                      <h6>{basePrice.toFixed(2)} €</h6>
                    </Grid>
                  </Grid>
                </Box>

              </React.Fragment>
            )}

            {this.props.appState?.appUser?.userRole?.code === 'MAVO_ADMIN' && this.props.caseSettingsState.case!.caseValuation !== null ? <React.Fragment>
              <Divider />
              <React.Fragment>
                <h5 style={{ marginLeft: 8, marginTop:8 }}>{accountingValueLabel}</h5>
                <Grid xs={3} style={{ marginLeft: 8, }}>
                    <h6 className="text-success">{this.state.accoutingValue ? this.state.accoutingValue.toFixed(2) : 0} €</h6> 
                </Grid>
              </React.Fragment>
              <Divider />
              <React.Fragment >
                <h5 style={{ marginLeft: 8, marginTop: 8 }}>{dealerPriceLabel}</h5>
                <Grid xs={3} style={{ marginLeft: 8, }} >
                  <h6  className="text-success">{this.state.discretionaryPriceDealer ? this.state.discretionaryPriceDealer.toFixed(2) : 0} €</h6>
                </Grid>
              </React.Fragment>
              <Divider />
              <React.Fragment>
                <h5 style={{ marginLeft: 8, marginTop: 8 }}>{employPriceLabel}</h5>
                <Grid xs={3} style={{ marginLeft: 8, }}  >
                  <h6 className="text-success">{this.state.discretionaryPriceEmployee ? this.state.discretionaryPriceEmployee.toFixed(2) : 0} €</h6>
                </Grid>
              </React.Fragment>
            </React.Fragment> : ''}

            <Divider />
            {!this.showManipulation(firstCalculation) ? (
              ''
            ) : (
              <React.Fragment>
                <Divider />
                <Box className="bg-secondary p-2 pl-4" borderRadius={0} textAlign="left">
                  <Grid container>
                    <Grid item xs={6} style={{ lineHeight: '18px' }}>
                      <div className="text-success">
                        <Label>{this.translatorService.Tranlate('CASE_CALCULATION_PARTS_VALUE', 'Valoare piese')}</Label>
                      </div>
                      <div className="text-success">
                        <Label> {this.translatorService.Tranlate('CASE_CALCULATION_TOTAL_SMALL_PIECES', 'Piese mici')}</Label>
                      </div>
                      <div className="text-success">
                        <Label>{this.translatorService.Tranlate('CASE_CALCULATION_MANIPULATION_PERCENT', 'Taxa manipulare')}</Label>
                      </div>
                      <div className="text-success">
                        <Label>{this.translatorService.Tranlate('CASE_CALCULATION_MANIPULATION_VALUE', 'Valoare taxa manipulare')}</Label>
                      </div>
                      <div className="text-success">
                        <Label>{this.translatorService.Tranlate('CASE_CALCULATION_LABOUR_VALUE', 'Valoare Manopera/Vopsitorie/Manopera Vopsitorie')}</Label>
                      </div>
                      <div className="text-success">
                        <Label>{this.translatorService.Tranlate('CASE_CALCULATION_TOTAL_SERVICE_VALUE', 'Total (val. taxa manipulare + val. man./vop./man. vop.)')}</Label>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <h6 className="text-success">{firstCalculation ? this.displayValueWithCurrency(firstCalculation.amountPieces, firstCalculation)
                        + ' (' + this.translatorService.Tranlate('WITHOUT_VAT', 'Fara TVA') +')'  : ''}</h6>
                      <h6 className="text-success">{firstCalculation ? this.displayValueWithCurrency(firstCalculation.smallPartsAquisitionValue, firstCalculation)
                        + ' (' + this.translatorService.Tranlate('WITHOUT_VAT', 'Fara TVA') +')' : '' }</h6>  
                      <h6 className="text-success">{firstCalculation ?  firstCalculation.manipulationPercent : ''} %</h6>
                      <h6 className="text-success">{firstCalculation ? this.displayValueWithCurrency(firstCalculation.manipulationValue, firstCalculation)
                                        + ' (' + this.translatorService.Tranlate('WITHOUT_VAT', 'Fara TVA') +')' : '' }</h6>
                      <h6 className="text-success">{this.state.lastServiceCalculation ? this.displayValueWithCurrency(this.state.lastServiceCalculation.amountLaborWithDiscount + this.state.lastServiceCalculation.amountPaintingMaterialsWithDiscount + this.state.lastServiceCalculation.amountPaintingLaborWithDiscount, this.state.lastServiceCalculation)
                              + ' (' + this.translatorService.Tranlate('WITHOUT_VAT', 'Fara TVA') +')' : '' }</h6>
                      <h6 className="text-success">{this.state.lastServiceCalculation && firstCalculation ? this.displayValueWithCurrency(firstCalculation.smallPartsAquisitionValue + firstCalculation.manipulationValue + this.state.lastServiceCalculation.amountLaborWithDiscount + this.state.lastServiceCalculation.amountPaintingMaterialsWithDiscount + this.state.lastServiceCalculation.amountPaintingLaborWithDiscount, firstCalculation)
                              + ' (' + this.translatorService.Tranlate('WITHOUT_VAT', 'Fara TVA') +')' : '' }</h6>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </React.Fragment>
            )}
            <div className="m-1">
              {this.state.caseCalculationHistory.length == 0 ? (<MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title={''}
                  data={this.state.caseCalculationHistory}
                  columns={this.getColumns()}
                  options={options}
                />
              </MuiThemeProvider>) : ( 
                <MUIDataTable
                  title={''}
                  data={this.state.caseCalculationHistory}
                  columns={this.getColumns()}
                  options={options}
                />
              ) }

            </div>

            <Dialog
              onClose={this.closeDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.isDialogOpen}
              fullScreen={this.state.selectedCalculationButton === null ? true : false}
              fullWidth={this.state.selectedCalculationButton === null ? false : true}
              maxWidth={this.state.selectedCalculationButton === null ? undefined : 'lg'}
            >
              {this.state.selectedCalculationButton === null ? (
                <DialogTitle
                  id="customized-dialog-title"
                  style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
                >
                  <div>
                    <Typography variant="h4" align="center">
                      {this.translatorService.Tranlate(
                        'CASE_CALCULATION_FORM_CALCULATION_DETAILS_MODAL_TITLE',
                        'Detalii Calculatie'
                      )}
                    </Typography>
                  </div>
                  <IconButton
                    aria-label="close"
                    className="position-absolute"
                    style={{ right: 12, top: 12, color: '#ffffff' }}
                    onClick={this.closeDialog}
                    size={'small'}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
              ) : this.state.selectedCalculationButton === this.ACTION_COMPARE ? (
                <DialogTitle
                  id="customized-dialog-title"
                  style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
                >
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={4} className="m-auto">
                      <Typography variant="h4" align="center">
                        {this.translatorService.Tranlate(
                          'CASE_CALCULATION_FORM_ITEMS_MODAL_TITLE',
                          'Repere'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography align="center">
                        {moment
                          .utc(this.state.caseCalculationHistory[this.state.rowsSelected[0]].date)
                          .local()
                          .toDate()
                          .toLocaleDateString(this.props.appState.language)}
                      </Typography>
                      <Typography align="center">
                        {this.state.caseCalculationHistory[this.state.rowsSelected[0]]!
                          .amountWithoutTVA +
                          ' ' +
                          this.state.caseCalculationHistory[this.state.rowsSelected[0]]!
                            .currencyCode}
                      </Typography>
                      <Typography align="center">
                        {
                          this.state.caseCalculationHistory[this.state.rowsSelected[0]]!.user!
                            .userName
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography align="center">
                        {moment
                          .utc(this.state.caseCalculationHistory[this.state.rowsSelected[1]]!.date)
                          .local()
                          .toDate()
                          .toLocaleDateString(this.props.appState.language)}
                      </Typography>
                      <Typography align="center">
                        {this.state.caseCalculationHistory[this.state.rowsSelected[1]]!
                          .amountWithoutTVA +
                          ' ' +
                          this.state.caseCalculationHistory[this.state.rowsSelected[1]]!
                            .currencyCode}
                      </Typography>
                      <Typography align="center">
                        {
                          this.state.caseCalculationHistory[this.state.rowsSelected[1]]!.user!
                            .userName
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogTitle>
              ) : null}

              <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                {this.state.selectedCalculationButton === null ? (
                  <CaseDetailsCaseTabCalculationDetails2
                    {...this.props}
                    handleModifiedPropChanges={this.handleModifiedPropChanges}
                    calculationHistoryId={this.state.calculationHistoryIds[0]}
                    onSave={this.closeDialog}
                    hasRights={this.state.hasRights}
                  />
                ) : this.state.selectedCalculationButton === this.ACTION_COMPARE ? (
                  <CaseDetailsCaseTabCompareCalculations
                    {...this.props}
                    calculationHistoryIds={this.state.calculationHistoryIds}
                  />
                ) : null}
              </DialogContent>
              {this.state.selectedCalculationButton === null ? null : this.state
                  .selectedCalculationButton === this.ACTION_COMPARE ? (
                <DialogActions>
                  <Button
                    className="shadow-none mr-4"
                    variant="contained"
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    {this.translatorService.Tranlate(
                      'CASE_CALCULATION_FORM_CLOSE_MODAL',
                      'Inchide'
                    )}
                  </Button>
                </DialogActions>
              ) : null}
            </Dialog>
            <div className="text-center">
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                onClick={this.handleConfirmChanges}
                disabled={!this.state.hasRights || this.state.executing || this.checkIntegrationRequirements()}
              >
                {this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration ? this.translatorService.Tranlate(
                  'CASE_CAR_CALCULATIONS_SEND_REPAIR_OFFER_BUTTON',
                  'Send repair Offer'
                ): step?.workflowStep?.code == 'TR_ACC' ?  this.translatorService.Tranlate(
                  'CASE_CALCULATIONS_SEND_TR_ACC_BUTTON',
                  'Trimite'
                ) : this.translatorService.Tranlate(
                  'CASE_CALCULATIONS_SEND_BUTTON',
                  'Salveaza'
                )}
              </Button>
            </div>
          </div>
        ) : null}

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogModifiedOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <div className="text-center p-5">
            <h4 className="font-weight-bold mt-4">
              {this.translatorService.Tranlate(
                'WARNING_UNSAVED_CHANGES',
                'Aveti modificari nesalvate. Sigur doriti sa iesiti?'
              )}
            </h4>
            <div className="pt-4">
              <Button
                onClick={this.closeModifiedDialog}
                variant="outlined"
                color="secondary"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_YES', 'Da')}
                </span>
              </Button>
              <Button
                onClick={() => {
                  this.setState({ isDialogModifiedOpen: false });
                }}
                color="primary"
                variant="contained"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_NO', 'Nu')}
                </span>
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          onClose={this.closeAlternativeCalcDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogAlternativeCalculationOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
          >
            <Typography className="MuiTypography-h4" align="center">
              {this.translatorService.Tranlate(
                'CASE_CALCULATION_FORM_ALTERNATIVE_CALCULATION_DETAILS_MODAL_TITLE',
                'Detalii calculatie alternativa'
              )}
            </Typography>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeAlternativeCalcDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <CaseDetailsAlternativeCalculationDetails
              {...this.props}
              calculationHistoryId={this.state.calculationHistoryIds[0]}
              orderList={this.state.orderList}
              stepFormId={this.props.stepFormId}
              closeDialog={this.closeAlternativeCalcDialog}
              manipulationTariff={this.state.manipulationTariff}
              insurerList={this.state.insurerList}
              serviceId={this.state.serviceId}
              additionTariff={this.state.additionTariff}
              discountTariff={this.state.discountTariff}
              packageCalculationId={this.state.packageCalculationId}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isValuationOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
          >
            <Typography component="div" variant="h4" align="center">
              {this.translatorService.Tranlate('VALUATION_DIALOG_HEADER', 'Evaluare Masina')}
            </Typography>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={(e) => {
                this.setState({ isValuationOpen: false });
              }}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
              <CaseDetailsValuation
                vin={null}
                firstRegistrationDate={null}
                {...this.props}
                workflowFlowCalculation={this.state.workflowCalculation}
              />
            </DialogContent>
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={this.closeQualityControlDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogQualityControlOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
          >
            <Typography className="MuiTypography-h4" align="center">
              {this.translatorService.Tranlate(
                'CASE_CALCULATION_FORM_QUALITY_CONTROL_MODAL_TITLE',
                'Control calitate'
              )}
            </Typography>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeQualityControlDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <CertificateOfControl />
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={this.closeRadarDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogRadarOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
          >
            <Typography className="MuiTypography-h4" align="center">
              Radar
            </Typography>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeRadarDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <Radar vehicleId={this.props.caseSettingsState.case?.vehicleId || undefined} />
          </DialogContent>
        </Dialog>
        <CaseValuationHistory 
          isOpen={this.state.showHistoryModal}
          close={() => this.setState({ showHistoryModal: false })}
          id={this.props.match.params.id}
        />
        {!this.state.workflowCalculation?.viewClientCommentsHistory ? null :  
          (this.state.isLoading ? null : (
            <CaseDetailsCaseRequests
              {...this.props}
              formId={this.props.workflowForm.id}
              caseRequests={this.state.caseRequests}
              carfixOfferComments={true}
            ></CaseDetailsCaseRequests>
          ))}
        {this.state.isMultipleTariffDialogOpen ? (
          <Dialog
            onClose={this.closeMultipleTariffDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isMultipleTariffDialogOpen}
            disableBackdropClick
            fullScreen={true}
            fullWidth={true}
            // maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
            >
              <Typography className="MuiTypography-h4" align="center">
                {this.translatorService.Tranlate('SELECT_TARIFF_HEADER', 'Selecteaza tarif')}
              </Typography>
              <IconButton
                aria-label="close"
                className="position-absolute"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={this.closeMultipleTariffDialog}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent 
              className="p-3" 
              dividers 
            >
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isOwnTariffLoading} />
              </div>
              {!this.state.isOwnTariffLoading ? (
                this.renderSelectTariffForm()
              ) : '' }
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
        <Modal open={this.state.isClientCommentOpen} onClose={this.handleCloseClientCommentModal}>
          <Dialog open={this.state.isClientCommentOpen} onClose={this.handleCloseClientCommentModal}>
            <DialogContent>
              <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {`${this.translatorService.Tranlate(
                  'CASE_CALCULATION_FORM_CLIENT_COMMENT_MODAL',
                  'Vă rugăm să țineți cont de solicitarea clientului - '
                )} `}
                {this.state.caseRequests.length > 0 ? (
                  <span className="timeline-item--label mb-2 color-red">
                    {this.state.caseRequests[0].text}
                  </span>
                ) : ''} !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => {
                this.handleCloseClientCommentModal();
                if (this.state.orginalCalculationTypeId) {
                  this.originalCalculationClick(this.state.orginalCalculationTypeId, null);
                }
              }}>
                {this.translatorService.Tranlate('CASE_CALCULATION_FORM_CLIENT_COMMENT_MODAL_YES', 'Am inteles')}
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabCalculationsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabCalculationsForm as any));
