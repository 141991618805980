import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Car } from '../agenda/agenda.types';
import { TranslatorService } from '../../services/TranslatorService';
import { useAppSelector } from '../../store/configureStore';
import { getDay } from '../../utils/appointments';

interface CarDetailsProps {
  selectedCar: Car | null;
  disabled?:boolean,
  type?:string
}

const CarDetails: React.FC<CarDetailsProps> = ({ selectedCar, disabled, type }) => {
  const translatorService = new TranslatorService();
  if (!selectedCar) {
    return null;
  }

  const CategoryAppointmentDetails = () =>{
    return <Box px={1} className='carDetailsGrid'>
      {selectedCar.plateNumber ? <Typography className='carDetailsField' style={disabled ? { color: '#00000061', borderColor: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_PLATE_NUMBER', 'Plate number')}: ${selectedCar.plateNumber}`}</Typography> :null}
      {selectedCar.vin ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_VIN', 'VIN')}: ${selectedCar.vin}`}</Typography>:null}
      {selectedCar.numberOfKilometers ? <Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_MILEAGE', 'Mileage')}: ${selectedCar.numberOfKilometers}`}</Typography> : null}
      {selectedCar.identifiedMake || selectedCar.model.brand ? <Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_MAKE', 'Make')}: ${selectedCar.identifiedMake ? selectedCar.identifiedMake : selectedCar.model.brand}`}</Typography>:null}
      {selectedCar.identifiedModel || selectedCar.model.name ? <Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_MODEL', 'Model')}: ${selectedCar.identifiedModel ? selectedCar.identifiedModel : selectedCar.model.name}`}</Typography>:null}
      {selectedCar.firstPlateDate ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_REGISTRATION_DATE', 'Registration date')}: `}{selectedCar.firstPlateDate ? getDay(new Date(selectedCar.firstPlateDate)) : ''}</Typography>:null}
      {selectedCar.identifiedFuelType ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_FUEL_TYPE', 'Fuel type')}: ${selectedCar.identifiedFuelType ?? ''}`}</Typography>:null}
      {selectedCar.engineSize ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_ENGINE_CAPACITY', 'Engine capacity')}: ${selectedCar.engineSize ?? ''}`}</Typography>:null}
      {selectedCar.power ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_POWER', 'Power (in KW)')}: ${selectedCar.power ?? ''}`}</Typography>:null}
      {selectedCar.identifiedCarBody ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_CAR_BODY', 'Car body')}: ${selectedCar.identifiedCarBody ?? ''}`}</Typography>:null}
      {selectedCar.motDate ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_REGULAR_MOT', 'Regular technical inspection until(MOT)')}: `}{selectedCar.motDate ? getDay(new Date(selectedCar.motDate)) : ''}</Typography>:null}
      {selectedCar.carColor ?<Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_COLOR', 'Color')}: ${selectedCar.carColor ?? ''}`}</Typography>:null}
      {selectedCar.engineType ? <Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_ENGINE_TYPE', 'Engine type')}: ${selectedCar.engineType ?? ''}`}</Typography>:null}
      {selectedCar.carCategory?.displayName ? <Typography className='carDetailsField' style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_VEHICLE_CATEGORY', 'Vehicle category')}: ${selectedCar.carCategory?.displayName ?? ''}`}</Typography>:null}
    </Box>
  }

  const CategoryAddAppointment = () =>{
    return <Box px={1} style={{ border: `2px solid ${disabled ? '#00000061' : '#1b6ec2'}`, borderRadius: 10, padding: 8, borderWidth: 2, display: 'flex', flexDirection: 'column', width: '100%', height:'90%' }}>
      {selectedCar?.plateNumber ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_PLATE_NUMBER', 'Plate number')}: ${selectedCar.plateNumber}`}</Typography>:null}
      {selectedCar?.vin ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_VIN', 'VIN')}: ${selectedCar.vin}`}</Typography>:null}
      {selectedCar?.identifiedMake || selectedCar?.model?.brand ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_MAKE', 'Make')}: ${selectedCar.identifiedMake ? selectedCar.identifiedMake : selectedCar.model.brand}`}</Typography>:null}
      {selectedCar?.identifiedModel || selectedCar?.model?.name ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_MODEL', 'Model')}: ${selectedCar.identifiedModel ? selectedCar.identifiedModel : selectedCar.model.name}`}</Typography>:null}
      {selectedCar?.identifiedCarBody ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_CAR_BODY', 'Car body')}: ${selectedCar.identifiedCarBody ?? ''}`}</Typography>:null}
      {selectedCar?.identifiedFuelType ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_FUEL_TYPE', 'Fuel type')}: ${selectedCar.identifiedFuelType ?? ''}`}</Typography>:null}
      {selectedCar?.firstPlateDate ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_REGISTRATION_DATE', 'Registration date')}: `}{selectedCar.firstPlateDate ? getDay(new Date(selectedCar.firstPlateDate)) : ''}</Typography>:null}
      {selectedCar?.motDate ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_REGULAR_MOT', 'Regular technical inspection until(MOT)')}: `}{selectedCar.motDate ? getDay(new Date(selectedCar.motDate)) : ''}</Typography>:null}
      {selectedCar?.engineSize ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_ENGINE_CAPACITY', 'Engine capacity')}: ${selectedCar.engineSize ?? ''}`}</Typography>:null}
      {selectedCar?.power ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_POWER', 'Power (in KW)')}: ${selectedCar.power ?? ''}`}</Typography>:null}
      {selectedCar?.engineType ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_ENGINE_TYPE', 'Engine type')}: ${selectedCar.engineType ?? ''}`}</Typography>:null}
      {selectedCar?.carCategory?.displayName ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_VEHICLE_CATEGORY', 'Vehicle category')}: ${selectedCar.carCategory?.displayName ?? ''}`}</Typography>:null}
      {selectedCar?.carColor ?<Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_VEHICLE_COLOR', 'Color')}: ${selectedCar.carColor ?? ''}`}</Typography>:null}
      {selectedCar?.numberOfKilometers ? <Typography style={disabled ? { color: '#00000061' } : {}} align="left">{`${translatorService.Tranlate('APPOINTMENT_CAR_DETAILS_MILEAGE', 'Mileage')}: ${selectedCar.numberOfKilometers}`}</Typography> : null}
    </Box>
  }

  return (<>
    {type === 'details' ? CategoryAppointmentDetails() : CategoryAddAppointment()}
  </>
  );
};

export default CarDetails;


