import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsActionCreators,
  VehicleActionCreators
} from '../../../store';
import { withSnackbar } from 'notistack';
import {
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Paper,
  Tooltip,
  InputAdornment,
  TextField,
  Modal,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ScaleLoader } from 'react-spinners';
import {
  ValuationVehicle,
  ValuationExtraOption,
  ValuationModel,
  ValuationBody,
  PlateNumber
} from '../../../interfaces/Case';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import {
  AttachmentTypeCode,
  CarDetailsSettings,
  CaseTypeCode,
  CurrencyCode,
  FuelTypeEnum,
  RefConstants,
  VehicleStatusCodeEnum
} from '../../../helpers/Constants';
import { AppUser } from '../../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferential, IReferentialModel } from '../../../interfaces/IReferential';
import { FormHelpers } from '../../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import CloseIcon from '@material-ui/icons/Close';
import CaseDetailsValuation from '../CaseDetailsValuation';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MaterialTable, { Column } from 'material-table';
import { Cost, Vehicle, VehicleOption } from '../../../interfaces/Vehicle';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import { Utils } from '../../../helpers/Utils';
import * as R from 'ramda';
import { ICaseDetailsCaseTabCarDetailsExtendedContainerState } from './ICaseDetailsCaseTabCarDetailsExtendedContainerState';
import { ExternalCaseDetailsCaseTabCarDetailsExtendedContainerProps } from './ExternalCaseDetailsCaseTabCarDetailsExtendedContainerProps';
import { ICaseDetailsCaseTabCarDetailsExtendedContainerProps } from './ICaseDetailsCaseTabCarDetailsExtendedContainerProps';
import { getLocalization, MaterialTableIcons } from '../../_shared/TableSettings';
import CaseDetailsCostDetailsForm from './CaseDetailsCostDetailsForm';
import _ from 'lodash';
import Loader from '../../Loader';

import manualVinIdentification from '../../../assets/images/vin/hand-pen-01.svg';
import MUIDataTable, { MUIDataTableColumnOptions, SelectableRows } from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';

type ParsedConditions = {
  mandatory: number[];
  atLeastOne: number[];
};

type OptionIds = 'includedExtraOptionIds' | 'excludedExtraOptionIds' | 'requiredExtraOptionIds';

type StatusProperties = 'isIncluded' | 'isRequired' | 'isExcluded';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

export const standardOptionsSchemaIds = [
  301, 2501, 3501, 3601, 23501, 23601, 23701, 23801, 23901, 24001, 31001, 32601, 34801, 38001,
  38701, 38801, 42101, 42901, 44501, 48401, 49001, 50001, 55601, 57901, 58201, 58301, 58401, 58501,
  58601, 59201, 59301, 59801, 63601, 63901
];

class CaseDetailsCaseTabCarDetailsExtendedContainer extends React.PureComponent<
  ICaseDetailsCaseTabCarDetailsExtendedContainerProps,
  ICaseDetailsCaseTabCarDetailsExtendedContainerState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  static displayName = 'CaseDetailsCaseTabCarDetailsExtendedForm';

  state = {
    isLoading: false,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    isValuationOpen: false,
    selectedVehicle: null,
    valuationVehicles: [] as ValuationVehicle[],
    valuationBodies: [] as ValuationBody[],
    valuationModels: [] as ValuationModel[],
    showWizard: false,
    attachType: {} as IReferential,
    personTypes: [] as IReferential[],
    brandTypes: [] as IReferential[],
    modelTypes: [] as IReferentialModel[],
    bodyTypes: [] as IReferential[],
    versionTypes: [] as IReferential[],
    fuelTypes: [] as IReferential[],
    vehicleTypes: [] as IReferential[],
    refurbishedList: [] as IReferential[],
    mvoDvBattypList: [] as IReferential[],
    polutionNormTypes: [] as IReferential[],
    transmissionTypes: [] as IReferential[],
    tractionTypes: [] as IReferential[],
    paintTypes: [] as IReferential[],
    tiresTypes: [] as IReferential[],
    rimsTypes: [] as IReferential[],
    colorTypes: [] as IReferential[],
    carInteriorStateTypes: [] as IReferential[],
    carExteriorStateTypes: [] as IReferential[],
    carMechanicalStateTypes: [] as IReferential[],
    carStandardOptions: [] as VehicleOption[],
    carOptionalOptions: [] as VehicleOption[],
    workflowCalculation: null,
    datePickerFirstRegistrationError: '',
    optionCodes: [] as string[],
    costValues: [] as Cost[],
    ignoreExistingMappingSwitch: false,
    ignoreTimeStampSwitch: false,
    carDetailsExtendedData: {
      id: 0,
      caseId: this.props.caseSettingsState.case ? this.props.caseSettingsState.case!.id : 0,
      workflowId: this.props.caseSettingsState.caseSettings
        ? this.props.caseSettingsState.caseSettings!.workflow.id
        : 0,
      plateNumber: '',
      vin: '',
      makeId: null,
      model: null,
      modelId: null,
      version: '',
      makeDate: '',
      firstRegistrationDate: '',
      kilometersNr: null,
      power: null,
      horsePower: null,
      engineSize: null,
      co2: null,
      inConditionComments: null,
      outConditionComments: null,
      technicalConditionComments: null,
      fuelTypeId: null,
      vehicleTypeId: null,
      pollutionNormId: null,
      transmissionTypeId: null,
      tractionTypeId: null,
      color: null,
      colorId: null,
      equipmentVersion: '',
      wheelsTypeId: null,
      tiresTypeId: null,
      paintTypeId: null,
      bodyTypeId: null,
      inConditionId: null,
      outConditionId: null,
      technicalConditionId: null,
      make: null,
      fuelType: null,
      vehicleType: null,
      pollutionNorm: null,
      transmissionType: null,
      tractionType: null,
      wheelsType: null,
      tiresType: null,
      paintType: null,
      bodyType: null,
      inCondition: null,
      outCondition: null,
      technicalCondition: null,
      vehicleOptions: [] as VehicleOption[],
      location: '',
      nrOfDoors: null,
      radioCode: '',
      engineManufactureCode: '',
      purchasePrice: '',
      refurbished: null,
      refurbishedId: null
    } as Vehicle,
    carDetailsFetchedData: {} as Partial<Vehicle>,
    isOpen: false,
    dialogVisible: false,
    isloadingIncludeExcludeOptions: false,
    includedExtraOptionIds: [],
    excludedExtraOptionIds: [],
    requiredExtraOptionIds: [],
    showOptionalRequiredOptions: false,
    optionalRequiredOptionsIds: []
  } as ICaseDetailsCaseTabCarDetailsExtendedContainerState;

  public componentDidMount() {
    if (!R.isNil(this.props.workflowForm)) {
      const caseSettings = this.props.caseSettingsState.caseSettings;
      const caseId = Number.parseInt(this.props.match.params.id);
      if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
        return;
      }
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseCarDetailsExtendedForm();
        await this.loadData();
      }
    );

    ValidatorForm.addValidationRule('isValidNumberOfKm', (value) => {
      if (value >= CarDetailsSettings.numberOfKm) {
        return false;
      }
      return true;
    });
  }

  loadCaseCarDetailsExtendedForm = async () => {
    const id = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(id)) {
      return;
    }

    const { personTypeRef, attachementTypeRef } = RefConstants;

    const [personTypeList, attachmentTypes] = await Promise.all([
      this.appReferentialService.Get(personTypeRef!.baseUrl),
      this.appReferentialService.Get(attachementTypeRef!.baseUrl)
    ]);

    const vehicleInfo = this.props.vehicleState.vehicle
      ? await this.vehicleService.GetVehicle(this.props.vehicleState.vehicle!.id as any)
      : null;
    this.props.vehicleState.vehicle = vehicleInfo;

    let hasRights = true;
    let caseCarDetailsExtended = this.state.carDetailsExtendedData;

    if (!R.isNil(this.props.workflowForm)) {
      hasRights = FormHelpers.HasRights(
        this.props.workflowForm!.workflowFormPermissions,
        this.props.appState.appUser!,
        this.props.caseSettingsState.case!.caseStatus.caseStatusId
      );

      const vehicle = this.props.vehicleState.vehicle;

      if (!R.isNil(vehicle)) {
        caseCarDetailsExtended = {
          ...vehicle,
          kilometersNr: vehicle.kilometersNr || null,
          power: vehicle.power || null,
          horsePower: vehicle.horsePower || null,
          engineSize: vehicle.engineSize || null,
          co2: vehicle.co2 || null,
          nrOfDoors: vehicle.nrOfDoors || null
        } as Vehicle;
      }
    } else {
      const vehicle = await this.vehicleService.GetVehicle(id as any);
      caseCarDetailsExtended = {
        ...vehicle,
        kilometersNr: vehicle.kilometersNr || null,
        power: vehicle.power || null,
        horsePower: vehicle.horsePower || null,
        engineSize: vehicle.engineSize || null,
        co2: vehicle.co2 || null,
        nrOfDoors: vehicle.nrOfDoors || null
      } as Vehicle;

      // if there is no workflowForm hasRights is computed from current status
      hasRights = FormHelpers.VehicleDetailsHasRights(
        caseCarDetailsExtended.currentStatus?.status?.code
      );
    }

    let extraOptions = caseCarDetailsExtended.vehicleOptions.filter((o) => !o.isStandard) as any;
    extraOptions = _.filter(extraOptions, function (object) {
      return object.isSelected;
    }).concat(
      _.filter(extraOptions, function (object) {
        return !object.isSelected;
      })
    );
    extraOptions.forEach((item: any) => {
      if (item.isSelected) {
        item.tableData = { checked: true };
        item.checked = true;
      }
    });

    let standardOptions = caseCarDetailsExtended.vehicleOptions.filter((o) => o.isStandard) as any;
    standardOptions = _.filter(standardOptions, function (object) {
      return object.isSelected;
    }).concat(
      _.filter(standardOptions, function (object) {
        return !object.isSelected;
      })
    );
    standardOptions.forEach((item: any) => {
      if (item.isSelected) {
        item.tableData = { checked: true };
        item.checked = true;
      }
    });

    this.setState({
      isLoading: false,
      hasRights: hasRights,
      isUserHidden: !hasRights,
      personTypes: personTypeList,
      carDetailsExtendedData: {
        ...caseCarDetailsExtended,
        workflowId: this.props.caseSettingsState.caseSettings
          ? this.props.caseSettingsState.caseSettings!.workflow.id
          : 0
      },
      carDetailsFetchedData: {
        ...caseCarDetailsExtended,
        workflowId: this.props.caseSettingsState.caseSettings
          ? this.props.caseSettingsState.caseSettings!.workflow.id
          : 0
      },
      attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
      carStandardOptions: standardOptions,
      carOptionalOptions: extraOptions
    });
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (R.isNil(newStatus)) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (!R.isNil(appUser)) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }
    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm!.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };

  updateOptionStatus(
    option: VehicleOption,
    stateProperty: OptionIds,
    statusProperty: StatusProperties
  ) {
    if (
      this.state[stateProperty] &&
      this.state[stateProperty]!.length !== 0 &&
      option.optionId &&
      this.state[stateProperty]!.includes(option.optionId)
    ) {
      option[statusProperty] = true;
    }
  }

  submitVehicleData = async (e: any) => {
    this.state.costValues.forEach(async (cost) => {
      if (cost.value) {
        await this.vehicleService.AddCost(cost);
      }
    });

    let newVehicle = { ...this.state.carDetailsExtendedData } as Vehicle;
    newVehicle.vehicleOptions = [];

    this.state.carStandardOptions.forEach((standardOption) => {
      standardOption.isSelected = standardOption.checked;
    });
    this.state.carOptionalOptions.forEach((optionalOption) => {
      optionalOption.isSelected = optionalOption.checked;
      // add included, excluded and required options
      this.updateOptionStatus(optionalOption, 'requiredExtraOptionIds', 'isRequired');
      this.updateOptionStatus(optionalOption, 'includedExtraOptionIds', 'isIncluded');
      this.updateOptionStatus(optionalOption, 'excludedExtraOptionIds', 'isExcluded');
    });

    newVehicle.vehicleOptions.push(...this.state.carStandardOptions);
    newVehicle.vehicleOptions.push(...this.state.carOptionalOptions);

    try {
      this.setState({ executing: true });

      let status = VehicleStatusCodeEnum.Available;

      if (this.props.caseSettingsState?.case?.caseType?.code === CaseTypeCode.AQUISITION) {
        status = VehicleStatusCodeEnum.Aquisition;
      }

      if (
        this.props.caseSettingsState?.case?.caseType?.code === CaseTypeCode.SALE ||
        this.props.caseSettingsState?.case?.caseType?.code === CaseTypeCode.OMV
      ) {
        status = VehicleStatusCodeEnum.ForSale;
      }

      if (this.state.carDetailsExtendedData.id === 0) {
        newVehicle = await this.caseService.AddVehicle(
          {
            vehicle: newVehicle,
            initialStatus: status
          },
          this.props.caseSettingsState.case!.id
        );
      } else {
        await this.vehicleService.UpdateVehicle(newVehicle);
        if (status === VehicleStatusCodeEnum.ForSale) {
          await this.vehicleService.ChangeVehicleStatus({
            vehicleId: newVehicle.id,
            statusId: status,
            comment: ''
          });
        }
      }

      if (!R.isNil(this.props.workflowForm)) {
        await this.submitForm(
          this.props.caseSettingsState.case!.id,
          this.props.workflowForm!.id,
          null
        );
      }

      if (newVehicle.valuationVehicleId && newVehicle.vin) {
        await this.caseService.ValuationSaveMapping(newVehicle.vin, newVehicle.valuationVehicleId);
      }
      await this.loadVehicleCosts();
      this.props.SetCaseVehicleData(newVehicle.id);
      this.props.setVehicleObject(newVehicle);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false,
        carDetailsExtendedData: {
          ...this.state.carDetailsExtendedData,
          id: newVehicle.id
        }
      });
    }
  };

  handleBrandTypeChange = async (e: any, newValue: IReferential | null) => {
    // if (R.isNil(this.state.selectedVehicle) && !R.isNil(newValue)) {
    //   const valuationModels = await this.caseService.GetValuationModels(
    //     newValue!.name!,
    //     new Date(this.state.carDetailsExtendedData.firstRegistrationDate)
    //   );
    //   const modelTypes = R.innerJoin(
    //     (stateModel, valuationModel) => stateModel.code === valuationModel.model,
    //     this.state.modelTypes,
    //     valuationModels
    //   ) as IReferential[] as any as ValuationModel[];

    //   this.setState({
    //     ...this.state,
    //     valuationModels: modelTypes
    //   });
    // }

    this.setState({
      ...this.state,
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        make: newValue,
        makeId: Utils.GetIdOrNull(newValue),
        model: null,
        modelId: null,
        bodyType: null,
        bodyTypeId: null
      }
    });
  };

  loadVehicleCosts = async () => {
    const [vehicleCosts] = await Promise.all([
      this.vehicleService.GetVehicleCosts(this.state.carDetailsExtendedData.id) as any as Cost[]
    ]);
    const newCostValues = this.state.costValues.map((cost: Cost) => {
      return { ...cost, files: [], value: null, secondValue: null };
    });
    this.setState({
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        vehicleCosts: vehicleCosts
      },
      costValues: newCostValues
    });
  };

  handleModelTypeChange = async (e: any, newValue: IReferential | null) => {
    // if (
    //   R.isNil(this.state.selectedVehicle) &&
    //   !R.isNil(newValue) &&
    //   !R.isNil(this.state.carDetailsExtendedData.make)
    // ) {
    //   const valuationBodies = await this.caseService.GetValuationBodies(
    //     this.state.carDetailsExtendedData.make!.name!,
    //     newValue!.name!,
    //     new Date(this.state.carDetailsExtendedData.firstRegistrationDate)
    //   );
    //   const bodyTypes = R.innerJoin(
    //     (stateBody, valuationBody) => stateBody.name === valuationBody.body,
    //     this.state.bodyTypes,
    //     valuationBodies
    //   ) as IReferential[] as any as ValuationBody[];

    //   this.setState({
    //     ...this.state,
    //     valuationBodies: bodyTypes
    //   });
    // }

    this.setState({
      ...this.state,
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        model: newValue,
        modelId: Utils.GetIdOrNull(newValue),
        bodyType: null,
        bodyTypeId: null
      }
    });
  };

  updateCostValue = (values: Cost[]) => {
    this.setState({ costValues: values });
  };

  onChange =
    (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
      if (fieldName === 'plateNumber') {
        const newValue = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ''); // Remove special characters from plate number input
        const updatedState = {
          ...this.state.carDetailsExtendedData,
          [fieldName]: newValue
        };

        if (fieldNameId) {
          updatedState[fieldNameId] = Utils.GetIdOrNull(newValue);
        }

        this.setState({
          carDetailsExtendedData: updatedState
        });
      } else {
        !R.isNil(fieldNameId)
          ? this.setState({
              carDetailsExtendedData: {
                ...this.state.carDetailsExtendedData,
                [fieldName]: newValue || e.target.value,
                [fieldNameId]: Utils.GetIdOrNull(newValue)
              }
            })
          : this.setState({
              carDetailsExtendedData: {
                ...this.state.carDetailsExtendedData,
                [fieldName]: newValue || e.target.value
              }
            });
      }
    };

  validateFirstRegistrationDate = (selectedDate?: string): string => {
    let error = '';

    if (!selectedDate) {
      error = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    } else {
      const currentDate = moment().format('YYYY-MM-DD');
      if (currentDate && selectedDate > currentDate) {
        error = this.translatorService.Tranlate(
          'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION_ERROR',
          'Data primei inmatriculari trebuie sa fie mai mica decat data curenta'
        );
      }
    }

    this.setState({
      datePickerFirstRegistrationError: error,
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        firstRegistrationDate: selectedDate || ''
      }
    });

    return error;
  };

  handleFirstRegistrationDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;
    this.validateFirstRegistrationDate(selectedDate);
  };

  handleYearOfFabricationDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        makeDate: date.format('YYYY-MM-DD')
      }
    });
  };

  setSelectedOptions = (extraoptions: ValuationExtraOption[], optionCodes: string[]) => {
    const indexArray: number[] = [];
    optionCodes.forEach((item) => {
      const index = extraoptions.findIndex((o) => o.optionCode === item);
      if (index !== undefined && index !== -1) {
        indexArray.push(index);

        (extraoptions[index] as any).tableData = { checked: true };
        extraoptions[index].checked = true;
      }
    });

    return indexArray;
  };

  private getModelsOptions(): IReferential[] | undefined {
    if (!R.isEmpty(this.state.valuationModels))
      return this.state.valuationModels as any as IReferential[];

    const filteredModelTypes = this.state.modelTypes.filter(
      (model) => model.makeId === this.state.carDetailsExtendedData.makeId
    );
    if (!R.isEmpty(filteredModelTypes)) return filteredModelTypes;

    return this.state.modelTypes;
  }

  private getBodiesOptions(): IReferential[] | undefined {
    if (!R.isEmpty(this.state.valuationBodies))
      return this.state.valuationBodies as any as IReferential[];

    return this.state.bodyTypes;
  }

  getValuationInfoConfirmation = async () => {
    const error = this.validateFirstRegistrationDate(
      this.state.carDetailsExtendedData.firstRegistrationDate
    );

    if (error) {
      return;
    }

    if (R.isEmpty(this.state.carDetailsExtendedData.vin)) return;

    if (
      !R.test(
        /^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/,
        this.state.carDetailsExtendedData.vin
      )
    ) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'INVALID_CAR_VIN_IDENTIFICATOR',
          "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"
        ),
        {
          variant: 'warning'
        }
      );
      return;
    }

    this.setState({ selectedVehicle: null, isOpen: true, dialogVisible: true }); //reset the vehicle brought from valuation
  };

  getValuationInfo = async () => {
    this.setState({ selectedVehicle: null, isOpen: false, dialogVisible: false });
    try {
      const body = {
        registration_date: new Date(this.state.carDetailsExtendedData.firstRegistrationDate!),
        ignore_date: false,
        override_existing_match: false
      };

      const result = await this.caseService.GetValuationVehiclesInfo(
        this.state.carDetailsExtendedData.vin,
        null,
        body
      );

      if (result.vehicles.length === 1) {
        result.vehicles[0].vinQuery = true;
        this.selectedVehicleHandler(result.vehicles[0], result.extraOptionCodes);
      } else {
        if (result.vehicles.length == 0) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'VIN_IDENTIFICATION_NOT_FOUND',
              'Identificarea VIN nu este posibilă , vă rugăm să continuaţi cu selectia manuală a autovehiculului!'
            ),
            {
              variant: 'warning',
              autoHideDuration: 5000
            }
          );
        }

        for (let i = 0; i < result.vehicles.length; i++) {
          result.vehicles[i].vinQuery = true;
        }

        this.setState({
          ...this.state,
          valuationVehicles: result.vehicles,
          optionCodes: result.extraOptionCodes,
          isValuationOpen: true
        });
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'VEHICLE_NOT_IDENTIFIED',
          'Masina nu se poate identifica dupa VIN si data primei inmatriculari. Va rugam incercati identificarea manuala'
        ),
        { variant: 'error' }
      );
    }
  };

  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  getManualValuationInfo = async () => {
    const error = this.validateFirstRegistrationDate(
      this.state.carDetailsExtendedData.firstRegistrationDate
    );

    if (error) {
      return;
    }

    if (R.isEmpty(this.state.carDetailsExtendedData.vin)) return;

    if (
      !R.test(
        /^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/,
        this.state.carDetailsExtendedData.vin
      )
    ) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'INVALID_CAR_VIN_IDENTIFICATOR',
          "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"
        ),
        {
          variant: 'warning'
        }
      );
      return;
    }
    this.setState({ selectedVehicle: null }); //reset the vehicle brought from valuation

    try {
      this.setState({
        ...this.state,
        valuationVehicles: [],
        optionCodes: [],
        isValuationOpen: true
      });
    } catch (err) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'VEHICLE_NOT_IDENTIFIED',
          'Masina nu se poate identifica dupa VIN si data primei inmatriculari. Va rugam incercati identificarea manuala'
        ),
        { variant: 'error' }
      );
    }
  };

  private resetVehicleState() {
    this.setState({
      ...this.state,
      isValuationOpen: false,
      selectedVehicle: null,
      carStandardOptions: [],
      carOptionalOptions: [],
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        fuelType: null,
        fuelTypeId: null,
        vehicleType: null,
        vehicleTypeId: null,
        mvoDvBattypId: null,
        mvoDvBattyp: null,
        model: null,
        modelId: null,
        transmissionType: null,
        transmissionTypeId: null,
        bodyType: null,
        bodyTypeId: null,
        make: null,
        makeId: null,
        version: '',
        equipmentVersion: '',
        kilometersNr: null,
        power: null,
        horsePower: null,
        engineSize: null,
        co2: null,
        inConditionComments: null,
        outConditionComments: null,
        technicalConditionComments: null,
        makeDate: '',
        engineManufactureCode: '',
        nrOfDoors: null,
        tractionType: null,
        tractionTypeId: null,
        color: null,
        colorId: null,
        paintType: null,
        paintTypeId: null,
        pollutionNorm: null,
        pollutionNormId: null
      }
    });
  }

  selectedVehicleHandler = async (selectedVehicle: any, optionsCodes: string[]) => {
    if (R.isNil(selectedVehicle)) return;

    const model = this.state.modelTypes.find((m) => m.code === selectedVehicle.model);
    const bodyType = this.state.bodyTypes.find((b) => b.code === selectedVehicle.body);
    const options = await this.caseService.GetValuationVehicleOptions(selectedVehicle.vehicleId);

    const fuelType = this.state.fuelTypes.find(
      (fuelType) => fuelType.name === selectedVehicle.fuelType
    );

    const vehicleType = this.state.vehicleTypes.find(
      (vehicleType) => vehicleType.name === selectedVehicle.vehicleType
    );

    const mvoDvBattyp = this.state.mvoDvBattypList.find(
      (mvoDvBattyp) => mvoDvBattyp.name === selectedVehicle.mvoDvBattyp
    );

    const refurbished = this.state.refurbishedList.find(
      (refurbishedItem) => refurbishedItem.name === selectedVehicle.refurbished
    );

    const transmissionType = this.state.transmissionTypes.find(
      (transmissionType) => transmissionType.code === selectedVehicle.transmission[0]
    );

    const make = this.state.brandTypes.find((make) => make.name! === selectedVehicle.manufacturer);

    const traction = this.state.tractionTypes.find(
      (tractionType) => tractionType.code === selectedVehicle.drivenWheels[0]
    );

    const pollutionNorm = this.state.polutionNormTypes.find(
      (x) => x.code === selectedVehicle.pollution
    );

    this.setSelectedOptions(options.extraOptions, optionsCodes);

    const valLanguageId = Utils.GetValuationLanguageId(this.props.appState.language);

    const carDetailsExtendedData = {
      ...this.state.carDetailsExtendedData,
      tractionType: traction || null,
      tractionTypeId: Utils.GetIdOrNull(traction),
      fuelType: fuelType || null,
      fuelTypeId: Utils.GetIdOrNull(fuelType),
      vehicleType: vehicleType || null,
      vehicleTypeId: Utils.GetIdOrNull(vehicleType),
      refurbishedId: Utils.GetIdOrNull(refurbished),
      refurbished: refurbished || null,
      mvoDvBattypId: Utils.GetIdOrNull(mvoDvBattyp),
      mvoDvBattyp: mvoDvBattyp || null,
      model: model || null,
      modelId: Utils.GetIdOrNull(model),
      transmissionType: transmissionType || null,
      transmissionTypeId: Utils.GetIdOrNull(transmissionType),
      make: make || null,
      makeId: Utils.GetIdOrNull(make),
      version: selectedVehicle.version || null,
      equipmentVersion: selectedVehicle.trimLevel !== undefined ? selectedVehicle.trimLevel : null,
      horsePower: selectedVehicle.powerHP || null,
      power: selectedVehicle.kw || null,
      engineSize: selectedVehicle.cc ?? 0,
      co2: selectedVehicle.co2 || 0,
      makeDate: selectedVehicle.fabricationDate,
      engineManufactureCode: selectedVehicle.engineManufactureCode ?? '',
      nrOfDoors: selectedVehicle.noOfDoors,
      bodyType: bodyType || null,
      bodyTypeId: Utils.GetIdOrNull(bodyType),
      pollutionNorm: pollutionNorm || null,
      pollutionNormId: Utils.GetIdOrNull(pollutionNorm),
      valuationVehicleId: selectedVehicle.vehicleId || null,
      vinQuery: selectedVehicle.vinQuery || false,
      propulsionSystem: selectedVehicle.propulsionSystem || null
    };
    // Since we need to make the fields that come from Vehicle Info API readonly after they are set
    // use a second state in order to allow the user edit the missing fields
    this.setState({
      carDetailsExtendedData,
      carDetailsFetchedData: {
        ...carDetailsExtendedData
      },
      isValuationOpen: false,
      selectedVehicle: selectedVehicle,
      carStandardOptions: options.standardOptions
        .filter(
          (item) =>
            item.languageId === valLanguageId && !standardOptionsSchemaIds.includes(item.schemaId)
        )
        .map((op) => {
          return {
            id: 0,
            code: '',
            name: op.content,
            isStandard: true,
            vehicleId: this.state.carDetailsExtendedData.id,
            checked: true
          } as VehicleOption;
        }),
      carOptionalOptions: options.extraOptions.map((op) => {
        return {
          id: 0,
          code: op.optionCode,
          name: op.description,
          isStandard: false,
          vehicleId: this.state.carDetailsExtendedData.id,
          checked: op.checked,
          price: op.price,
          optionId: op.optionId
        } as VehicleOption;
      })
    });
  };

  getCarStandardOptionsColumns = () => {
    return [
      {
        field: 'name',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_STANDARD_OPTION', 'Nume'),
        searchable: true
      } as Column<any>
    ];
  };

  getCarOptionalOptionsColumns = () => {
    return [
      {
        field: 'name',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_OPTIONAL_OPTION', 'Nume'),
        searchable: true,
        render: (rowData) => {
          const isIncluded = this.isIncluded(rowData.optionId);
          const isExcluded = this.isExcluded(rowData.optionId);
          const isRequired = this.isRequired(rowData.optionId);
          const style = {
            color: '#fff',
            borderRadius: '0.15rem',
            padding: '2px 5px',
            fontSize: '12px',
            fontWeight: 'bold',
            width: 'fit-content'
          };
          const excludedStyle = {
            backgroundColor: '#6c757d'
          };
          const includedStyle = {
            backgroundColor: '#28a745'
          };
          const mandatoryStyle = {
            backgroundColor: '#007bff'
          };

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {`${rowData.name}${rowData.price !== undefined ? ` - ${rowData.price} €` : ''}`}
              {isExcluded && <span style={{ ...style, ...excludedStyle }}>Indisponibila</span>}
              {isIncluded && (
                <span style={{ ...style, ...includedStyle }}>Inclusa in alt pachet</span>
              )}
              {isRequired && (
                <span style={{ ...style, ...mandatoryStyle }}>Obligatorie din alt pachet</span>
              )}
            </div>
          );
        }
      } as Column<any>,
      {
        field: 'code',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_OPTIONAL_CODE', 'Cod'),
        searchable: true
      } as Column<any>,
      {
        field: 'price',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_OPTIONAL_PRICE', 'Pret'),
        searchable: false,
        hidden: true
      } as Column<any>
    ];
  };

  loadData = async () => {
    const {
      vehicleMakeRef,
      bodyTypeRef,
      wheelsTypeRef,
      tractionTypeRef,
      transmissionTypeRef,
      fuelTypeRef,
      tiresTypeRef,
      paintTypeRef,
      vehicleConditionRef,
      pollutionNormRef,
      modelRef,
      colorRef,
      vehicleTypeRef,
      mvoDvBattypRef,
      refurbishedRef
    } = RefConstants;

    const [
      vehicleMakeList,
      bodyTypeList,
      wheelsTypeList,
      tractionTypeList,
      transmissionTypeList,
      fuelTypeList,
      tiresTypeList,
      paintTypeList,
      vehicleConditionList,
      pollutionNormList,
      modelTypesList,
      colorTypeList,
      vehicleTypeList,
      mvoDvBattypList,
      refurbishedList
    ] = await Promise.all([
      this.appReferentialService.Get(vehicleMakeRef?.baseUrl),
      this.appReferentialService.Get(bodyTypeRef!.baseUrl),
      this.appReferentialService.Get(wheelsTypeRef!.baseUrl),
      this.appReferentialService.Get(tractionTypeRef!.baseUrl),
      this.appReferentialService.Get(transmissionTypeRef!.baseUrl),
      this.appReferentialService.Get(fuelTypeRef!.baseUrl),
      this.appReferentialService.Get(tiresTypeRef!.baseUrl),
      this.appReferentialService.Get(paintTypeRef!.baseUrl),
      this.appReferentialService.Get(vehicleConditionRef!.baseUrl),
      this.appReferentialService.Get(pollutionNormRef!.baseUrl),
      this.appReferentialService.Get(modelRef!.baseUrl),
      this.appReferentialService.Get(colorRef!.baseUrl),
      this.appReferentialService.Get(vehicleTypeRef!.baseUrl),
      this.appReferentialService.Get(mvoDvBattypRef!.baseUrl),
      this.appReferentialService.Get(refurbishedRef!.baseUrl)
    ]);

    this.setState({
      isLoading: false,
      brandTypes: vehicleMakeList,
      bodyTypes: bodyTypeList,
      rimsTypes: wheelsTypeList,
      tractionTypes: tractionTypeList,
      transmissionTypes: transmissionTypeList,
      fuelTypes: fuelTypeList,
      tiresTypes: tiresTypeList,
      paintTypes: paintTypeList,
      carInteriorStateTypes: vehicleConditionList,
      carExteriorStateTypes: vehicleConditionList,
      carMechanicalStateTypes: vehicleConditionList,
      polutionNormTypes: pollutionNormList as IReferentialModel[],
      modelTypes: modelTypesList as IReferentialModel[],
      colorTypes: colorTypeList,
      vehicleTypes: vehicleTypeList,
      refurbishedList: refurbishedList,
      mvoDvBattypList: mvoDvBattypList
    });
  };

  onChangeFirstRegistrationDate = (date: Date | null) => {
    if (date === null) {
      return;
    }
    const selectedDate = date ? moment(date).format('YYYY-MM-DD') : '';

    this.setState({
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        firstRegistrationDate: selectedDate
      }
    });
  };

  standardOnRowAdd = (newData: VehicleOption): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        // newData.organizationId = this.props.organizationId;

        // await this.organizationService.AddOrganizationBankAccount(newData);
        // await this.loadData();
        this.setState({
          carStandardOptions: [
            ...this.state.carStandardOptions,
            {
              id: 0,
              vehicleId: this.state.carDetailsExtendedData.id,
              code: newData.code || '',
              name: newData.name,
              isStandard: true,
              checked: true,
              isSelected: true
            }
          ]
        });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });
  };

  standardOnRowUpdate = (newData: any, oldData?: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          //   newData.currency= null;
          //   await this.organizationService.UpdateOrganizationBankAccount(newData);
          //   await this.loadData();

          //this.setState({
          //  carStandardOptions: [
          //    ...this.state.carStandardOptions,
          //    {
          //      id: 0,
          //      vehicleId: this.state.carDetailsExtendedData.id,
          //      code: newData.code,
          //      name: newData.name,
          //      isStandard: false,
          //      checked: true
          //    }
          //  ]
          //});

          setTimeout(() => {
            const dataUpdate = [...this.state.carStandardOptions];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            this.setState({ carStandardOptions: [...dataUpdate] });

            this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
              variant: 'success'
            });
            resolve();
          }, 200);
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  standardOnRowDelete = (oldData: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          //   await this.organizationService.RemoveOrganizationBankAccount(oldData.id);
          //   await this.loadData();
          setTimeout(() => {
            const dataDelete = [...this.state.carStandardOptions];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            this.setState({ carStandardOptions: [...dataDelete] });
            this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
              variant: 'success'
            });
            resolve();
          }, 200);
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  optionalOnRowAdd = (newData: VehicleOption): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        // newData.organizationId = this.props.organizationId;

        // await this.organizationService.AddOrganizationBankAccount(newData);
        // await this.loadData();

        this.setState({
          carOptionalOptions: [
            ...this.state.carOptionalOptions,
            {
              id: 0,
              vehicleId: this.state.carDetailsExtendedData.id,
              code: newData.code,
              name: newData.name,
              isStandard: false,
              checked: true,
              isSelected: true
            }
          ]
        });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });
  };

  optionalOnRowUpdate = (newData: any, oldData?: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          //   newData.currency= null;
          //   await this.organizationService.UpdateOrganizationBankAccount(newData);
          //   await this.loadData();

          this.setState({
            carOptionalOptions: [
              ...this.state.carOptionalOptions,
              {
                id: 0,
                vehicleId: this.state.carDetailsExtendedData.id,
                code: newData.code,
                name: newData.name,
                isStandard: false,
                checked: true,
                isSelected: true
              }
            ]
          });

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  optionalOnRowDelete = (oldData: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          //   await this.organizationService.RemoveOrganizationBankAccount(oldData.id);
          //   await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  public renderCarStandardOptionsTable = () => {
    const data = this.state.carStandardOptions.map((o: VehicleOption) => {
      return { ...o, tableData: { checked: o.checked } };
    });
    return (
      <MaterialTable
        icons={MaterialTableIcons}
        title={''}
        columns={this.getCarStandardOptionsColumns()}
        data={data}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          selection: this.state.hasRights,
          selectionProps: () => ({
            disabled: !this.state.hasRights
          }),
          toolbar: this.state.hasRights
          // paging: false
        }}
        localization={getLocalization(this.translatorService)}
        editable={{
          onRowAdd: this.state.hasRights
            ? (newData: any) => this.standardOnRowAdd(newData)
            : undefined,
          onRowUpdate: (newData: any, oldData?: any) => this.standardOnRowUpdate(newData, oldData),
          /*onRowDelete: (oldData: any) => this.standardOnRowDelete(oldData),*/
          isEditHidden: (rowData: any) => !this.state.hasRights
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
        onSelectionChange={(rows) => {
          const standardOptions = [...this.state.carStandardOptions];
          standardOptions.forEach((item) => {
            item.checked = false;
          });
          rows.forEach((item) => {
            standardOptions[item.tableData.id].checked = true;
          });

          this.setState({
            carStandardOptions: standardOptions
          });
        }}
      />
    );
  };

  public renderCarOptionalOptionsTable = () => {
    const data = this.state.carOptionalOptions.map((o: VehicleOption) => {
      return { ...o, tableData: { checked: o.checked } };
    });
    return (
      <MaterialTable
        icons={MaterialTableIcons}
        title={''}
        columns={this.getCarOptionalOptionsColumns()}
        data={data}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          selection: this.state.hasRights,
          selectionProps: (rowData: any) => ({
            //checked: rowData.checked === true,
            disabled:
              !this.state.hasRights ||
              this.isIncluded(rowData.optionId) ||
              this.isExcluded(rowData.optionId) ||
              this.isRequired(rowData.optionId)
          }),
          toolbar: this.state.hasRights,
          rowStyle: (rowData) => ({
            color:
              this.isIncluded(rowData.optionId) ||
              this.isExcluded(rowData.optionId) ||
              this.isRequired(rowData.optionId)
                ? 'rgba(0, 0, 0, 0.54)'
                : ''
          })
          //paging: false,
        }}
        localization={getLocalization(this.translatorService)}
        editable={
          {
            // onRowAdd: (newData: any) => this.optionalOnRowAdd(newData),
            // onRowUpdate: (newData: any, oldData?: any) => this.optionalOnRowUpdate(newData, oldData),
            // onRowDelete: (oldData: any) => this.optionalOnRowDelete(oldData)
          }
        }
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
        onSelectionChange={async (rows) => {
          let extraOptions = [...this.state.carOptionalOptions];
          // Save the current state of selections for comparison
          const previousSelections = extraOptions.filter((opt) => opt.checked);

          extraOptions.forEach((item) => {
            item.checked = false;
          });
          rows.forEach((item) => {
            extraOptions[item.tableData.id].checked = true;
          });

          // Determine which items were newly selected or deselected
          const newSelections = extraOptions.filter((opt) => opt.checked);
          const newlySelected = newSelections.filter((opt) => !previousSelections.includes(opt));
          const newlyDeselected = previousSelections.filter((opt) => !newSelections.includes(opt));
          const lastSelectedItem =
            newlySelected.length > 0
              ? newlySelected[newlySelected.length - 1]
              : newlyDeselected[newlyDeselected.length - 1];

          const lastSelectedOptionId = lastSelectedItem.optionId ? lastSelectedItem.optionId : 0;
          const vehicleId = this.state.carDetailsExtendedData.valuationVehicleId
            ? this.state.carDetailsExtendedData.valuationVehicleId
            : 0;
          let finalIncludedOptionIds = this.state.includedExtraOptionIds
            ? Array.from(new Set([...this.state.includedExtraOptionIds]))
            : [];
          let finalExcludedOptionIds = this.state.excludedExtraOptionIds
            ? Array.from(new Set([...this.state.excludedExtraOptionIds]))
            : [];
          let finalRequiredOptionIds = this.state.requiredExtraOptionIds
            ? Array.from(new Set([...this.state.requiredExtraOptionIds]))
            : [];

          if (lastSelectedOptionId !== 0) {
            const { includedOptionIds, excludedOptionIds, requiredOptionIds } =
              await this.fetchIncludeAndExcludeOptions(lastSelectedOptionId, vehicleId);
            // if an option is selected
            if (newlySelected.length > 0) {
              if (includedOptionIds) {
                finalIncludedOptionIds = Array.from(
                  new Set([...finalIncludedOptionIds, ...includedOptionIds])
                );

                extraOptions = this.updateCheckedExtraOptionsStatus(
                  extraOptions,
                  includedOptionIds
                );
              }

              if (requiredOptionIds) {
                if (requiredOptionIds.mandatory.length > 0) {
                  finalRequiredOptionIds = Array.from(
                    new Set([...finalRequiredOptionIds, ...requiredOptionIds.mandatory])
                  );

                  extraOptions = this.updateCheckedExtraOptionsStatus(
                    extraOptions,
                    requiredOptionIds.mandatory,
                    true
                  );
                }

                if (requiredOptionIds.atLeastOne.length > 0) {
                  this.setState({
                    showOptionalRequiredOptions: true,
                    optionalRequiredOptionsIds: requiredOptionIds.atLeastOne
                  });
                }
              }

              if (excludedOptionIds) {
                finalExcludedOptionIds = Array.from(
                  new Set([...finalExcludedOptionIds, ...excludedOptionIds])
                );

                extraOptions = this.updateCheckedExtraOptionsStatus(
                  extraOptions,
                  excludedOptionIds
                );
              }

              extraOptions = [...extraOptions];
            }

            // if an option is deselected
            if (newlyDeselected.length > 0) {
              if (includedOptionIds) {
                finalIncludedOptionIds = this.removeIndexes(
                  finalIncludedOptionIds,
                  includedOptionIds
                );

                extraOptions = this.updateCheckedExtraOptionsStatus(
                  extraOptions,
                  includedOptionIds
                );
              }

              if (requiredOptionIds) {
                if (requiredOptionIds.mandatory.length > 0) {
                  finalRequiredOptionIds = this.removeIndexes(
                    finalRequiredOptionIds,
                    requiredOptionIds.mandatory
                  );
                  finalExcludedOptionIds = this.removeIndexes(
                    finalExcludedOptionIds,
                    requiredOptionIds.mandatory
                  );

                  extraOptions = this.updateCheckedExtraOptionsStatus(
                    extraOptions,
                    requiredOptionIds.mandatory
                  );
                }

                if (requiredOptionIds.atLeastOne.length > 0) {
                  finalRequiredOptionIds = this.removeIndexes(
                    finalRequiredOptionIds,
                    requiredOptionIds.atLeastOne
                  );
                  finalExcludedOptionIds = this.removeIndexes(
                    finalExcludedOptionIds,
                    requiredOptionIds.atLeastOne
                  );

                  extraOptions = this.updateCheckedExtraOptionsStatus(
                    extraOptions,
                    requiredOptionIds.atLeastOne
                  );
                }
              }

              if (excludedOptionIds) {
                finalExcludedOptionIds = this.removeIndexes(
                  finalExcludedOptionIds,
                  excludedOptionIds
                );

                extraOptions = this.updateCheckedExtraOptionsStatus(
                  extraOptions,
                  excludedOptionIds
                );
              }
            }
          }

          finalExcludedOptionIds = this.removeIndexes(
            finalExcludedOptionIds,
            finalRequiredOptionIds
          );
          finalIncludedOptionIds = this.removeIndexes(
            finalIncludedOptionIds,
            finalRequiredOptionIds
          );

          this.setState({
            carOptionalOptions: extraOptions,
            includedExtraOptionIds: finalIncludedOptionIds,
            excludedExtraOptionIds: finalExcludedOptionIds,
            requiredExtraOptionIds: finalRequiredOptionIds
          });
        }}
      />
    );
  };

  fetchIncludeOptions = async (optionId: number, vehicleId: number): Promise<number[] | null> => {
    let includeOptionsRequest = null;

    try {
      includeOptionsRequest = await this.caseService.GetIncludeOptions(optionId, vehicleId);
    } catch (error) {}

    if (includeOptionsRequest) {
      const extractedIndexes = this.extractIndexes(includeOptionsRequest.rule);
      return extractedIndexes;
    }

    return includeOptionsRequest;
  };

  fetchExcludeOptions = async (optionId: number, vehicleId: number): Promise<number[] | null> => {
    let excludeOptionsRequest = null;

    try {
      excludeOptionsRequest = await this.caseService.GetExcludeOptions(optionId, vehicleId);
    } catch (error) {}

    if (excludeOptionsRequest) {
      const extractedIndexes = this.extractIndexes(excludeOptionsRequest.rule);
      return extractedIndexes;
    }

    return excludeOptionsRequest;
  };

  fetchRequiredOptions = async (
    optionId: number,
    vehicleId: number
  ): Promise<ParsedConditions | null> => {
    let requiredOptionsRequest = null;

    try {
      requiredOptionsRequest = await this.caseService.GetRequiredOptions(optionId, vehicleId);
    } catch (error) {}

    if (requiredOptionsRequest) {
      const parsedConditions = this.parseRequiredConditions(requiredOptionsRequest.rule);
      return parsedConditions;
    }

    return requiredOptionsRequest;
  };

  fetchIncludeAndExcludeOptions = async (optionId: number, vehicleId: number) => {
    let includedOptionIds = null;
    let excludedOptionIds = null;
    let requiredOptionIds = null;

    try {
      this.setState({ isloadingIncludeExcludeOptions: true });

      includedOptionIds = await this.fetchIncludeOptions(optionId, vehicleId);
      excludedOptionIds = await this.fetchExcludeOptions(optionId, vehicleId);
      requiredOptionIds = await this.fetchRequiredOptions(optionId, vehicleId);
    } catch (error) {
      this.setState({ isloadingIncludeExcludeOptions: false });
    }

    this.setState({ isloadingIncludeExcludeOptions: false });
    return {
      includedOptionIds,
      excludedOptionIds,
      requiredOptionIds
    };
  };

  removeIndexes(excludedOptionsIndexes: number[], excludedIndexes: number[]): number[] {
    return excludedOptionsIndexes.filter((item) => !excludedIndexes.includes(item));
  }

  extractIndexes = (input: string): number[] => {
    // Use regular expression to match all numbers within braces
    const matches = input.match(/\{(\d+)\}/g);

    // If no matches found, return an empty array
    if (!matches) {
      return [];
    }

    // Extract the numbers and convert them to integers
    const indexes = matches.map((match) => parseInt(match.replace(/[{}]/g, ''), 10));

    return indexes;
  };

  isIncluded = (optionId: number) => {
    if (!this.state.includedExtraOptionIds) return;

    return this.state.includedExtraOptionIds.includes(optionId);
  };

  isExcluded = (optionId: number) => {
    if (!this.state.excludedExtraOptionIds) return;

    return this.state.excludedExtraOptionIds.includes(optionId);
  };

  isRequired = (optionId: number) => {
    if (!this.state.requiredExtraOptionIds) return;

    return this.state.requiredExtraOptionIds.includes(optionId);
  };

  parseRequiredConditions(input: string): ParsedConditions {
    const mandatory: number[] = [];
    const atLeastOne: number[] = [];

    // Regex for extracting all conditions (e.g., {1234})
    const allConditionsRegex = /{(\d+)}/g;
    let match;

    // Collect all numbers within curly braces
    const allConditions: number[] = [];
    while ((match = allConditionsRegex.exec(input)) !== null) {
      allConditions.push(parseInt(match[1], 10));
    }

    // Check if the input contains 'AND' or 'OR'
    if (input.includes('AND')) {
      // Regex for extracting mandatory conditions before 'AND'
      const mandatoryPart = input.split(' AND ')[0];
      const mandatoryRegex = /{(\d+)}/g;
      let mandatoryMatch;
      while ((mandatoryMatch = mandatoryRegex.exec(mandatoryPart)) !== null) {
        mandatory.push(parseInt(mandatoryMatch[1], 10));
      }

      // Regex for extracting optional conditions within parentheses
      const optionalPartMatch = input.match(/\(([^)]+)\)/);
      if (optionalPartMatch) {
        const optionalRegex = /{(\d+)}/g;
        let optionalMatch;
        while ((optionalMatch = optionalRegex.exec(optionalPartMatch[1])) !== null) {
          atLeastOne.push(parseInt(optionalMatch[1], 10));
        }
      }
    } else if (input.includes('OR')) {
      // If there are no 'AND' conditions, treat all as optional
      for (const num of allConditions) {
        atLeastOne.push(num);
      }
    } else {
      // If no logical operator is present, all conditions are mandatory
      mandatory.push(...allConditions);
    }

    return {
      mandatory,
      atLeastOne
    };
  }

  updateCheckedExtraOptionsStatus = (
    extraOptions: VehicleOption[],
    includedOptionIds: number[],
    addIndexes?: boolean // if true, add indexes instead of removing them
  ) => {
    if (!includedOptionIds) return extraOptions;

    addIndexes = addIndexes ? addIndexes : false;

    extraOptions.forEach((item) => {
      if (!item.optionId) return;

      if (includedOptionIds.includes(item.optionId) && !addIndexes) {
        item.checked = false;
      } else if (includedOptionIds.includes(item.optionId) && addIndexes) {
        item.checked = true;
      }
    });

    return extraOptions;
  };

  convertKwToHp = (kw: number) => (R.isEmpty(kw) ? '' : Math.round(kw * 1.36));
  convertHpToKw = (hp: number) => (R.isEmpty(hp) ? '' : Math.round(hp / 1.36));

  handlePowerChange = (e: any) => {
    this.setState({
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        power: e.target.value,
        horsePower: this.convertKwToHp(e.target.value)
      }
    });
  };

  handleChassisNumberChange = (e: any) => {
    this.setState(
      {
        carDetailsExtendedData: {
          ...this.state.carDetailsExtendedData,
          vin: e.target.value
        }
      },
      () =>
        R.test(
          /^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/,
          this.state.carDetailsExtendedData.vin
        ) && this.resetVehicleState()
    );
  };

  handleHPChange = (e: any) => {
    this.setState({
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        horsePower: e.target.value,
        power: this.convertHpToKw(e.target.value)
      }
    });
  };

  isDisabled = (fieldValue: any): boolean => {
    if (this.state.carDetailsExtendedData.currentStatus?.status?.code !== 'Available') {
      return !this.state.hasRights;
    }

    return !this.state.hasRights || fieldValue ? true : false;
  };

  checkIfIsElectric = (): boolean => {
    if (
      this.state.carDetailsExtendedData.fuelType &&
      this.state.carDetailsExtendedData.fuelType.code === FuelTypeEnum.E
    ) {
      //PHEV - "propulsionSystem": "PHEV(Vehic electr plug-in hybrid)"
      if (this.state.carDetailsExtendedData.propulsionSystem && this.state.carDetailsExtendedData.propulsionSystem.includes('PHEV')) {
        return false;
      }
      //HEV = "propulsionSystem": "hybrid",
      if (this.state.carDetailsExtendedData.propulsionSystem == 'hybrid') {
        return false;
      }

      return true;
    }
    return false;
  };

  handlePlateNumberBlur = async (plateNumber: PlateNumber) => {
    if (
      this.props.caseSettingsState.caseSettings!.workflow.isDgpciIntegration &&
      plateNumber.PlateNumber.length > 0
    ) {
      try {
        const vinRequest = await this.caseService.GetVinByPlateNumberRequest(plateNumber);

        if (vinRequest.success) {
          this.setState({
            carDetailsExtendedData: {
              ...this.state.carDetailsExtendedData,
              vin: vinRequest.vin
            }
          });

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } else {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('ERROR_MSG_VIN', 'Eroare la obtinerea VIN'),
            {
              variant: 'error'
            }
          );
        }
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  renderValidators = (rules: string[]) => {
    if (this.props.type !== 'history') {
      return rules;
    }
    return [];
  };

  rendeClientDataForm = () => {
    const showAquisitionPrice =
      this.state.carDetailsExtendedData.currentStatus?.statusId == VehicleStatusCodeEnum.Aquisition
        ? false
        : true;
    const saleCase =
      this.props.caseSettingsState?.case?.caseType?.code === CaseTypeCode.SALE ? true : false;

    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitVehicleData(e);
        }}
      >
        <div className="m-3 text-center">
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.isValuationOpen}
            maxWidth={'lg'}
            fullWidth={true}
          >
            <DialogTitle
              id="customized-dialog-title"
              style={{
                color: '#ffffff',
                backgroundColor: '#1F2857'
              }}
            >
              <Typography component="div" variant="h4" align="center">
                {this.translatorService.Tranlate('VALUATION_DIALOG_HEADER', 'Selectare vehicul')}
              </Typography>
              <IconButton
                aria-label="close"
                className="position-absolute"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={(e) => {
                  this.setState({ isValuationOpen: false });
                }}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
              <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                <CaseDetailsValuation
                  {...this.props}
                  vin={this.state.carDetailsExtendedData.vin}
                  firstRegistrationDate={
                    new Date(this.state.carDetailsExtendedData.firstRegistrationDate)
                  }
                  workflowFlowCalculation={this.state.workflowCalculation}
                  onSelectedVehicle={this.selectedVehicleHandler}
                  valuationVehicles={this.state.valuationVehicles}
                  brandTypes={this.state.brandTypes}
                  modelTypes={this.state.modelTypes}
                  bodyTypes={this.state.bodyTypes}
                  optionCodes={this.state.optionCodes}
                  fuelTypes={this.state.fuelTypes}
                  onChangeFirstRegistrationDate={this.onChangeFirstRegistrationDate}
                />
              </DialogContent>
            </DialogContent>
          </Dialog>
          <Grid container spacing={0}>
            <Grid item xs={showAquisitionPrice ? 6 : 12}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="plate-number"
                className="m-2 pr-3"
                name="plateNumber"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_PLATE_NUMBER',
                  'Numar inmatriculare'
                )}
                value={this.state.carDetailsExtendedData.plateNumber}
                onChange={(e: any) => {
                  this.onChange('plateNumber')(e);
                }}
                error={!Utils.validatePlateNumber(this.state.carDetailsExtendedData.plateNumber)}
                helperText={
                  Utils.validatePlateNumber(this.state.carDetailsExtendedData.plateNumber)
                    ? ''
                    : this.translatorService.Tranlate(
                        'CAR_DETAILS_PLATE_NUMBER_ERROR',
                        'Numarul de inmatriculare nu este valid'
                      )
                }
                onBlur={async (e: any) => {
                  const plateNumberObject: PlateNumber = {
                    PlateNumber: e.target.value,
                    externalClient: false
                  };
                  await this.handlePlateNumberBlur(plateNumberObject);
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CAR_DETAILS_PLATE_NUMBER',
                  'Numar inmatriculare'
                )}
              />
            </Grid>
            {showAquisitionPrice && (
              <Grid item xs={6}>
                <TextValidator
                  fullWidth
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : true
                  }
                  id="purchasePrice"
                  className="m-2 pr-3"
                  name="plateNumber"
                  placeholder={this.translatorService.Tranlate(
                    'CAR_DETAILS_PURCHASE_PRICE',
                    'Pret Achizitie cu TVA'
                  )}
                  label={
                    this.state.carDetailsExtendedData.purchaseVatRate === 0
                      ? this.translatorService.Tranlate(
                          'CAR_DETAILS_PURCHASE_PRICE_WITHOUT_VAT',
                          'Pret Achizitie (vehicul achizitionat cu TVA = 0)'
                        )
                      : this.translatorService.Tranlate(
                          'CAR_DETAILS_PURCHASE_PRICE',
                          'Pret Achizitie cu TVA'
                        )
                  }
                  value={this.state.carDetailsExtendedData?.purchasePrice}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : this.isDisabled(this.state.carDetailsFetchedData.makeDate)
                  }
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  variant="inline"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="dateOfFabrication"
                  label={this.translatorService.Tranlate(
                    'CAR_DETAILS_DATE_OF_FABRICATION',
                    'Data fabricatie'
                  )}
                  value={this.state.carDetailsExtendedData.makeDate || null}
                  onChange={this.handleYearOfFabricationDateChange}
                  autoOk={true}
                  error={
                    this.state.carDetailsExtendedData.makeDate &&
                    this.state.carDetailsExtendedData.firstRegistrationDate &&
                    this.state.carDetailsExtendedData.makeDate >=
                      this.state.carDetailsExtendedData.firstRegistrationDate
                      ? true
                      : false
                  }
                  helperText={
                    this.state.carDetailsExtendedData.makeDate &&
                    this.state.carDetailsExtendedData.firstRegistrationDate &&
                    this.state.carDetailsExtendedData.makeDate >=
                      this.state.carDetailsExtendedData.firstRegistrationDate
                      ? this.translatorService.Tranlate(
                          'CAR_DETAILS_DATE_OF_FABRICATION_ERROR',
                          'Data fabricatiei trebuie sa fie mai mica decat cea a primei inmatriculari'
                        )
                      : ''
                  }
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={4}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : this.isDisabled(this.state.carDetailsExtendedData.firstRegistrationDate)
                  }
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="firstRegistrationDate"
                  label={this.translatorService.Tranlate(
                    'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION',
                    'Data primei inmatriculari'
                  )}
                  value={this.state.carDetailsExtendedData.firstRegistrationDate || null}
                  onChange={this.handleFirstRegistrationDateChange}
                  autoOk={true}
                  error={this.state.datePickerFirstRegistrationError ? true : false}
                  helperText={this.state.datePickerFirstRegistrationError}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="km-number"
                className="m-2 pr-3"
                name="numberOfKilometers"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_NUMBER_OF_KILOMETERS',
                  'Numar kilometri'
                )}
                value={this.state.carDetailsExtendedData.kilometersNr || ''}
                onChange={(e: any) => this.onChange('kilometersNr')(e)}
                validators={['required', 'isValidNumberOfKm']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                  this.translatorService.Tranlate(
                    'CAR_DETAILS_NUMBER_OF_KILOMETERS_ERROR',
                    'Numarul de kilometri nu trebuie sa fie mai mare de 1000000'
                  )
                ]}
                label={this.translatorService.Tranlate(
                  'CAR_DETAILS_NUMBER_OF_KILOMETERS',
                  'Numar kilometri'
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="d-flex align-items-center justify-content-between">
                <TextValidator
                  fullWidth
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : this.isDisabled(this.state.carDetailsExtendedData.vin)
                  }
                  id="chasis-code"
                  className="m-2 pr-0 mr-0"
                  name="chasisCode"
                  placeholder={this.translatorService.Tranlate(
                    'CAR_DETAILS_CHASIS_CODE',
                    'Serie sasiu'
                  )}
                  value={this.state.carDetailsExtendedData.vin}
                  onChange={this.handleChassisNumberChange}
                  validators={[
                    'required',
                    'matchRegexp:^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$'
                  ]}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    ),
                    this.translatorService.Tranlate('VIN_INVALID', 'Numarul VIN nu este valid')
                  ]}
                  label={this.translatorService.Tranlate('CAR_DETAILS_CHASIS_CODE', 'Serie sasiu')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={this.translatorService.Tranlate(
                            'VIN_IDENTIFICATION',
                            'Identificare VIN'
                          )}
                        >
                          <IconButton
                            component="div"
                            disabled={
                              this.props.vehicleInfoDisabled ||
                              R.isEmpty(this.state.carDetailsExtendedData.vin)
                            }
                            onClick={this.getValuationInfoConfirmation}
                          >
                            <img
                              className="app-header-logo-img"
                              alt="ClaimBox Logo"
                              src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA8klEQVRIie2W0Q2CMBRFL37eP1dwBVZgBRyBFWQFR8AVWIERcAQdQf7eb03NC6mlUCRiQuL5IS23ve9dSiABYDAkCczNYbDXbuFGs3E7WFr1GOYnHaxusDqJMaFD9D1+8wxIFiQNyZN7k+SDZOuMrebmjVtvzZvmZSAiFwAdgNwRFgD2AOpIkSnJarIDpVZxquPMmY9hE8hDGt8AKj5oN42I3CObXwFYTaXrwgYi0qg4c6KaU72NsdTrICr/FNkNbRU2/06fTRQRsevOTqyTBlCTOdX3iEipCYwbaN6NZ/YJRz2NPdt/k1fn/8GJsvG/CgBPf0RH7J2R1ysAAAAASUVORK5CYII="
                              style={{ height: '24px', width: '24px', boxSizing: 'border-box' }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={this.translatorService.Tranlate(
                            'MANUAL_IDENTIFICATION',
                            'Identificare Manuala'
                          )}
                        >
                          <IconButton
                            component="div"
                            disabled={
                              this.props.vehicleInfoDisabled ||
                              R.isEmpty(this.state.carDetailsExtendedData.vin)
                            }
                            onClick={this.getManualValuationInfo}
                          >
                            <img
                              className="app-header-logo-img"
                              alt="ClaimBox Logo"
                              src={manualVinIdentification}
                              style={{ height: '24px', width: '24px', boxSizing: 'border-box' }}
                            />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : true
                }
                id="car-brand"
                className="m-2"
                options={this.state.brandTypes}
                value={this.state.carDetailsExtendedData.make}
                onChange={this.handleBrandTypeChange}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="brand"
                    value={this.state.carDetailsExtendedData.make}
                    label={this.translatorService.Tranlate('CAR_DETAILS_BRAND', 'Marca')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : true
                }
                id="car-model"
                className="m-2"
                options={this.getModelsOptions()}
                value={this.state.carDetailsExtendedData.model}
                onChange={this.handleModelTypeChange}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="model"
                    value={this.state.carDetailsExtendedData.model}
                    label={this.translatorService.Tranlate('CAR_DETAILS_MODEL', 'Model')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : true
                }
                id="car-body-type"
                className="m-2"
                options={this.getBodiesOptions()}
                value={this.state.carDetailsExtendedData.bodyType}
                onChange={this.onChange('bodyType', 'bodyTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="bodyType"
                    value={this.state.carDetailsExtendedData.bodyType}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_BODY_TYPE',
                      'Tip caroserie'
                    )}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : true
                }
                id="car-version"
                className="m-2 pr-3"
                name="version"
                placeholder={this.translatorService.Tranlate('CAR_DETAILS_VERSION', 'Versiune')}
                value={this.state.carDetailsExtendedData.version || ''}
                onChange={(e: any) => this.onChange('version')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CAR_DETAILS_VERSION', 'Versiune')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : true
                }
                id="car-equipment-version"
                className="m-2 pr-3"
                name="equipmentVersion"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_EQUIPMENT_VERSION',
                  'Echipare'
                )}
                value={this.state.carDetailsExtendedData.equipmentVersion || ''}
                onChange={(e: any) => this.onChange('equipmentVersion')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CAR_DETAILS_EQUIPMENT_VERSION', 'Echipare')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.power)
                }
                id="car-power"
                className="m-2 pr-3"
                name="power"
                placeholder={this.translatorService.Tranlate('CAR_DETAILS_POWER', 'Putere (kW)')}
                value={this.state.carDetailsExtendedData.power || ''}
                onChange={this.handlePowerChange}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CAR_DETAILS_POWER', 'Putere (kW)')}
              />
            </Grid>

            <Grid item xs={3}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.horsePower)
                }
                id="car-horse-power"
                className="m-2 pr-3"
                name="horsePower"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_HORSE_POWER',
                  'Putere (CP)'
                )}
                value={this.state.carDetailsExtendedData.horsePower || ''}
                onChange={this.handleHPChange}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CAR_DETAILS_HORSE_POWER', 'Putere (CP)')}
              />
            </Grid>

            <Grid item xs={3}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.engineSize)
                }
                id="car-cilinder-cap"
                className="m-2 pr-3"
                name="cilinderCap"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_CILINDER_CAP',
                  'Capacitate cilindrica (cm3)'
                )}
                value={this.state.carDetailsExtendedData.engineSize || ''}
                onChange={(e: any) => this.onChange('engineSize')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CAR_DETAILS_CILINDER_CAP',
                  'Capacitate cilindrica (cm3)'
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.co2)
                }
                id="car-co2-value"
                className="m-2 pr-3"
                name="co2"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_CO2_VALUE',
                  'Valoare Co2'
                )}
                value={this.state.carDetailsExtendedData.co2 || ''}
                onChange={(e: any) => this.onChange('co2')(e)}
                label={this.translatorService.Tranlate('CAR_DETAILS_CO2_VALUE', 'Valoare Co2')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.fuelType)
                }
                id="car-fuel"
                className="m-2"
                options={this.state.fuelTypes}
                value={this.state.carDetailsExtendedData.fuelType}
                onChange={this.onChange('fuelType', 'fuelTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="fuel"
                    value={this.state.carDetailsExtendedData.fuelType}
                    label={this.translatorService.Tranlate('CAR_DETAILS_FUEL_TYPE', 'Combustibil')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.pollutionNorm)
                }
                id="car-polution-norm"
                className="m-2"
                options={this.state.polutionNormTypes}
                value={this.state.carDetailsExtendedData.pollutionNorm}
                onChange={this.onChange('pollutionNorm', 'pollutionNormId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="polutionNorm"
                    value={this.state.carDetailsExtendedData.pollutionNorm}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_POLUTION_NORM',
                      'Norma de poluare'
                    )}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.transmissionType)
                }
                id="car-transmission"
                className="m-2"
                options={this.state.transmissionTypes}
                value={this.state.carDetailsExtendedData.transmissionType}
                onChange={this.onChange('transmissionType', 'transmissionTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="transmission"
                    value={this.state.carDetailsExtendedData.transmissionType}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_TRANSMISION_TYPE',
                      'Transmisie'
                    )}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.tractionType)
                }
                id="car-traction"
                className="m-2"
                options={this.state.tractionTypes}
                value={this.state.carDetailsExtendedData.tractionType}
                onChange={this.onChange('tractionType', 'tractionTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="traction"
                    value={this.state.carDetailsExtendedData.tractionType}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_TRACTION_TYPE',
                      'Tractiune'
                    )}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="car-color"
                className="m-2"
                options={this.state.colorTypes}
                value={this.state.carDetailsExtendedData.color}
                onChange={this.onChange('color', 'colorId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="color"
                    value={this.state.carDetailsExtendedData.color}
                    label={this.translatorService.Tranlate('CAR_DETAILS_COLOR', 'Culoare')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="car-paint"
                className="m-2"
                options={this.state.paintTypes}
                value={this.state.carDetailsExtendedData.paintType}
                onChange={this.onChange('paintType', 'paintTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="traction"
                    value={this.state.carDetailsExtendedData.paintType}
                    label={this.translatorService.Tranlate('CAR_DETAILS_PAINT_TYPE', 'Tip vopsea')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="car-tires"
                className="m-2"
                options={this.state.tiresTypes}
                value={this.state.carDetailsExtendedData.tiresType}
                onChange={this.onChange('tiresType', 'tiresTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="tireType"
                    value={this.state.carDetailsExtendedData.tiresType}
                    label={this.translatorService.Tranlate('CAR_DETAILS_TIRE_TYPE', 'Tip anvelope')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="car-rims"
                className="m-2"
                options={this.state.rimsTypes}
                value={this.state.carDetailsExtendedData.wheelsType}
                onChange={this.onChange('wheelsType', 'wheelsTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="traction"
                    value={this.state.carDetailsExtendedData.wheelsType}
                    label={this.translatorService.Tranlate('CAR_DETAILS_RIMS_TYPE', 'Tip jante')}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="car-vehicleType"
                className="m-2"
                options={this.state.vehicleTypes}
                value={this.state.carDetailsExtendedData.vehicleType}
                onChange={this.onChange('vehicleType', 'vehicleTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="vehicleType"
                    value={this.state.carDetailsExtendedData.vehicleType}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_VEHICLE_TYPE',
                      'Tip vehicul'
                    )}
                    fullWidth
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
            {saleCase && (
              <Grid item xs={6}>
                <Autocomplete
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : !this.state.hasRights
                  }
                  id="car-refurbished"
                  className="m-2"
                  options={this.state.refurbishedList}
                  value={this.state.carDetailsExtendedData.refurbished}
                  onChange={this.onChange('refurbished', 'refurbishedId')}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="refurbished"
                      value={this.state.carDetailsExtendedData.refurbished}
                      label={this.translatorService.Tranlate('CAR_DETAILS_REFURBISHED', 'Reparata')}
                      fullWidth
                      validators={this.renderValidators(['required'])}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
            )}

            {!saleCase && this.state.carDetailsExtendedData.fuelType && this.checkIfIsElectric() && (
              <Grid item xs={6}>
                <Autocomplete
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : !this.state.hasRights
                  }
                  id="car-mvoDvBattyp"
                  className="m-2"
                  options={this.state.mvoDvBattypList}
                  value={this.state.carDetailsExtendedData.mvoDvBattyp || null}
                  onChange={this.onChange('mvoDvBattyp', 'mvoDvBattypId')}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="mvoDvBattyp"
                      value={this.state.carDetailsExtendedData.mvoDvBattyp}
                      label={this.translatorService.Tranlate(
                        'CAR_DETAILS_MVODVBATTYP',
                        'Achizitie'
                      )}
                      fullWidth
                      validators={this.renderValidators(['required'])}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          {saleCase && this.state.carDetailsExtendedData.fuelType && this.checkIfIsElectric() && (
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : !this.state.hasRights
                  }
                  id="car-mvoDvBattyp"
                  className="m-2"
                  options={this.state.mvoDvBattypList}
                  value={this.state.carDetailsExtendedData.mvoDvBattyp || null}
                  onChange={this.onChange('mvoDvBattyp', 'mvoDvBattypId')}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="mvoDvBattyp"
                      value={this.state.carDetailsExtendedData.mvoDvBattyp}
                      label={this.translatorService.Tranlate(
                        'CAR_DETAILS_MVODVBATTYP',
                        'Achizitie'
                      )}
                      fullWidth
                      validators={this.renderValidators(['required'])}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : !this.state.hasRights
                }
                id="locationEntities"
                className="m-2 pr-3 mt-0"
                name="color"
                placeholder={this.translatorService.Tranlate('VEHICLES_LOCATION_LABEL', 'Locatie')}
                value={this.state.carDetailsExtendedData.location || ''}
                onChange={(e: any) => this.onChange('location')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('VEHICLES_LOCATION_LABEL', 'Locatie')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.nrOfDoors)
                }
                id="nr of doors"
                className="m-2 pr-3"
                name="nrOfDoors"
                placeholder={this.translatorService.Tranlate('CAR_DETAILS_NROFDOORS', 'Numar usi')}
                value={this.state.carDetailsExtendedData.nrOfDoors || ''}
                onChange={(e: any) => this.onChange('nrOfDoors')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CAR_DETAILS_NROFDOORS', 'Numar usi')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.radioCode)
                }
                id="radio code"
                className="m-2 pr-3"
                name="radioCode"
                placeholder={this.translatorService.Tranlate('CAR_DETAILS_RADIO_CODE', 'Cod radio')}
                value={this.state.carDetailsExtendedData.radioCode}
                onChange={(e: any) => this.onChange('radioCode')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CAR_DETAILS_RADIO_CODE', 'Cod radio')}
              />
            </Grid>

            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={
                  this.props.type === 'history'
                    ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                    : this.isDisabled(this.state.carDetailsFetchedData.engineManufactureCode)
                }
                id="engine manufacture code"
                className="m-2 pr-3"
                name="engineManufactureCode"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_ENGINE_MANUFACTURE_CODE',
                  'Cod fabricare motor'
                )}
                value={this.state.carDetailsExtendedData.engineManufactureCode || ''}
                onChange={(e: any) => this.onChange('engineManufactureCode')(e)}
                validators={this.renderValidators(['required'])}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CAR_DETAILS_ENGINE_MANUFACTURE_CODE',
                  'Cod fabricare motor'
                )}
              />
            </Grid>
            {this.props.appState?.appUser?.userRole?.code === 'MAVO_ADMIN' && (
              <Grid item xs={4}>
                <TextValidator
                  fullWidth
                  disabled={
                    this.props.type === 'history'
                      ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                      : this.isDisabled(this.state.carDetailsFetchedData.accountingValue)
                  }
                  id="accounting value"
                  className="m-2 pr-3"
                  name="accountingValue"
                  placeholder={this.translatorService.Tranlate(
                    'CAR_DETAILS_ACCOUNTING_VALUE',
                    'Valoarea contabila'
                  )}
                  value={this.state.carDetailsExtendedData.accountingValue || ''}
                  onChange={(e: any) => this.onChange('accountingValue')(e)}
                  validators={this.renderValidators(['required'])}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  label={this.translatorService.Tranlate(
                    'CAR_DETAILS_ACCOUNTING_VALUE',
                    'Valoarea contabila'
                  )}
                />
              </Grid>
            )}
          </Grid>
          {this.props.appState?.appUser?.userRole?.code === 'MAVO_ADMIN' && (
            <div className="car-state">
              <h5 className="bg-theme-primary text-left mt-2 p-1" style={{ color: 'white' }}>
                {this.translatorService.Tranlate('CAR_DETAILS_USER_STATE', 'Date utilizator')}
              </h5>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <TextValidator
                    fullWidth
                    disabled={
                      this.props.type === 'history'
                        ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                        : this.isDisabled(this.state.carDetailsFetchedData.rcrEmployeeLastName)
                    }
                    id="rcr Employee Last Name"
                    className="m-2 pr-3"
                    name="rcrEmployeeLastName"
                    placeholder={this.translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_LAST_NAME',
                      'Nume'
                    )}
                    value={this.state.carDetailsExtendedData.rcrEmployeeLastName || ''}
                    onChange={(e: any) => this.onChange('rcrEmployeeLastName')(e)}
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_LAST_NAME',
                      'Nume'
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextValidator
                    fullWidth
                    disabled={
                      this.props.type === 'history'
                        ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                        : this.isDisabled(this.state.carDetailsFetchedData.rcrEmployeeFirstName)
                    }
                    id="rcr Employee First Name"
                    className="m-2 pr-3"
                    name="rcrEmployeeFirstName"
                    placeholder={this.translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_FIRST_NAME',
                      'Prenume'
                    )}
                    value={this.state.carDetailsExtendedData.rcrEmployeeFirstName || ''}
                    onChange={(e: any) => this.onChange('rcrEmployeeFirstName')(e)}
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_FIRST_NAME',
                      'Prenume'
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextValidator
                    fullWidth
                    disabled={
                      this.props.type === 'history'
                        ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                        : this.isDisabled(this.state.carDetailsFetchedData.rcrEmployeeEmail)
                    }
                    id="rcr Employee Email"
                    className="m-2 pr-3"
                    name="rcrEmployeeEmail"
                    placeholder={this.translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_EMAIL',
                      'Email'
                    )}
                    value={this.state.carDetailsExtendedData.rcrEmployeeEmail || ''}
                    onChange={(e: any) => this.onChange('rcrEmployeeEmail')(e)}
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    label={this.translatorService.Tranlate('CAR_DETAILS_EMPLOYEE_EMAIL', 'Email')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextValidator
                    fullWidth
                    disabled={
                      this.props.type === 'history'
                        ? this.props.appState?.appUser?.userRole?.code !== 'MAVO_ADMIN'
                        : this.isDisabled(this.state.carDetailsFetchedData.rcrEmployeePhone)
                    }
                    id="rcr Employee Phone"
                    className="m-2 pr-3"
                    name="rcrEmployeePhone"
                    placeholder={this.translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_PHONE',
                      'Phone'
                    )}
                    value={this.state.carDetailsExtendedData.rcrEmployeePhone || ''}
                    onChange={(e: any) => this.onChange('rcrEmployeePhone')(e)}
                    validators={this.renderValidators(['required'])}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    label={this.translatorService.Tranlate('CAR_DETAILS_EMPLOYEE_PHONE', 'Phone')}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <div className="car-state">
            <h5 className="bg-theme-primary text-left mt-2 p-1" style={{ color: 'white' }}>
              {this.translatorService.Tranlate('CAR_DETAILS_CAR_STATE', 'Stare masina')}
            </h5>
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="car-state-interior"
                  className="m-2"
                  options={this.state.carInteriorStateTypes}
                  value={this.state.carDetailsExtendedData.inCondition}
                  onChange={this.onChange('inCondition', 'inConditionId')}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="carInteriorStateType"
                      value={this.state.carDetailsExtendedData.inCondition}
                      label={this.translatorService.Tranlate(
                        'CAR_DETAILS_CAR_INTERIOR_STATE',
                        'Interior'
                      )}
                      fullWidth
                      validators={this.renderValidators(['required'])}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="car-state-exterior"
                  className="m-2"
                  options={this.state.carExteriorStateTypes}
                  value={this.state.carDetailsExtendedData.outCondition}
                  onChange={this.onChange('outCondition', 'outConditionId')}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="carExteriorStateType"
                      value={this.state.carDetailsExtendedData.outCondition}
                      label={this.translatorService.Tranlate(
                        'CAR_DETAILS_CAR_EXTERIOR_STATE',
                        'Exterior'
                      )}
                      fullWidth
                      validators={this.renderValidators(['required'])}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="car-state-mechanical"
                  className="m-2"
                  options={this.state.carMechanicalStateTypes}
                  value={this.state.carDetailsExtendedData.technicalCondition}
                  onChange={this.onChange('technicalCondition', 'technicalConditionId')}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="carExteriorStateType"
                      value={this.state.carDetailsExtendedData.technicalCondition}
                      label={this.translatorService.Tranlate(
                        'CAR_DETAILS_CAR_MECHANICAL_STATE',
                        'Mecanica'
                      )}
                      fullWidth
                      validators={this.renderValidators(['required'])}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="car-in-condition-comments"
                  className="m-2 pr-3"
                  name="inConditionComments"
                  placeholder={this.translatorService.Tranlate(
                    'CAR_DETAILS_COMMENTS',
                    'Observatii'
                  )}
                  value={this.state.carDetailsExtendedData.inConditionComments || ''}
                  onChange={(e: any) => this.onChange('inConditionComments')(e)}
                  label={this.translatorService.Tranlate('CAR_DETAILS_COMMENTS', 'Observatii')}
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="car-out-condition-comments"
                  className="m-2 pr-3"
                  name="outConditionComments"
                  placeholder={this.translatorService.Tranlate(
                    'CAR_DETAILS_COMMENTS',
                    'Observatii'
                  )}
                  value={this.state.carDetailsExtendedData.outConditionComments || ''}
                  onChange={(e: any) => this.onChange('outConditionComments')(e)}
                  label={this.translatorService.Tranlate('CAR_DETAILS_COMMENTS', 'Observatii')}
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="car-technical-condition-comments"
                  className="m-2 pr-3"
                  name="technicalConditionComments"
                  placeholder={this.translatorService.Tranlate(
                    'CAR_DETAILS_COMMENTS',
                    'Observatii'
                  )}
                  value={this.state.carDetailsExtendedData.technicalConditionComments || ''}
                  onChange={(e: any) => this.onChange('technicalConditionComments')(e)}
                  label={this.translatorService.Tranlate('CAR_DETAILS_COMMENTS', 'Observatii')}
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
          </div>
          {this.props.showCostTypes ? (
            <div className="car-state">
              <h5 className="bg-theme-primary text-left mt-2 p-1" style={{ color: 'white' }}>
                {this.translatorService.Tranlate('COSTS', 'Costuri')}
              </h5>
            </div>
          ) : null}
          {this.props.showCostTypes ? (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
              {this.state.carDetailsExtendedData?.vehicleCosts ? (
                <div style={{ width: '50%' }}>
                  <CaseDetailsCostDetailsForm
                    vehicleId={Number.parseInt(this.props.match.params.id)}
                    showTitle={false}
                    costValues={this.state.carDetailsExtendedData.vehicleCosts}
                    updateCostValues={this.updateCostValue}
                    currencyTypeCode={CurrencyCode.EUR}
                    caseSectionStepFormId={this.props.workflowForm?.id}
                    readonly={true}
                  />
                </div>
              ) : null}
              <div style={{ width: '50%' }}>
                <CaseDetailsCostDetailsForm
                  vehicleId={Number.parseInt(this.props.match.params.id)}
                  showTitle={false}
                  costValues={this.state.costValues}
                  updateCostValues={this.updateCostValue}
                  currencyTypeCode={CurrencyCode.EUR}
                  caseSectionStepFormId={this.props.workflowForm?.id}
                  readonly={false}
                />
              </div>
            </div>
          ) : null}
          <div className="standard-car-options">
            <h5 className="bg-theme-primary text-left mt-2 p-1" style={{ color: 'white' }}>
              {this.translatorService.Tranlate(
                'CAR_DETAILS_CAR_STANDARD_OPTIONS',
                'Dotari standard'
              )}
            </h5>

            {!this.state.isLoading ? this.renderCarStandardOptionsTable() : null}
          </div>

          <div className="optional-car-options">
            <h5 className="bg-theme-primary text-left mt-2 p-1" style={{ color: 'white' }}>
              {this.translatorService.Tranlate(
                'CAR_DETAILS_CAR_OPTIONAL_OPTIONS',
                'Dotari optionale'
              )}
              {this.state.showOptionalRequiredOptions && this.renderMandatoryOptionsDialog()}
            </h5>
            <div
              style={
                this.state.isloadingIncludeExcludeOptions
                  ? {
                      zIndex: 100000,
                      pointerEvents: 'none'
                    }
                  : {}
              }
            >
              {!this.state.isLoading ? this.renderCarOptionalOptionsTable() : null}
            </div>
            <Grid
              style={{
                position: 'fixed',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 9999,
                width: '100%',
                textAlign: 'center'
              }}
            >
              {this.state.isloadingIncludeExcludeOptions ? <Loader /> : null}
            </Grid>
          </div>
        </div>

        <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
          <Dialog open={this.state.dialogVisible} onClose={this.handleCloseConfirmationDialog}>
            <DialogContent>
              <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {`${this.translatorService.Tranlate(
                  'VIN_IDENTIFICATION_CONFIRMATION_MESSAGE',
                  'Identificarea conform VIN presupune un cost suplimentar - Doriţi să continuaţi ?'
                )} `}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => this.getValuationInfo()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
              </Button>
              <Button onClick={() => this.handleCloseConfirmationDialog()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          onClick={() =>
            this.validateFirstRegistrationDate(
              this.state.carDetailsExtendedData.firstRegistrationDate
            )
          }
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  renderMandatoryOptionsDialog = () => {
    const data = this.state.carOptionalOptions.filter((option) => {
      return this.state.optionalRequiredOptionsIds!.includes(option.optionId!);
    });

    return (
      <Dialog open={this.state.showOptionalRequiredOptions!}>
        <DialogTitle id="alert-dialog-title">
          {'Pentru selectia anterioara este necesara adaugarea unei optiuni din lista'}
        </DialogTitle>
        <DialogContent>
          <MUIDataTable
            title=""
            data={data!}
            columns={[
              {
                name: 'code',
                options: {
                  display: 'excluded',
                  filter: false,
                  sort: false
                } as MUIDataTableColumnOptions
              },
              {
                name: 'name',
                label: 'Optiune',
                options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value: any, tableMeta: any) => {
                    const rowData = tableMeta.rowData;

                    return `[${rowData[0]}] ${value} - ${rowData[2]} €`;
                  }
                }
              },
              {
                name: 'price',
                label: 'Pret',
                options: {
                  display: 'excluded',
                  filter: false,
                  sort: false
                }
              }
            ]}
            options={{
              viewColumns: false,
              filter: false,
              search: false,
              pagination: false,
              selectableRows: 'single' as SelectableRows,
              selectableRowsHeader: false,
              disableToolbarSelect: true,
              print: false,
              download: false,
              textLabels: MUITranslations.GetTranslations(this.translatorService),
              elevation: 1,

              onRowsSelect: (rowSelected: any, allRows: any) => {
                const { dataIndex } = rowSelected[0];
                const selectedOption = data?.filter((option) => {
                  return this.state.optionalRequiredOptionsIds!.includes(option.optionId!);
                })[dataIndex];
                const finalRequiredIds = Array.from(
                  new Set([...this.state.requiredExtraOptionIds!, selectedOption.optionId!])
                );
                const optionsToBeExcludedIds = data
                  ?.filter((option) => option.optionId !== selectedOption.optionId!)
                  ?.map((option) => option.optionId!);
                let finalExtraOptions = [...this.state.carOptionalOptions];
                let finalExcludedOptionIds = Array.from(
                  new Set([...this.state.excludedExtraOptionIds!, ...optionsToBeExcludedIds])
                );
                let finalIncludedOptionIds = [...this.state.includedExtraOptionIds!];

                finalExtraOptions = this.updateCheckedExtraOptionsStatus(
                  finalExtraOptions,
                  finalRequiredIds,
                  true
                );

                finalExtraOptions = this.updateCheckedExtraOptionsStatus(
                  finalExtraOptions,
                  optionsToBeExcludedIds
                );

                finalIncludedOptionIds = this.removeIndexes(finalIncludedOptionIds, [
                  ...finalRequiredIds,
                  selectedOption.optionId!
                ]);

                finalExcludedOptionIds = this.removeIndexes(finalExcludedOptionIds, [
                  ...finalRequiredIds,
                  selectedOption.optionId!
                ]);

                this.setState({
                  ...this.state,
                  showOptionalRequiredOptions: false,
                  carOptionalOptions: finalExtraOptions,
                  includedExtraOptionIds: finalIncludedOptionIds,
                  requiredExtraOptionIds: finalRequiredIds,
                  excludedExtraOptionIds: finalExcludedOptionIds
                });
              }
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeClientDataForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: ExternalCaseDetailsCaseTabCarDetailsExtendedContainerProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators }
)(withSnackbar(CaseDetailsCaseTabCarDetailsExtendedContainer as any));
