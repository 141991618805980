import React, { Fragment, useContext, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnOptions, SelectableRows } from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { useHistory } from 'react-router';
import { ApplicationContext } from '../../context/Contexts';
import { Typography, Button, Grid } from '@material-ui/core';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import { ValuationCarFile } from './evaluation.types';
import { getValuationVehicleHistory } from './evaluation.requests';

const EvaluationHistory = () => {
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(ApplicationContext);
  const history = useHistory();
  const translatorService = context.translatorService;
  const [isLoading, setIsLoading] = useState(true);
  const [valuationHistory, setValuationHistory] = useState<ValuationCarFile[]>([]);

  const fetchHistory = async () => {
    setIsLoading(true);

    try {
      const historyValuationResponse = await getValuationVehicleHistory();

      if (historyValuationResponse.error || !historyValuationResponse.data) {
        enqueueSnackbar(
          translatorService.Tranlate(
            'EVALUATION_CAR_FILE_FETCHING_HISTORY_ERROR',
            'A aparut o eroare la preluarea istoricului de evaluari'
          ),
          { variant: 'error' }
        );
        return;
      }

      const sortedHistory = historyValuationResponse.data.sort((a, b) => (a.id > b.id ? -1 : 1));

      setValuationHistory(sortedHistory);
    } catch (e) {
      enqueueSnackbar(
        translatorService.Tranlate(
          'EVALUATION_CAR_FILE_FETCHING_HISTORY_ERROR',
          'A aparut o eroare la preluarea istoricului de evaluari'
        ),
        { variant: 'error' }
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const goToNewEvaluation = () => {
    history.push('/valuation');
  };

  const getHistoryColumns = () => {
    return [
      {
        name: 'id',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_TABLE_ID_LABEL', 'ID'),
        options: {
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'make',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_MANUFACTURER', 'Producator'),
        options: {
          filter: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'model',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_MODEL', 'Model'),
        options: {
          filter: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'version',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_VERSION', 'Versiune'),
        options: {
          filter: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'equipmentVersion',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_EQUIPMENT', 'Echipare'),
        options: {
          filter: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'fabricationDate',
        label: translatorService.Tranlate(
          'VALUATION_CAR_FILE_DATE_OF_FABRICATION',
          'Data fabricatiei'
        ),
        options: {
          filter: true,
          customBodyRender: (value: string) => {
            return value ? new Date(value).toLocaleDateString('en-GB') : '-';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'registrationDate',
        label: translatorService.Tranlate(
          'VALUATION_CAR_FILE_DATE_OF_REGISTRATION',
          'Data primei inmatriculari'
        ),
        options: {
          filter: true,
          customBodyRender: (value: string) => {
            return value ? new Date(value).toLocaleDateString('en-GB') : '-';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateCreation',
        label: translatorService.Tranlate(
          'VALUATION_CAR_FILE_DATE_OF_EVALUATION',
          'Data evaluarii'
        ),
        options: {
          filter: true,
          customBodyRender: (value: string) => {
            return value ? new Date(value).toLocaleDateString('en-GB') : '-';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'numberOfKilometers',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_KILOMETERS', 'Kilometri'),
        options: {
          filter: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicleSourceId',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_CAR_ID', 'Id autovehicul'),
        options: {
          filter: false,
          sort: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vin',
        label: translatorService.Tranlate('VALUATION_CAR_FILE_CAR_VIN', 'VIN'),
        options: {
          filter: false,
          sort: false
        } as MUIDataTableColumnOptions
      }
    ];
  };

  const getHistoryOptions = () => {
    return {
      selectableRows: 'none' as SelectableRows,
      download: false,
      print: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 20],
      textLabels: MUITranslations.GetTranslations(translatorService),
      elevation: 1,

      onRowClick: (rowData: string[]) => {
        history.push(`/valuation/file/${rowData[0]}`);
      }
    };
  };
  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="h2" className="m-1 mb-4">
              Istoric evaluari
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="m-2 mb-4"
              onClick={() => {
                goToNewEvaluation();
              }}
            >
              {translatorService.Tranlate('VALUATION_CAR_FILE_NEW_EVALUATION', 'Evaluare noua')}
            </Button>
          </Grid>

          <Grid container justify="space-around">
            <Grid item xs={12}>
              <MUIDataTable
                title=""
                data={valuationHistory}
                columns={getHistoryColumns()}
                options={getHistoryOptions()}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Fragment>
  );
};

export default EvaluationHistory;
