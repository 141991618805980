import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import TabPanel from '../_shared/Tabs';
import {
  Tab,
  Tabs,
  Stepper,
  StepConnector,
  Step,
  StepLabel,
  AppBar,
  StepButton
} from '@material-ui/core';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { ProviderContext, withSnackbar } from 'notistack';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import {
  Organization,
  OrganizationCaseType,
  OrganizationCaseTypeStep,
  OrganizationCaseTypeStepForm
} from '../../interfaces/Organization';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IReferential } from '../../interfaces/IReferential';
import Referentials from '../../helpers/Referentials.json';
import { CaseStatusCodeEnum, GTErrorCode, ReferentialCode } from '../../helpers/Constants';
import { isNullOrUndefined } from 'util';
import { ScaleLoader } from 'react-spinners';
import { Card } from 'reactstrap';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import Form from '@rjsf/material-ui';
import { FormButton } from '../../helpers/forms/FormButton';
import { FormCode } from '../../helpers/forms/FormCode';
import { ISubmitEvent } from '@rjsf/core';
import { SearchCaseFormData, SearchNoticeFormData } from '../../interfaces/FormData';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  SelectableRows,
  MUIDataTableMeta
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import DoubleArrowTwoTone from '@material-ui/icons/DoubleArrowTwoTone';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { Workflow } from '../../interfaces/Workflow';
import Workflows from '../newCase/Workflows';
import moment from 'moment';
import SearchVehicleForm from './SearchVehicleForm';
import SearchClientForm from './searchClientForm';
import { CaseTypeCode } from '../../helpers/Constants';
import { Case, ClientDto, ModelData, Notice, VehicleDto } from '../../interfaces/Case';
import CloseIcon from '@material-ui/icons/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';
import { Button, Typography, Grid, Box, Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import * as R from 'ramda';

type NewCaseProps = AppState & ProviderContext & RouteComponentProps;

interface INewCaseState {
  selectedTab: number;
  isLoading: boolean;
  organizationCaseTypes: OrganizationCaseType[];
  selectedOrganizationCaseType: OrganizationCaseType | null;
  organizationSteps: OrganizationCaseTypeStep[];
  activeStep: number;
  organizationForms: OrganizationCaseTypeStepForm[];
  selectedTabForm: number;
  selectedOrganizationForm: OrganizationCaseTypeStepForm | null;
  noticeList: Notice[];
  claimReviewList: Case[];
  formData: any;
  workflows: Workflow[];
  selectedWorkflow: Workflow | null;
  selectedNotice: { id: number; orgId: number; companyNumber: string } | null;
  selectedVehicle: VehicleDto | null;
  selectedClient: ClientDto | null;
  selectedCase: Case | null;
  isDialogOpen: boolean;
  modelData: ModelData[];
  selectedModelData: ModelData | null;
  caseStatuses: IReferential[];
  organization: Organization | null;
}
class NewCase extends React.PureComponent<NewCaseProps, INewCaseState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;

  state = {
    selectedTab: 0,
    isLoading: false,
    organizationCaseTypes: [],
    selectedOrganizationCaseType: null,
    organizationSteps: [],
    activeStep: 0,
    organizationForms: [],
    selectedTabForm: 0,
    selectedOrganizationForm: null,
    noticeList: [],
    claimReviewList: [],
    formData: null,
    workflows: [],
    selectedWorkflow: null,
    selectedNotice: null,
    selectedVehicle: null,
    selectedClient: null,
    selectedCase: null,
    isDialogOpen: false,
    modelData: [],
    selectedModelData: null,
    caseStatuses: [],
    organization: null
  } as INewCaseState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseTypesStepsForms(null);
      }
    );
  }

  loadCaseTypesStepsForms = async (caseType: OrganizationCaseType | null) => {
    const hoId =
      this.props.appUser!.organization!.hoId === null
        ? this.props.appUser!.organizationId
        : this.props.appUser!.organization!.hoId;

    if (hoId === null) {
      return;
    }

    if (Number.isNaN(hoId)) {
      return;
    }

    const refCaseType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseType
    );

    const refCaseStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );

    const [orgCaseList, orgCaseTypes, caseStatuses, workflows, organization] = await Promise.all([
      this.organizationService.GetUserOrganizationCaseType(),
      this.appReferentialService.Get(refCaseType!.baseUrl),
      this.appReferentialService.Get(refCaseStatus!.baseUrl),
      this.workflowService.GetUserWorkflows(),
      R.isNil(this.props!.appUser!.organizationId) ? null :
        this.organizationService.GetOrganizationInfo(this.props!.appUser!.organizationId)
    ]);

    const owners = await this.organizationService.GetUserOrganizationsByIds(
      Array.from(new Set(workflows.map((item) => item.organizationOwnerId)))
    );
    workflows.forEach((w) => {
      const owner = owners.find((item) => item.id === w.organizationOwnerId);
      w.organizationOwner = isNullOrUndefined(owner) ? null : owner;
    });

    const organizationCaseTypes = orgCaseList.map((item: OrganizationCaseType) => {
      item.caseType = orgCaseTypes.find(
        (caseType: IReferential) => caseType.id === item.caseTypeId
      )!;

      return item;
    });

    if (caseType === null) {
      caseType = organizationCaseTypes.sort(function (a, b) {
        return a.order - b.order;
      })[0];
    }

    this.setState(
      {
        isLoading: false,
        organizationCaseTypes: organizationCaseTypes.sort(function (a, b) {
          return a.order - b.order;
        }),
        selectedOrganizationCaseType: caseType,
        selectedTabForm: 0,
        noticeList: [],
        claimReviewList: [],
        workflows: workflows,
        caseStatuses: caseStatuses,
        organization: organization
      },
      async () => {
        await this.loadOrganizationSteps();
      }
    );
  };

  loadOrganizationSteps = async () => {
    if (isNullOrUndefined(this.state.selectedOrganizationCaseType)) {
      return;
    }

    let orgCaseTypeStepList = await this.organizationService.GetUserOrganizationCaseTypeStep(
      this.state.selectedOrganizationCaseType!.id
    );
    orgCaseTypeStepList = orgCaseTypeStepList.sort(function (a, b) {
      return a.order - b.order;
    });

    const orgCaseTypeStepFormList =
      this.state.activeStep > orgCaseTypeStepList.length - 1
        ? []
        : await this.organizationService.GetUserOrganizationCaseTypeStepForms(
            orgCaseTypeStepList[this.state.activeStep].id
          ); //orgCaseList[0].id);

    this.setState({
      isLoading: false,
      organizationSteps: orgCaseTypeStepList,
      organizationForms: orgCaseTypeStepFormList.sort(function (a, b) {
        return a.order - b.order;
      }),
      selectedTabForm: 0,
      //this.state.activeStep > orgCaseTypeStepList.length - 1 ? null :
      selectedOrganizationForm: orgCaseTypeStepFormList.sort(function (a, b) {
        return a.order - b.order;
      })[0],
      noticeList: [],
      claimReviewList: []
    });
  };

  loadOrganizationcaseTypeStepForms = async () => {
    const orgCaseTypeStepFormList =
      await this.organizationService.GetUserOrganizationCaseTypeStepForms(
        this.state.organizationSteps[this.state.activeStep].id
      ); //orgCaseList[0].id);

    this.setState({
      isLoading: false,
      organizationForms: orgCaseTypeStepFormList.sort(function (a, b) {
        return a.order - b.order;
      }),
      selectedOrganizationForm: orgCaseTypeStepFormList.sort(function (a, b) {
        return a.order - b.order;
      })[0],
      selectedTabForm: 0,
      noticeList: [],
      claimReviewList: []
    });
  };

  handleChangeTab = async (event: React.ChangeEvent<any>, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        activeStep: 0,
        selectedOrganizationCaseType: this.state.organizationCaseTypes[newValue]
      },
      async () => {
        await this.loadOrganizationSteps();
      }
    );
  };

  renderCaseTypes = () => {
    const tabs = [] as any[];
    if (Object.keys(this.state.organizationCaseTypes).length !== 0) {
      this.state.organizationCaseTypes.forEach((item: OrganizationCaseType, index: number) => {
        tabs.push(<Tab key={index} label={item.caseType!.displayName} />);
      });
    }

    return tabs;
  };

  renderForms = () => {
    const forms = [] as any[];
    if (Object.keys(this.state.organizationForms).length !== 0) {
      this.state.organizationForms.forEach((form: OrganizationCaseTypeStepForm, index: number) => {
        // const className = this.state.selectedOrganizationForm !== null && this.state.selectedOrganizationForm === form ? "contained" : "outlined";
        forms.push(<Tab key={index} label={form.displayName} />);
      });
    }

    return forms;
  };

  onSubmit = async (e: ISubmitEvent<any>, code: string) => {
    if (code === FormCode.SEARCH_NOTICE) {
      const data = e.formData as SearchNoticeFormData;

      if (
        isNullOrUndefined(data.vin) &&
        isNullOrUndefined(data.plateNumber) &&
        isNullOrUndefined(data.noticeNumber) &&
        isNullOrUndefined(data.caseNumber)
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'SEARCH_NOTICE_EMPTY_FIELDS_MSG',
            'Completati cel putin un criteriu de cautare'
          ),
          { variant: 'info' }
        );
        return;
      }

      const noticeList = await this.caseService.SearchNotice(
        isNullOrUndefined(data.vin) ? '' : data.vin,
        isNullOrUndefined(data.plateNumber) ? '' : data.plateNumber,
        isNullOrUndefined(data.noticeNumber) ? '' : data.noticeNumber,
        isNullOrUndefined(data.caseNumber) ? '' : data.caseNumber
      );

      if (noticeList.length === 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'SEARCH_NOTICE_RESULT_EMPTY_MSG',
            'Nu au fost gasite notificari'
          ),
          { variant: 'info' }
        );
      }
      this.setState({ noticeList: noticeList, selectedNotice: null, selectedCase: null });
    }
    if (code === FormCode.SEARCH_CLAIM_REVIEW) {
      const data = e.formData as SearchCaseFormData;

      if (isNullOrUndefined(data.caseNumber) && isNullOrUndefined(data.cbCaseNumber)) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'SEARCH_NOTICE_EMPTY_FIELDS_MSG',
            'Completati cel putin un criteriu de cautare'
          ),
          { variant: 'info' }
        );
        return;
      }

      const caseList = await this.caseService.SearchByCaseNumber(
        isNullOrUndefined(data.cbCaseNumber) ? 0 : Number.parseInt(data.cbCaseNumber),
        isNullOrUndefined(data.caseNumber) ? '' :  data.caseNumber,
      );

      if (caseList.length === 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'SEARCH_CLAIM_REVIEW_RESULT_EMPTY_MSG',
            'Nu au fost gasite constatari'
          ),
          { variant: 'info' }
        );
      }
      this.setState({ claimReviewList: caseList });
    }
  };

  renderSelectedForm = () => {
    const tabPanels = [] as any[];

    if (Object.keys(this.state.organizationForms).length !== 0) {
      this.state.organizationForms.forEach((item: OrganizationCaseTypeStepForm, index: number) => {
        if (
          item.form.code === FormCode.SEARCH_VEHICLE ||
          item.form.code === FormCode.SEARCH_CLIENT
        ) {
          this.renderStaticForm(tabPanels, item, index);
        } else {
          this.renderJsonForm(tabPanels, item, index);
        }
      });
    }
    return tabPanels;
  };

  renderStaticForm = (
    tabPanels = [] as any[],
    item: OrganizationCaseTypeStepForm,
    index: number
  ) => {
    tabPanels.push(
      <TabPanel value={this.state.selectedTabForm} index={index} key={index}>
        <div className="ml-5 mr-5 mb-3">
          {item.form.code === FormCode.SEARCH_VEHICLE ? (
            <SearchVehicleForm
              {...this.props}
              onVehicleSelected={this.onVehicleSelected}
              organizationCaseTypeStepForm={item}
              searchInVehicleStock={this.state.organizationCaseTypes[this.state.selectedTab].caseType?.code?.toUpperCase() === "VANZARE"}
            ></SearchVehicleForm>
          ) : (
            ''
          )}
          {item.form.code === FormCode.SEARCH_CLIENT ? (
            <SearchClientForm
              {...this.props}
              onClientSelected={this.onClientSelected}
              organizationCaseTypeStepForm={item}
            ></SearchClientForm>
          ) : (
            ''
          )}
        </div>
        {this.renderFormResults(item.form.code)}
        
      </TabPanel>
    );
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  submitSelectedModelData = async () => {
    try {
      const isSaleCase = this.state.organizationCaseTypes[this.state.selectedTab]?.caseType?.code === CaseTypeCode.SALE;      
      if (!isSaleCase) {
        if (this.state.selectedVehicle !== null) {
          this.setState(
            {
              selectedVehicle: {
                ...this.state.selectedVehicle, 
                make: this.state.selectedModelData!.makeDescription, 
                model: this.state.selectedModelData!.modelDescription
              }

            }
          );
        }
      }
      this.setState({
        isDialogOpen: false
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  handleAutocompleteModelDataChange = async (newValue: ModelData | null) => {
    this.setState({
      selectedModelData: newValue
    });
  };
  
  
  renderAddModelForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitSelectedModelData();
        }}
      >
        <div className="text-center m-0">
          <Autocomplete
            id="supplier"
            className="mb-3"
            options={this.state.modelData}
            value={this.state.selectedModelData}
            onChange={(e: any, newValue: ModelData | null) =>
              this.handleAutocompleteModelDataChange(newValue)
            }
            getOptionLabel={(option: ModelData) => option.modelDescription || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="supplier"
                value={this.state.selectedModelData}
                label={this.translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model Autovehicul')}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        </div>
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  vinDecoder = async () => {
    try {
      const isSaleCase = this.state.organizationCaseTypes[this.state.selectedTab]?.caseType?.code === CaseTypeCode.SALE;      
      console.log('isSaleCase', isSaleCase);
      if (!isSaleCase) {
        if (this.state.selectedVehicle !== null) {
          const value = this.state.selectedVehicle.vin;
          const [result, latestCaseVehicleHistory, vehicle] = await Promise.all([
            this.caseService.DecodeVin(value),
            this.caseService.GetLatestCaseVehicleHistory(value),
            this.caseService.GetVehicleFromVQRepo(value)
          ]); 

          if (latestCaseVehicleHistory !== null) {
            this.setState(
              {
                selectedVehicle: {
                  ...this.state.selectedVehicle, 
                  numberOfKilometers: latestCaseVehicleHistory.numberOfKilometers
                }
              }
            );
          }   
             
          if (result.error !== null) {
            if (result.error.errorInfo.code == GTErrorCode.THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL) {
              this.props.enqueueSnackbar(this.translatorService.Tranlate('THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL', 'Din păcate autovehiculul selectat nu poate fi identificat în aplicație !'), {
                variant: 'warning',
                autoHideDuration: 5000
              });
              this.setState({
                selectedVehicle: null
              });
              return;
            }
          }
  
          if (result.responseInfo.vehicleInfoDec.modelList.modelData.length > 1) {
            if (vehicle === null) {
              this.props.enqueueSnackbar(this.translatorService.Tranlate('SELECT_MODEL_DIALOG', 'Va rugam selectati modelul auto!'), {
                variant: 'warning',
                autoHideDuration: 5000
              });
              this.setState({
                isDialogOpen: true,
                modelData: result.responseInfo.vehicleInfoDec.modelList.modelData
              });
            } else {
              this.setState(
                {
                  selectedVehicle: {
                    ...this.state.selectedVehicle, 
                    make: vehicle.make, 
                    model: vehicle.model
                  }
                }
              );
            }
          } else if (result.responseInfo.vehicleInfoDec.modelList.modelData.length === 1) {
            this.setState(
              {
                selectedVehicle: {
                  ...this.state.selectedVehicle, 
                  make: result.responseInfo.vehicleInfoDec.modelList.modelData[0].makeDescription, 
                  model: result.responseInfo.vehicleInfoDec.modelList.modelData[0].modelDescription
                }

              }
            );
          }
        }
      }
    }catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  onVehicleSelected = async (vehicle: VehicleDto) => {
    const isSaleCase =
      this.state.organizationCaseTypes[this.state.selectedTab]?.caseType?.code ===
      CaseTypeCode.SALE;
    // on sale cases don't send the firstPlateDate as this field will be populated from DB
    const selectedVehicle = isSaleCase ? omit(vehicle, ['firstPlateDate']) : vehicle;
    this.setState(
      {
        selectedVehicle,
        activeStep: this.state.activeStep + 1
        //isLoading: true
      },
      () => {
        this.loadOrganizationcaseTypeStepFormsAndVehicleInfo();
      }
    );
  };

  loadOrganizationcaseTypeStepFormsAndVehicleInfo = async () => {
    await Promise.all([this.loadOrganizationcaseTypeStepForms(), this.vinDecoder()]);
  }

  onClientSelected = (client: ClientDto) => {
    this.setState(
      {
        selectedClient: client,
        activeStep: this.state.activeStep + 1
        //isLoading:true
      },
      () => {
        this.loadOrganizationcaseTypeStepForms();
      }
    );
  };

  renderJsonForm = (tabPanels = [] as any[], item: OrganizationCaseTypeStepForm, index: number) => {
    let jsonSchema = {};
    let jsonGUI = {};
    try {
      jsonSchema = JSON.parse(item.json1);
      jsonGUI = JSON.parse(item.json2);
    } catch {
      console.log('json schema/gui error');
    }
    const schema = this.translatorService.TranslateSchema(jsonSchema);

    tabPanels.push(
      <TabPanel value={this.state.selectedTabForm} index={index} key={index}>
        <div className="ml-5 mr-5 mb-3 pl-5 pr-5">
          <Form
            schema={schema}
            uiSchema={jsonGUI}
            liveValidate
            showErrorList={false}
            formData={this.state.formData}
            onChange={(e) => this.setState({ formData: e.formData })}
            onSubmit={async (e: ISubmitEvent<any>) => await this.onSubmit(e, item.form.code)}
            transformErrors={this.translatorService.TranslateErrors}
          >
            <div>{FormButton.GetButtons(item.form.code, this.translatorService, true, false)}</div>
          </Form>
        </div>
        {this.renderFormResults(item.form.code)}
      </TabPanel>
    );
  };

  renderSteps = () => {
    const steps = [] as any[];
    if (Object.keys(this.state.organizationSteps).length !== 0) {
      this.state.organizationSteps.forEach((step: OrganizationCaseTypeStep, index: number) => {
        steps.push(
          <Step key={index}>
            <StepButton onClick={this.handleStep(index)}>
              <StepLabel>{step.organizationStep!.displayName}</StepLabel>
            </StepButton>
          </Step>
        );
      });
    }

    return steps;
  };

  handleChangeTabForm = (event: React.ChangeEvent<any>, newValue: number) => {
    this.setState({
      selectedTabForm: newValue,
      selectedOrganizationForm: this.state.organizationForms[newValue]
    });
  };

  renderNoticeResults = () => {
    const options = {
      filter: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      // responsive: "stacked",
      elevation: 0,
      search: false,
      viewColumns: false,
      pagination: false
    };

    return (
      <div className="m-2">
        <MUIDataTable
          title={''}
          data={this.state.noticeList}
          columns={this.getNoticeColumns()}
          options={options}
        />
      </div>
    );
  };

  renderClaimReviewResults = () => {
    const options = {
      filter: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      // responsive: "stacked",
      elevation: 0,
      search: false,
      viewColumns: false,
      pagination: false
    };

    return (
      <div className="m-2">
        <MUIDataTable
          title={''}
          data={this.state.claimReviewList}
          columns={this.getClaimReviewColumns()}
          options={options}
        />
      </div>
    );
  };

  renderFormResults = (code: string) => {
    if (code === FormCode.SEARCH_NOTICE) {
      return this.renderNoticeResults();
    }
    if (code === FormCode.SEARCH_CLAIM_REVIEW) {
      return this.renderClaimReviewResults();
    }
    if (code === FormCode.SELECT_WORKFLOW) {
      return (
        <Workflows
          {...this.props}
          workflows={this.state.workflows}
          selectedNotice={this.state.selectedNotice}
          selectedCase={this.state.selectedCase}
          selectedVehicle={this.state.selectedVehicle}
          selectedClient={this.state.selectedClient}
          selectedOrganizationCaseType={this.state.selectedOrganizationCaseType}
          caseStatuses={this.state.caseStatuses}
          organization={this.state.organization}
        />
      );
    }

    return '';
  };

  onNoticeClick = async (e: any, notice: { id: number; orgId: number; companyNumber: string }) => {
    this.setState(
      {
        selectedNotice: notice,
        activeStep: this.state.activeStep + 1
        //isLoading: true
      },
      () => {
        this.loadOrganizationcaseTypeStepForms();
      }
    );
  };

  onCaseClick = async (e: any, id: number) => {
    const casee = this.state.claimReviewList.find((item) => item.id === id);
    if (casee?.caseStatus?.caseStatus) {
      const caseStatus = casee?.caseStatus?.caseStatus?.code;
      // switch (caseStatus) {
      //   case CaseStatusCodeEnum.PRELUAT_SERVICE:
      //     this.props.enqueueSnackbar(
      //       this.translatorService.Tranlate(
      //         'CASE_STATUS_PRELUAT_SERVICE_MSG',
      //         'Dosarul a fost preluat deja de service!'
      //       ),
      //       { variant: 'info' }
      //     );
      //     return;
      //   default:
      //     break;
      // }
    }

    this.setState(
      {
        selectedCase: casee === undefined ? null : casee,
        activeStep: this.state.activeStep + 1
        //isLoading: true
      },
      () => {
        this.loadOrganizationcaseTypeStepForms();
      }
    );
  };

  getNoticeColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'organizationOwnerId',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'companyNumber',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: '',
        label: '',
        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                disabled={!isNullOrUndefined(tableMeta.rowData[9])}
                className="m-0"
                size="small"
                onClick={async (e) =>
                  await this.onNoticeClick(e, {
                    id: tableMeta.rowData[0],
                    orgId: tableMeta.rowData[1],
                    companyNumber: tableMeta.rowData[2]
                  })
                }
              >
                <DoubleArrowTwoTone />
              </IconButton>
            );
          }
        }
      },
      {
        name: 'noticeNumber',
        label: this.translatorService.Tranlate('NOTICE_NOTICE_NUMBER_HEADER', 'Numar avizare'),
        options: {}
      },
      {
        name: 'caseNumber',
        label: this.translatorService.Tranlate('NOTICE_CASE_NUMBER_HEADER', 'Numar Dosar'),
        options: {}
      },
      {
        name: 'policyNumber',
        label: this.translatorService.Tranlate('NOTICE_POLICY_NUMBER_HEADER', 'Numar Polita'),
        options: {}
      },
      {
        name: 'vin',
        label: this.translatorService.Tranlate('NOTICE_VIN_HEADER', 'Serie Sasiu'),
        options: {
          filter: false
        }
      },
      {
        name: 'plateNumber',
        label: this.translatorService.Tranlate('NOTICE_PLATE_NUMBER_HEADER', 'Numar inmatriculare'),
        options: {
          sort: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'claimBoxCaseNumber',
        label: this.translatorService.Tranlate(
          'NOTICE_CLAIMBOX_CASE_NUMBER_HEADER',
          'Numar dosar Claimbox'
        ),
        options: {}
      }
    ];

    return columns;
  };

  getClaimReviewColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: '',
        label: '',
        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                className="m-0"
                size="small"
                onClick={async (e) => await this.onCaseClick(e, tableMeta.rowData[0])}
              >
                <DoubleArrowTwoTone />
              </IconButton>
            );
          }
        }
      },
      {
        name: 'id',
        label: this.translatorService.Tranlate(
          'NOTICE_CLAIMBOX_CASE_NUMBER_HEADER',
          'Numar dosar Claimbox'
        ),
        options: {}
      },
      // {
      //   name: "caseWorkflow.workflowDisplayName",
      //   label: this.translatorService.Tranlate("CLAIMREVIEW_WORKFLOW_OWNER_HEADER", "Proprietar Flux")
      // },
      {
        name: 'caseVehicle.vin',
        label: this.translatorService.Tranlate('NOTICE_VIN_HEADER', 'Serie Sasiu'),
        options: {
          filter: false
        }
      },
      {
        name: 'caseVehicle.plateNumber',
        label: this.translatorService.Tranlate('NOTICE_PLATE_NUMBER_HEADER', 'Numar inmatriculare'),
        options: {
          sort: true
        }
      },
      {
        name: 'caseEvent.caseNumber',
        label: this.translatorService.Tranlate('NOTICE_CASE_NUMBER_HEADER', 'Numar Dosar'),
        options: {}
      },
      {
        name: 'caseEvent.policyNumber',
        label: this.translatorService.Tranlate('NOTICE_POLICY_NUMBER_HEADER', 'Numar Polita')
      },
      {
        name: 'caseStatus.caseStatus.displayName',
        label: this.translatorService.Tranlate('CLAIM_REVIEW_STATE_HEADER', 'Stare')
      },
      // {
      //   name: "createdBy",
      //   label: this.translatorService.Tranlate("CLAIM_REVIEW_CREATEDBY_HEADER", "Creat De")
      // },
      {
        name: 'dateCreation',
        label: this.translatorService.Tranlate('CLAIM_REVIEW_DATE_CREATION_HEADER', 'Data Creare'),
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <div className="font-size-sm">
                {moment.utc(value).local().toDate().toLocaleDateString(this.props.language)}
              </div>
            );
          }
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  handleStep = (step: number) => () => {
    this.setState(
      {
        activeStep: step
        //isLoading: true
      },
      () => {
        this.loadOrganizationcaseTypeStepForms();
      }
    );
  };

  renderStepper = () => {
    return (
      <div className="">
        <Stepper
          nonLinear
          className="flex justify-content-center ml-5 mr-5 pl-5 pr-5"
          connector={<StepConnector style={{ maxWidth: '100px' }} />}
          activeStep={this.state.activeStep}
          style={{ backgroundColor: 'transparent' }}
        >
          {this.renderSteps()}
        </Stepper>
      </div>
    );
  };

  renderTabForms = () => {
    return (
      <div className="text-center">
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            <AppBar position="static" elevation={0} color="secondary">
              <Tabs
                TabIndicatorProps={{
                  style: {
                    height: '4px'
                  }
                }}
                value={this.state.selectedTabForm}
                onChange={this.handleChangeTabForm}
                variant="fullWidth"
                centered={true}
                indicatorColor="primary"
                aria-label="simple tabs example"
              >
                {this.renderForms()}
              </Tabs>
            </AppBar>

            {this.renderSelectedForm()}
          </div>
        ) : null}

        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            disableBackdropClick
            fullWidth={true}
          >
              <DialogTitle
                id="customized-dialog-title"
                style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
              >
                <span>{this.translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model Autovehicul')}</span>
                <IconButton
                  aria-label="close"
                  className="position-absolute"
                  style={{ right: 12, top: 12, color: '#ffffff' }}
                  onClick={this.closeDialog}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent className="p-3" dividers style={{ backgroundColor: '#fafafa' }}>
                {this.renderAddModelForm()}
              </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
        {/* {this.renderStepsButtons()} */}
      </div>
    );
  };

  renderCaseTypeTabPanel = () => {
    const tabs = [] as any[];
    if (Object.keys(this.state.organizationCaseTypes).length !== 0) {
      this.state.organizationCaseTypes.forEach((item: OrganizationCaseType, index: number) => {
        tabs.push(
          <div className="" key={index}>
            <TabPanel value={this.state.selectedTab} index={index}>
              {/* <Stepper className="mt-2"  activeStep={this.state.activeStep} connector={<this.ColorlibConnector />}> */}
              {/* <Card className ="m-3"> */}
              {this.renderStepper()}
              {/* </Card> */}
              <Card>{this.renderTabForms()}</Card>
            </TabPanel>
          </div>
        );
      });
    }

    return tabs;
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <Fragment>
        <Box mb={1} pb={1}>
          <Card>
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '6px'
                }
              }}
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="fullWidth"
              centered={true}
              color="primary"
              onChange={this.handleChangeTab}
              aria-label="disabled tabs example"
            >
              {this.renderCaseTypes()}
            </Tabs>
          </Card>
        </Box>

        <Box>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? <div>{this.renderCaseTypeTabPanel()}</div> : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.app // Selects which action creators are merged into the component's props
)(withSnackbar(NewCase as any));
