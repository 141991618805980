import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsActionCreators,
  CaseSettingsState,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Box, Tabs, Tab, Button, Typography } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { RouteComponentProps } from 'react-router';
import { PageTitle } from '../../layout-components';
import { Card } from 'reactstrap';
import TabPanel from '../_shared/Tabs';
import CaseDetailsCaseTab from './CaseDetailsCaseTab';
import CaseDetailsCommentsTab from './CaseDetailsCommentsTab';
import CaseDetailsHistoryTab from './CaseDetailsHistoryTab';
import { isNullOrUndefined } from 'util';
import { ScaleLoader } from 'react-spinners';
import { ICaseService } from '../../services/Interfaces/ICaseService';

interface ICaseDetailsState {
  selectedTab: number;
}

type ICaseDetailsProps = {
  caseSettingsState: CaseSettingsState;
  vehicleState: VehicleState;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetails extends React.PureComponent<ICaseDetailsProps, ICaseDetailsState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    selectedTab: 0
  } as ICaseDetailsState;

  public async componentDidMount() {
    const caseId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(caseId)) {
      return;
    }

    await this.props.SetCaseSettings(caseId);
    await this.props.setVehicleObject(null);

    if (!isNullOrUndefined(this.props.caseSettingsState.case!.vehicleId)) {
      await this.props.setVehicle(this.props.caseSettingsState.case!.vehicleId);
    }

    let selectedTab = 0;
    if (this.props.match.url.toLowerCase() === '/cases/' + caseId + '/comments') {
      selectedTab = 1;
    }

    this.setState({ selectedTab: selectedTab });
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  private getTitleDescription(): string | null {
    const { make, model, version, plateNumber } = this.props.vehicleState?.vehicle || {};
    const { brand, displayName } = this.props.caseSettingsState.case?.caseVehicle?.model || {};
    const { plateNumber: casePlateNumber } = this.props.caseSettingsState.case?.caseVehicle || {};

    const carMake = make?.name || brand || '';
    const carModel = model?.name || '';
    const carVersion = version || displayName || '';
    const carPlateNumber = plateNumber || casePlateNumber || '';

    return carMake && carVersion ? `${carMake} ${carModel} ${carVersion} ${carPlateNumber}` : null;
  }

  private getStatusDescription(): string | null {
    const { caseStatus} = this.props.caseSettingsState.case?.caseStatus || {};
    return caseStatus  ? `${caseStatus.displayName}` : null;
  }

  renderLastStatus = () =>{
    const text = this.getStatusDescription();
    return <Box style={{display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'space-between', height:'90%'}}>
      <div>
      <Box
        style={{
          padding:'10px 10px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          backgroundColor: '#81C784'
        }}
      >
        <Typography variant="h6" style={{color:'white'}}>
          {text}
        </Typography>
      </Box>
      </div>
    </Box>
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.caseService = (this.context as AppContext).caseService;
    const description = this.getTitleDescription();

    if (isNullOrUndefined(this.props.caseSettingsState.caseSettings)) {
      return (
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.caseSettingsState.isLoading} />
        </div>
      );
    }

    return (
      <Fragment>
        <PageTitle
          titleHeading={
            this.translatorService.Tranlate('CASES_CASE_TAB', 'Dosar') +
            ' ' +
            this.props.caseSettingsState.case!.id
          }
          titleDescription={description}
          actionButton={
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              <Typography variant="h5" style={{ marginRight: '10px', color: 'black' }}>
                {this.translatorService.Tranlate('CASE_STATUS_LABEL', 'Status: ')}
              </Typography>
              {this.renderLastStatus()}
            </Box>
          }
        />
        {!this.props.caseSettingsState.isLoading ? (
          <Box>
            <Card>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    height: '6px'
                  }
                }}
                value={this.state.selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="fullWidth"
                centered={true}
                color="primary"
                onChange={this.onTabChange}
                aria-label="disabled tabs example"
              >
                <Tab label={this.translatorService.Tranlate('CASES_CASE_TAB', 'Dosar')} />
                {!this.props.caseSettingsState.caseSettings.workflow.hideComments && (
                  <Tab label={this.translatorService.Tranlate('CASES_COMMENTS_TAB', 'Comentarii')} />
                )}
                <Tab label={this.translatorService.Tranlate('CASES_HISTORY_TAB', 'History')} />
              </Tabs>
            </Card>
          </Box>
        ) : null}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.caseSettingsState.isLoading} />
        </div>
        {!this.props.caseSettingsState.isLoading && !this.props?.vehicleState?.vehicle?.loading ? (
          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <CaseDetailsCaseTab {...this.props} />
            </TabPanel>
            {!this.props.caseSettingsState.caseSettings.workflow.hideComments && (
              <TabPanel value={this.state.selectedTab} index={1}>
                <CaseDetailsCommentsTab {...this.props} />
              </TabPanel>
            )}
            <TabPanel value={this.state.selectedTab} index={this.props.caseSettingsState.caseSettings.workflow.hideComments ? 1 : 2}>
              <CaseDetailsHistoryTab {...this.props} />
            </TabPanel>
          </div>
        ) : null}

      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return { caseSettingsState: state.caseSettings, vehicleState: state.vehicle };
  },
  { ...CaseSettingsActionCreators, ...VehicleActionCreators }
)(withSnackbar(CaseDetails as any));
