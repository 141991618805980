import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Model } from '../../../interfaces/Case';

interface IModelCarClassState {
  newModel: Model;
  carClasses: IReferential[];
  modelList: Model[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newModelId: number | null;
}

type IModelCarClassProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class ModelCarClass extends React.PureComponent<
  IModelCarClassProps,
  IModelCarClassState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;


  static contextType = ApplicationContext;
  private newModel = {
    id: 0,
    carClassId: 0
  } as unknown as Model;

  state = {
    modelList: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newModel: this.newModel,
    carClasses: [],
    newModelId: null
  } as IModelCarClassState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadModels();
      }
    );
  }

  loadModels = async () => {
    const models = await this.caseService.GetModelList();
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarClass
    );
    const carClasses = (await this.appReferentialService.Get(ref!.baseUrl)).filter((item) => item.isActive === true);

    this.setState({
      modelList: models,
      isLoading: false,
      carClasses: carClasses,
      newModel: { ...this.newModel }
    });
  };

  private handleCarClassChange = (newValue: IReferential | null) => {
    this.setState({
      newModel: {
        ...this.state.newModel,
        carClass: newValue,
        carClassId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public updateCarClassModel = async () => {
    try {
      await this.caseService.UpdateCarClassModel(this.state.newModel);

      await this.loadModels();

      this.closeDialog();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.updateCarClassModel} instantValidate={true}>
        <Autocomplete
          id="carClass"
          className="m-2"
          options={this.state.carClasses}
          value={this.state.newModel.carClass}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleCarClassChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="carClass"
              value={this.state.newModel.carClass}
              label={this.translatorService.Tranlate(
                'CAR_CLASS',
                'Clasa masina'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        />
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'brand',
        label: this.translatorService.Tranlate(
          'CASE_VALUATION_BRAND_LABEL',
          'Marca'
        )
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate(
          'CASE_VALUATION_MODEL_LABEL',
          'Model'
        )
      },
      {
        name: 'description',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_DESCRIPTION_HEADER',
          'Descriere'
        )
      },
      {
        name: 'carClass',
        label: this.translatorService.Tranlate(
          'CAR_CLASS',
          'Clasa masina'
        ),
        options: {
          sort: false,
          customBodyRender: (value: IReferential) => {
            return value ? value.name : null;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newCarClassPopup(tableMeta.rowData[0])}
                >
                    <EditOutlinedIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  newCarClassPopup = async (modelId: number | null) => {
    let newModel: Model;
    if (modelId == null) {
      newModel = { ...this.state.newModel } as Model;
    } else {
      newModel = this.state.modelList.find((item) => item.id === modelId)!;
    }
    this.setState({
      isDialogOpen: true,
      newModel: newModel,
      newModelId: modelId
    });
  };

  closeDialog = async () => {
    this.setState(
        { 
          isDialogOpen: false, 
          newModel: this.newModel 
        }
      );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: true
    };

    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.modelList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                {this.translatorService.Tranlate('MODEL_CAR_CLASS_UPDATE_MODAL_TITLE', 'Actualizeaza clasa masina')}      
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{this.renderAddForm()}</DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(ModelCarClass as any));
