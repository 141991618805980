import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import {
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { WorkflowSectionStep, WorkflowSection } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import EditWorkflowStep from './editWorkflowStep/EditWorkflowStep';
import CloseIcon from '@material-ui/icons/Close';

interface IWorkflowSectionStepState {
  workflowSectionSteps: WorkflowSectionStep[];
  isLoading: boolean;
  workflowSectionTypes: WorkflowSection[];
  workflowSectionStepTypes: IReferential[];
  selectedworkflowSectionType: WorkflowSection | null;
  selectedworkflowSectionStepType: IReferential | null;
  workflowId: number;
  isDialogOpen: boolean;
  isEditDialogOpen: boolean;
  workflowSectionStepId: number | null;
  modalFormTitle: string;
  stepId: number;
}

type IWorkflowSectionStepProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowCaseStructureStepsTab extends React.PureComponent<
  IWorkflowSectionStepProps,
  IWorkflowSectionStepState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowSectionSteps: [],
    isLoading: false,
    workflowSectionTypes: [],
    workflowSectionStepTypes: [],
    selectedworkflowSectionType: null,
    selectedworkflowSectionStepType: null,
    workflowId: 0,
    isDialogOpen: false,
    workflowSectionStepId: null,
    isEditDialogOpen: false,
    modalFormTitle: '',
    stepId: 0
  } as IWorkflowSectionStepState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowSectionSteps(null);
      }
    );
  }

  loadWorkflowSectionSteps = async (sectionTypeId: number | null) => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }
    const refSteps = Referentials.referential.find(
      (item) => item.code === ReferentialCode.WorkflowSteps
    );

    const [workflowSectionList, workflowSectionStepList, workflowSectionStepTypes] =
      await Promise.all([
        this.workflowService.GetWorkflowSections(workflowId),
        sectionTypeId === null ? [] : this.workflowService.GetWorkflowSectionSteps(sectionTypeId),
        this.appReferentialService.Get(refSteps!.baseUrl)
      ]);

    this.setState({
      workflowSectionSteps: workflowSectionStepList.sort(function (a, b) {
        return a.order - b.order;
      }),
      workflowSectionTypes: workflowSectionList.sort(function (a, b) {
        return a.order - b.order;
      }),
      workflowSectionStepTypes: workflowSectionStepTypes.filter((item) => item.isActive === true),
      isLoading: false,
      workflowId: workflowId
    });
  };

  reloadWorkflowSectionSteps = async (sectionTypeId: number | null) => {
    const [workflowSectionStepList] = await Promise.all([
      sectionTypeId === null ? [] : this.workflowService.GetWorkflowSectionSteps(sectionTypeId)
    ]);

    this.setState({
      workflowSectionSteps: workflowSectionStepList.sort(function (a, b) {
        return a.order - b.order;
      }),
      isLoading: false
    });
  };

  moveUp = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.workflowSectionSteps];
    const itemToMoveUpIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveUpIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveUpIndex].order === 1) {
      return;
    }

    const itemToMoveDownIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveUpIndex].order - 1
    );

    cloneArray[itemToMoveUpIndex] = {
      ...cloneArray[itemToMoveUpIndex],
      order: cloneArray[itemToMoveUpIndex].order - 1
    };
    cloneArray[itemToMoveDownIndex] = {
      ...cloneArray[itemToMoveDownIndex],
      order: cloneArray[itemToMoveDownIndex].order + 1
    };

    try {
      await this.workflowService.UpdateWorkflowSectionStep(cloneArray[itemToMoveUpIndex]);
      await this.workflowService.UpdateWorkflowSectionStep(cloneArray[itemToMoveDownIndex]);

      this.setState({
        workflowSectionSteps: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveDown = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.workflowSectionSteps];
    const itemToMoveIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveIndex].order === cloneArray.length) {
      return;
    }

    const itemToMoveToIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveIndex].order + 1
    );

    cloneArray[itemToMoveIndex] = {
      ...cloneArray[itemToMoveIndex],
      order: cloneArray[itemToMoveIndex].order + 1
    };
    cloneArray[itemToMoveToIndex] = {
      ...cloneArray[itemToMoveToIndex],
      order: cloneArray[itemToMoveToIndex].order - 1
    };

    try {
      await this.workflowService.UpdateWorkflowSectionStep(cloneArray[itemToMoveIndex]);
      await this.workflowService.UpdateWorkflowSectionStep(cloneArray[itemToMoveToIndex]);

      this.setState({
        workflowSectionSteps: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowSectionSteps = [...this.state.workflowSectionSteps];
    const updatedWorkflowSectionStepIndex = workflowSectionSteps.findIndex((o) => o.id === id);

    if (updatedWorkflowSectionStepIndex >= 0) {
      workflowSectionSteps[updatedWorkflowSectionStepIndex] = {
        ...workflowSectionSteps[updatedWorkflowSectionStepIndex],
        isActive: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowSectionStep(
          workflowSectionSteps[updatedWorkflowSectionStepIndex]
        );

        this.setState({ workflowSectionSteps: workflowSectionSteps });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  onRowClick = (e: any, id: number, stepName: any) => {
    this.setState({ isEditDialogOpen: true, stepId: id, modalFormTitle: stepName });
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'workflowStep',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_STEP_STEP_HEADER',
          'Pas'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0], value.displayName)}
              >
                <span>{value.displayName}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_STEP_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'order',
        sortDirection: 'asc',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_STEP_STEP_ORDER_HEADER',
          'Ord'
        ),
        options: { sort: false }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          sortOrder: {
            name: 'order',
            direction: 'asc'
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Fragment>
                <IconButton color="inherit" size="small" onClick={(e) => this.moveUp(e, tableMeta)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => this.moveDown(e, tableMeta)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) =>
                  this.setState({ isDialogOpen: true, workflowSectionStepId: tableMeta.rowData[0] })
                }
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgStep = async () => {
    try {
      await this.workflowService.AddWorkflowSectionStep({
        order: this.state.workflowSectionSteps.length + 1,
        stepId: this.state.selectedworkflowSectionStepType!.id,
        workflowSectionId: this.state.selectedworkflowSectionType!.id,
        isActive: true
      } as WorkflowSectionStep);

      this.setState(
        {
          isLoading: true,
          selectedworkflowSectionStepType: null
        },
        () => {
          this.reloadWorkflowSectionSteps(this.state.selectedworkflowSectionType!.id);
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteSectionChange = (newValue: any | null) => {
    this.setState(
      {
        selectedworkflowSectionType: newValue,
        isLoading: true
      },
      () => {
        this.reloadWorkflowSectionSteps(newValue === null ? null : newValue.id);
      }
    );
  };

  handleAutocompleteStepChange = (newValue: IReferential | null) => {
    this.setState({ selectedworkflowSectionStepType: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgStep}>
              <Autocomplete
                id="sectionType"
                className="m-2"
                options={this.state.workflowSectionTypes}
                value={this.state.selectedworkflowSectionType}
                onChange={(e: any, newValue: WorkflowSection | null) =>
                  this.handleAutocompleteSectionChange(newValue)
                }
                getOptionLabel={(option: WorkflowSection) => option.section!.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="sectionType"
                    value={this.state.selectedworkflowSectionType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_STEP_SECTION_LABEL',
                      'Sectiune dosar'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="stepTypes"
                className="m-2"
                options={this.state.workflowSectionStepTypes}
                value={this.state.selectedworkflowSectionStepType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleAutocompleteStepChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="stepTypes"
                    value={this.state.selectedworkflowSectionStepType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_STEP_STEP_TYPE_LABEL',
                      'Pas'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  closeDialog = async () => {
    this.setState({ isDialogOpen: false });
  };

  deleteWorkflowSectionStep = async () => {
    try {
      await this.workflowService.RemoveWorkflowStep(this.state.workflowSectionStepId!);
      await this.reloadWorkflowSectionSteps(this.state.selectedworkflowSectionType!.id);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleClose = (showForm: boolean) => {
    this.setState({ isEditDialogOpen: showForm }, async () => {
      await this.reloadWorkflowSectionSteps(this.state.selectedworkflowSectionType!.id);
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowSectionSteps}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <div className="text-center p-5">
            <h4 className="font-weight-bold mt-4">
              {this.translatorService.Tranlate(
                'DELETE_TEXT',
                'Sigur doriti sa stergeti aceasta inregistrare?'
              )}
            </h4>
            <div className="pt-4">
              <Button
                onClick={this.closeDialog}
                variant="outlined"
                color="secondary"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('CANCEL', 'Anuleaza')}
                </span>
              </Button>
              <Button
                onClick={() => {
                  this.setState(
                    { isDialogOpen: false },
                    async () => await this.deleteWorkflowSectionStep()
                  );
                }}
                color="primary"
                variant="contained"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('DELETE', 'Sterge')}
                </span>
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isEditDialogOpen}
          fullWidth={true}
          maxWidth="md"
          scroll="body"
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">{this.state.modalFormTitle}</Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleClose(false)}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <EditWorkflowStep
              {...this.props}
              workflowStepId={this.state.stepId}
              workflowStepChanged={this.handleClose}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowCaseStructureStepsTab as any));
