import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/carfix_newlogo.svg';
import projectLogoIcon from '../../assets/images/Brand_Mark.png';

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}
      >
        <Box className="header-logo-wrapper" title="Claim Box">
          <Link to="/DashboardDefault" className="header-logo-wrapper-link">
            <IconButton color="primary" size="medium" className="header-logo">
              {sidebarToggle ? (
                <img
                  className="app-header-logo-img"
                  alt="ClaimBox Logo"
                  src={projectLogoIcon}
                  style={{ height: '40px', width: '52px', boxSizing: 'border-box' }}
                />
              ) : (
                <img className="app-header-logo-img" alt="ClaimBox Logo" src={projectLogo} />
              )}
            </IconButton>
          </Link>
          {/* <Box className="header-logo-text">Carolina</Box> */}
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.themeOptions.sidebarToggle,
  sidebarHover: state.themeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
