import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationLevelType, OrganizationLevel } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, IconButton, Card, CardContent } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface IOrganizationHierarchyState {
  organizationLevels: OrganizationLevel[];
  isLoading: boolean;
  organizationLevelTypes: OrganizationLevelType[];
  selectedOrganizationLevelType: OrganizationLevelType | null;
  organizationId: number;
}

type IOrganizationHierarchyPros = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationHierarchy extends React.PureComponent<
  IOrganizationHierarchyPros,
  IOrganizationHierarchyState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationLevels: [],
    isLoading: false,
    organizationLevelTypes: [],
    selectedOrganizationLevelType: null,
    organizationId: 0
  } as IOrganizationHierarchyState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationLevels();
      }
    );
  }

  loadOrganizationLevels = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationLevel
    );

    const orgList = await this.organizationService.GetOrganizationLevels(hoId);
    const orgLevelTypes = await this.appReferentialService.Get(ref!.baseUrl);

    const filterLevels = this.filterLevelTypes(
      orgList,
      orgLevelTypes as any as OrganizationLevelType[]
    );

    this.setState({
      organizationLevels: orgList.sort(function (a, b) {
        return a.order - b.order;
      }),
      organizationLevelTypes: filterLevels,
      isLoading: false,
      organizationId: hoId
    });
  };

  filterLevelTypes = (orglevels: OrganizationLevel[], levelTypes: OrganizationLevelType[]) => {
    return levelTypes.filter(
      (item) =>
        orglevels.find((r) => r.organizationLevelTypeId === item.id) === undefined &&
        item.isActive === true
    ) as OrganizationLevelType[];
  };

  deleteOrgLevel = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationLevel(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadOrganizationLevels();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveUp = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.organizationLevels];
    const itemToMoveUpIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveUpIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveUpIndex].order === 1) {
      return;
    }

    const itemToMoveDownIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveUpIndex].order - 1
    );

    cloneArray[itemToMoveUpIndex] = {
      ...cloneArray[itemToMoveUpIndex],
      order: cloneArray[itemToMoveUpIndex].order - 1
    };
    cloneArray[itemToMoveDownIndex] = {
      ...cloneArray[itemToMoveDownIndex],
      order: cloneArray[itemToMoveDownIndex].order + 1
    };

    try {
      await this.organizationService.UpdateOrganizationLevel(cloneArray[itemToMoveUpIndex]);
      await this.organizationService.UpdateOrganizationLevel(cloneArray[itemToMoveDownIndex]);

      this.setState({
        organizationLevels: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveDown = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.organizationLevels];
    const itemToMoveIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveIndex].order === cloneArray.length) {
      return;
    }

    const itemToMoveToIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveIndex].order + 1
    );

    cloneArray[itemToMoveIndex] = {
      ...cloneArray[itemToMoveIndex],
      order: cloneArray[itemToMoveIndex].order + 1
    };
    cloneArray[itemToMoveToIndex] = {
      ...cloneArray[itemToMoveToIndex],
      order: cloneArray[itemToMoveToIndex].order - 1
    };

    try {
      await this.organizationService.UpdateOrganizationLevel(cloneArray[itemToMoveIndex]);
      await this.organizationService.UpdateOrganizationLevel(cloneArray[itemToMoveToIndex]);

      this.setState({
        organizationLevels: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'organizationLevelType',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_ORGANIZATION_NAME_HEADER',
          'Organizatie'
        ),
        options: {
          sort: false,
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'order',
        sortDirection: 'asc',
        label: this.translatorService.Tranlate('ORGANIZATION_HIERARCHY_ORDER_HEADER', 'Ord'),
        options: { sort: false }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          sortOrder: {
            name: 'order',
            direction: 'asc'
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Fragment>
                <IconButton color="inherit" size="small" onClick={(e) => this.moveUp(e, tableMeta)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => this.moveDown(e, tableMeta)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgLevel(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgLevel = async () => {
    try {
      await this.organizationService.AddOrganizationLevel({
        order: this.state.organizationLevels.length + 1,
        organizationLevelTypeId: this.state.selectedOrganizationLevelType!.id,
        organizationId: this.state.organizationId
      } as OrganizationLevel);

      this.setState(
        {
          isLoading: true,
          selectedOrganizationLevelType: null
        },
        () => {
          this.loadOrganizationLevels();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteChange = (newValue: OrganizationLevelType | null) => {
    this.setState({ selectedOrganizationLevelType: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgLevel}>
              <Autocomplete
                id="role"
                className="m-2"
                options={this.state.organizationLevelTypes}
                value={this.state.selectedOrganizationLevelType}
                onChange={(e: any, newValue: OrganizationLevelType | null) =>
                  this.handleAutocompleteChange(newValue)
                }
                getOptionLabel={(option: OrganizationLevelType) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationLevelType"
                    value={this.state.selectedOrganizationLevelType}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATION_LEVEL_TYPE_LABEL',
                      'Nivel Ierarhic'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
      // responsive: "stacked",
      // customToolbar: () => {
      //   return (
      //     <Tooltip title={this.translatorService.Tranlate("Add", "Adauga")}>
      //       <IconButton aria-label="add" color="primary" className="m-2" onClick={() => { this.props.history.push("/admin/users/new") }}>
      //         <AddCircleTwoToneIcon />
      //       </IconButton>
      //     </Tooltip>)
      // }
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationLevels}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationHierarchy as any));
