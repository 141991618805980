import * as React from 'react';

import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../store';
import { Case, CaseFilters, CaseList } from '../../interfaces/Case';
import { Organization } from '../../interfaces/Organization';
import { ScaleLoader } from 'react-spinners';
import { Box, Grid, ListItem } from '@material-ui/core';
import { withRouter } from 'react-router';
import moment from 'moment';
import { VehicleFilters, VehicleList } from '../../interfaces/Vehicle';
import * as R from 'ramda';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { RoleClaimsEnum } from '../../helpers/Constants';
import isNil from 'lodash/isNil';
import unionBy from 'lodash/unionBy';

interface Vehicle {
  id: number;
  plateNumber: string;
  name: string;
  vin: string;
  status?: string;
  firstRegistrationDate: string;
  cases: Case[];
}

export interface ExternalSearchCaseProps {
  handleSearchPopverClose: () => void;
  searchValue: string;
  searchId: string;
}
type SearchCaseProps = { appState: AppState } & ExternalSearchCaseProps & RouteComponentProps;

interface ISearchCaseState {
  vehicleList: Vehicle[];

  isLoading: boolean;
}

class SearchCase extends React.PureComponent<SearchCaseProps, ISearchCaseState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    vehicleList: [],
    isLoading: false
  } as ISearchCaseState;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public async componentDidMount() {}

  async componentDidUpdate(prevProps: SearchCaseProps) {
    if (this.props.searchId !== prevProps.searchId) {
      this.setState(
        {
          isLoading: true
        },
        this.loadCases
      );
    }
  }

  loadCases = async () => {
    const vehicles = await this.loadData();

    this.setState({
      isLoading: false,
      vehicleList: vehicles
    });
  };

  /**
   * Warning! dragons in here due to the API.
   * Case and Vehicle and kept in different databases and are accessed with different services.
   * Steps needed for searching are:
   * 1. Search by VIN, case id or registration number in Case DB => some cases are returned
   * 2. Search in Vehicle DB => some vehicles are returned
   * 3. Back to Case DB and grab all the cases associated with the vehicles found on step 2.
   * 4. In order to be safe, combine cases found on step 1 and step 3 and remove duplicates
   */
  loadData = async (): Promise<Vehicle[]> => {
    const vehicleEmptyList = { vehicles: [], total: 0, page: 0 } as VehicleList;

    // Step 1 and 2 from the description above
    const [cases, vehicleExtensionList] = await Promise.all([
      this.cbContext.caseService.GetCases({ text: this.props.searchValue.replace(/\s+/g, ''), countryCode: this.props.appState.appUser?.organization?.country?.code } as any as CaseFilters),
      this.cbContext.vehicleService.GetVehicles({
        text: this.props.searchValue.replace(/\s+/g, '')
      } as any as VehicleFilters)
    ]);

    const vehicleList: VehicleList = {
      vehicles: vehicleExtensionList.vehicles.map(({ vehicle }) => vehicle),
      page: vehicleExtensionList.page,
      total: vehicleExtensionList.total
    };

    const vehicles = this.props.appState.user!.profile.role.includes(RoleClaimsEnum.Vehicle)
      ? vehicleList
      : vehicleEmptyList;

    const vehicleIds = vehicles.vehicles.map(({ id }) => id);
    if (vehicleIds.length) {
      // Step 3 from the description above
      const allCasesFromVehicles = await this.cbContext.caseService.GetCases({
        vehicleIds
      } as any as CaseFilters);

      // Step 4 from the description above
      cases.cases = unionBy(cases.cases, allCasesFromVehicles.cases, 'id');
    }

    const vehicleIdsFromCases = Array.from(
      new Set(cases.cases.filter((c) => c.vehicleId !== null).map((item) => item.vehicleId!))
    );
    const vehicleIdsFromVehicles = Array.from(new Set(vehicleIds));

    const orphaneVehiclesIds = vehicleIdsFromVehicles.filter(
      (vid) => vehicleIdsFromCases.find((item) => item === vid) === undefined
    );
    const orphaneCasesVehIds = vehicleIdsFromCases.filter(
      (vid) => vehicleIdsFromVehicles.find((item) => item === vid) === undefined
    );

    // We also need to check for vehicles which are not associated with a case => orphan vehicles
    // and cases which are not associated with a vehicle => orphan cases
    const [orphaneCases, orphaneVehicles] = await Promise.all([
      orphaneVehiclesIds.length === 0
        ? ({ cases: [], page: 0, total: 0 } as CaseList)
        : this.cbContext.caseService.GetCases({
            vehicleIds: orphaneVehiclesIds
          } as any as CaseFilters),
      this.cbContext.vehicleService.GetVehicleList(orphaneCasesVehIds)
    ]);

    cases.cases.push(...orphaneCases.cases);
    vehicles.vehicles.push(...orphaneVehicles);

    let usersAssignedLIst: any[] = [];
    let orgOwners: Organization[] = [];

    if (cases.cases.length) {
      [usersAssignedLIst, orgOwners] = await Promise.all([
        this.cbContext.appUserService.GetUsersInfo(
          Array.from(new Set(cases.cases.map((item) => item.caseStatus.assignedToId)))
        ),
        this.cbContext.organizationService.GetUserOrganizationsByIds(
          Array.from(new Set(cases.cases.map((item) => item.organizationOwnerId)))
        )
      ]);
    }
    cases.cases.forEach((cd) => {
      const userAssignedTo = usersAssignedLIst.find(
        (item) => item.id === cd.caseStatus.assignedToId
      );
      const organizationOwner = orgOwners.find((item) => item.id === cd.organizationOwnerId);
      const vehicle = vehicles.vehicles.find((item) => item.id === cd.vehicleId);

      cd.caseStatus.assignedTo = R.isNil(userAssignedTo) ? null : userAssignedTo;
      cd.organizationOwner = R.isNil(organizationOwner) ? null : organizationOwner;
      cd.vehicle = R.isNil(vehicle) ? null : vehicle;
    });

    const vehiclesInStock = vehicles.vehicles.map((item) => {
      return {
        id: item.id,
        vin: item.vin,
        plateNumber: item.plateNumber,
        name: item.make && item.model ? `${item.make?.displayName} ${item.model?.displayName}` : '',
        status: item.currentStatus?.status?.displayName,
        firstRegistrationDate: item.firstRegistrationDate,
        cases: cases.cases.filter((i) => i.vehicle?.id === item.id)
      };
    });

    const caseVehicles = cases.cases
      .filter((i) => !i.vehicle && i.caseVehicle)
      .map((i) => i.caseVehicle);

    const vehiclesNotInStock = caseVehicles.map((item) => {
      return {
        id: -1,
        vin: item.vin || '',
        plateNumber: item.plateNumber || '',
        name: item.model ? `${item.model?.brand} ${item.model?.name}` : '',
        status: item.currentStatus?.status?.displayName,
        firstRegistrationDate: item.registrationDate ? item.registrationDate : '',
        cases: cases.cases.filter((i) => i.caseVehicle?.id === item.id)
      };
    });

    const caseWithNoVehicles = cases.cases.filter(
      (i) => isNil(i.caseVehicle) && isNil(i.vehicleId)
    );

    const emptyVehicles = [];
    if (caseWithNoVehicles.length)
      emptyVehicles.push({
        id: -1,
        vin: ' - ',
        plateNumber: ' - ',
        name: ' - ',
        status: undefined,
        firstRegistrationDate: '',
        cases: caseWithNoVehicles
      });

    return [...vehiclesInStock, ...vehiclesNotInStock, ...emptyVehicles];
  };

  onCaseClick = (id: number) => {
    if (!R.isNil(this.props.handleSearchPopverClose)) {
      this.props.handleSearchPopverClose();
    }

    const currentPath = this.props.history.location.pathname.toLowerCase();
    this.props.history.push('/cases/' + id);

    if (currentPath.indexOf('/cases/') !== -1) {
      this.props.history.go(0);
    }
  };

  onVehicleClick = (id: number) => {
    if (!id || id < 0) {
      return;
    }

    if (!R.isNil(this.props.handleSearchPopverClose)) {
      this.props.handleSearchPopverClose();
    }

    const currentPath = this.props.history.location.pathname.toLowerCase();
    this.props.history.push('/vehicle/' + id);

    if (currentPath.indexOf('/vehicle/') !== -1) {
      this.props.history.go(0);
    }
  };

  renderVehicles = (vehicles: Vehicle[]) => {
    return vehicles.map((item, index) => (
      <div key={index}>
        <ListItem
          button
          style={{ backgroundColor: '#5383ff' }}
          divider
          key={index}
          onClick={(e) => this.onVehicleClick(item.id)}
        >
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <div className="font-size-sm text-white">{item.plateNumber}</div>
            </Grid>
            <Grid item xs={3}>
              <div>{item.status}</div>
              <div className="font-size-sm text-white">{item.vin}</div>
            </Grid>
            <Grid item xs={4}>
              <div className="font-size-sm text-white">{item.name}</div>
            </Grid>
            <Grid item xs={3}>
              <div> </div>
              <div className="font-size-sm text-white">
                {item.firstRegistrationDate
                  ? moment
                      .utc(item.firstRegistrationDate)
                      .local()
                      .toDate()
                      .toLocaleDateString(this.props.appState.language)
                  : ' - '}
              </div>
            </Grid>
          </Grid>
        </ListItem>
        {this.renderCases(item.cases)}
      </div>
    ));
  };

  renderCases = (cases: Case[]) => {
    return cases.map((item, index) => (
      <ListItem button divider key={index} onClick={(e) => this.onCaseClick(item.id)}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <div>{item.id}</div>
            <div className="font-size-sm text-black-50">
              {VehicleHelper.GetPlateNumber(item, item.vehicle)}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>{item.caseType!.displayName}</div>
            <div className="font-size-sm text-black-50">
              {R.isNil(item.caseEvent) ? '' : item.caseEvent.caseNumber}
            </div>
            <div className="font-size-sm text-black-50">
              {VehicleHelper.GetVIN(item, item.vehicle)}
            </div>
          </Grid>

          <Grid item xs={4}>
            <div>{item.organizationOwner!.name}</div>
            {/* <div className = "font-size-sm text-black-50">{item.createdByUser!.userName}</div> */}
            <div className="font-size-sm text-black-50">{item.caseStatus.assignedTo!.userName}</div>
          </Grid>
          <Grid item xs={3}>
            <div>{item.caseStatus!.caseStatus!.displayName}</div>
            {/* <div className = "font-size-sm text-black-50">{moment.utc(item.dateCreation).local().toDate().toLocaleDateString(this.props.appState.language)}</div> */}
            <div className="font-size-sm text-black-50">
              {moment
                .utc(item.caseStatus!.date)
                .local()
                .toDate()
                .toLocaleDateString(this.props.appState.language)}
            </div>
          </Grid>
        </Grid>
      </ListItem>
    ));
  };

  public render() {
    this.cbContext = this.context as AppContext;

    return (
      <React.Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>

        {!this.state.isLoading && this.state.vehicleList.length != 0 ? (
          <Box maxHeight={300} overflow="auto">
            {this.renderVehicles(this.state.vehicleList)}
          </Box>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withRouter(SearchCase));
